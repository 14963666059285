import {createReducer} from 'react-use';
import {createLogger} from 'redux-logger';
import useSagaReducer from './use-saga-reducer';


const reducerLogger = createLogger({
    level: 'debug',
    duration: true
});
const useLoggingReducer = createReducer(reducerLogger);

export function useLoggingSagaReducer(saga, reducer, initializerArg, initializer, runSagaOptions) {
    const [state, dispatch] = useSagaReducer(saga, reducer, initializerArg, initializer, runSagaOptions, useLoggingReducer);
    return [state, dispatch];
}