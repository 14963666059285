import classNames from 'classnames';
import _ from 'lodash';
import {isValidElement, memo} from 'react';
import {Area, Curve, Layer} from 'recharts';
import {pathParse, serializePath} from 'svg-path-parse';
import {errorHandler} from '../lib/errors';

// Monkey-copied from node_modules/recharts/src/util/types.ts
const SVGContainerPropKeys = ['viewBox', 'children'];
const SVGElementPropKeys = [
    'aria-activedescendant',
    'aria-atomic',
    'aria-autocomplete',
    'aria-busy',
    'aria-checked',
    'aria-colcount',
    'aria-colindex',
    'aria-colspan',
    'aria-controls',
    'aria-current',
    'aria-describedby',
    'aria-details',
    'aria-disabled',
    'aria-errormessage',
    'aria-expanded',
    'aria-flowto',
    'aria-haspopup',
    'aria-hidden',
    'aria-invalid',
    'aria-keyshortcuts',
    'aria-label',
    'aria-labelledby',
    'aria-level',
    'aria-live',
    'aria-modal',
    'aria-multiline',
    'aria-multiselectable',
    'aria-orientation',
    'aria-owns',
    'aria-placeholder',
    'aria-posinset',
    'aria-pressed',
    'aria-readonly',
    'aria-relevant',
    'aria-required',
    'aria-roledescription',
    'aria-rowcount',
    'aria-rowindex',
    'aria-rowspan',
    'aria-selected',
    'aria-setsize',
    'aria-sort',
    'aria-valuemax',
    'aria-valuemin',
    'aria-valuenow',
    'aria-valuetext',
    'className',
    'color',
    'height',
    'id',
    'lang',
    'max',
    'media',
    'method',
    'min',
    'name',
    'style',
    'target',
    'type',
    'width',
    'role',
    'tabIndex',
    'accentHeight',
    'accumulate',
    'additive',
    'alignmentBaseline',
    'allowReorder',
    'alphabetic',
    'amplitude',
    'arabicForm',
    'ascent',
    'attributeName',
    'attributeType',
    'autoReverse',
    'azimuth',
    'baseFrequency',
    'baselineShift',
    'baseProfile',
    'bbox',
    'begin',
    'bias',
    'by',
    'calcMode',
    'capHeight',
    'clip',
    'clipPath',
    'clipPathUnits',
    'clipRule',
    'colorInterpolation',
    'colorInterpolationFilters',
    'colorProfile',
    'colorRendering',
    'contentScriptType',
    'contentStyleType',
    'cursor',
    'cx',
    'cy',
    'd',
    'decelerate',
    'descent',
    'diffuseConstant',
    'direction',
    'display',
    'divisor',
    'dominantBaseline',
    'dur',
    'dx',
    'dy',
    'edgeMode',
    'elevation',
    'enableBackground',
    'end',
    'exponent',
    'externalResourcesRequired',
    'fill',
    'fillOpacity',
    'fillRule',
    'filter',
    'filterRes',
    'filterUnits',
    'floodColor',
    'floodOpacity',
    'focusable',
    'fontFamily',
    'fontSize',
    'fontSizeAdjust',
    'fontStretch',
    'fontStyle',
    'fontVariant',
    'fontWeight',
    'format',
    'from',
    'fx',
    'fy',
    'g1',
    'g2',
    'glyphName',
    'glyphOrientationHorizontal',
    'glyphOrientationVertical',
    'glyphRef',
    'gradientTransform',
    'gradientUnits',
    'hanging',
    'horizAdvX',
    'horizOriginX',
    'href',
    'ideographic',
    'imageRendering',
    'in2',
    'in',
    'intercept',
    'k1',
    'k2',
    'k3',
    'k4',
    'k',
    'kernelMatrix',
    'kernelUnitLength',
    'kerning',
    'keyPoints',
    'keySplines',
    'keyTimes',
    'lengthAdjust',
    'letterSpacing',
    'lightingColor',
    'limitingConeAngle',
    'local',
    'markerEnd',
    'markerHeight',
    'markerMid',
    'markerStart',
    'markerUnits',
    'markerWidth',
    'mask',
    'maskContentUnits',
    'maskUnits',
    'mathematical',
    'mode',
    'numOctaves',
    'offset',
    'opacity',
    'operator',
    'order',
    'orient',
    'orientation',
    'origin',
    'overflow',
    'overlinePosition',
    'overlineThickness',
    'paintOrder',
    'panose1',
    'pathLength',
    'patternContentUnits',
    'patternTransform',
    'patternUnits',
    'pointerEvents',
    'points',
    'pointsAtX',
    'pointsAtY',
    'pointsAtZ',
    'preserveAlpha',
    'preserveAspectRatio',
    'primitiveUnits',
    'r',
    'radius',
    'refX',
    'refY',
    'renderingIntent',
    'repeatCount',
    'repeatDur',
    'requiredExtensions',
    'requiredFeatures',
    'restart',
    'result',
    'rotate',
    'rx',
    'ry',
    'seed',
    'shapeRendering',
    'slope',
    'spacing',
    'specularConstant',
    'specularExponent',
    'speed',
    'spreadMethod',
    'startOffset',
    'stdDeviation',
    'stemh',
    'stemv',
    'stitchTiles',
    'stopColor',
    'stopOpacity',
    'strikethroughPosition',
    'strikethroughThickness',
    'string',
    'stroke',
    'strokeDasharray',
    'strokeDashoffset',
    'strokeLinecap',
    'strokeLinejoin',
    'strokeMiterlimit',
    'strokeOpacity',
    'strokeWidth',
    'surfaceScale',
    'systemLanguage',
    'tableValues',
    'targetX',
    'targetY',
    'textAnchor',
    'textDecoration',
    'textLength',
    'textRendering',
    'to',
    'transform',
    'u1',
    'u2',
    'underlinePosition',
    'underlineThickness',
    'unicode',
    'unicodeBidi',
    'unicodeRange',
    'unitsPerEm',
    'vAlphabetic',
    'values',
    'vectorEffect',
    'version',
    'vertAdvY',
    'vertOriginX',
    'vertOriginY',
    'vHanging',
    'vIdeographic',
    'viewTarget',
    'visibility',
    'vMathematical',
    'widths',
    'wordSpacing',
    'writingMode',
    'x1',
    'x2',
    'x',
    'xChannelSelector',
    'xHeight',
    'xlinkActuate',
    'xlinkArcrole',
    'xlinkHref',
    'xlinkRole',
    'xlinkShow',
    'xlinkTitle',
    'xlinkType',
    'xmlBase',
    'xmlLang',
    'xmlns',
    'xmlnsXlink',
    'xmlSpace',
    'y1',
    'y2',
    'y',
    'yChannelSelector',
    'z',
    'zoomAndPan',
    'ref',
    'key',
    'angle'
];

const EventKeys = [
    'dangerouslySetInnerHTML',
    'onCopy',
    'onCopyCapture',
    'onCut',
    'onCutCapture',
    'onPaste',
    'onPasteCapture',
    'onCompositionEnd',
    'onCompositionEndCapture',
    'onCompositionStart',
    'onCompositionStartCapture',
    'onCompositionUpdate',
    'onCompositionUpdateCapture',
    'onFocus',
    'onFocusCapture',
    'onBlur',
    'onBlurCapture',
    'onChange',
    'onChangeCapture',
    'onBeforeInput',
    'onBeforeInputCapture',
    'onInput',
    'onInputCapture',
    'onReset',
    'onResetCapture',
    'onSubmit',
    'onSubmitCapture',
    'onInvalid',
    'onInvalidCapture',
    'onLoad',
    'onLoadCapture',
    'onError',
    'onErrorCapture',
    'onKeyDown',
    'onKeyDownCapture',
    'onKeyPress',
    'onKeyPressCapture',
    'onKeyUp',
    'onKeyUpCapture',
    'onAbort',
    'onAbortCapture',
    'onCanPlay',
    'onCanPlayCapture',
    'onCanPlayThrough',
    'onCanPlayThroughCapture',
    'onDurationChange',
    'onDurationChangeCapture',
    'onEmptied',
    'onEmptiedCapture',
    'onEncrypted',
    'onEncryptedCapture',
    'onEnded',
    'onEndedCapture',
    'onLoadedData',
    'onLoadedDataCapture',
    'onLoadedMetadata',
    'onLoadedMetadataCapture',
    'onLoadStart',
    'onLoadStartCapture',
    'onPause',
    'onPauseCapture',
    'onPlay',
    'onPlayCapture',
    'onPlaying',
    'onPlayingCapture',
    'onProgress',
    'onProgressCapture',
    'onRateChange',
    'onRateChangeCapture',
    'onSeeked',
    'onSeekedCapture',
    'onSeeking',
    'onSeekingCapture',
    'onStalled',
    'onStalledCapture',
    'onSuspend',
    'onSuspendCapture',
    'onTimeUpdate',
    'onTimeUpdateCapture',
    'onVolumeChange',
    'onVolumeChangeCapture',
    'onWaiting',
    'onWaitingCapture',
    'onAuxClick',
    'onAuxClickCapture',
    'onClick',
    'onClickCapture',
    'onContextMenu',
    'onContextMenuCapture',
    'onDoubleClick',
    'onDoubleClickCapture',
    'onDrag',
    'onDragCapture',
    'onDragEnd',
    'onDragEndCapture',
    'onDragEnter',
    'onDragEnterCapture',
    'onDragExit',
    'onDragExitCapture',
    'onDragLeave',
    'onDragLeaveCapture',
    'onDragOver',
    'onDragOverCapture',
    'onDragStart',
    'onDragStartCapture',
    'onDrop',
    'onDropCapture',
    'onMouseDown',
    'onMouseDownCapture',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseMoveCapture',
    'onMouseOut',
    'onMouseOutCapture',
    'onMouseOver',
    'onMouseOverCapture',
    'onMouseUp',
    'onMouseUpCapture',
    'onSelect',
    'onSelectCapture',
    'onTouchCancel',
    'onTouchCancelCapture',
    'onTouchEnd',
    'onTouchEndCapture',
    'onTouchMove',
    'onTouchMoveCapture',
    'onTouchStart',
    'onTouchStartCapture',
    'onPointerDown',
    'onPointerDownCapture',
    'onPointerMove',
    'onPointerMoveCapture',
    'onPointerUp',
    'onPointerUpCapture',
    'onPointerCancel',
    'onPointerCancelCapture',
    'onPointerEnter',
    'onPointerEnterCapture',
    'onPointerLeave',
    'onPointerLeaveCapture',
    'onPointerOver',
    'onPointerOverCapture',
    'onPointerOut',
    'onPointerOutCapture',
    'onGotPointerCapture',
    'onGotPointerCaptureCapture',
    'onLostPointerCapture',
    'onLostPointerCaptureCapture',
    'onScroll',
    'onScrollCapture',
    'onWheel',
    'onWheelCapture',
    'onAnimationStart',
    'onAnimationStartCapture',
    'onAnimationEnd',
    'onAnimationEndCapture',
    'onAnimationIteration',
    'onAnimationIterationCapture',
    'onTransitionEnd',
    'onTransitionEndCapture'
];

function adaptEventHandlers(props, newHandler) {
    if (!props || typeof props === 'function' || typeof props === 'boolean') {
        return null;
    }

    let inputProps = props;

    if (isValidElement(props)) {
        inputProps = props.props;
    }

    if (!_.isObject(inputProps)) {
        return null;
    }

    const out = {};

    Object.keys(inputProps).forEach(key => {
        if (EventKeys.includes(key)) {
            out[key] = newHandler || ((e) => inputProps[key](inputProps, e));
        }
    });

    return out;
}

function filterProps(props, includeEvents, isSvg) {
    if (!props || typeof props === 'function' || typeof props === 'boolean') {
        return null;
    }

    let inputProps = props;

    if (isValidElement(props)) {
        inputProps = props.props;
    }

    if (!_.isObject(inputProps)) {
        return null;
    }

    const out = {};

    Object.keys(inputProps).forEach(key => {
        // viewBox only exist in <svg />
        if (
            SVGElementPropKeys.includes(key) ||
            (isSvg && SVGContainerPropKeys.includes(key)) ||
            (includeEvents && EventKeys.includes(key))
        ) {
            out[key] = inputProps[key];
        }
    });

    return out;
}

/**
 * <Curve> allowing to set color separately for each segment.
 */
class ColorSegmentCurveClass extends Curve {
    render() {
        const {className, points, colors, pathRef} = this.props;
        if (_.size(points) < 5 || _.isEmpty(colors))
            return null;

        // Construct separate SVG paths for each colored segment. For size=241:
        // M(last(0)), 1, L(last(238)), 239, Z
        // M(last(1)), 2, L(last(237)), 238, Z
        // ...
        // M(last(118)), 119, L(last(120)), 121, Z
        const {segments, type} = pathParse(this.getPath()).getSegments(),
            size = _.size(segments),
            half = _.floor(size / 2);
        const arrays = [_.slice(segments, 0, half - 1), _.slice(segments, 1, half), _.reverse(_.slice(segments, half, size - 2)), _.reverse(_.slice(segments, half + 1, size - 1))],
            cropTo = _.min([_.size(arrays[0]), _.size(colors)]),
            zipped = _.zip(_.take(colors, cropTo), ..._.map(arrays, (a) => _.take(a, cropTo)));

        function colorSegmentPathGenerator([color, first, second, third, fourth], idx) {
            try {
                if (!third || !fourth) return null; // Handle against weird things like ["L", 986, 170] in the middle.
                return [color, serializePath({type, segments: [['M', ..._.takeRight(first, 2)], second, ['L', ..._.takeRight(third, 2)], fourth, ['Z']]})]
            } catch (ex) {
                const input = JSON.stringify({color, first, second, third, fourth}),
                    fullInput = JSON.stringify(segments);
                ex.message = `ColorSegmentCurveClass: error generating path "${ex.message}" for segment #${idx} ${input} in ${fullInput}`;
                errorHandler.report(ex);
                return null;
            }
        }
        const paths = _.filter(_.map(zipped, colorSegmentPathGenerator), _.negate(_.isNil));

        // Apply strokeWidth=1 as workaround for thin vertical splits between paths. A simplification but works for opacity=1.
        return <>
            {_.map(paths, ([color, d], key) =>
                (<path
                    {...filterProps(this.props)}
                    {...adaptEventHandlers(this.props)}
                    className={classNames('recharts-curve', className)}
                    fill={color} stroke={color} strokeWidth={1}
                    d={d}
                    key={key}
                    ref={pathRef}
                />))
            }
        </>;
    }
}

const ColorSegmentCurve = memo(function ColorSegmentCurveFunc(props) {
    return <ColorSegmentCurveClass {...props} />;
}, function areCurvePropsEqual(prev, next) {
    // Avoid lenghty redraw of curve when points are the same.
    // Workaround for AreaChart recalculating points structure every time, even though data did not change.
    const [{points: prevPoints, ...prevProps}, {points: nextPoints, ...nextProps}] = [prev, next];
    return _.isEqual(prevPoints, nextPoints) && _.isEqual(prevProps, nextProps);
});

const ColorAreaLayer = memo(function ColorAreaLayers_({clipPath, fillCurveProps, points, colors, connectNulls, type, baseLine, layout, stroke, strokeCurveProps, isRange}) {
    return <Layer clipPath={clipPath}>
        <ColorSegmentCurve
            {...fillCurveProps}
            points={points}
            colors={colors}
            connectNulls={connectNulls}
            type={type}
            baseLine={baseLine}
            layout={layout}
            stroke="none"
            className="recharts-area-area"
        />
        {stroke !== 'none' && (
            <Curve
                {...strokeCurveProps}
                className="recharts-area-curve"
                layout={layout}
                type={type}
                connectNulls={connectNulls}
                fill="none"
                points={points}
            />
        )}
        {stroke !== 'none' && isRange && (
            <Curve
                {...strokeCurveProps}
                className="recharts-area-curve"
                layout={layout}
                type={type}
                connectNulls={connectNulls}
                fill="none"
                points={baseLine}
            />
        )}
    </Layer>;
}, function areCurveLayerPropsEqual(prev, next) {
    // Avoid lenghty redraw of curve when points are the same.
    // Workaround for AreaChart recalculating points structure every time, even though data did not change.
    const [{points: prevPoints, ...prevProps}, {points: nextPoints, ...nextProps}] = [prev, next];
    return _.isEqual(prevPoints, nextPoints) && _.isEqual(prevProps, nextProps);
});

/**
 * <Area> with additional colorKey attribute, which will specify color for each area segment (between two points).
 * Makes sense for X-based areas only
 */
export class ColorSegmentArea extends Area {
    renderAreaStatically(points, baseLine, needClip, clipPathId) {
        const {layout, type, stroke, connectNulls, isRange, ref, colorKey, ...others} = this.props;

        const colors = _.map(points, `payload.${colorKey}`),
            clipPath = needClip ? `url(#clipPath-${clipPathId})` : null,
            fillCurveProps = filterProps(others, true),
            strokeCurveProps = filterProps(this.props);

        return <ColorAreaLayer {...{clipPath, fillCurveProps, isRange, points, colors, connectNulls, type, baseLine, layout, stroke, strokeCurveProps}} />;
    }
}


