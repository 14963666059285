import {getCoord} from '@turf/turf';
import _ from 'lodash';
import RBush from 'rbush';
import {useMemo} from 'react';
import {useViewState} from '../components/ViewStateContext';
import {featureCoords} from './routing';
import {useMapLibre} from './useMapLibre';


export const TRACK_POI_SIZE = 30,
    RBUSH_RADIUS = TRACK_POI_SIZE / 2,
    POI_PRIORITY_LOWEST = 1000;

function rbushPOIsReducerFactory(mapLibre) {
    const poiToMapPoint = ({feature}) => mapLibre.project(getCoord(feature));
    // RBush is an efficient spatial index for finding colliding points.
    // Using it to clean overlapping POIs.
    class PointsRBush extends RBush {
        toBBox(featurePOI) {
            const {x, y} = poiToMapPoint(featurePOI),
                // Display least interesting features (1000) only if really nothing interesting around.
                radius = (featurePOI?.priority ?? POI_PRIORITY_LOWEST) < POI_PRIORITY_LOWEST ? RBUSH_RADIUS * 1.2 : (RBUSH_RADIUS * 2.5);
            return {minX: x - radius, minY: y - radius, maxX: x + radius, maxY: y + radius};
        }

        compareMinX(a, b) {
            return poiToMapPoint(a).x - poiToMapPoint(b).x;
        }

        compareMinY(a, b) {
            return poiToMapPoint(a).y - poiToMapPoint(b).y;
        }
    }

    function reducePOIs(featurePOIs) {
        const tree = new PointsRBush();
        _.each(featurePOIs, (featurePOI) => {
            if (featureCoords(featurePOI.feature) && !tree.collides(tree.toBBox(featurePOI))) {
                tree.insert(featurePOI);
            }
        });
        return tree.all();  // Includes the POIs out-of-canvas
    }

    return reducePOIs;
}

export function useRBushPOIsReducer() {
    const {mapLibre} = useMapLibre(),
        {zoom} = useViewState(),
        reducePOIs = useMemo(
            () => mapLibre?.project ? rbushPOIsReducerFactory(mapLibre) : _.stubArray,
            // RBush depends on mapLibre.project to determine overlap buffer, which depends on zoom, so need to invalidate on every zoom change.
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [mapLibre, zoom]);
    return reducePOIs;
}