import {fetchSignInMethodsForEmail} from 'firebase/auth';
import _ from 'lodash';
import {useState} from 'react';
import {Alert, Button, Modal} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import {FaLightbulb} from 'react-icons/fa';
import {auth} from '../../lib/firebase';
import {EmailInput} from './EmailInput';


export function EmailRequired({show, showModal, provider, why, onSuccess = null, onError = null}) {
    const [email, setEmail] = useState('');
    const [invalidEmailFeedback, setInvalidEmailFeedback] = useState('');

    function onEmailChange({target: {value}}) {
        setInvalidEmailFeedback('');
        setEmail(value);
    }

    function showSignIn() {
        const leftButton = <Button variant="link" size="sm" onClick={() => showModal('EmailRequired')}>&lt;&lt; Sign up with other email</Button>;
        showModal('SignIn', {initialMode: 'email', initialEmail: email, leftButton});
    }

    async function submitEmail() {
        const methods = await fetchSignInMethodsForEmail(auth, email);
        if (!_.isEmpty(methods)) {
            const feedback = <span>
                <Trans>You have already signed up without <span className="text-capitalize">{{provider}}</span>.
                <Button size="sm" variant="link" className="align-baseline p-0" onClick={showSignIn}>Sign in with password</Button> to join the accounts or use other e-mail to create a new account.</Trans>
            </span>;
            setInvalidEmailFeedback(feedback);
            return;
        }
        showModal(null);
        onSuccess?.(email);
    }

    return <Modal backdrop={true} show={show} onHide={() => showModal(false)} size="lg" centered animation={false}>
        <Modal.Header closeButton={true}>
            <Modal.Title>{why}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Alert variant="info">
                <Trans><span className="text-capitalize">{{provider}}</span> has not provided your e-mail address being your login to Tarmacs. Please type it to complete your sign up.</Trans>
                <br/><small><FaLightbulb/> <Trans>Your e-mail is necessary to send you routes, notifications and provide you with fallback sign-in method.</Trans></small>
            </Alert>
            <EmailInput {...{email, onEmailChange, invalidEmailFeedback}} />
        </Modal.Body>
        <Modal.Footer className="justify-content-right">
            <Button variant="primary" className="me-0" onClick={submitEmail}><Trans>Submit</Trans></Button>
        </Modal.Footer>
    </Modal>;
}
