import styled from '@emotion/styled';
import classNames from 'classnames';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {Form, Stack} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import {FaArrowDown, FaArrowUp} from 'react-icons/fa';
import {stateSetter} from '../lib/util';


function getPoint(points, value, descending) {
    const predicate = descending ?
        ([v]) => v <= value :
        ([v]) => v >= value;
    return _.findIndex(points, predicate);
}

function DiscreteRangeSliderControl({className, descending, parseValue, points, setValue, tooltipLabel, tooltipPlacement, value, ...props}) {
    const initial = getPoint(points, value, descending),
        [point, setPoint] = useState(initial),
        maxVal = _.size(points) - 1, // Workaround for tooltip displayed when hovering outside of the slider.
        [tooltip, setTooltip] = useState("off");

    const getLabel = (point) => points?.[point]?.[1],
        convertValue = (point) => points?.[point]?.[0];

    useEffect(() => {
        setPoint(getPoint(points, value, descending));
    }, [descending, points, setPoint, value]);

    return <RangeSlider min={0} max={maxVal} step={1} value={point}
                        tooltip={tooltip} tooltipLabel={getLabel} tooltipPlacement={tooltipPlacement}
                        onMouseOver={() => setTooltip("on")}
                        onMouseOut={() => setTooltip("off")}
                        onChange={stateSetter(setPoint, parseValue)}
                        onAfterChange={stateSetter(setValue, convertValue)}
                        {...props} />;
}

function ContinuousRangeSlider({value, setValue, parseValue, ...props}) {
    const [curValue, setCurValue] = useState(value);
    return <RangeSlider {...props} value={curValue}
        onChange={stateSetter(setCurValue, parseValue)}
        onAfterChange={() => setValue(curValue)}
    />
}

/**
 * Range slider with predefined, discrete points.
 */
export function StyledRangeSlider({control: RangeSlider = ContinuousRangeSlider, value, setValue, parseValue = parseInt, label, labelClassName, icons, tooltipLabel, tooltipPlacement, className, ...props}) {
    const [LeftIcon, RightIcon] = icons ?? [FaArrowDown, FaArrowUp];

    return <Stack className={classNames("mx-0", className)} style={{marginBottom: '-6px'}}>
        <div className={classNames("pb-0 my-0 d-flex justify-content-center", labelClassName)} ><Form.Label className="pb-0 my-0">{label}</Form.Label></div>
        <div className="mb-0 ms-1 me-1 align-self-center align-items-center d-flex flex-nowrap justify-content-center" style={{marginTop: '-12px'}}>
            <div className="pe-2"><LeftIcon size="1.5em"/></div>
            <RangeSlider {...{value, setValue, tooltipLabel, tooltipPlacement, parseValue, ...props}} className={classNames("flex-grow-1", 'flex')} />
            <div className="ps-2"><RightIcon size="1.5em"/></div>
        </div>
    </Stack>;
}

const StyledRangeSliderLongTooltip = styled(StyledRangeSlider)`
    .range-slider__tooltip__label {
        max-width: 30em !important;
    }
`;

export function DiscreteRangeSlider({value, setValue, points, label, icons, tooltipPlacement, children, ...props}) {
    return <StyledRangeSliderLongTooltip control={DiscreteRangeSliderControl} {...{value, setValue, points, label, icons, tooltipPlacement, ...props}}/>
}