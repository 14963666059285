import _ from 'lodash';
import {useContext, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useLocation} from 'react-router-dom';
import {useReadLocalStorage} from 'usehooks-ts';
import {FirebaseAuth} from './firebase';


const BLACKLISTED_LOCATIONS = [
    '/shaper',
    '/thumbnailer'
];

const isBlacklisted = (pathname) => _.some(BLACKLISTED_LOCATIONS, (loc) => pathname.startsWith(loc));

export function gaInit() {
    if (isBlacklisted(window.location.pathname)) {
        console.log('Blacklisted for GA4. Disabling.');
        window[`ga-disable-G-G2Z0SEVV83`] = true;
    }
    ReactGA.initialize('G-G2Z0SEVV83', {debug: process.env.NODE_ENV !== 'production'});
}

export function ga(event, variables) {
    ReactGA.event(event, variables);
}

export function onGAEvent(handler, event, variables) {
    return (...args) => {
        ga(event, variables);
        return handler(...args);
    };
}

export function GAPageViewTracker() {
    const location = useLocation(),
        {user} = useContext(FirebaseAuth),
        ga4Enabled = useReadLocalStorage('ga4Enabled');

    useEffect(() => {
        const userDimensions = user?.uid ? {userId: user.uid, dimension1: user.uid, dimension2: user.uid} : {};
        const isDebug = ga4Enabled ?? ((process.env.NODE_ENV !== 'production') || _.startsWith(user?.email ?? '', 'alek.kowalczyk')),
            debugDimensions = isDebug ? {debug_mode: true} : {};
        ReactGA.set({page: location.pathname, ...userDimensions, ...debugDimensions});
        ReactGA.send({hitType: 'pageview', ...userDimensions, ...debugDimensions});
    }, [location.pathname, user?.email, user?.uid, ga4Enabled]);

    return null;
}

