import _ from 'lodash';
import React from 'react';
import {Button, Image, Stack} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {FaUserPlus} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import Anonymous from '../images/anonymous.png';
import {followConverter} from './converters';
import {useFirebaseDocData, useFirebaseDocDeletionInvalidate, useFirebaseDocMutationInvalidate, useUserData} from './db';
import {showSuccess} from './toasts';


function ToastContent({photo_url, children}) {
    return <Stack direction="horizontal" gap={2}>
        <UserAvatar photo_url={photo_url} size={40}/>
        <div><small>{children}</small></div>
    </Stack>;
}

const FOLLOW_ID_SEPARATOR = '..';

function invalidationPredicate(query, defaultPredicate) {
    return defaultPredicate(query) || _.isEqual(_.take(query?.queryKey, 3), ['feed', 'routes', 'indices']);
}

export function useFollow(actorUid, followedUid, onFollowSuccess) {
    const {t} = useTranslation(),
        {data: followedData} = useUserData(followedUid);

    const {display_name = '', photo_url} = followedData ?? {},
        followId = actorUid && followedUid && actorUid !== followedUid ? `${actorUid}${FOLLOW_ID_SEPARATOR}${followedUid}` : null,
        followOptions = {
            onSuccess: async (data, variables, context) => {
                const children = <ToastContent photo_url={photo_url}>
                    <Trans>We will let you know when {{display_name}} do something interesting.<br/>
                        Also you might want to <Link to={`/routes/view/user/${followedUid}`}>view his routes</Link></Trans>
                </ToastContent>;
                showSuccess(t("Followed user: {{display_name}}!", {display_name}), null, children);
                onFollowSuccess?.(data, variables, context);
            }
        }, unfollowOptions = {
            onSuccess: async (data, variables, context) => {
                const children = <ToastContent photo_url={photo_url}>
                    <Trans>Did you unfollow by mistake? </Trans><Button size="sm" variant="link" onClick={() => follow()}><FaUserPlus/>&nbsp;<Trans>Follow the user again</Trans></Button>
                </ToastContent>;
                showSuccess(t("Unfollowed user: {{display_name}}.", {display_name}), null, children);
            }
        };

    const
        query = useFirebaseDocData(['follows', followId]),
        mutation = useFirebaseDocMutationInvalidate(['follows', followId], followConverter(actorUid, followedUid), null, followOptions, invalidationPredicate),
        deletion = useFirebaseDocDeletionInvalidate(['follows', followId], unfollowOptions, invalidationPredicate),
        [{data: following}, {mutate: follow}, {mutate: unfollow}] = [query, mutation, deletion];
    return {following, follow, unfollow, query, mutation, deletion};
}


export function UserAvatar({photo_url, size = 30, style, ...props}) {
    return <Image src={photo_url ?? Anonymous} roundedCircle style={{width: size, height: size, ...(style ?? {})}} {...props} />;
}

export function UserLink({display_name, uid, className, onClick}) {
    return <Link to={`/routes/view/user/${uid}`} className={className} onClick={onClick}>{display_name}</Link>;
}