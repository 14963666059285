import styled from '@emotion/styled';
import {booleanPointInPolygon, destination, getCoord, length, lineSplit, lineString, midpoint, point, polygon} from '@turf/turf';
import _ from 'lodash';
import React, {memo, useMemo} from 'react';
import {Card, Container, Row, Stack} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {GiPathDistance} from 'react-icons/gi';
import {ImArrowUpRight} from 'react-icons/im';
import {Customized} from 'recharts';
import {ErrorBoundary} from '../lib/errors';
import {toLatLngArray} from '../lib/getClosestPointAndSegment';
import {coordinateSegments} from '../lib/routing';
import {SpriteSheet} from '../lib/Spritesheet';
import {useMapLibreEvents} from '../lib/useMapLibre';
import {getLineStringCoords, mergeSameEdges, useDataAPIQuery} from '../lib/util';
import {ElevationViewer} from './ElevationViewer';
import {Barrier, Bollard, Tunnel} from './IconsSVG';
import {getPOIFeature} from './PointsOfInterest';
import {CardCloseButton, POIMarker, StyledPopup, usePOIPopup} from './POIPopup';
import {SteepnessTrackLayer} from './Track';
import {STEEPNESS_MARKER_SIZE, Uphill, UphillSVG} from './Uphill';


function fromVia(t, {start_point, via}) {
    return (start_point && via) ? t("from {{start_point}} through {{via}}", {start_point, via}) :
        start_point ? t("from {{start_point}}", {start_point}) :
            via ? t("through {{via}}", {via}) : null;
}

function ClimbProps({ascent, distance, ...props}) {
    const {t} = useTranslation();

    return <Stack direction="horizontal" {...props}>
        <span className="text-center text-wrap flex-shrink-1">
            <GiPathDistance/>
            <span className="text-nowrap mx-1">
                {t("{{distance, number}}", {distance, style: 'unit', unit: 'kilometer', unitDisplay: 'short', maximumFractionDigits: 1})}
            </span>
        </span>
        <span className="text-center text-wrap flex-shrink-1">
            <ImArrowUpRight/>
            <span className="text-nowrap mx-1">
                {t("{{ascent, number}}", {ascent, style: 'unit', unit: 'meter', unitDisplay: 'short', maximumFractionDigits: 0})}
            </span>
        </span>
    </Stack>;
}

function ClimbPOIInfoBox({classes, pois, ranges, sprite, summit, ...props}) {
    const distances = _.sortBy(_.map(
        _.filter(pois?.features, (poi) => _.includes(classes, poi?.properties?.class)),
        ({properties}) => ranges[properties?.nearest_vertex_index] / 1000));

    return <ClimbInfoBox icon={<POIMarker sprite={sprite} size={21} className="flex-shrink-0"/>} distances={distances} summit={summit} {...props}/>;
}

function TunnelsWarningInfoBox({ranges, summit, tunnels}) {
    const tunnelSegments = _.filter(mergeSameEdges(tunnels), ([, , tunnel]) => tunnel),
        distances = _.map(tunnelSegments, ([startIdx]) => ranges[startIdx] / 1000);

    return <ClimbInfoBox icon={<Tunnel width={21}/>} distances={distances} summit={summit} variant="warning"/>;
}

function ObstaclesDangerInfoBox({icon, obstacles, ranges, summit}) {
    const distances = _.map(obstacles, ([idx]) => ranges[idx] / 1000);
    return <ClimbInfoBox icon={icon} distances={distances} summit={summit} variant="danger"/>;
}

function ClimbInfoBox({icon, distances, summit, variant, ...props}) {
    const {t} = useTranslation();

    if (_.isEmpty(distances)) return null;

    const rounder = d => _.round(d, 0),
        mapper = d => (d < 2 ? t("start") :
            (d > summit - 1 ? t("summit") :
                t("{{distance, number}}", {distance: d, style: 'unit', unit: 'kilometer', unitDisplay: 'short', maximumFractionDigits: 0}))),
        simplified = _.uniq(_.map(_.map(distances, rounder), mapper)),
        poisList = _.join(_.initial(simplified), ', '),
        poisStr = _.size(simplified) > 1 ?
            t("{{poisList}} and {{lastPOI}}", {poisList, lastPOI: _.last(simplified)}) :
            _.last(simplified);

    return <Card className="d-flex flex-row align-items-center p-1" border={variant} {...props}>
        {icon}
        <span className="ms-1">{poisStr}</span>
    </Card>;
}

export function CategoryIcon({category, className, size = 28}) {
    if (!category)
        return null;
    const categoryStr = category.toLowerCase();
    const sprite = _.includes(['1', '2', '3', '4', 'hc'], categoryStr) ? `climb_${categoryStr}` : 'climb_4';
    return <SpriteSheet sprite={sprite} size={size} className={className}/>;
}

// Helper function to find the index of the nearest point after a given distance
function findIndexAtDistance(ranges, startIndex, targetDistance) {
    return _.findIndex(ranges, (range) => range >= targetDistance, startIndex);
}

// Function to calculate avg gradient for each point for provided distances look-ahead
function calculateGradients(ranges, elevation, distances) {
    return _.map(ranges, (range, index) =>
        _.map(distances, (distance) => {
            const startIndex = findIndexAtDistance(ranges, 0, range - distance / 2),
                endIndex = findIndexAtDistance(ranges, index, range + distance / 2);
            if (startIndex === -1 || endIndex === -1 || startIndex >= endIndex) return null;
            const distanceDifference = ranges[endIndex] - ranges[startIndex],
                elevationDifference = elevation[endIndex] - elevation[startIndex],
                gradient = (elevationDifference / distanceDifference) * 100;
            return gradient;
        })
    );
}

function GradientAlert({gradient, offset = 0, range, x, y}) {
    const size = STEEPNESS_MARKER_SIZE,
        xOffset = _.clamp(x + offset - size, 0, x + offset - size),
        yOffset = _.clamp(y - 30 - size, 0, y - 30 - size);
    return <g>
        <g transform={`translate(${xOffset},${yOffset})`}>
            <UphillSVG steepness={gradient} size={size} fontSize={10} range={range}/>
        </g>
    </g>;
}

function GradientAlerts({ranges, elevation, xScale, yScale}) {
    const {t} = useTranslation();
    const gradientRanges =
        _.max(ranges) > 8000 ?
            // Intentionally untranslated, to avoid too long 'massimo' and such.
            [[300, "300m max"], [1000, "1km max"]] :
            [[100, "100m max"], [500, "500m max"]];
    const maxGradients = useMemo(() => {
        if (!ranges || !elevation)
            return {};

        const gradients = _.zip(calculateGradients(ranges, elevation, _.map(gradientRanges, '0')), ranges, elevation),
            markers = _.filter(_.map(gradientRanges, ([grange, label], idx) => {
                const [maxes, rng, ele] = _.maxBy(gradients, ([maxes]) => maxes[idx]) ?? [];
                return maxes?.[idx] ? {label, gradient: maxes[idx], rng, ele} : null;
            })),
            markersNoOverlap = _.map(markers, (mg, idx, all) => {
                if (!idx) return {...mg, x: xScale(mg.rng)};
                const x = xScale(mg.rng),
                    prevDist = _.clamp(xScale(markers[idx - 1].rng) - x, -50, 50);
                return {...mg, x: prevDist < 0 ? x + 50 + prevDist : x - 50 + prevDist};
            });
        return markersNoOverlap;
    }, [ranges, elevation]);

    return _.map(maxGradients, ({label, gradient, rng, ele, x}) =>
        <GradientAlert x={x} y={yScale(ele)} range={label} gradient={gradient} offset={0} key={label}/>
    );
}

function POIsInfoboxes({gates, pois, ranges, summit, tunnels}) {
    return <>
        <ClimbPOIInfoBox pois={pois} ranges={ranges} sprite="drinking_water_15" summit={summit}
                         classes={['drinking_water', 'spring', 'toilets']}/>
        <ClimbPOIInfoBox pois={pois} ranges={ranges} sprite="cafe_15" summit={summit}
                         classes={['fast_food', 'bakery', 'ice_cream', 'restaurant', 'beer', 'bar', 'cafe']}/>
        <ClimbPOIInfoBox pois={pois} ranges={ranges} sprite="grocery_15" summit={summit}
                         classes={['grocery', 'alcohol_shop']}/>
        <ClimbPOIInfoBox pois={pois} ranges={ranges} sprite="attraction_15" summit={summit}
                         classes={['place_of_worship', 'attraction', 'viewpoint', 'waterfall', 'cave_entrance', 'volcano', 'castle', 'fortress', 'ruins', 'memorial', 'monument']}/>
        <TunnelsWarningInfoBox ranges={ranges} tunnels={tunnels} summit={summit}/>
        <ObstaclesDangerInfoBox ranges={ranges} icon={<Barrier width={21}/>} summit={summit}
                                obstacles={_.filter(gates, ([, gate]) => gate !== 'bollard')}/>
        <ObstaclesDangerInfoBox ranges={ranges} icon={<Bollard width={21}/>} summit={summit}
                                obstacles={_.filter(gates, ([, gate]) => gate === 'bollard')}/>
    </>;
}

function ClimbPOICard({cancelFeature, details}) {
    const {t} = useTranslation();

    const {pois, track, track_json} = details ?? {},
        {ascent, category, distance, grade_avg, name, start_point, via} = track?.properties,
        {elevation, gates, ranges, steepness, tunnels} = track_json ?? {};
    if (!details) return null;

    const from_via = fromVia(t, {start_point, via}),
        coordinates = getLineStringCoords(details.track),
        summit = _.last(ranges) / 1000;

    return <Card>
        <Card.Header className="mt-0 me-0 px-2">
            <Stack direction="horizontal" className="gap-2 align-items-end">
                <div className="text-center align-top">
                    <Uphill steepness={grade_avg} size={40} fontSize={10}/>
                </div>
                <span className="flex-grow-1">
                    {name}
                    {from_via ? <><br/><small className="smaller">{from_via}</small></> : null}
                </span>
                <ClimbProps className="ms-auto smaller gap-1 flex-grow-0 flex-shrink-1 align-items-end" ascent={ascent} distance={distance / 1000} steepness={grade_avg}/>
                <CategoryIcon category={category} className="flex-grow-0 flex-shrink-0 me-1"/>
            </Stack>
            <CardCloseButton className="btn-close pt-0" onClick={() => cancelFeature({clear: true})}/>
        </Card.Header>
        <Card.Body className="px-0 py-0">
            <Container className="p-0">
                <Row className="p-0 g-0 smaller" xs={2}>
                    <POIsInfoboxes gates={gates} pois={pois} ranges={ranges} summit={summit} tunnels={tunnels}/>
                </Row>
            </Container>
            <ElevationViewer coordinates={coordinates} elevation={elevation} height={200} steepness={steepness} width="100%" fontSize="smaller">
                <Customized component={({xAxisMap, yAxisMap}) => (
                    <GradientAlerts ranges={ranges} elevation={elevation}
                                    xScale={(x) => xAxisMap[0].scale(x / 1000)} yScale={(y) => yAxisMap[0].scale(y)}/>
                )}/>
            </ElevationViewer>
        </Card.Body>
    </Card>;
}

function linesInPolygon(line, polygon) {
    // See also: https://gis.stackexchange.com/a/459122/217036
    const split = lineSplit(line, polygon);
    const linesInside = [];
    for (const part of split.features) {
        const midPoint = midpoint(
            point(part.geometry.coordinates[0]),
            point(part.geometry.coordinates[part.geometry.coordinates.length - 1])
        );
        if (booleanPointInPolygon(midPoint, polygon)) {
            linesInside.push(part);
        }
    }
    return linesInside;
}

const ANGLES = [
    [-180, 'top'], [-135, 'top-right'], [-90, 'right'], [-45, 'bottom-right'],
    [0, 'bottom'], [45, 'bottom-left'], [90, 'left'], [135, 'top-left']
];

function normalizeAngle(angle) {
    return ((angle + 180) % 360 + 360) % 360 - 180;
}

function getPopupAnchor(track) {
    const trackCoords = getLineStringCoords(track);
    const trackLS = lineString(trackCoords);
    const startPoint = point(trackCoords[0]);
    const distance = 50; // Assuming units are kilometers as defined

    const sectors = _.map(ANGLES, ([angle, anchor]) => {
        const point1 = destination(startPoint, distance, normalizeAngle(angle - 22.5), {units: 'kilometers'});
        const point2 = destination(startPoint, distance, normalizeAngle(angle + 22.5), {units: 'kilometers'});
        const sector = polygon([[
            getCoord(startPoint),
            getCoord(point1),
            getCoord(point2),
            getCoord(startPoint) // Ensure closure of the polygon
        ]]);
        return {angle, sector, anchor};
    });

    const overlaps = _.map(sectors, (sector, idx) => {
        const overlaps = linesInPolygon(trackLS, sector.sector);
        const overlapLengths = _.map(overlaps, line => length(line, {units: 'kilometers'}));
        const overlapLength = _.sum(overlapLengths);
        return {overlapLength, sector, overlaps, idx};
    });

    const [overlapping, notOverlapping] = _.partition(overlaps, 'overlapLength');
    if (_.isEmpty(notOverlapping)) {  // Are there any climbs with snail-like track around the point? Return least evil.
        return _.minBy(overlaps, 'overlapLength').sector.anchor;
    }
    // First two are undefined if overlap goes through 7 and 0 - using first not overlapping is the safe bet.
    const bestSector = overlaps[_.first(overlapping).idx - 3] ?? overlaps[_.last(overlapping).idx + 3] ?? _.nth(notOverlapping, 3) ?? _.nth(notOverlapping, 2) ?? _.first(notOverlapping);
    return bestSector?.sector?.anchor;
}

const ClimbStyledPopup = styled(StyledPopup)`
    min-width: 450px;
    max-width: 450px;
`;

export function ClimbPOIPopup({cancelFeature, poi}) {
    const {feature, lngLat} = poi,
        {lng, lat} = lngLat ?? {},
        uid = feature?.properties?.uid,
        {data: details} = useDataAPIQuery(`/cycling-climbs/climbs/${uid}`, null, null, {enabled: !!uid});

    if (!details?.track) return null;  // TODO: suspense loading feedback for climb popup

    const anchor = getPopupAnchor(details.track);

    return <ErrorBoundary silent resetKeys={[details]}>
        <ClimbStyledPopup longitude={lng} latitude={lat} className="maplibregl-container-reboot p-0"
                          closeButton={false} anchor={anchor}>
            <ClimbPOICard cancelFeature={cancelFeature} details={details}/>
        </ClimbStyledPopup>
        <Climb climb={feature}/>
    </ErrorBoundary>;
}

function useClimbData(uid) {
    const {data: details} = useDataAPIQuery(`/cycling-climbs/climbs/${uid}`, null, null, {enabled: !!uid}),
        {segments} = useMemo(() => {
            const lineFeature = details?.track;
            if (!lineFeature) return {};
            // Stelvio from Prato (cba60fae38fdeeef77f9909db893e979) is a MultiLineString, so handle accordingly.
            const coords = getLineStringCoords(lineFeature),
                {steepness} = details.track_json ?? {},
                segments = coordinateSegments(toLatLngArray(coords), _.map(steepness, ([r0, r1, {grade}]) => ([r0, r1, grade])));
            return {
                segments: _.map(segments, ([coords, grade, ...range]) => ({coords, grade, range}))
            };
        }, [details]);
    return {segments};
}

export const Climbs = memo(function Climbs() {
    const [, setFeature, cancelFeature, setImmediateFeature] = usePOIPopup(),
        events = useMemo(() => {

            function popup(evt) {
                const feature = _.first(evt?.features),
                    poi = getPOIFeature(feature, evt, false, 'climb_poi');
                return {...poi, PopupComponent: ClimbPOIPopup};
            }

            function onMouseOverDisplayPopup(evt) { setFeature(popup(evt)); }

            function onMouseOutHidePopup(evt) { cancelFeature?.(); }

            function onClickDisplayPopup(evt) {
                console.debug('mapEvent.click.climb_poi: ', evt, evt?.originalEvent, evt?.features, evt?.originalEvent?.target?.classList);
                // Note: climb_poi is determined and handled specially in Track.click event too. Otherwise clicking would draw a route point too.
                setImmediateFeature(popup(evt));
            }

            return [['mouseover', 'climb_poi', onMouseOverDisplayPopup],
                ['mouseout', 'climb_poi', onMouseOutHidePopup],
                ['click', 'climb_poi', onClickDisplayPopup]];
        }, [cancelFeature, setImmediateFeature, setFeature]);
    useMapLibreEvents(events);
});

const Climb = memo(function Climb({climb}) {
    const {segments} = useClimbData(climb?.properties?.uid);
    return <>
        {segments && <SteepnessTrackLayer segments={segments}/>}
    </>;
});
