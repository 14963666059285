import {buffer, center, distance, getCoord, getCoords, lineString, lineToPolygon, point, polygonToLine, simplify} from '@turf/turf';
import concaveman from 'concaveman';
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';
import {useViewState} from '../components/ViewStateContext';
import {errorHandler} from './errors';
import {nearestPointOnLine, toLngLatArray} from './getClosestPointAndSegment';
import {useMapLibre} from './useMapLibre';
import {getLineStringCoords} from './util';


/**
 * Creates a function to adjust marker positions around a track to prevent overlapping,
 * by ensuring markers are placed outside a specified buffer zone around the track.
 * The computation-intensive tasks (like calculating the buffered track and simplified track)
 * are memoized to prevent sluggish UX during map zoom changes.
 *
 * @param {Array.<Array.<number>>} trackCoords - Array of coordinates for the track, each coordinate being a [longitude, latitude] tuple.
 * @param {number} bufferPx - Width of the buffer around the track in pixels, used to determine the non-overlapping zone for markers.
 * @returns {Array} Returns an array containing:
 *                  1. A function that takes a [longitude, latitude] coordinate pair and
 *                     returns a turf.Point object representing the adjusted marker position.
 *                  2. A turf.Linestring object representing the track buffered by the specified width.
 *                  3. A turf.LineString object representing the simplified version of the track, being the basis.
 */
export function useTrackMarkerPositionAdjuster(trackCoords, bufferPx) {
    const {mapLibre} = useMapLibre(),
        {zoom, zooming} = useViewState(),
        // Buffer operation is CPU-intensive for complex tracks, so call trackPolygon to simplify to a simple polygon before buffering.
        // Avoids expensive operation on every zoom change.
        simplifiedPoly = useMemo(() => trackPolygon(trackCoords), [trackCoords]),
        [[positionAdjuster, bufferedTrack], setAdjuster] = useState([_.identity, simplifiedPoly && polygonToLine(simplifiedPoly)]);

    useEffect(() => {
        if (zooming || !simplifiedPoly)
            return;

        function calcBufferMargin(lngLat, bufferPx) {
            const {x, y} = mapLibre.project(lngLat),
                {lng: lng1, lat: lat1} = mapLibre.unproject([x + bufferPx, y + bufferPx]),
                distanceKm = distance(point(lngLat), point([lng1, lat1]), {units: 'kilometers'});
            return distanceKm;
        }

        function bufferTrack() {
            const polyCenter = center(simplifiedPoly),
                bufferKm = calcBufferMargin(getCoord(polyCenter), bufferPx);
            try {
                const buffered = polygonToLine(buffer(simplifiedPoly, bufferKm, {units: 'kilometers'}));
                return buffered;
            } catch (ex) {
                ex.message = `${ex.message}. Vertices: ${JSON.stringify(simplifiedPoly)}`;
                errorHandler.report(ex);
                return getLineStringCoords(polygonToLine(simplifiedPoly));
            }
        }

        const bufferedTrack = bufferTrack();

        function positionAdjuster(lngLat) {
            return nearestPointOnLine(bufferedTrack, lngLat);
        }

        setAdjuster([positionAdjuster, bufferedTrack]);
    }, [bufferPx, mapLibre, simplifiedPoly, zoom, zooming]);

    return [positionAdjuster, bufferedTrack, simplifiedPoly];
}

/**
 * Create a concave polygon around the track, as a guide for positioning POIs, Weather and similar markers.
 * @param trackCoords - legacy-ordered coords
 * @returns  post-buffer polygon
 */
export function trackPolygon(trackCoords) {
    if (_.size(trackCoords) < 4)  // lineToPolygon raises for lines with less than 4 coords.
        return null;

    try {
        const lngLatCoords = toLngLatArray(trackCoords),
            // Speed up concaveman 10x or so.
            simplified = getCoords(simplify(lineString(lngLatCoords), {tolerance: 0.002})),
            // Using mapbox/concaveman because turf.concave is buggy and raises for quite sane tracks.
            // Taking to account that simplified track may have less than 4 points - use the original one then.
            concavePolyCoords = concaveman(_.size(simplified) < 4 ? lngLatCoords : simplified, 2, 0.01),
            cpcLS = lineString(concavePolyCoords),
            poly = lineToPolygon(cpcLS);
        return poly;
    } catch (ex) {
        errorHandler.report(`Mute: ${ex}: trackPolygon() raised for trackCoords=${JSON.stringify(trackCoords)}.`);
        return null;
    }
}