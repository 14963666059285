import _ from 'lodash';
import {Form, InputGroup} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';


export function EmailInput({email, onEmailChange, invalidEmailFeedback}) {
    const {t} = useTranslation();
    return <>
        <Form.Group controlId="email">
            <InputGroup className="mb-0" hasValidation>
                    <InputGroup.Text>@</InputGroup.Text>
                <Form.Control autoComplete="email" className="form-control" placeholder={t("Email")} type="text" value={email}
                              onChange={onEmailChange} isInvalid={!_.isEmpty(invalidEmailFeedback)}/>
                <Form.Control.Feedback type="invalid">
                    {invalidEmailFeedback}
                </Form.Control.Feedback>
            </InputGroup>
            {_.isEmpty(invalidEmailFeedback) && <Form.Text className="text-muted">
                <Trans>We don't share your email with anyone else.</Trans>
            </Form.Text>}
        </Form.Group>
    </>;

}