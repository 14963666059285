
import maplibregl from 'maplibre-gl';
import {useCallback} from 'react';
import {Map} from 'react-map-gl';
import {useViewState} from './ViewStateContext';

// Note: requires properly crafted browserslist to avoid empty map.
// See also: https://github.com/maplibre/maplibre-gl-js/issues/1011
export function MaplibreMap({children, ...props}) {
    const {bearing, latitude, longitude, padding, pitch, zoom, updateViewState} = useViewState(),
        onMove = useCallback(({viewState, originalEvent}) => {
            // 'resize' can be triggered by the browser when opening a RouteViewer page, so it is not user action.
            // This caused a bug: map sticked to default fallback location.
            const cameraSource = (originalEvent && originalEvent.type !== 'resize') ? {cameraSource: 'user'} : {};
            updateViewState({...viewState, ...cameraSource});
        }, [updateViewState]);

    return <Map mapLib={maplibregl} mapStyle={process.env.REACT_APP_TILES_URL} baseApiUrl="https://tiles.tarmacs.app/styles/tarmacs/" id="tarmacsMap"
                interactive={true} cursor="crosshair" attributionControl={false}
                scrollZoom={true} dragRotate={true} dragPan={true} keyboard={true} doubleClickZoom={true} touchZoomRotate={true} touchPitch={true}
                {...{bearing, latitude, longitude, padding, pitch, zoom}}
                onMove={onMove}
                {...props}>
        {children}
    </Map>;
}