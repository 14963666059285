import {Marker} from 'react-map-gl';


export function DivMarker({children, position, style = {}, ...props}) {
    if (!position) return null;
    const [longitude, latitude] = position;
    return <>
        <Marker {...props} latitude={latitude} longitude={longitude} style={{cursor:'pointer', ...style}}>
            {children}
        </Marker>
    </>;
}