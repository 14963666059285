import React, {useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useCurrentUserData} from './db';
import {ga} from './ga';
import {visiblePOIsQueryParams} from './routing';
import {showSuccess} from './toasts';
import {useBackendAPIMutation} from './util';


export function useSendToGarmin({routeId, exportPOIs, onSuccess}) {
    const {t} = useTranslation(),
        params = useMemo(() => visiblePOIsQueryParams(exportPOIs), [exportPOIs]),
        {mutateAsync: sendToGarmin} = useBackendAPIMutation(`/api/garmin/courses/${routeId}`, params, null, {
            onSuccess: () => {
                showSuccess(t("We sent your route to Garmin Connect"), {},
                    <p><Trans>You can view the route in Garmin Connect and on your synced Garmin devices.</Trans></p>);
                ga('route_export', {'method': 'garmin_connect', 'content_type': 'gpx', 'item_id': routeId});
                onSuccess?.();
            }
        });
    return sendToGarmin;
}

export function useGarminAccount() {
    const [userData] = useCurrentUserData();
    const token = userData?.garmin_info?.access_token ?? {};
    const hasGarmin = userData?.garmin_id && !userData?.garmin_info?.disabled && token.oauth_token && token.oauth_token_secret;
    return [hasGarmin, userData?.garmin_id, userData?.garmin_info];
}