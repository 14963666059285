import {collection, endAt, getDocs, limit, orderBy, query, startAfter, startAt, where} from 'firebase/firestore';
import {distanceBetween, geohashQueryBounds} from 'geofire-common';
import {asyncInterleaveReady} from 'iter-tools';
import _ from 'lodash';
import {useContext} from 'react';
import {bboxCenter, routeConverter} from './converters';
import {db, FirebaseAuth} from './firebase';
import {useInfiniteIteratorQuery} from './iteratorPager';


async function fetchRoutes(coll, firebaseWhere, ...args) {
    const routesQuery = query(coll, ...firebaseWhere, ..._.filter(args));
    try {
        const snapshots = await getDocs(routesQuery);
        return snapshots.docs;
    } catch (e) {
        console.error(e);
    }
}

const PAGE_SIZE = 3;
// For bigger radiuses than 20 km geohash queries do not fetch too logically by distance (go through one axis first).
const GEOHASH_RADIUS_KM = 20;
const RADIUS_KM = 50;

export async function* fetchRoutesInVicinity(user, center, bikeKind) {
    const bounds = geohashQueryBounds(center, GEOHASH_RADIUS_KM * 1000),
        firebaseWhere = bikeKind ? [where('bikeKind', '==', bikeKind)] : [];

    console.log('fetchRoutesInVicinity: bounds', bounds)
    function filterRoutesInVicinity(route){
        const routeData = route.data(),
            routeCenter = bboxCenter(routeData.track?.bbox);
        if (routeData.deleted || routeData.private || (user?.uid && routeData.owner === user.uid) || !routeCenter)
            return null;
        return distanceBetween(center, routeCenter);
    }

    async function* fetchShard([start, end]) {
        let startClause = startAt(start),
            endClause = endAt(end);
        while (true) {
            const snapshotDocs = await fetchRoutes(collection(db, 'routes').withConverter(routeConverter(user)), firebaseWhere,
                orderBy('centerHash'), startClause, endClause, limit(PAGE_SIZE));
            if (_.isEmpty(snapshotDocs)) return;  // No more docs in this geohash cluster. Finished.
            for (const snap of snapshotDocs) {
                const {centerHash} = snap.data().routeProps
                if (centerHash > end) return;  // This and following docs are beyond our cluster. Finished.
                const distance = filterRoutesInVicinity(snap);
                if (!_.isNil(distance) && distance < RADIUS_KM) {
                    console.log(`fetchRoutesInVicinity.fetchShard yielding id=${snap.ref.id} at distance=${distance.toFixed(1)}km: ${centerHash} (${start}..${end})`);
                    yield snap;
                }
                startClause = startAfter(centerHash);
            }
        }
    }

    const seen = new Set();
    const asyncShards = _.map(bounds, fetchShard);
    for await (const route of asyncInterleaveReady(...asyncShards)) {
        if (!seen.has(route.id))
            yield route;
        route.id && seen.add(route.id);
    }
}

const _snasphotDocsToData = (route) => ({...route.data(), id: route.id});

export function useRoutesInVicinity(center, bikeKind, postprocessor = _snasphotDocsToData) {
    const {user} = useContext(FirebaseAuth),
        options = {
            enabled: !!center,
            keepPreviousData: true,
            staleTime: 1000 * 60 * 30
        };

    async function* generatorFn() {
        yield* fetchRoutesInVicinity(user, center, bikeKind);
    }

    const {data, hasNextPage, fetchNextPage} = useInfiniteIteratorQuery(['routes', {bikeKind, center, user}], generatorFn, PAGE_SIZE, options, postprocessor),
        pages = data?.pages ?? [];
    return [pages, hasNextPage, fetchNextPage];
}
