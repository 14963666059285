import styled from '@emotion/styled';
import classNames from 'classnames';
import {collection, doc, getDoc, setDoc} from 'firebase/firestore';
import {routeConverter} from 'lib/converters';
import {useFirebaseDocData, useFirebaseDocMutationInvalidate, useUserData} from 'lib/db';
import _ from 'lodash';
import {DateTime} from 'luxon';
import React, {memo, useCallback, useContext, useState} from 'react';
import {Alert, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, Row} from 'react-bootstrap';
import {Share} from 'react-facebook';
import {Helmet} from 'react-helmet';
import {Trans, useTranslation} from 'react-i18next';
import {FaCheckSquare, FaEdit, FaFacebook, FaFacebookSquare, FaTrash} from 'react-icons/fa';
import {HiOutlineClipboard} from 'react-icons/hi';
import {IoDuplicate} from 'react-icons/io5';
import {FullscreenControl} from 'react-map-gl';
import {LinkContainer} from 'react-router-bootstrap';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {BIKE_KINDS, EPIC_CYCLING_ROUTES} from '../lib/bikeKinds';
import {db, FirebaseAuth} from '../lib/firebase';
import {ga} from '../lib/ga';
import {showError, showSuccess} from '../lib/toasts';
import {BBOX_MARGIN} from '../lib/useInitMaplibreLocation';
import {useRouteReducer} from '../lib/useRouteReducer';
import {useTrackPOIs} from '../lib/useTrackPOIs';
import {DesktopOnly, MobileOnly, toHostPath, useDeviceType, useFillViewEffect, useMaxMediaBreakpoint} from '../lib/util';
import {ActionButton} from './ActionButton';
import {AuthButton} from './auth/AuthButton';
import {ElevationViewer} from './ElevationViewer';
import {TimeAgo} from './Format';
import {ExportGPXButtonWithModal, ExportToDeviceButtonWithModal, ExportToDeviceModal, ExportToGarminButtonWithModal, SendEmailWithGPXButton} from './Garmin';
import {InviteFriendsPanel, PostInvitationPanel} from './Invitations';
import {MaplibreMap} from './MaplibreMap';
import {POIPopupContextProvider} from './POIPopup';
import {FavToggler, PraiseToggler, PrivateToggler} from './RouteCard';
import {BikeKindEmblem, MapAttributionControl, MapComponents, RoutePropertiesCard} from './RouteMapping';
import {User} from './User';
import {MapViewStateProvider} from './ViewStateContext';
import {WeatherPanel} from './WeatherPanel';


export const StyledRouteViewerMap = styled(MaplibreMap)`
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export function CopyLinkButton({routeId, tiny, ...props}) {
    const {t} = useTranslation();

    async function onCopyLinkClick() {
        try {
            await navigator.clipboard.writeText(window.location);
            showSuccess(
                t("Link copied to clipboard"), {},
                t("Share it to your friends or social network!"));
        } catch (error) {
            showError(error, {
                heading: t("Your browser did not allow to copy to clipboard"),
                hint: t("You can still copy the link address bar.")
            });
        }
        ga('share', {'method': 'Clipboard', 'content_type': 'route', 'item_id': routeId});
    }

    return <ActionButton icon={HiOutlineClipboard} text={t("Copy link")} tooltip={t("Copy hyperlink to clipboard. Then you can paste it to a message to your fellow rider!")}
                         onClick={onCopyLinkClick} tiny={tiny} {...props}/>;
}

export function DeleteButton({routeId, tiny, ...props}) {
    const {t} = useTranslation(),
        navigate = useNavigate(),
        {user} = useContext(FirebaseAuth),
        {mutateAsync: mutateRoute} = useFirebaseDocMutationInvalidate(['routes', routeId], routeConverter(user));

    async function onDeleteClick() {
        await mutateRoute({deleted: true});
        navigate('/routes/view/');
    }

    return <ActionButton icon={FaTrash} text={t("Delete")} tooltip={t("Is that route a bad idea? Just throw it away.")} onClick={onDeleteClick} tiny={tiny} {...props}/>;
}

export const RouteActionsToolbar = memo(({route, tiny, style, ...props}) => {
    const {user} = useContext(FirebaseAuth),
        md = useMaxMediaBreakpoint('md'),
        {id: routeId, routeProps = {}} = route ?? {},
        {owner, visiblePOIs} = routeProps,
        isOwner = user?.uid === owner,
        {t} = useTranslation(),
        size = tiny ? 'sm' : null;

    return <>
        <ButtonToolbar {...props}>
            <ButtonGroup vertical className="w-50 mt-2 pe-1 gap-1" size={size}>
                {md ? (<>
                    <ExportGPXButtonWithModal routeId={routeId} exportPOIs={visiblePOIs}/>
                    <ExportToGarminButtonWithModal routeId={routeId} tiny={true} exportPOIs={visiblePOIs}/>
                </>) : (<>
                    <ExportToDeviceButtonWithModal routeId={routeId} exportPOIs={visiblePOIs}/>
                    <SendEmailWithGPXButton routeId={routeId} exportPOIs={visiblePOIs}/>
                </>)
                }
            </ButtonGroup>
            <ButtonGroup vertical className="w-50 mt-2 gap-1" size={size}>
                <ShareRouteToFacebook routeId={routeId} routeProps={routeProps}/>
                <CopyLinkButton icon={HiOutlineClipboard} routeId={routeId}/>
            </ButtonGroup>
            <ButtonGroup className="gap-1 mt-2" size={size}>
                {isOwner &&
                    <LinkContainer to={`/routes/edit/${routeId}`}>
                        <ActionButton icon={FaEdit} text={t("Edit")} tooltip={t("Edit this route")} variant="outline-primary"/>
                    </LinkContainer>}
                <DuplicateButton routeId={routeId} isOwner={isOwner} variant="outline-primary"/>
                {isOwner &&
                    <DeleteButton routeId={routeId} text={t("Delete")} variant="outline-primary"/>}
            </ButtonGroup>
        </ButtonToolbar>
    </>;
});

function LikeUsOnFacebookNudge({children}) {
    return <div className="d-flex align-items-center">
        <Button variant="primary" href="https://www.facebook.com/TarmacsApp" target="_blank" className="flex-shrink-0 me-2">
            <FaFacebookSquare/>&nbsp;<Trans>Open fanpage</Trans>
        </Button>
        <small>{children}</small>
    </div>;
}

function PostSaveAlert({show, toggleShow, className}) {
    if (!show)
        return null;
    return <Alert variant="success" dismissible onClose={() => toggleShow(false)} className={classNames(className, "lh-sm")}>
        <Alert.Heading as="h6"><Trans>Export your route to GPS device or share to friends!</Trans></Alert.Heading>
        <hr className="mt-0 mb-1"/>
        <ul className="small list-unstyled mb-0">
            <MobileOnly>
                <li><FaCheckSquare/> <Trans>Click <strong>Get GPX track</strong> or <strong>Send to Garmin</strong> to load the track to Wahoo or Garmin.</Trans></li>
            </MobileOnly>
            <DesktopOnly>
                <li><FaCheckSquare/> <Trans><strong>Export to device</strong> will load the track to Wahoo or Garmin.</Trans></li>
                <li><FaCheckSquare/> <Trans><strong>Email me GPX</strong> to open the track in on your mobile phone and load to your GPS app.</Trans></li>
            </DesktopOnly>
            <li><FaCheckSquare/> <Trans>Share the link to the route to your friends!</Trans></li>
        </ul>
        <hr className="mt-1 mb-2"/>
        <LikeUsOnFacebookNudge><Trans>We did a good job? Like us on Facebook!</Trans></LikeUsOnFacebookNudge>
    </Alert>;
}

function RouteViewerMap({pois, routeState = {}, setElevationViewerSegment, setVolatileCoordinate, volatileCoordinate}) {
    return <StyledRouteViewerMap className="fill-view">
        <FullscreenControl/>
        <MapComponents editable={false} {...{pois, routeState, volatileCoordinate, setVolatileCoordinate, setElevationViewerSegment}} bboxMargin={BBOX_MARGIN} />
        <MapAttributionControl />
    </StyledRouteViewerMap>;
}

const RouteHeader = memo(function RouteHeader({dispatchRoute, routeState, tiny}) {
    const {user} = useContext(FirebaseAuth),
        location = useLocation(),
        navigate = useNavigate(),
        showPostSave = location?.state?.saved,
        routeId = routeState?.id,
        {routeProps = {}} = routeState ?? {},
        {bikeKind, name = "", owner} = routeProps,
        isOwner = user && user?.uid === owner;

    const toggleShowPostSave = useCallback((show) => {
        navigate({pathname: location.pathname, ...(location.state ?? {}), saved: show}, {replace: true});
    }, [location, navigate]);

    const updateRouteProp = useCallback((prop, value, dirty) =>
        dispatchRoute({type: 'updateRouteProps', routeProps: {[prop]: value}, dirty, save: isOwner}), [dispatchRoute, isOwner]);

    return <>
        <h1 className="border-bottom d-flex fs-2 align-items-center py-1">
            <PraiseToggler routeId={routeId} className="ms-auto me-1 py-1"/>
            <span className="flex-grow-1">{name}</span>
            <FavToggler routeId={routeId} size="lg" className="ms-auto me-0 my-0 pt-0 pb-1 px-2"/>
            <small className="fs-6 ms-0 me-0"><BikeKindEmblem bikeKind={bikeKind}/></small>
        </h1>
        {!isOwner && <PostInvitationPanel/>}
        <PostSaveAlert show={showPostSave} toggleShow={toggleShowPostSave} className="mt-2 mb-0 pb-2"/>
        <RouteActionsToolbar route={routeState} tiny={tiny} className="mb-2"/>
        {!showPostSave && <LikeUsOnFacebookNudge><Trans>The route looks good? Like us on Facebook!</Trans></LikeUsOnFacebookNudge>}
        <InviteFriendsPanel className="mt-2 w-100" routeId={routeId} owner={owner}/>
        <WeatherPanel route={routeState?.route} routeProps={routeProps} updateRouteProp={updateRouteProp}/>
    </>;
});

export function PrivateImmediateToggler({routeId}) {
    const {user} = useContext(FirebaseAuth),
        {data: route} = useFirebaseDocData(['routes', routeId], routeConverter(user)),
        // Intentionally bypassing routeConverter for simplicity.
        {mutate: mutateRoute} = useFirebaseDocMutationInvalidate(['routes', routeId]);

    return <PrivateToggler isPrivate={route?.routeProps?.private} togglePrivate={(priv) => mutateRoute({private: priv})}/>;
}

const RouteDetails = memo(function RouteDetails({pois, route, setVolatileCoordinate, elevationViewerSegment}) {
    const {user} = useContext(FirebaseAuth),
        {track, created, routeProps: {owner, description} = {}} = route ?? {},
        {steepness, coordinates, elevation} = track,
        isOwner = user?.uid === owner;

    return <>
        <Card className="mt-2">
            <Card.Header as="h6"><Trans>Elevation profile</Trans></Card.Header>
            <RoutePropertiesCard track={track} className="p-0 border-0"/>
            <hr className="m-0"/>
            <Card.Body>
                <ElevationViewer width="100%" height={200} className="mt-3"
                                 {...{pois, setVolatileCoordinate, elevationViewerSegment, steepness, coordinates, elevation}}/>
            </Card.Body>
        </Card>
        {description && <Card className="mt-2">
            <Card.Header as="h5"><Trans>Description</Trans></Card.Header>
            <Card.Body>
                {description}
            </Card.Body>
        </Card>}
        {!isOwner && <>
            <hr/>
            <h6><Trans>Route design</Trans></h6>

            <User userId={owner} className="me-auto ms-0">
                <div className="pt-0 mt-0" style={{lineHeight: 1.0}}><small>
                    <Trans i18nKey="Designed <timeago/>" components={{timeago: <TimeAgo date={created}/>}}/>
                </small></div>
            </User>
        </>}
        {isOwner && <PrivateImmediateToggler routeId={route?.id}/>}
    </>;
});

const EqualAspectRatioRow = styled(Row)`
    padding-top: 100%;
    position: relative;
`;

const EqualAspectRatioCol = styled(Col)`
    position: absolute;
    top: 0;
    height: 100%;
`;

export function RouteViewer() {
    return <Container fluid>
        <MapViewStateProvider>
            <POIPopupContextProvider>
                <RouteViewerInner/>
            </POIPopupContextProvider>
        </MapViewStateProvider>
    </Container>;
}

/**
 * Can't remember 100% but likely separate from RouteViewer to reduce re-renders.
 */
function RouteViewerInner() {
    const {t} = useTranslation(),
        {isDesktop, isMobile} = useDeviceType(),
        urlParams = useParams(),
        [routeState, dispatchRoute, , cleanFollowUpActionParams] = useRouteReducer(urlParams?.routeId, {withLocalStorage: false}),
        [volatileCoordinate, setVolatileCoordinate] = useState(null),
        [elevationViewerSegment, setElevationViewerSegment] = useState(null),
        {routePOIs, routeProps, showModal, track} = routeState ?? {},
        // TODO: obey visiblePOIs.
        {data: pois} = useTrackPOIs(track, routePOIs, undefined),
        {data: designer} = useUserData(routeProps?.owner),
        {first_name, last_name} = designer ?? {},
        designedBy = designer ? t("{{first_name}} {{last_name}}", {first_name, last_name}) : "",
        epicTitle = t(EPIC_CYCLING_ROUTES[routeProps?.bikeKind ?? 'road']),
        title = (designer && routeProps) ?
            t("{{designedBy}}'s {{routeName}} | {{epicTitle}} | Tarmacs.App", {routeName: routeProps?.name, epicTitle, designedBy}) :
            t("{{epicTitle}} | Tarmacs.App", {epicTitle}),
        toggleShow = useCallback((show) => {
            dispatchRoute({type: 'showModal', modal: show ? 'ExportToDeviceModal' : null});
            !show && cleanFollowUpActionParams();
        }, [cleanFollowUpActionParams, dispatchRoute]);

    useFillViewEffect(isDesktop);

    if (!routeState)
        return null;

    // <DesktopOnly> and <MobileOnly> caused "Error: Multiple maps with the same id: tarmacsMap" sometimes.
    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {isMobile ? (<>
            <Row><Col>
                <RouteHeader routeState={routeState} tiny={true} dispatchRoute={dispatchRoute}/>
            </Col></Row>
            <EqualAspectRatioRow className="mt-2"><EqualAspectRatioCol>
                <RouteViewerMap {...{pois, routeState, setElevationViewerSegment, setVolatileCoordinate, volatileCoordinate}}/>
            </EqualAspectRatioCol></EqualAspectRatioRow>
            <Row><Col>
                <RouteDetails route={routeState} {...{pois, setVolatileCoordinate, elevationViewerSegment}}/>
            </Col></Row>
        </>) : (<Row className="fill-view">
            <Col xs={12} md={7} xl={8} className="p-2">
                <RouteViewerMap {...{pois, routeState, setElevationViewerSegment, setVolatileCoordinate, volatileCoordinate}}/>
            </Col>
            <Col className="p-2 d-flex flex-column overflow-scroll h-100">
                <RouteHeader routeState={routeState} dispatchRoute={dispatchRoute}/>
                <RouteDetails route={routeState} {...{pois, setVolatileCoordinate, elevationViewerSegment}}/>
            </Col>
        </Row>)}
        <ExportToDeviceModal show={showModal === 'ExportToDeviceModal'} routeId={routeState?.id} toggleShow={toggleShow}/>
    </>;
}

function DuplicateButton({routeId, tiny, isOwner, ...props}) {
    const {t} = useTranslation(),
        navigate = useNavigate(),
        {user} = useContext(FirebaseAuth);

    const onDuplicateClick = useCallback(async () => {
        const routesRef = collection(db, 'routes'),
            docRef = doc(routesRef, routeId).withConverter(routeConverter(user)),
            cloneRef = doc(routesRef).withConverter(routeConverter(user)),
            snapshot = await getDoc(docRef),
            data = snapshot.data();

        ga('route_dupe', {item_id: routeId, own: user.uid === data.owner, old_owner: data.owner, new_item_id: cloneRef.id});

        const clonedData = {
            ...data,
            routeProps: {
                ..._.omit(data.routeProps, 'garmin_id', 'garmin_ids', 'starred', 'praised'),
                clonedFrom: docRef,
                // Mark as initialClone to handle notifications properly: prevent now, but send in next write (when user clicks Save).
                initialClone: true,
                owner: user.uid,
                id: cloneRef.id
            },
            created: DateTime.now().toJSDate()
        };

        await setDoc(cloneRef, clonedData);
        navigate(`/routes/edit/${cloneRef.id}`);
    }, [navigate, routeId, user]);

    const text = t("Create similar route"),
        tooltip = isOwner ? t("Design another variant of the route, while keeping the original intact.") : t("Design similar route, starting from this one.");
    return <AuthButton as={ActionButton} icon={IoDuplicate} tiny={tiny} onClick={onDuplicateClick} activityName="DuplicateButton"
                       text={text} tooltip={tooltip} {...props}/>;
}

function ShareRouteToFacebook({routeId, routeProps, tiny, ...props}) {
    const {t} = useTranslation();

    function getRouteParams() {
        const pathname = `/routes/view/${routeId}`,
            url = toHostPath({urlString: 'https://tarmacs.app', pathname}),
            {description, bikeKind} = routeProps,
            /* i18next-extract-disable-next-line */
            routeType = BIKE_KINDS[bikeKind] ? t(BIKE_KINDS[bikeKind]) : t(BIKE_KINDS.road);

        const quote = t("{{routeType}} bike route created in Tarmacs.App! {{description}}", {routeType, description});
        return {url, quote};
    }

    const {url, quote} = getRouteParams();
    return <Share href={url} quote={quote} hashtag="#tarmacs">
        {({handleClick}) => {
            const onClick = (evt) => {
                handleClick(evt);
                ga('share', {'method': 'Facebook', 'content_type': 'route', 'item_id': routeId});
            };
            return <ActionButton icon={FaFacebook} text={t("Share")} tooltip={t("Share this route with your Facebook friends!")} tiny={tiny} onClick={onClick} {...props}/>;
        }}
    </Share>;
}

