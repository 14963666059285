import {lineString} from '@turf/turf';
import _ from 'lodash';
import {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Card, Form, InputGroup, Modal, Stack} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {FaRoute} from 'react-icons/fa';
import {useBoolean} from 'usehooks-ts';
import {useCurrentUserProp} from '../lib/db';
import {toLngLatArray} from '../lib/getClosestPointAndSegment';
import {FormCheckWithPopover} from '../lib/toasts';
import {useGarminAccount} from '../lib/useSendToGarmin';
import {checkToggler, useDeviceTypeVariant, validatingHandler} from '../lib/util';
import {useFetchRoundtripLocationNames} from './InspirationsSelector';
import {TrackPOIsSelector} from './POIMarkersToggleButtonGroup';
import {PrivateToggler} from './RouteCard';


export const SaveRoute = memo(function SaveRoute({coordinates, distance, onHide, onSave, routeId, routeProps: routePropsInitial, show}) {
    const {t} = useTranslation(),
        initialName = routePropsInitial?.name,
        [routeProps, setRouteProps] = useState(routePropsInitial || {}),
        {name, inspirationId, visiblePOIs} = routeProps ?? {},
        {value: validated, setValue: setValidated, setFalse: clearValidated} = useBoolean(),
        {value: edited, setTrue: markEdited, setFalse: clearEdited} = useBoolean(),
        track = useMemo(() =>
            (coordinates && show ? lineString(toLngLatArray(coordinates)) : null), [coordinates, show]),
        locationNames = useFetchRoundtripLocationNames(track, distance, true),
        suggestedName = _.join(locationNames, " > "),
        nameRef = useRef(),
        [hasGarmin] = useGarminAccount(),
        [saveToGarmin, setSaveToGarmin] = useCurrentUserProp('saveToGarmin', true),
        [selectedPOIs, setSelectedPOIs] = useState(visiblePOIs),
        direction = useDeviceTypeVariant({mobile: 'vertical', desktop: 'horizontal'});

    useEffect(() => setRouteProps(state =>
            // Do not overwrite title for pre-existing routes (user already set the title) or inspirations (have nice AI-generated titles).
            ((!show || _.isEmpty(suggestedName) || inspirationId || edited || !_.isEmpty(initialName)) ? state : {...state, name: suggestedName})),
        [edited, initialName, inspirationId, routeId, show, suggestedName]);

    useEffect(() => {name === suggestedName && nameRef?.current?.select();}, [nameRef, name, suggestedName]);

    const onShow = useCallback(() => {
        setRouteProps(routePropsInitial);
        clearValidated();
        clearEdited();
    }, [clearEdited, clearValidated, routePropsInitial, setRouteProps]);

    const eventSetter = (attr) => ({target: {value}}) => setRouteProps((prevProps) => ({...prevProps, [attr]: value})),
        onRouteNameChange = (evt) => {
            markEdited();
            eventSetter('name')(evt);
        };

    function onSubmit() {
        onSave(routeProps, {saveToGarmin: hasGarmin && saveToGarmin, selectedPOIs});
        onHide();
    }

    // Form noValidate disables default browser validation, not custom.
    return <Modal backdrop={true} {...{show, onHide}} size="lg" centered animation={false} onShow={onShow}>
        <Modal.Header closeButton={true}>
            <Modal.Title><Trans>Save route</Trans></Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={validatingHandler(setValidated, onSubmit)}>
            <Modal.Body>
                <Stack direction="vertical" gap={3}>
                    <InputGroup hasValidation>
                        <InputGroup.Text><FaRoute/></InputGroup.Text>
                        <Form.Control className="form-control" placeholder={t("Route name (required)")} type="text" autoFocus
                                      value={routeProps.name} onChange={onRouteNameChange} required ref={nameRef}/>
                        <Form.Control.Feedback type="invalid">
                            <Trans>Please type descriptive route name.</Trans>
                        </Form.Control.Feedback>
                    </InputGroup>

                    <Form.Group>
                        <Form.Control as="textarea" rows={3}
                                      placeholder={t("Share some details with others")}
                                      value={routeProps.description} onChange={eventSetter('description')}/>
                    </Form.Group>

                    {hasGarmin && <Card>
                        <Card.Header>
                            <FormCheckWithPopover type="switch" label={t("Push to Garmin Connect after saving")} id="push-to-garmin-after-save"
                                                  checked={saveToGarmin} onChange={checkToggler(setSaveToGarmin)}>
                                <Trans>Toggle off to prevent pushing the route to Garmin Connect after saving. You can always push later.</Trans>
                            </FormCheckWithPopover>
                        </Card.Header>
                        {saveToGarmin &&
                        <Card.Body>
                            <TrackPOIsSelector className="ms-0 me-auto mb-2" direction={direction} id="save-" gap={1} show={show} infoBox={false}
                                               exportPOIs={visiblePOIs} setSelectedPOIs={setSelectedPOIs}/>
                        </Card.Body>}
                    </Card>}

                    <Card><Card.Header>
                        <PrivateToggler isPrivate={routeProps?.private} togglePrivate={(priv) => setRouteProps((props) => ({...props, private: priv}))}/>
                    </Card.Header></Card>
                </Stack>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button variant="link" className="me-auto" size="sm" onClick={() => onHide()}><Trans>Continue editing</Trans></Button>
                <Button variant="primary" type="submit" className="me-0"><Trans>Save route</Trans></Button>
            </Modal.Footer>
        </Form>
    </Modal>;
});