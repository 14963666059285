import {getIdToken, signInWithCustomToken} from 'firebase/auth';
import _ from 'lodash';
import {createContext, memo, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useDeepCompareEffect, useLatest} from 'react-use';
import {call, put, select, take, takeLatest} from 'redux-saga/effects';
import connectWithStrava from '../../images/btn_strava_connectwith_orange.png';
import connectWithGarmin from '../../images/connect_logo_black.png';
import {useCurrentUserData} from '../../lib/db';
import {getDataOrThrow} from '../../lib/errors';
import {auth, FirebaseAuth} from '../../lib/firebase';
import {ga} from '../../lib/ga';
import {showError} from '../../lib/toasts';
import {useLoggingSagaReducer} from '../../lib/useLoggingReducer';
import {toHostPath, useSearchParamsObject} from '../../lib/util';
import {useLocaleSettings} from '../l10n';
import {SignInModal, SignUpModal} from './AuthenticationModal';
import {EmailRequired} from './EmailRequired';
import {Onboarding} from './Onboarding';


async function initOAuth(provider, user, activity_name, params) {
    const idToken = user && await getIdToken(user);
    params = {...params, activity_name: (activity_name || params?.activity_name) ?? ""};
    const url = toHostPath({searchParams: params});
    const searchParams = {url, ...(idToken ? {id_token: idToken} : {})};
    const loginUrl = toHostPath({urlString: process.env.REACT_APP_API_URL, pathname: `/oauth/${provider}/login`, searchParams});
    window.location.href = loginUrl;
}

export async function finalizeOAuth({email, finalization_token, locale}) {
    try {
        const resp = await fetch(
                `/oauth/finalize`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({email, finalization_token, locale})
                }),
            json = await getDataOrThrow(resp),
            {custom_token, user_data: userData} = json;

        return {custom_token, userData};
    } catch (ex) {
        console.error('finalizeOAuth: ', ex);
        return {ex};
    }
}

export function StravaOAuthButton({activityName, tiny}) {
    const {user} = useContext(FirebaseAuth),
        [params] = useSearchParamsObject(),
        style = tiny ? {maxWidth: '140px'} : {maxWidth: '193px'};
    return <Button variant="link" size={tiny ? "sm": "lg"} onClick={() => initOAuth('strava', user, activityName, params)}>
        <img alt="Connect with strava" src={connectWithStrava} style={style}/>
    </Button>;
}

export function GarminOAuthButton({activityName, tiny}) {
    const {user} = useContext(FirebaseAuth),
        [params] = useSearchParamsObject(),
        style = tiny ? {maxWidth: '140px'} : {maxWidth: '193px'};

    return <Button variant="outline-secondary" size={tiny ? "sm": "lg"} onClick={() => initOAuth('garmin', user, activityName, params)}>
        <img style={style} alt="Connect with Garmin" src={connectWithGarmin}/>
    </Button>;
}

function* signInThroughOAuth({custom_token, finalization_token, provider}) {
    let state = yield select((state) => (state));
    if (!custom_token) {
        while (!state.isSignedIn) {
            // isSignedIn might be null/undefined when Firebase is still authenticating.
            if (!_.isNil(state.isSignedIn) && _.isEmpty(state.email) && finalization_token) {
                // This is the first time auth and provider does not provide email in the user profile, so get it from the user directly.
                // Otherwise, we would lose our user if the auth provider stops working in the future.
                yield put({type: 'showModal', modal: 'EmailRequired'});
            }
            const {email, isSignedIn} = yield take(({type}) => _.includes(['emailProvided', 'authStateChange'], type));
            if (email || isSignedIn) {
                // E-mail provided or user signed in other tab or long Firebase round-trip.
                const {userData, custom_token} = yield call(finalizeOAuth, {email, finalization_token, locale: state.localeRef.current});
                if (custom_token) {
                    // We were not signed in yet.
                    yield call(signInWithCustomToken, auth, custom_token);
                    ga('sign_up', {method: `oauth.${provider}`});
                    yield put({type: 'userDataRefresh', userData});
                }
            }
            state = yield select((state) => (state));
        }
    } else {
        yield call(signInWithCustomToken, auth, custom_token);
        ga('login', {method: `oauth.${provider}`});
    }
    // Hide the dialog if present (EmailRequired or SignIn switched from EmailRequired)
    yield put({type: 'showModal', modal: null});
}

function* signInThroughEmail({defaultModal, modalOptions}) {
    yield put({type: 'showModal', modal: defaultModal, ...(modalOptions || {})});
    // Workaround: onboardingRequired must be set explicitly on the action payload to be able to use 'yield take'
    // 'yield select' called after would randomly get previous state (mostly on first run of saga after html page load)
    // This is bug https://github.com/azmenak/use-saga-reducer/issues/6
    let {user} = yield take('authStateChange');
    //  This could be change from undefined to false, so keep trying
    while (!user) {
        user = (yield take('authStateChange'))?.user;
    }
    const showModal = yield select(({showModal}) => showModal);
    yield put({type: 'showModal', modal: null});
    const event = {'SignUp': 'sign_up', 'SignIn': 'login'}[showModal];
    event && ga(event, {method: 'email'});
    if (_.isEmpty(user.displayName)) {  // Indicator we have not passed through onboarding yet.
        yield put({type: 'showModal', modal: 'Onboarding'});
        yield take('onboardingDone');
    }
}

/**
 * The saga defines the workflow after OAuth(1/2) screen redirected back to the app, after backend obtained the access token.
 * If the oauth provider did not provide e-mail address, displays additional email prompt to join the logins
 * Finally the backend responds with Firebase custom token which is used to authenticate the client in Firebase.
 */
function* oauthSaga() {
    try {
        let state = yield select((state) => (state));
        while (!state.isSignedIn) {
            const {isSignedIn, defaultModal, authParams, modalOptions, localeRef} = state;
            const {finalization_token, custom_token, provider} = authParams ?? {};
            const oauthMode = !isSignedIn && (custom_token || finalization_token),
                connectOtherProviderMode = isSignedIn && !custom_token && finalization_token,
                emailMode = !isSignedIn && !custom_token && !finalization_token;
            if (oauthMode) {
                yield call(signInThroughOAuth, {custom_token, finalization_token, provider});
            } else if (connectOtherProviderMode) {
                // Connect signed in account to a new provider. Finalization request is sent with ID token, so not necessary to pass email.
                yield call(finalizeOAuth, {finalization_token, locale: localeRef.current});
            } else if (emailMode) {
                yield call(signInThroughEmail, {defaultModal, modalOptions});
            }
            state = yield select((state) => (state));
        }

        while (!_.isEmpty(state.authParams?.activity_name) && !state.activitiesMap?.[state.authParams?.activity_name]) {
            yield take('activitiesMapChange');
            state = yield select((state) => (state));
        }
        const activity = state.activitiesMap?.[state.authParams?.activity_name];
        const {setSearchParamsRef} = state;
        yield call(setSearchParamsRef.current, (params) => {
            return _.omit(params, activity?.params ?? [], _.keys(state.authParams));
            }, {replace: true});
        if (activity) {
            const params = _.pick(state.params, activity.params);
            yield call(activity.activity, params);
        }
        state = yield select((state) => (state));
        yield put({type: 'done'});
    } catch (error) {
        console.error('oauthSaga: error', error);
        yield put({type: 'error', error});
    }
}

export function* oauthWatcherSaga() {
    yield takeLatest('trigger', oauthSaga);
}

function extractAuthParams(searchParams) {
    const {activity_name, finalization_token, custom_token, provider, error, ...params} = searchParams ?? {};
    return [{activity_name, finalization_token, custom_token, provider, error}, params];
}

export function oauthWorkflowReducer(state = {}, {type, ...params}) {
    const actions = {
        trigger: ({defaultModal, modalOptions, ...searchParams} = {}) => {
            defaultModal = _.isEmpty(defaultModal) ? state.defaultModal : defaultModal;
            let [authParams, params] = extractAuthParams(searchParams);
            authParams = _.mergeWith(state.authParams, authParams, (v1, v2) => (_.isEmpty(v2) ? v1 : v2));
            return {...state, defaultModal, modalOptions, authParams, params};
        },
        setDefaultModal: ({defaultModal}) => (defaultModal ? {...state, defaultModal} : state),
        authStateChange: ({isSignedIn, email, user}) => (
            _.isEqual({isSignedIn, email, user}, _.pick(state, ['isSignedIn', 'email', 'user'])) ?
                state : {...state, isSignedIn, email, user}),
        activitiesMapChange: ({activitiesMap}) => ({...state, activitiesMap}),
        showModal: ({modal, ...modalOptions}) => ({...state, showModal: modal, modalOptions}),
        emailProvided: ({email}) => ({...state, email}),
        error: ({error}) => ({...state, error}),
        clearError: () => _.omit(state, 'error', 'activity_name'),
        userDataRefresh: (userData) => ({...state, userData}),
        showDefaultModal: () => ({...state, showModal: state.defaultModal}),
        onboardingDone: () => ({...state, onboardingRequired: false}),
        done: () => _.omit(state, 'authParams', 'params')
    };
    const newState = actions[type]?.(params);
    !newState && console.error(`oauthWorkflowReducer: does action ${type} exist and returns state?`);
    return newState ?? state;
}

function useOAuthSagaSingleton(activitiesMap) {
    const [, , {isSignedIn, user}, {refetch: refreshUser}] = useCurrentUserData({require: false}),
        [searchParams, setSearchParams] = useSearchParamsObject(),
        {locale} = useLocaleSettings(),
        setSearchParamsRef = useLatest(setSearchParams),
        localeRef = useLatest(locale),
        [authParams, params] = extractAuthParams(searchParams),
        [authWorkflow, dispatchAuthWorkflow] = useLoggingSagaReducer(oauthWatcherSaga, oauthWorkflowReducer, {authParams, params, setSearchParamsRef, localeRef});

    useEffect(() => {
        dispatchAuthWorkflow({type: 'authStateChange', isSignedIn, email: user?.email, user});
    }, [isSignedIn, user, dispatchAuthWorkflow]);
    useEffect(() => {
        dispatchAuthWorkflow({type: 'activitiesMapChange', activitiesMap});
    }, [activitiesMap, dispatchAuthWorkflow]);
    useEffect(() => {
        user && refreshUser();
    }, [user, refreshUser]);
    useEffect(() => {
        if (!authWorkflow?.error)
            return;
        setSearchParamsRef.current((params) => _.omit(params, 'error', 'activity_name'), {replace: true});  // Clear the URL.
        dispatchAuthWorkflow({type: 'clearError'})
        showError(authWorkflow.error);
    }, [authWorkflow?.error, dispatchAuthWorkflow, setSearchParamsRef]);

    useEffect(() => {
        const {error, activity_name} = searchParams;
        if (_.isNil(activity_name)) return;
        if (error) {
            dispatchAuthWorkflow({type: 'error', ...searchParams, error: new Error(error)})
        } else {
            dispatchAuthWorkflow({type: 'trigger', ...searchParams})
        }
    }, [searchParams, dispatchAuthWorkflow]);

    return [authWorkflow, dispatchAuthWorkflow];
}

export const OAuthContext = createContext({authWorkflow: {}});

export function OAuthProvider({children}) {
    const [activitiesMap, _setActivitiesMap] = useState({});
    const [authWorkflow, dispatchAuthWorkflow] = useOAuthSagaSingleton(activitiesMap);

    const setActivitiesMap = useCallback(
        (activitiesToAdd) => _setActivitiesMap((state) => {
            // Filter nulls and normalize short-hand activity definitions
            activitiesToAdd = _.mapValues(_.pickBy(activitiesToAdd), (v) => (_.isObjectLike(v) ? v : {activity: v, params: []}));
            return _.isEmpty(activitiesToAdd) ? state : {...state, ...activitiesToAdd};
        }), [_setActivitiesMap]);

    return (
        <OAuthContext.Provider value={{activitiesMap, setActivitiesMap, authWorkflow, dispatchAuthWorkflow}}>
            {children}
        </OAuthContext.Provider>
    );
}

function AuthControls() {
    const {t} = useTranslation(),
        [authWorkflow, dispatchAuthWorkflow] = useOAuthSaga();

    const {activity_name: activityName} = authWorkflow.authParams ?? {};
    const showModal = useCallback((modal, modalOptions) => dispatchAuthWorkflow({type: 'showModal', modal, ...(modalOptions ?? {})}), [dispatchAuthWorkflow]);
    const onSuccess = useCallback(() => dispatchAuthWorkflow({type: 'onboardingDone'}), [dispatchAuthWorkflow]);
    // const leftButton = <Button variant="link" size="sm" onClick={() => showModal('EmailRequired')}>&lt;&lt; Sign up with other email</Button>;

    return <>
        <SignInModal activityName={activityName} showModal={showModal} show={authWorkflow.showModal === 'SignIn'} options={authWorkflow.modalOptions}/>
        <SignUpModal activityName={activityName} showModal={showModal} show={authWorkflow.showModal === 'SignUp'} options={authWorkflow.modalOptions}/>
        <Onboarding activityName={activityName} showModal={showModal} show={authWorkflow.showModal === 'Onboarding'} options={authWorkflow.modalOptions}
                    onSuccess={onSuccess}/>
        <EmailRequired
            why={t("Provide your e-mail to complete sign-up")}
            showModal={showModal} show={authWorkflow.showModal === 'EmailRequired'} provider={authWorkflow.authParams?.provider} options={authWorkflow.modalOptions}
            onSuccess={(email) => dispatchAuthWorkflow({type: 'emailProvided', email})}/>
    </>;
}

function useOAuthActivitiesMap(activitiesMap) {
    const {setActivitiesMap} = useContext(OAuthContext);

    useDeepCompareEffect(() => {
        !_.isEmpty(activitiesMap) && setActivitiesMap?.(activitiesMap);
    }, [activitiesMap]);
}

/**
 * Continue post-oauth actions, after OAuth provider redirects back to app.
 * OAuthCallback is a separate component because it displays user-visible dialogs so must be nested within LocaleProvider.
 * While LocaleProvider must be inside OAuthProvider.
 */
export function OAuthCallback() {
    return <AuthControls/>;
}

export function useOAuthSaga() {
    const {authWorkflow, dispatchAuthWorkflow} = useContext(OAuthContext);
    return [authWorkflow, dispatchAuthWorkflow];
}

export function useOAuthActivity(activityName, activity, ...params) {
    const activitiesMap = useMemo(() => (activityName ? {[activityName]: {activity: (activity ?? _.noop), params}} : {}),
        [activityName, activity, params]);
    return useOAuthActivitiesMap(activitiesMap);
}

export const OAuthActivitiesMap = memo(function OAuthActivitiesMap_({activitiesMap}) {
    useOAuthActivitiesMap(activitiesMap);
    return null;
}, ({activitiesMap: prev}, {activitiesMap: next}) => _.isEqual(prev, next));