import {useQuery} from 'react-query';


function useStyleJson() {
    const {data: styleJson} = useQuery(['style.json'], async () => (await fetch(process.env.REACT_APP_TILES_URL)).json(), {staleTime: Infinity, cacheTime: Infinity});
    return styleJson;
}

const SPRITESHEET_DIMENSIONS = {
    '': [512, 257],
    '@2x': [1024, 514],
    '@3x': [1536, 771],
    '@4x': [2048, 1028]
}

function useMapSprites(multi = '') {  // or '@2x' or '@4x'
    const styleJson = useStyleJson(),
        {data: spriteConfig} = useQuery(['sprite.json', multi], async () => (await fetch(`${styleJson.sprite}${multi}.json`)).json(), {enabled: !!styleJson, staleTime: Infinity, cacheTime: Infinity}),
        spriteImageUrl = styleJson?.sprite && `${styleJson?.sprite}${multi}.png`,
        dimensions = SPRITESHEET_DIMENSIONS[multi]

    return [spriteImageUrl, spriteConfig, dimensions, styleJson];
}


function Sprite({filename, x, y, width, height, style, backgroundSize, borderWidth, ...props}) {
    if (!filename)
        return null;

    style = {
        backgroundImage: `url(${filename})`,
        backgroundPosition: `${x * (-1)}px ${y * (-1)}px`,
        backgroundRepeat: 'no-repeat',
        borderWidth,
        backgroundSize,
        width, height, ...(style ?? {})
    };

    return <div style={style} {...props}/>;
}

/**
 * Simple sprite displaying component
 * @param filename - spritesheet file name
 * @param data - spritesheet json
 * @param sprite - sprite id
 * @param w
 * @param h
 * @param borderWidth
 * @param props - props passed to underlying div.
 * @returns {JSX.Element|null}
 */
export function SpriteSheet({sprite, size, borderWidth = 0, ...props}) {
    const [filename, data, dimensions] = useMapSprites('@4x');

    if (!filename || !data || !dimensions || !sprite || !data[sprite])
        return null;

    const adjuster = borderWidth,
        {x, y, width, height} = data[sprite],
        [px, py] = (size) ? [(size - adjuster) / width, (size - adjuster) / height] : [1, 1],
        [imgX, imgY] = dimensions,
        backgroundSize = `${px * imgX}px ${py * imgY}px`;

    // const transform = (w && h) ? `scale(${px}, ${py})` : 'none';
    props = {x: x * px, y: y * py, width: (size ?? width) + adjuster, height: (size ?? height) + adjuster, filename, borderWidth, backgroundSize, ...props};
    return <Sprite {...props} />;
}

