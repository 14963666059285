// Loosely inspired by react-planet (depends on @mui/styles not supporting React 18)
// and react-circular-menu (no controlled state support, too complex/hardcoded elements)
import _ from 'lodash';
import {cloneElement, useMemo} from 'react';
import styled from '@emotion/styled';
import {consumeProps} from '../lib/util';


const StyledCircleMenuItem = styled('li', consumeProps('centerSize', 'open', 'angle', 'radius'))`
    position: absolute;
    top: ${({centerSize}) => `calc(${centerSize}px / 2)`};
    left: ${({centerSize}) => `calc(${centerSize}px / 2)`};
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
    z-index: 102;
    list-style: none;
    transform-origin: center;    
    ${({open}) => open && `visibility: visible; opacity: 1;`}
    ${({angle, radius}) => `        transform: translate(-50%, -50%) rotate(${angle}deg) translate(${radius}rem) rotate(${-angle}deg);`};
`;

export function ClickableCircularMenu({centerSize, children, className, itemSize, menuToggleElement, onClose, onOpen, open, radius, rotationAngle, startAngle}) {
    const menuToggleEvents = useMemo(() => ({
            onClick: (evt) => {
                console.log('ClickableCircularMenu: onClick', open, evt);
                evt?.stopPropagation?.();
                !open && onOpen?.(evt);
                open && onClose?.();
            }
        }), [open, onOpen, onClose]);

    return <CircularMenu {...{centerSize, className, itemSize, menuToggleElement, open, radius, rotationAngle, startAngle}} {...menuToggleEvents}>
        {children}
    </CircularMenu>;
}

function CircularMenu({centerSize, children, className, itemSize = 2, menuToggleElement, open, radius = 2, rotationAngle, startAngle, ...props}) {
    const menuToggle = cloneElement(menuToggleElement, props),
        itemCount = _.size(children),
        items = _.map(children, (child, index) => {
            const angle = startAngle + Math.round(rotationAngle / itemCount) * index;
            return <StyledCircleMenuItem key={`cm-${index}`} size={itemSize} open={open} radius={radius} angle={angle} centerSize={centerSize}>
                {child}
            </StyledCircleMenuItem>;
        });

    return <>
        {menuToggle}
        {items}
    </>;
}