import _ from 'lodash';

// Not an object because keys would be converted to strings.
export const STEEPNESS = [
    // Set markers to false to hide lower steepnesses
    [[-100, 0.1], {labelColor: 'black', color: 'white', markers: false, chartColor: 'green'}],
    [[0.1, 2], {labelColor: 'black', color: 'white', markers: false, chartColor: 'green'}],
    [[2, 3], {labelColor: 'black', color: 'yellow', chartColor: 'yellow', markers: false}],
    [[3, 6.5], {labelColor: 'black', color: 'yellow', chartColor: 'yellow', markers: true}],
    [[6.5, 10], {labelColor: 'white', color: 'darkorange', chartColor: 'darkorange'}],
    [[10, 15], {labelColor: 'white', color: 'red', chartColor: 'red'}],
    [[15, 100], {labelColor: 'white', color: 'darkred', chartColor: 'darkred'}]
];

export function getSteepnessAttrs(steepness) {
    const [[min, max], attrs] = _.find(STEEPNESS, ([[min, max]]) => (steepness >= min && steepness < max)) ?? [[-100, 1], {}];
    return {...attrs, range: [min, max]};
}