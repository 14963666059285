import classNames from 'classnames';
import {Formik, useField, useFormikContext} from 'formik';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, ButtonGroup, Container, Form, InputGroup, Modal, Row, Spinner} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {Trans, useTranslation} from 'react-i18next';
import {FaAddressBook, FaBirthdayCake, FaFemale, FaMale} from 'react-icons/fa';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {useMutation} from 'react-query';
import * as yup from 'yup';
import {uploadImage, useCurrentUserData} from '../lib/db';
import {canvasToBlob} from '../lib/thumbnailer';
import {showSuccess} from '../lib/toasts';
import {generateId} from '../lib/util';
import {ChangePassword} from './auth/ChangePassword';
import {t_} from './l10n';
import {CurrentUserAvatar} from './User';


async function readFile(file) {
    const reader = new FileReader(),
        promise = new Promise((resolve) => {
            reader.addEventListener('load', () => resolve(reader.result));
            reader.readAsDataURL(file);
        });

    return await promise;
}

export default function AvatarSelector() {
    const {t} = useTranslation();
    const [, setUserData, {user}] = useCurrentUserData({create: false, require: false}),
        [baseAvatarImage, setBaseAvatarImage] = useState(),
        [showCropDialog, setShowCropDialog] = useState(false),
        imgRef = useRef(null),
        previewCanvasRef = useRef(null),
        [crop, setCrop] = useState({unit: '%', width: 30, aspect: 1.0}),
        [completedCrop, setCompletedCrop] = useState(null);

    const storeCroppedAvatar = useCallback(async () => {
        if (!completedCrop || !previewCanvasRef.current)
            return;

        const path = `avatars/${user.uid}`,
            imageBlob = await canvasToBlob(previewCanvasRef.current),
            {downloadUrl} = await uploadImage(imageBlob, path);

        // Update photo URL in Firestore user record. A cloud function will sync to Firebase auth.
        await setUserData({photo_url: downloadUrl}, {merge: true});
    }, [user, completedCrop, setUserData]);

    const storeAvatarMutation = useMutation(
        () => storeCroppedAvatar(),
        {
            onSuccess: () => {
                setShowCropDialog(false);
                setBaseAvatarImage(null);
                showSuccess("The avatar has been saved.", {}, <span><CurrentUserAvatar/>&nbsp;You are looking cool!</span>);
            }
        });

    const onSelectFile = async (e) => {
        const file = _.first(e?.target?.files),
            imageData = file && await readFile(file);
        if (imageData) {
            setBaseAvatarImage(imageData);
            setShowCropDialog(true);
        }
    };

    const onImageLoaded = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef?.current || !imgRef?.current)
            return;

        const image = imgRef.current,
            canvas = previewCanvasRef.current,
            crop = completedCrop;

        const scaleX = image.naturalWidth / image.width,
            scaleY = image.naturalHeight / image.height,
            ctx = canvas.getContext('2d'),
            pixelRatio = window.devicePixelRatio;

        // Canvas size is not (re)scaled to avoid huge files
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        const sourceRect = [crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY],
            destRect = [0, 0, crop.width, crop.height];

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(image, ...sourceRect, ...destRect);
    }, [completedCrop]);

    return (<>
        <Form>
            <Row className="mt-3">
                <h2><Trans>Your avatar</Trans></h2>
            </Row>
            <Row xs={12} className="mt-3">
                <Form.Group controlId="avatar-file-selector" className="mb-3 w-100">
                    <Form.Label><Trans>Select a file with your new avatar:</Trans></Form.Label>
                    <Form.Control type="file" label={baseAvatarImage ? "" : t("Select PNG or JPG file.")}
                                  custom size="lg" accept="image/*" onChange={onSelectFile}/>
                </Form.Group>
            </Row>
        </Form>

        <Modal show={showCropDialog} backdrop="static" centered onHide={() => setShowCropDialog(false)} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title><Trans>Crop the avatar image</Trans></Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <ReactCrop src={baseAvatarImage} crop={crop} imageStyle={{maxWidth: 240, maxHeight: 240}}
                           onImageLoaded={onImageLoaded} onChange={setCrop} onComplete={setCompletedCrop}/>
                <div className="d-none">
                    <canvas ref={previewCanvasRef} // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                            style={{width: Math.round(completedCrop?.width ?? 0), height: Math.round(completedCrop?.height ?? 0)}}/>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button type="button" disabled={!completedCrop?.width || !completedCrop?.height || storeAvatarMutation.isLoading}
                        onClick={() => storeAvatarMutation.mutate()}>
                    <Trans>Crop and store the avatar</Trans>
                    {storeAvatarMutation.isLoading && <Spinner animation="border" role="status"/>}
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}

const GENDERS = [[t_('Male'), 'M'], [t_('Female'), 'F'], [t_('Nope'), '']];

export function GenderSelector({value, onChange, className, ...props}) {
    const {t} = useTranslation();
    const uid = useMemo(() => generateId(), []); // Radios require IDs for clickable labels - ensure uniqueness.
    const options = _.map(GENDERS, ([label, gender]) => ( /* i18next-extract-disable-next-line */
        <Form.Check key={gender} type="radio" label={t(label)} inline className="large" id={`${uid}-${gender}`}
                    name="gender" value={gender} checked={value === gender} onChange={onChange}/>
    ));

    return <ButtonGroup className={classNames("ps-3 border border-1 align-items-center flex-grow-1", className)} {...props}>
        {options}
    </ButtonGroup>;
}

function FormikDatePicker({...props}) {
    const {setFieldValue} = useFormikContext(),
        [field] = useField(props);

    return <Form.Control as={DatePicker} {...field} {...props} dateFormat={'dd.MM.yyyy'}
                         selected={field.value} onChange={(val) => setFieldValue(field.name, val)}/>;
}

export function UserProfile() {
    const validationSchema = yup.object().shape({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        birthday: yup.date().required(),
        gender: yup.string().required()
    });

    const {t} = useTranslation();
    const [userData, setUserDoc] = useCurrentUserData({require: false});
    const [validated] = useState(false),
        maxDate = DateTime.now().minus({years: 13}).toJSDate(),
        minDate = DateTime.now().minus({years: 125}).toJSDate(),
        openToDate = userData?.birthday ?? DateTime.now().minus({years: 25}).startOf('day').toJSDate();

    if (!userData)
        return null;

    async function _setUserDoc({first_name, last_name, ...doc}, callbacks) {
        await setUserDoc({...doc, first_name, last_name});
        showSuccess(t("Your profile data have been saved."), {}, "");
    }

    return <Container className="mt-5">
        <Formik validationSchema={validationSchema} initialValues={userData} onSubmit={_setUserDoc}>{
            ({handleSubmit, handleChange, values, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <h1 className="ms-0 me-0"><Trans>Your profile</Trans></h1>
                    </Row>
                    <Row className="mt-3">
                        <Form.Group className="w-100">
                            <Form.Label><Trans>Your name:</Trans></Form.Label>
                            <InputGroup hasValidation size="lg">
                                <InputGroup.Text>
                                    <InputGroup.Text><FaAddressBook/></InputGroup.Text>
                                </InputGroup.Text>
                                <Form.Control autoComplete="given-name" className="form-control" placeholder={t("Your given name")} type="text" size="lg"
                                              name="first_name" value={values.first_name} onChange={handleChange} isInvalid={!!errors.first_name}/>
                                <Form.Control autoComplete="family-name" className="form-control" placeholder={t("Your family name")} type="text" size="lg"
                                              name="last_name" value={values.last_name} onChange={handleChange} isInvalid={!!errors.last_name}/>
                                <Form.Control.Feedback type="invalid">
                                    <Trans>Please type your name.</Trans>
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Form.Group className="btn-group-vertical w-100">
                            <Form.Label><Trans>Your birthday and gender:</Trans></Form.Label>
                            <InputGroup hasValidation size="lg" className="d-flex align-items-stretch">
                                <InputGroup.Text>
                                    <InputGroup.Text><FaBirthdayCake/></InputGroup.Text>
                                </InputGroup.Text>
                                <InputGroup.Text>
                                    <InputGroup.Text><FaMale/><FaFemale/></InputGroup.Text>
                                </InputGroup.Text>
                                <FormikDatePicker openToDate={openToDate} autoComplete="bday" maxDate={maxDate} minDate={minDate}
                                                  name="birthday" peekNextMonth showMonthDropdown showYearDropdown
                                                  dropdownMode="select" isInvalid={!!errors.birthday}
                                                  placeholderText={t("Your day of birth")} required
                                                  className="align-self-stretch border-1 px-3 py-3 my-0"
                                                  wrapperClassName={classNames('flex-grow-1', 'flex-shrink-1', 'w-auto',
                                                      validated && !values.birthday ? 'is-invalid' : 'align-self-center border-0')}
                                />
                                <GenderSelector name="gender" value={values.gender} isInvalid={!!errors.gender} onChange={handleChange}/>
                                <Form.Control.Feedback type="invalid">
                                    <Trans>We need this information to rank you within gender and age group and ensure you are legally allowed to use the app.</Trans>
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="d-grid mt-3">
                        <Button variant="primary" size="lg" className="me-0" type="submit"><Trans>Save</Trans></Button>
                    </Row>
                </Form>
            )
        }</Formik>
        <hr/>
        <AvatarSelector/>
        <hr/>
        <div className="d-grid">
            <ChangePassword size="lg" className="m-0"/>
        </div>
    </Container>;
}