import styled from '@emotion/styled';
import classNames from 'classnames';
import {arrayRemove, limit, orderBy, where, writeBatch} from 'firebase/firestore';
import _ from 'lodash';
import {memo, useCallback, useContext, useMemo} from 'react';
import {Badge, Button, Dropdown, Nav} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import {BsRocketTakeoffFill} from 'react-icons/bs';
import {FaBell, FaHourglassHalf, FaRegEye, FaRoute, FaStar, FaUserPlus} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {down, up} from 'styled-breakpoints';
import {useFirebaseCollection, useFirebaseDocMutationInvalidate} from '../lib/db';
import {useFollow, UserAvatar, UserLink} from '../lib/feed';
import {db, FirebaseAuth} from '../lib/firebase';
import {consumeProps, useDeviceType} from '../lib/util';
import {TimeAgo} from './Format';


export const ScrollableDropdownMenu = styled(Dropdown.Menu)`
  max-height: 300px;
  overflow-y: auto;
  transform: translate(0, -2px);

  ${down('md')} {
    width: 95vw;
  }

  ${up('md')} {
    min-width: 20em;
  }
`;

function RouteLink({name, id, className, onClick}) {
    return <Link to={`/routes/view/${id}`} className={className} onClick={onClick}>{name}</Link>;
}

function CreatedRoute({actor, object, onRead}) {
    const {bikeKind = ''} = object;
    return <><FaRoute className="me-1"/>
        <Trans><UserLink {...actor} className="prevent-stretched-link"/> created a new {{bikeKind}} route: <RouteLink {...object} className="stretched-link" onClick={onRead}/></Trans>
    </>;
}

const NotificationButton = styled(Button)`
  font-size: inherit;
  vertical-align: baseline;
  font-weight: inherit;
`;

function NotificationAction({children, className, ...props}) {
    return <NotificationButton variant="link" size="sm" className={classNames("prevent-stretched-link small p-0", className)} {...props} >{children}</NotificationButton>;
}

function FollowedYou({actor, onRead, children}) {
    const {user} = useContext(FirebaseAuth),
        {following, follow} = useFollow(user?.uid, actor.uid);

    const content = children ?? <>
        <FaUserPlus className="text-success me-1"/>
        <Trans tOptions={{context: actor.gender}} context={actor.gender}><UserLink {...actor} className="stretched-link" onClick={onRead}/> followed you.</Trans>
    </>;

    return <>
        {content}<br/>
        {!following &&
            <small><Trans tOptions={{context: actor.gender}} context={actor.gender}
                          i18nKey="If you wish, <0>follow the user back</0>"
                          components={[<NotificationAction onClick={() => follow()}>...</NotificationAction>]}/></small>}
    </>;
}

function FollowedYouReminder({actor, onRead}) {
    const {user} = useContext(FirebaseAuth),
        // Grab original follow to find time ago.
        {following} = useFollow(actor.uid, user?.uid);

    return <FollowedYou {...{actor, onRead}}>
        <FaHourglassHalf className="text-success me-1" />
        <Trans tOptions={{context: actor.gender}} context={actor.gender}>
            <UserLink {...actor} className="stretched-link" onClick={onRead}/> followed you <TimeAgo date={following?.created?.toDate?.()}/>.
        </Trans>
    </FollowedYou>;
}

function Starred({actor, onRead, object}) {
    return <><FaStar className="text-primary me-1"/>
        <Trans><UserLink {...actor} className="prevent-stretched-link"/> starred your route:</Trans><br/>
        <small><RouteLink {...object} className="stretched-link" onClick={onRead}/></small>
    </>;
}

function Praised({actor, onRead, object}) {
    return <><BsRocketTakeoffFill className="text-danger me-1"/>
        <Trans><UserLink {...actor} className="prevent-stretched-link"/> praised your route:</Trans><br/>
        <small><RouteLink {...object} className="stretched-link" onClick={onRead}/></small>
    </>;
}

// Keep in sync with route_notifications.py
const DISPATCH = {
    'created': CreatedRoute,
    'followed_you': FollowedYou,
    'followed_you_reminder': FollowedYouReminder,
    'starred': Starred,
    'praised': Praised
};

function isFeedItemNew(user, {followers_unread}) {
    return _.includes(followers_unread, user?.uid);
}

function Activity({doc, onClick}) {
    const {user} = useContext(FirebaseAuth),
        item = doc.data(),
        path = _.split(doc.ref.path, '/'),
        unread = isFeedItemNew(user, item),
        {mutate: mutateNotification} = useFirebaseDocMutationInvalidate(path);

    const onRead = useCallback(() => {
        mutateNotification({followers_unread: arrayRemove(user.uid)});
        onClick?.();
    }, [user, mutateNotification, onClick]);

    const DispatchedActivity = DISPATCH[item.verb];
    if (!DispatchedActivity)
        return null;
    return <small className={classNames({unread}, 'd-inline-flex align-items-center')}>
        <div className="me-3 my-1 d-flex flex-column align-items-center">
            <UserAvatar photo_url={item.actor.photo_url} size={30}/>
            <NewNotificationsBadge offsetX={0} offsetY={-5} style={unread ? {} : {opacity: 0, height: 0}}/>
        </div>
        <div className="lh-base">
            <DispatchedActivity {...item} onRead={onRead}/>
        </div>
    </small>;
}

const NotificationItem = styled(Dropdown.Item)`
  cursor: pointer;

  small.unread {
    font-weight: bold;
  }
`;

const NotificationsList = memo(function NotificationsList_({docs, onClick}) {
    return <>{
        _.map(docs, (doc) => (
            <NotificationItem key={doc.ref.path} className="py-0 position-relative" as="div">
                <Activity doc={doc} onClick={onClick}/>
            </NotificationItem>)
        )}
    </>;
});

// shouldForwardProp avoids "Unknown prop on tag" react error.
// noinspection JSUnusedGlobalSymbols
const NewNotificationsBadgeStyled = styled(Badge, consumeProps('offsetX', 'offsetY'))`
  font-size: xx-small;
  position: relative;
  left: ${props => props.offsetX}px;
  top: ${props => props.offsetY}px;
  margin-right: ${props => props.offsetX}px;
`;

export function NewNotificationsBadge({offsetX = -10, offsetY = -10, ...props}) {
    return <NewNotificationsBadgeStyled offsetX={offsetX} offsetY={offsetY} bg="info" as="div" {...props}><Trans>new</Trans></NewNotificationsBadgeStyled>;
}

export function useNotificationsQuery() {
    const {user} = useContext(FirebaseAuth),
        {data: snap, refetch} = useFirebaseCollection(['*', 'indices'],
            [where('followers', 'array-contains', user?.uid), orderBy('created', 'desc'), limit(20)],
            null, {idField: 'id', subscribe: true}, {enabled: !!user?.uid}),
        docs = snap?.docs,
        areNew = useMemo(() => !!_.find(docs, (doc) => isFeedItemNew(user, doc.data())), [user, docs]);

    const markAllAsRead = useCallback(async () => {
        const batch = writeBatch(db);
        _.each(docs, ({ref}) => batch.update(ref, {followers_unread: arrayRemove(user.uid)}));
        await batch.commit();
        await refetch();
    }, [docs, refetch]);

    return {areNew, docs, markAllAsRead, user};
}

export function Notifications({showState}) {
    const {value: show, toggle: toggleShow, setValue: setShow, setTrue: doShow, setFalse: doHide} = showState,
        {areNew, docs, markAllAsRead, user} = useNotificationsQuery(),
        {isMobile} = useDeviceType();

    if (!user)
        return null;

    return <Dropdown as={Nav.Item} align="end" show={show} autoClose="outside" onMouseEnter={doShow} onMouseLeave={doHide} onToggle={setShow}>
        <Dropdown.Toggle as={Nav.Link} className="pt-0 pt-lg-2 pb-0 pb-lg-2" onClick={() => toggleShow()}>
            <FaBell/>&nbsp;
            {isMobile && <Trans>Notifications</Trans>}
            {areNew &&
                <NewNotificationsBadge/>}
        </Dropdown.Toggle>
        <ScrollableDropdownMenu id="notifications-dropdown-menu">
            {areNew &&
                <Dropdown.Item size="sm" as={Button} variant="link" onClick={markAllAsRead} className="text-end align-middle small">
                    <FaRegEye/><small>&nbsp;<Trans>Mark all as read</Trans></small>
                </Dropdown.Item>}
            <NotificationsList docs={docs} onClick={() => toggleShow()}/>
        </ScrollableDropdownMenu>
    </Dropdown>;
}