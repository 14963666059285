import classNames from 'classnames';
import _ from 'lodash';
import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Alert, Badge, Button, ButtonGroup, ButtonToolbar, Card, CloseButton, InputGroup, Table} from 'react-bootstrap';
import {AsyncTypeahead, Highlighter} from 'react-bootstrap-typeahead';
import {Trans, useTranslation} from 'react-i18next';
import {FaLightbulb} from 'react-icons/fa';
import {RiMailCheckLine, RiMailDownloadLine, RiMailForbidLine, RiMailSendLine} from 'react-icons/ri';
import {useFirebaseCollectionData, useFirebaseDocDeletion} from '../lib/db';
import {UserAvatar} from '../lib/feed';
import {FirebaseAuth} from '../lib/firebase';
import {ga} from '../lib/ga';
import {useMeilisearchLookupQuery} from '../lib/meilisearch';
import {showSuccess} from '../lib/toasts';
import {useAPIMutation, useAPIQuery, useSearchParamsObject} from '../lib/util';
import {AuthButton} from './auth/AuthButton';
import {t_} from './l10n';

/* Keep in sync with `invitations.InvitationStatus` */
const INVITATION_STATUS = {
    pending: {color: 'secondary', msg: t_("Pending"), emblem: <RiMailSendLine/>},
    sent: {color: 'info', msg: t_("Sent"), emblem: <RiMailDownloadLine/>},
    accepted: {color: 'success', msg: t_("Accepted"), emblem: <RiMailCheckLine/>},
    error: {color: 'danger', msg: t_("Error"), emblem: <RiMailForbidLine/>}
};

function InvitationStatus({status, ...props}) {
    const {t} = useTranslation();
    status = INVITATION_STATUS[status] ?? null;
    // i18next-extract-disable-next-line
    return status && <Badge bg={status.color} {...props}>{t(status.msg)} {status?.emblem}</Badge>;
}

function Invitation({routeId, invitation}) {
    const {user} = useContext(FirebaseAuth),
        {mutate: revoke} = useFirebaseDocDeletion(['routes', routeId, 'invitations', invitation.email]),
        {inviter_name, inviter_id, email, display_name, status, photo_url} = invitation;

    return <tr className="align-middle">
        <td><UserAvatar photo_url={photo_url}/></td>
        <td className="w-100 lh-1">{display_name ?? email}<br/><small style={{fontSize: 'x-small'}}><Trans>Sent by {{inviter_name}}</Trans></small></td>
        <td className="text-end"><InvitationStatus status={status}/></td>
        <td>{status !== 'accepted' && inviter_id === user.uid &&
            <CloseButton onClick={() => revoke()}/>}
        </td>
    </tr>;
}

function Invitations({routeId, invitations}) {
    if (!invitations)
        return null;

    return <Table size="sm" className="mb-0">
        <tbody>
        {_.map(invitations, (invitation) =>
            <Invitation key={invitation.user_id ?? invitation.email} routeId={routeId} invitation={invitation}/>
        )}
        </tbody>
    </Table>;
}

function useInvitationToken() {
    const [{invitationToken}, setQueryString] = useSearchParamsObject(),
        showInvitation = !!invitationToken,
        hideInvitation = useCallback(() => setQueryString((params) => _.omit(params, 'invitationToken')), [setQueryString]),
        {data: invitation} = useAPIQuery(`/api/invitations/${invitationToken}`, null, null, {enabled: showInvitation}),
        {mutate: acceptInvitation} = useAPIMutation(`/api/invitations/${invitationToken}`, null,{json: {status: 'accepted'}, method: 'PUT'}, {
            onSuccess: () => {
                hideInvitation();
                showSuccess('Invitation accepted. Have fun!');
            }
        });

    return [invitation, hideInvitation, acceptInvitation];
}

export function PostInvitationPanel({className}) {
    const {isSignedIn} = useContext(FirebaseAuth),
        [invitation, hideInvitation, acceptInvitation] = useInvitationToken(),
        onAcceptInvitation = useCallback(() => {
            acceptInvitation();
            ga('invitation_accepted');
        }, [acceptInvitation]);
    useEffect(() => {invitation && ga('invitation_viewed')}, [invitation]);

    if (!invitation || invitation.status === 'accepted')
        return null;

    const isNewUser = !invitation.user_id,
        {inviter_name} = invitation,
        activityName = isNewUser ? 'signUp' : 'signIn',
        defaultModal = isNewUser ? 'SignUp' : 'SignIn';

    return <Alert variant="success" dismissible onClose={() => hideInvitation()} className={classNames(className, "lh-sm")}>
        <Alert.Heading as="h6"><Trans>{{inviter_name}} invited you to this route.</Trans></Alert.Heading>
        <hr className="mt-0 mb-2"/>
        <ul className="small list-unstyled">
            <li><FaLightbulb/> <Trans>Click <strong>Accept invitation</strong> button to join the ride.</Trans></li>
            <li><FaLightbulb/> <Trans>Or just check out this route with no hassle!</Trans></li>
        </ul>
        <ButtonToolbar><ButtonGroup>
            <AuthButton variant="success" defaultModal={defaultModal} activityName="acceptInvitation" onClick={onAcceptInvitation} size="sm">
                <Trans>Accept invitation</Trans>
            </AuthButton>
            {!isSignedIn &&
                <AuthButton variant="outline-success" defaultModal={defaultModal} activityName={activityName} size="sm">
                    {isNewUser ?
                        <Trans>Sign up (disregard invitation)</Trans> :
                        <Trans>Sign in (disregard invitation)</Trans>}
                </AuthButton>}
        </ButtonGroup></ButtonToolbar>
    </Alert>;
}

export function UsersTypeahead({id, allowNew, onSetFriend, filter, renderMenuItemChildren, ...props}) {
    const [typedText, setTypedText] = useState(),
        {data: suggestions, isLoading} = useMeilisearchLookupQuery('users', typedText, {filter}),
        defaultRenderMenuItemChildren = useCallback((result, props) => {
            return <>
                <UserAvatar photo_url={result.photo_url} className="me-2"/>
                <Highlighter search={props.text}>{result.display_name}</Highlighter>
            </>;
        }, []),
        onFocusSelectAll = useCallback(({target}) => { target.select() }, []);

    return <AsyncTypeahead {...props} id={id} allowNew={allowNew} isLoading={isLoading} options={suggestions}
                           labelKey="display_name"
                           onSearch={setTypedText}
                           onChange={([friend]) => onSetFriend({...friend, ...(friend?.id ? {uid: friend.id} : {})})}
                           onFocus={onFocusSelectAll}
                           renderMenuItemChildren={renderMenuItemChildren ?? defaultRenderMenuItemChildren}/>;
}

export const InviteFriendsPanel = memo(function InviteFriendsPanel({className, routeId, owner}) {
    const {t} = useTranslation(),
        {user} = useContext(FirebaseAuth),
        [friend, setFriend] = useState(),
        {data: invitations, refetch: fetchInvitations} = useFirebaseCollectionData(['routes', routeId, 'invitations'], null, null, {subscribe: true}),
        alreadyInvited = useMemo(() => _.keys(_.keyBy(invitations, 'user_id')), [invitations]),
        {mutateAsync: invite} = useAPIMutation(`/api/routes/${routeId}/invitations`, null, {
            json: friend?.customOption ? {email: friend?.display_name} : {user_id: friend?.id}
        }, {enabled: !_.isEmpty(friend), onSuccess: fetchInvitations});

    const onInviteClick = useCallback(async () => {
        await invite();
        ga('invitation_sent', {'method': friend?.customOption ? 'email' : 'user_id'});
    }, [invite, friend?.customOption]);
    const canInvite = user && (user.uid === owner || _.find(invitations, {'user_id': user.uid}) || _.find(invitations, {'email': user.email}));

    if (!canInvite)
        return null;

    return <Card className={className}>
        <Card.Header as="h6"><Trans>Invite your fellow riders</Trans></Card.Header>
        <Card.Body className="p-1">
            <InputGroup>
                <UsersTypeahead id="invite-friends-edit"
                                placeholder={t("Type your fellow rider's e-mail or name")}
                                promptText={t("Type your fellow rider's e-mail or name")}
                                searchText={t("Searching...")}
                                emptyLabel={t("No users found. Type your friend's e-mail to send an invite.")}
                                newSelectionPrefix={t("Fellow rider's e-mail: ")}
                                allowNew={true}
                                filter={(hits) => _.reject(hits, ({id}) => _.includes(alreadyInvited, id))}
                                onSetFriend={setFriend}/>
                <Button variant="outline-primary" onClick={onInviteClick} disabled={_.isEmpty(friend)}><Trans>Invite</Trans></Button>
            </InputGroup>
            <Invitations routeId={routeId} invitations={invitations}/>
        </Card.Body></Card>;
});