import '@formatjs/intl-numberformat/locale-data/cs';
import '@formatjs/intl-numberformat/locale-data/da';
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/es';
import '@formatjs/intl-numberformat/locale-data/fi';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/it';
import '@formatjs/intl-numberformat/locale-data/nl';
import '@formatjs/intl-numberformat/locale-data/no';
import '@formatjs/intl-numberformat/locale-data/pl';
import '@formatjs/intl-numberformat/locale-data/pt';
import '@formatjs/intl-numberformat/locale-data/se';
import '@formatjs/intl-numberformat/locale-data/sk';
import '@formatjs/intl-numberformat/locale-data/sl';
import '@formatjs/intl-numberformat/polyfill';
import rdpCS from 'date-fns/locale/cs';
import rdpDA from 'date-fns/locale/da';
import rdpDE from 'date-fns/locale/de';
import rdpEN from 'date-fns/locale/en-US';
import rdpES from 'date-fns/locale/es';
import rdpFI from 'date-fns/locale/fi';
import rdpFR from 'date-fns/locale/fr';
import rdpIT from 'date-fns/locale/it';
import rdpNL from 'date-fns/locale/nl';
import rdpNO from 'date-fns/locale/nb';  // no - Norwegian.
import rdpPL from 'date-fns/locale/pl';
import rdpPT from 'date-fns/locale/pt';
import rdpSE from 'date-fns/locale/sv';  // se - Swedish.
import rdpSK from 'date-fns/locale/sk';
import rdpSL from 'date-fns/locale/sl';
import {use} from 'i18next';
import JavascriptTimeAgo from 'javascript-time-ago';
import _ from 'lodash';
import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {initReactI18next, useTranslation} from 'react-i18next';
import {Navigate, useLocation} from 'react-router-dom';
import {useShallowCompareEffect} from 'react-use';
import {useLocalStorage} from 'usehooks-ts';
import cs from '../i18n/cs.po.json';
import da from '../i18n/da.po.json';
import de from '../i18n/de.po.json';
import en from '../i18n/en.po.json';
import es from '../i18n/es.po.json';
import fi from '../i18n/fi.po.json';
import fr from '../i18n/fr.po.json';
import it from '../i18n/it.po.json';
import nl from '../i18n/nl.po.json';
import no from '../i18n/no.po.json';
import pl from '../i18n/pl.po.json';
import pt from '../i18n/pt.po.json';
import se from '../i18n/se.po.json';
import sk from '../i18n/sk.po.json';
import sl from '../i18n/sl.po.json';
import {useCurrentUserData} from '../lib/db';

// Format is {lang: {translation: {...json}, ...}}
export const MESSAGES = {
    cs: {translation: cs}, da: {translation: da}, de: {translation: de}, en: {translation: en}, es: {translation: es},
    fi: {translation: fi}, fr: {translation: fr}, it: {translation: it}, nl: {translation: nl}, no: {translation: no},
    pl: {translation: pl}, pt: {translation: pt}, se: {translation: se}, sk: {translation: sk}, sl: {translation: sl}
};
export const SUPPORTED_LOCALES = _.keys(MESSAGES);
export const DEFAULT_LANG = 'en';

// Register react-datepicker locales
registerLocale('cs', rdpCS);
registerLocale('da', rdpDA);
registerLocale('de', rdpDE);
registerLocale('en', rdpEN);
registerLocale('es', rdpES);
registerLocale('fi', rdpFI);
registerLocale('fr', rdpFR);
registerLocale('it', rdpIT);
registerLocale('nl', rdpNL);
registerLocale('no', rdpNO);
registerLocale('pl', rdpPL);
registerLocale('pt', rdpPT);
registerLocale('se', rdpSE);
registerLocale('sk', rdpSK);
registerLocale('sl', rdpSL);

// A gettext_lazy or N_ counterpart.
export function t_(str) {
    return str;
}

export function LangByRouteSetter({setLocale, children, lang}) {
    // Set locale based on the lang prefix in the route
    useEffect(() => {
        console.log('lang: ', lang);
        if (!lang) return;
        const locale = _.includes(SUPPORTED_LOCALES, lang) ? lang : DEFAULT_LANG;
        console.log('locale: ', locale);
        setLocale(locale);
    }, [lang, setLocale]);

    return <>
        {children}
    </>;
}

const LocaleContext = createContext({locale: DEFAULT_LANG});

export function useLocaleSettings() {
    return useContext(LocaleContext);
}

// Note: Keep these options logically in sync with options in .babelrc for `i18next-extract` extraction plugin.
// Not using ICU plugin suggested by react-i18next. Seems to be outdated and actually not parse number formats.
use(initReactI18next).init({
    resources: MESSAGES,
    lng: 'en',
    cleanCode: true,
    interpolation: {escapeValue: false},
    fallbackLng: false,
    debug: true,
    keySeparator: false,   // Otherwise dot in sentences splits the strings into hierarchy.
    nsSeparator: false,
    contextSeparator: '##',   // Keep in sync with package.json and .babelrc
    pluralSeparator: '__',    // Keep in sync with package.json and .babelrc
    namespaceSeparator: false,
    returnEmptyString: false,
    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ["a", "br", "strong", "i", "a", "b", "em", "small", "span", "img", "p"]
    }
});

function useLocaleFromRoute() {
    const {pathname} = useLocation(),
        match = pathname?.match(/[/](?<lang>[a-z]{2})/i),
        groups = match?.groups,
        lang = groups?.lang?.toLowerCase(),
        locale = _.includes(SUPPORTED_LOCALES, lang) ? lang : null;
    return locale;
}

export function LocaleProvider({children, head, tail}) {
    // The following rules apply:
    // Root page is redirected to /<detected-lang or /en if not supported. Language switcher is supported.
    // App pages are served from /<page> (non-localized page title). Language is set the following:
    //  * when explicitly selected -> user profile -> local storage
    //  * stored in user profile
    //  * stored in local storage
    //  * inherited from what was set by the root page
    // Non-app localized pages are served from /<detected-lang/<localized-page-title>
    const
        [finalLocale, setFinalLocale] = useState(null),
        {i18n} = useTranslation(),
        [storageLocale, setLocalStorageLocale] = useLocalStorage('locale'),
        [userData, setUserData, {isSignedIn}] = useCurrentUserData({require: false}),
        localeFromRoute = useLocaleFromRoute(),
        userLocale = userData?.locale;

    const getBrowserLocale = useCallback(() => {
        const splitLangCountry = (langCountry) => _.first(_.split(langCountry, '-', 1)),
            preferredLanguages = _.map(navigator.languages ?? [navigator.language], splitLangCountry),
            matchingLocale = _.first(_.intersection(preferredLanguages, SUPPORTED_LOCALES)) || DEFAULT_LANG;
        return matchingLocale;
    }, []);

    const _setFinalLocale = useCallback((locale) => {
        console.log('LocaleProvider._setFinalLocale: Setting locale to: ', locale);
        return setFinalLocale((prevLocale) => _.isEqual(prevLocale, locale) ? prevLocale : locale);
    }, [setFinalLocale]);

    useEffect(function applyLocale() {
        if (finalLocale) {
            console.log('LocaleProvider.applyLocale: Applying new locale to i18n modules', finalLocale);
            JavascriptTimeAgo.setDefaultLocale(finalLocale);
            setDefaultLocale(finalLocale);
            i18n.changeLanguage(finalLocale);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n, finalLocale]);

    useEffect(function setLocaleFromUser() {
        console.log('LocaleProvider.setLocaleFromUser', {isSignedIn, userLocale, localeFromRoute});
        isSignedIn && userLocale && !localeFromRoute && _setFinalLocale(userLocale);
    }, [isSignedIn, localeFromRoute, userLocale, _setFinalLocale]);

    useEffect(function setLocaleFromStorageOrBrowser() {
        const locale = storageLocale || getBrowserLocale();
        console.log('LocaleProvider.setLocaleFromStorageOrBrowser', {storageLocale, locale, localeFromRoute, noUserLocale: !userLocale});
        if (localeFromRoute) {
            _setFinalLocale(localeFromRoute);
        } else {
            locale && !userLocale && _setFinalLocale(locale);
        }
    }, [localeFromRoute, storageLocale, userLocale, getBrowserLocale, _setFinalLocale]);

    const setSelectedLocale = useCallback(function setSelectedLocale(locale) {
        if (locale) {
            console.log('LocaleProvider.setSelectedLocale', locale);
            isSignedIn ?
                setUserData({locale}, {merge: true}) :
                setLocalStorageLocale(locale);
        }
    }, [isSignedIn, setUserData, setLocalStorageLocale]);

    useShallowCompareEffect(function setStorageLocaleFromUser() {
        console.log('LocaleProvider.setStorageLocaleFromUser', userLocale);
        userLocale && setLocalStorageLocale(userLocale);
    }, [userLocale, setLocalStorageLocale]);

    return <LocaleContext.Provider value={{locale: finalLocale, setLocale: setSelectedLocale}}>
        {head}
        {children}
        {tail}
    </LocaleContext.Provider>;
}

export function RedirectToLocaleRoute() {
    const {locale} = useLocaleSettings();
    console.log('RedirectToLocaleRoute', locale);
    if (locale) {

    }
    return locale ? <Navigate to={`/${locale}`}/> : <></>;
}

