import polyline from '@mapbox/polyline';
import {asyncCycle, asyncInterleaveReady, asyncWrap, range} from 'iter-tools';
import _ from 'lodash';
import {useCallback, useContext, useMemo} from 'react';
import {retryAsync} from 'ts-retry';
import {fetchRoutesInVicinity} from './fetchRoutes';
import {FirebaseAuth} from './firebase';
import {asyncRoundRobinAll, useInfiniteIteratorQuery} from './iteratorPager';
import {queryValhallaTrack, VALHALLA_PRECISION} from './routing';
import {fetchBackendAPI} from './util';


const DISTANCES = [50, 80, 120];

export const ROUTE_PROPOSALS = {
    USER: 'USER',
    AI_INSPIRATION: 'AI_INSPIRATION',
    DYNAMIC_ROUNDTRIP: 'DYNAMIC_ROUNDTRIP'
};

const FETCHER_RETRY_OPTIONS = {
    delay: 0,
    maxTry: 2
};

function retriedFetcher(fetcherFn) {
    return async function retriedWithExceptionCatch(...args) {
        try {
            return await retryAsync(async () => fetcherFn(...args), FETCHER_RETRY_OPTIONS);
        } catch (e) {
            console.log(`retriedFetcher: Exhaused ${FETCHER_RETRY_OPTIONS.maxTry}: ${e}`);
            return undefined;
        }
    };
}

async function* fetchDynamicProposals({bikeKind, start, suitability, hills, popularity, mainRoads, ridingSpeed}) {
    const roundtripProposalFetcher = retriedFetcher(async function roundtripProposalFetcher(distance) {
        const roundtrip = {distance: distance * 1000};  // Remaining required roundtrip params are applied by /roundtrip_route proxy endpoint.
        const track = await queryValhallaTrack([start], [true],
            {bikeKind, suitability, hills, popularity, mainRoads, ridingSpeed, roundtrip, avoidRidden: 0},
            // Call routing service through backend proxy to generate random roundtrip params, and thumbnail.
            // Backend proxy also filters out (retries) on nonsense tracks.
            `${process.env.REACT_APP_BACKEND_URL}/api/roundtrip_route`);

        return {..._.omit(track, 'summary'), proposal: ROUTE_PROPOSALS.DYNAMIC_ROUNDTRIP, route: [start]};
    });

    for await (const distances of asyncCycle([DISTANCES])) {
        // Launch all 3 distances in parallel to speed up.
        const fetchers = _.map([...distances], (d) => asyncWrap([roundtripProposalFetcher(d)]));
        for await (const proposal of asyncInterleaveReady(...fetchers))
            if (proposal) {
                console.log('fetchDynamicProposals yielding');
                yield proposal;
            }
    }
}

async function* fetchUserProposals(user, start, bikeKind) {
    if (!start) return;
    for await (const routeSnap of fetchRoutesInVicinity(user, start, bikeKind)) {
        // Convert to "inspiration" format.
        const {track, route, routeProps} = routeSnap.data(),
            {bbox, distance} = track,
            {name, owner, thumbnail} = routeProps,
            inspiration = {
                bbox, distance, name, owner, route,
                proposal: ROUTE_PROPOSALS.USER,
                // Lazy load track shape, to avoid yanking when fetching proposals. Will be accessed only on hover.
                get trackShape() {
                    return polyline.encode(track.coordinates, VALHALLA_PRECISION);
                },
                thumbnail: thumbnail?.downloadUrl,
                // Beyond inspiration format - allows to dispatch 'use' action on the route with all the details.
                routeProps, track
            };
        yield inspiration;
    }
}

const PROD_BUCKET = 'cycling-routes',
    PHOTOS_BUCKET = process.env.PHOTOS_BUCKET || process.env.REACT_APP_PHOTOS_BUCKET || PROD_BUCKET;
// For production bucket use CloudFlare public URL. Otherwise, access Backblaze directly.
export const IMAGES_URL = PHOTOS_BUCKET !== PROD_BUCKET ? `https://f003.backblazeb2.com/file/${PHOTOS_BUCKET}` : 'https://images.tarmacs.app/cycling-routes';

export function routeMapUrl({country_slug, region_slug, locality_slug, bike_kind, id}) {
    return `${IMAGES_URL}/routemaps/600/${country_slug}/${region_slug}/${locality_slug}/${bike_kind}/${id}.webp`;
}

async function* fetchAIGeneratedProposals(start, bike_kind, distance = 20) {
    if (!start) return;
    const fetchBackendApiRetried = retriedFetcher(fetchBackendAPI),
        [lat, lng] = start;

    for (const page of range()) {
        const inspirationsPage = await fetchBackendApiRetried('/api/inspirations/routes', {lng, lat, bike_kind, distance, page});
        if (_.isEmpty(inspirationsPage)) return;
        for (const insp of inspirationsPage) {
            // Convert to unified "inspiration" format.
            const {ascent, difficulty, distance, epicness, keynote, route: trackShape, summary, title: name} = insp,
                inspiration = {
                    proposal: ROUTE_PROPOSALS.AI_INSPIRATION,
                    ascent, difficulty, distance, epicness, keynote, name, summary,
                    thumbnail: routeMapUrl(insp),
                    trackShape // this is already a polyline
                };
            console.log('fetchAIGeneratedProposals yielding');
            yield inspiration;
        }
    }
}

/**
 * Fetch route inspirations.
 *
 * Start with real-people routes around (match by bikeKind and start within RADIUS).
 * When exhausted, generate inspirations by our custom Valhalla roundtrip route generator.
 */
async function* generateInspirations(user, {bikeKind, distance, hills, mainRoads, popularity, ridingSpeed, start, suitability}) {
    if (!start) return;

    const asyncIterators = [
        fetchUserProposals(user, start, bikeKind, distance),
        fetchAIGeneratedProposals(start, bikeKind, distance),
        // Round-trip proposals have value for exact-start proposals.
        // For loose matching they are not so attractive as stock inspirations which are augmented with AI content.
        ...(distance ? [] : [fetchDynamicProposals({bikeKind, start, suitability, hills, popularity, mainRoads, ridingSpeed})])
    ];
    // asyncInterleaveReady seemed to be buggy and fetches inifinitely, overloading thumbnailer, but somehow this works now.
    // yield* asyncRoundRobinAll(...asyncIterators);
    yield* asyncInterleaveReady(...asyncIterators);
}

export function useInspirations({bikeKind, distance, hills, mainRoads, popularity, ridingSpeed, start, suitability}, pageSize, queryOptions = {}) {
    const {user} = useContext(FirebaseAuth),
        generatorFn = useCallback(async function* generatorFn() {
            console.log('useInspirations.generatorFn: yielding START');
            const inspirationsGenerator = generateInspirations(user, {bikeKind, distance, hills, mainRoads, popularity, ridingSpeed, start, suitability});
            for await (const inspiration of inspirationsGenerator) {
                // Annotate with lazy evaluated track feature, to avoid UI yanking when fetching proposals.
                Object.defineProperty(inspiration, 'trackFeature', {
                    get: function() { // noinspection JSUnresolvedReference
                        return this.trackShape ? polyline.toGeoJSON(this.trackShape, VALHALLA_PRECISION) : null;
                    },
                    enumerable: true, configurable: true
                });
                yield inspiration;
            }
        }, [bikeKind, distance, hills, mainRoads, popularity, ridingSpeed, start, suitability]),
        // Prevent destroying thumbnail components when fetching suggestions after location change.
        options = useMemo(() =>
            ({...queryOptions, keepPreviousData: true}), [queryOptions]);

    const {data: {pages} = {}, fetchNextPage, refetch, ...rest} = useInfiniteIteratorQuery(['roundtrip-proposals', {bikeKind, start}], generatorFn, pageSize, options);
    return {pages, fetchNextPage, refetch, ...rest};
}