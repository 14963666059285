import styled from '@emotion/styled';


export const Signet = styled.img`
    height: 40px;
`;

export const Logotype = styled.span`
    color: white;
    padding-left: 0.8em;
    font-size: larger;
    font-style: italic;
    font-variant: small-caps;
    small {
        font-weight: bold;
    }
`;