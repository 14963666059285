import styled from '@emotion/styled';
import {Content, Item, Root, Trigger} from '@radix-ui/react-accordion';
import classNames from 'classnames';
import _ from 'lodash';
import {memo, useState} from 'react';
import {Card} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import {useCustomCompareEffect} from 'react-use';
import {ErrorBoundary} from '../lib/errors';
import {ACCORDION_HEIGHT} from '../lib/useInitMaplibreLocation';
import {consumeProps, useDeviceTypeVariant} from '../lib/util';
import {ZINDEX} from '../lib/zindexes';
import {ElevationViewer} from './ElevationViewer';
import {InspirationsSelectorAccordionItem} from './InspirationsSelector';

// width: ${props => props['data-state'] === 'open' ? props.width : 0};
const HorizontalAccordionCardBody = styled(Card.Body)`
    padding: 0;
`;

const HorizontalAccordionItemCard = styled(Card)`
    width: ${props => props['data-state'] === 'open' ? '100%' : 'auto'};
`;

export function HorizontalAccordionItem({className, eventKey, header, children}) {
    return <Item value={eventKey} asChild>
        <HorizontalAccordionItemCard className={classNames("bg-light m-0 flex-row align-items-stretch h-100", className)}>
            <Trigger asChild>
                <Card.Header className="px-1 flex-grow-0 flex-shrink-0 text-center" role="button"
                             style={{writingMode: 'vertical-lr', transform: 'rotate(180deg)', transformOrigin: 'center center'}}>
                    {header}
                </Card.Header>
            </Trigger>
            <Content asChild>
                {/* Any width less than w-100 will do here. Cannot fully understand that. */}
                <HorizontalAccordionCardBody className="flex-grow-1 p-0 w-25">
                    {children}
                </HorizontalAccordionCardBody>
            </Content>
        </HorizontalAccordionItemCard>
    </Item>;
}

function ElevationViewerAccordionItem({coordinates, elevation, elevationViewerSegment, eventKey, pois, setVolatileCoordinate, steepness}) {
    return <HorizontalAccordionItem eventKey={eventKey}
                                    header={<Trans>Elevation</Trans>}>
        <ElevationViewer width="100%" height="100%"
                         {...{coordinates, elevation, elevationViewerSegment, pois, setVolatileCoordinate, steepness}} />
    </HorizontalAccordionItem>;
}

const OverAccordionDiv = styled('div', consumeProps('bottom'))`
    position: absolute;
    right: 0;
    bottom: ${_.property('bottom')};
    padding: 0 3px;
`;

export const MapHorizontalAccordion = memo(function MapHorizontalAccordion({
    bikeKind, children, dispatchRoute, elevationViewerSegment, hills, mainRoads, onPreviewRoute,
    pois, popularity, ridingSpeed, setVolatileCoordinate, size, start, suggested, suitability, track
}) {
    // 'jail-mouse-move' prevents TrackPolyline from handling mouse moves originating from this control.
    // Cannot use stopPropagation because TrackPolyline receives the event first.
    const {coordinates, elevation, steepness} = track ?? {},
        heightPx = useDeviceTypeVariant(ACCORDION_HEIGHT),
        height = `${heightPx}px`,
        hasRoute = size >= 2,
        [activeKey, setActiveKey] = useState(!hasRoute ? 'inspirations' : 'elevation'),
        expanded = !_.isEmpty(activeKey);

    useCustomCompareEffect(() => {setActiveKey(!hasRoute ? 'inspirations' : 'elevation');}, [size, setActiveKey],
        ([prevSize], [size]) => (prevSize < 2) === (size < 2));

    return <ErrorBoundary silent resetKeys={[coordinates, elevation, elevationViewerSegment, pois, steepness]}>
        {expanded && <OverAccordionDiv className="maplibregl-container-reboot hstack align-items-end w-100" bottom={height}>{children}</OverAccordionDiv>}
        <div style={{zIndex: ZINDEX.MAP_ACCORDION, height}} className="start-0 bottom-0 position-absolute maplibregl-container-reboot jail-mouse-move w-100">
            <Root type="single" value={activeKey} orientation="horizontal" collapsible onValueChange={setActiveKey} className="hstack h-100">
                {size >= 2 && <ElevationViewerAccordionItem eventKey={'elevation'}
                                                            {...{coordinates, elevation, elevationViewerSegment, pois, setVolatileCoordinate, steepness}} />}
                <InspirationsSelectorAccordionItem
                    active={activeKey === 'inspirations'}
                    header={<span className="align-middle"><Trans>Inspirations</Trans></span>}
                    eventKey={'inspirations'} {...{
                    bikeKind, dispatchRoute, hasRoute, hills, mainRoads, popularity, onPreviewRoute,
                    ridingSpeed, start, suitability
                }}/>
                {!expanded && <div className="flex-grow-1 align-self-end hstack align-items-end">{children}</div>}
            </Root>
        </div>
    </ErrorBoundary>;
});