import styled from '@emotion/styled';
import jumbotronBackground from 'images/jumbotron.jpg'; // https://unsplash.com/photos/X4dBqRUzO2U
import {useContext, useEffect} from "react";
import {Button, Container, Nav, Navbar} from "react-bootstrap";
import {Helmet} from 'react-helmet';
import {Trans, useTranslation} from 'react-i18next';
import {BsFillGrid3X2GapFill} from 'react-icons/bs';
import {FaRoute} from 'react-icons/fa';
import {MdExitToApp} from 'react-icons/md';
import {LinkContainer} from 'react-router-bootstrap';
import {Link, NavLink, Outlet} from "react-router-dom";
import logo192 from '../images/logo192.png';
import {FirebaseAuth} from '../lib/firebase';
import privacyPolicyHtml from '../privacy-policy.html';
import {FirebaseAuthConsumer} from './auth/FirebaseAuth';
import {Logotype, Signet} from './Brand';
import {useDetectGeoLocation} from './GeoLocationContext';
import {LanguageSelector, UserMenu} from './User';


const TransparentJumbotron = styled(Container)`
    background-color: rgba(0, 0, 0, 0);
    color: white;
`;

const JumbotronContainer = styled(Container)`
    background-color: rgba(0, 0, 0, .5);
    padding: 4rem;
`;

const PolicyContainer = styled(Container)`
    background-color: rgba(0, 0, 0, .5);
    padding: 4rem;
    color: white;
`;

const StickyFooter = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
`;

const HoverLink = styled(Nav.Link)`
    color: var(--bs-light) !important;

    &:hover {
        color: var(--bs-info) !important;
    }
`;

function LandingNavBar() {
    const {isSignedIn} = useContext(FirebaseAuth),
        {t} = useTranslation();

    return <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect><Container fluid>
        <LinkContainer to="/"><Navbar.Brand bg="dark">
            <Signet src={logo192}/>
            <Logotype className="align-baseline d-none d-sm-inline">&nbsp;&nbsp;Tarmacs</Logotype>
        </Navbar.Brand></LinkContainer>
        <HoverLink href={t("/cycling-routes")} className="text-light align-middle ms-2 me-auto" title={t("View a comprehensive catalogue of cycling routes across Europe")}><Trans>Cycling routes
            catalogue</Trans></HoverLink>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav" className="me-auto">
            <Nav className="me-auto"></Nav>
            <Nav style={{lineHeight: '32px'}}>
                <LanguageSelector updatePagePrefix={true}/>
                {isSignedIn ?
                    <LinkContainer to="/routes/view"><Nav.Link><MdExitToApp/> <Trans>Go to app</Trans></Nav.Link></LinkContainer> :
                    <FirebaseAuthConsumer><UserMenu/></FirebaseAuthConsumer>
                }
            </Nav>
        </Navbar.Collapse></Container>
    </Navbar>;
}

export default function RootPage() {
    useEffect(() => {
        // Override background of document tag on page enter, remove override on exit.
        const {backgroundImage, backgroundPosition} = document.documentElement.style;
        document.documentElement.style.backgroundImage = `url(${jumbotronBackground})`;
        document.documentElement.style.backgroundPosition = 'center';
        document.body.style.backgroundColor = 'rgba(0,0,0,0);';

        return function cleanup() {
            document.documentElement.style.backgroundImage = backgroundImage;
            document.documentElement.style.backgroundPosition = backgroundPosition;
        };
    });

    return (<>
        <header>
            <LandingNavBar/>
        </header>
        <main>
            <Outlet/>
        </main>
        <StickyFooter>
            <Navbar collapseOnSelect fixed="bottom" bg="dark" variant="dark" className="justify-content-center">
                <Navbar.Text><p className="text-center my-0" style={{lineHeight: "1.0"}}><small style={{fontSize: 'x-small'}}>
                    <Trans>We use cookies to improve performance and enhance your experience.
                        By using our website you agree to our use of cookies in accordance with our &nbsp; <NavLink to="/privacy-policy">privacy policy</NavLink></Trans>
                </small></p></Navbar.Text>
            </Navbar>
        </StickyFooter>
    </>);
}

export function IndexPage() {
    const {t} = useTranslation(),
        title = t("Route planner app dedicated for road and gravel bikers | Tarmacs.App"),
        {isSignedIn} = useContext(FirebaseAuth),
        {setGeoLocationEnabled} = useDetectGeoLocation();

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <TransparentJumbotron as="section">
            <JumbotronContainer className="text-center">
                <h1 className="pb-4"><Trans>Bike riders want great roads</Trans></h1>
                <h2 className="pb-3 px-lg-5">
                    <Trans><strong>Tarmacs</strong> is the route planner app dedicated for road and gravel bikers.</Trans>
                </h2>
                <p><Trans><strong>Tarmacs</strong> suggests best tarmac and gravel roads thanks to synergy of social sharing.</Trans>
                </p>
                <Button size="lg" as={Link} role="button" to="/routes/edit/new" className="mt-4 font-weight-bold"
                        onClick={() => setGeoLocationEnabled(true)}
                >
                    <FaRoute/>&nbsp;&nbsp;<Trans>Plan your route!</Trans>
                </Button>
                {isSignedIn && (<>
                    <div className="m-2"><Trans>or</Trans></div>
                    <LinkContainer to="/routes/view"><Button variant="outline-light"><BsFillGrid3X2GapFill/>&nbsp;&nbsp;<Trans>View your routes</Trans></Button></LinkContainer>
                </>)}
            </JumbotronContainer>
        </TransparentJumbotron>
    </>;
}

export function PrivacyPolicy() {
    return <PolicyContainer>
        <Helmet>
            <meta name="robots" content="noindex"/>
            <title>Privacy Policy | Tarmacs.App</title>
        </Helmet>
        <div dangerouslySetInnerHTML={{__html: privacyPolicyHtml}}/>
    </PolicyContainer>;
}