import polyline from '@mapbox/polyline';
import {bbox, destination, distance, ellipse, getCoords, point, simplify, transformScale} from '@turf/turf';
import JSD from 'json-stringify-date';
import _ from 'lodash';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useCustomCompareEffect, usePrevious} from 'react-use';
import {v4 as uuidv4} from 'uuid';
import {useOAuthActivity, useOAuthSaga} from '../components/auth/OAuth';
import {usePageLoadingToggle} from '../components/PageLoading';
import {useViewState} from '../components/ViewStateContext';
import {routeConverter} from './converters';
import {newDocPath, useFirebaseDocData, useFirebaseDocMutationInvalidate} from './db';
import {errorHandler} from './errors';
import {FirebaseAuth} from './firebase';
import {ga} from './ga';
import {toLatLngArray, toLngLat} from './getClosestPointAndSegment';
import {getSliceRange, mergeTrackSlice, NO_TRACK, queryValhallaFullTrack, queryValhallaTrackDetails, TRACK_VERSION, VALHALLA_PRECISION, visiblePOIsQueryParams} from './routing';
import {showError, showRoutingError} from './toasts';
import {useSendToGarmin} from './useSendToGarmin';
import {useThunkFunctionsReducer} from './useThunkReducer';
import {useSearchParamsObject} from './util';


async function requestTrack({route, routePOIs, routeProps, dirty = false}) {
    if (!dirty)
        return;

    const isRoundtrip = routeProps.roundtrip && _.size(route) === 1;
    if (_.size(route) < 2 && !isRoundtrip) {
        return {coordinates: null, routePoints: null, bbox: null, steepness: null};
    }

    const breaks = _.map(routePOIs, _.negate(_.isNil));
    const track = await queryValhallaFullTrack(route, breaks, routeProps);
    return track;
}

async function requestTrackSlice({type, idx, route, routePOIs, routeProps}) {
    const {replaced, range} = getSliceRange(type, idx, route);
    const track = await requestTrack({route: route.slice(...range), routePOIs: routePOIs?.slice(...range), routeProps, dirty: true});
    return {track, replaced, range};
}

function getSpinningIndices(type, idx, route) {
    return {insert: [idx ?? _.size(route) - 1], move: [idx], delete: [idx - 1, idx]}[type];
}

const NEW_ROUTE_STATE = {
    // User-entered route points
    route: [], // Routed track coordinates and mapping of coordinates to route points
    routePOIs: [],
    track: {...NO_TRACK}, // Remaining user-entered attributes
    routeProps: {
        bikeKind: 'road', description: '', hills: 0.75, mainRoads: 1.0,
        medium: 'bicycle', name: '', plannedDate: null, popularity: 1.0,
        ridingSpeed: null, routeType: 'ride', showPopularity: true,
        showTrackPOIs: true, showTrails: true,
        suitability: 0.9, weatherOnRoute: true
    },
    suppressTour: true
};

function routeReducer(state, act) {
    state = state ?? {};
    const {type, ...params} = act;
    const idx = params.idx ?? _.size(state.route);
    const prevRoutePOIs = state.routePOIs ?? new Array(_.size(state.route));
    const affectedIndices = {insert: [idx, idx], move: [idx, idx + 1], delete: [idx, idx + 1]}[type];

    const slicer = (prev, val, after, before) => [...prev.slice(0, after), ...(_.isUndefined(val) ? [] : [val]), ...prev.slice(before)],
        deleter = (indices) => ({
            ...state, dirty: true,
            route: slicer(state.route, undefined, ...indices),
            routePOIs: slicer(prevRoutePOIs, undefined, ...indices)
        });

    // noinspection JSUnusedGlobalSymbols
    const actions = {
        // Ensure we inserted null POI when non-POI point was inserted.
        insert: ({coordinate, poi = null}) => ({
            ...state, dirty: true,
            route: slicer(state.route, coordinate, ...affectedIndices),
            routePOIs: slicer(prevRoutePOIs, poi, ...affectedIndices)
        }),
        showModal: ({modal, ...modalOptions}) => ({...state, showModal: modal, modalOptions}),
        move: ({coordinate, poi = null}) => {
            // Return original state to avoid updates. Handles 'onPointMove' caused by non-dragging clicks.
            return _.isEqual(state.route[idx], coordinate) ? state : {
                ...state, dirty: true,
                route: slicer(state.route, coordinate, ...affectedIndices),
                routePOIs: slicer(prevRoutePOIs, poi, ...affectedIndices)
            };
        },
        delete: () => deleter(affectedIndices),
        stayHere: () => _.omit(deleter([0, idx - 1]), 'showModal', 'modalOptions'),
        scrollBack: () => _.omit(deleter([idx - 1, idx]), 'showModal', 'modalOptions'),
        use: ({route, routePOIs, track, routeProps, dirty = false}) => (
            _.isEqual(state, {dirty, route, routePOIs, routeProps, track}) ?
                state :
                {
                    ...state,
                    ...(routeProps.suggested ? {base: state.route} : {}),
                    route, routePOIs, track,
                    routeProps: {...state.routeProps, ..._.omit(routeProps, 'garmin_id', 'garmin_ids', 'owner', 'starred', 'praised')}, dirty
                }
        ),
        dirty: ({dirty = true}) => ({...state, dirty}),
        activateFetch: (params) => ({...state, activeFetches: [...state.activeFetches ?? [], params]}),
        cancelFetch: ({uuid}) => ({...state, activeFetches: _.reject(state.activeFetches ?? [], {uuid})}),
        setTrack: ({dirty = false, replaced, track, uuid}) => {
            const newTrack = {...mergeTrackSlice(state.track, track, replaced), uuid};
            return {..._.omit(state, 'trackShape'), track: newTrack, route: (params.route ?? state.route), dirty, activeFetches: _.tail(state.activeFetches), loading: false};
        },
        // "Trash can" action: reset route, but keep preferences and optionally start point.
        reset: ({keepStart}) => ({
            ...state, track: NO_TRACK,
            route: (keepStart ? _.take(state.base ?? state.route) : []),
            routePOIs: (keepStart ? _.take(prevRoutePOIs) : []),
            routeProps: {...state.routeProps, suggested: false}
        }),
        // "Plan new route" action: reset everything.
        new: ({suppressTour}) => ({...NEW_ROUTE_STATE, suppressTour}),
        // Danger: _.reverse mutates array. Slice it before.
        reverse: ({dirty = false}) => ({...state, route: _.reverse(_.slice(state.route)), routePOIs: _.reverse(_.slice(prevRoutePOIs)), dirty}),
        setRouteProps: ({routeProps, dirty = false}) => ({...state, routeProps, dirty}),
        updateRouteProps: ({routeProps, dirty = false, save = false}) => ({...state, routeProps: {...state.routeProps, ...routeProps}, dirty}),
        saveRoute: ({routeProps, selectedPOIs, followUp}) => ({...state, routeProps: {...state.routeProps, ...routeProps}, selectedPOIs, followUp}),
        loadRoute: ({routeId, followUp, suppressTour}) => ({...state, id: routeId, followUp, suppressTour}),
        readFromStorage: () => ({...state}),
        loadedRoute: (params) => ({...state, ...params}),
        savedToStorage: ({uuid}) => ({...state, saved: uuid}),
        proposeRoundtrip: ({roundtrip: {ellipse_ratio, bearing, distance, ideal_snap}, avoidRidden}) => ({
            ...state, dirty: true, routeProps: {...state.routeProps, avoidRidden, roundtrip: {ellipse_ratio, bearing, distance, ideal_snap}}
        }),
        // Apply pre-fetched route (e.g roundtrip). Will launch valhalla for filling missing details (trace_path + height calls).
        // Store base point to allow for coming back to picking other ones.
        applyRoundtripRoute: ({profile, route, trackShape}) => ({
            ...state, base: state.route, profile, route, trackShape, loading: true, routeProps: {...state.routeProps, suggested: true}
        }),
        // Apply imported route. Will launch valhalla for filling missing details (trace_path + height calls) too.
        importRoute: ({geojson, tolerance}) => ({...state, geojson, tolerance, loading: true}),
        // Inspirations integration. Note there are thunk functions below (same names).
        importInspiration: ({inspiration, followUp, suppressTour}) => ({...state, inspiration, followUp, loading: true, suppressTour}),
        // Edit action is simple.
        edit: () => state,
        // Three steps of "send to gps" action. Complex because we need intermediate OAuth, save and navigate (path param change).
        saveAndSendToGPS: () => _.omit(state, 'followUp'),
        sendToGPS: () => state,
        showGPSDialog: () => state,
        saveToGarmin: () => _.omit(state, 'followUp')
    };

    return actions[type](params);
}

export function loadFromBrowserStorage() {
    // sessionStorage would do better than localStorage by allowing multi-tab, but it will not do with "continue in this tab" in magic link flow.
    // TODO: consider sessionStorage only for magic links. Also test if normal oauth will work with Firefox containers.
    // See also: a340b8f570a7a83cbce2f2982612ef75e27b16cf (magic links introduced)
    const sessionValue = window.localStorage.getItem('routePlannerState'),
        content = JSD.parse(sessionValue) ?? {};
    return content;
}

async function saveToBrowserStorage(state) {
    // 'id' would overwrite a new one set after OAuth roundtrip, resulting in saving other route then the exported/redirected to.
    const sessionValue = JSD.stringify(_.omit(state, 'callbacks', 'activeFetches', 'followUp', 'loading', 'id'));
    try {
        window.localStorage.setItem('routePlannerState', sessionValue);
    } catch (exc) {
        errorHandler.report(exc);
    }
}

function useRouteId(routeIdPathParam) {
    const currentId = routeIdPathParam === 'new' ? null : routeIdPathParam,
        [{routeId, isNew}, setRouteId] = useState({routeId: currentId, isNew: _.isNil(currentId)});

    useEffect(() => {
        if (_.isNil(currentId)) {
            setRouteId({routeId: _.last(newDocPath('routes', currentId)), isNew: true});
        } else {
            setRouteId({routeId: currentId, isNew: false});
        }
    }, [currentId, setRouteId]);

    return {routeId, isNew};
}

const NOGO_AREA_POLY = 32;

/**
 * Calculate "no go" ellipse for distance
 * L = pi * (3/2 * (a + b) - sqrt(a * b))
 * ratio = a / b
 * a = L / (pi * (3/2 * (1 + 1/ratio) - 1/sqrt(ratio)))
 */
export function calcNoGoArea({start, distance, bearing, axisRatio}) {
    if (!distance || !_.isNumber(bearing) || !axisRatio || !start) return null;
    const a = distance / (Math.PI * (3 / 2 * (1 + 1 / axisRatio) - 1 / Math.sqrt(axisRatio))) / 1_000,
        b = a / axisRatio;

    const startPoint = point(toLngLat(start)),
        center = destination(startPoint, a, bearing, {units: 'kilometers'}),
        ideal = ellipse(center, a, b, {angle: bearing - 90, steps: NOGO_AREA_POLY}),
        noGo = transformScale(ideal, 0.5);

    return {ideal, noGo};
}

/**
 * Fetch track or its fragment, maintaining original calls order when setting results to state.
 *
 * AJAX requests for fetching tracks or their parts can resolve in random order.
 * Ensure we call setTrack in the original order:
 *  * `activateFetch` action pushes our uuid on the end of activeFetches queue (list).
 *  * each `setTrack` pops an uuid from the queue
 *  * `cancelFetch` action handles error.
 *  * we hook on `setTrack` actions until we find our uuid to be first and then we call our `setTrack`.
 */
async function fetchInOrder(dispatch, getState, promise, uuid, indices, save) {
    dispatch({type: 'activateFetch', promise, uuid, indices});

    try {
        const result = await promise;
        // The request for this route change finished, but preceding might have not finished yet.
        // Wait until we are first in the FIFO.
        let {activeFetches} = getState();
        console.debug(`Awaiting for ${uuid} while active fetches are:`, _.map(activeFetches, 'uuid').join(','));
        // isEmpty makes sense when clicking "plan new route" while drawing a track.
        while (!_.isEmpty(activeFetches) && _.head(activeFetches)?.uuid !== uuid) {
            await _.head(activeFetches).promise;
            activeFetches = getState().activeFetches;
            console.debug(`Awaiting for ${uuid} while active fetches are:`, _.map(activeFetches, 'uuid').join(','));
        }
        dispatch({type: 'setTrack', ...result, dirty: false, indices, uuid, save});
    } catch (ex) {
        dispatch({type: 'cancelFetch', uuid, indices});
        showRoutingError(ex);
    }
}

const INSERT_THRESHOLD = 200;

/** Prevent unintended adding next point 200+ kms from first one. */
async function insert(dispatch, getState, action) {
    const {coordinate} = action,
        {route} = getState(),
        prevRoutePoint = _.nth(route, -2);

    if (prevRoutePoint) {
        const points = [point(toLngLat(prevRoutePoint)), point(toLngLat(coordinate))];
        const dist = distance(...points, {units: 'kilometers'});
        if (dist > INSERT_THRESHOLD) {
            dispatch({type: 'showModal', modal: 'ConfirmInsertPoint', distance: dist, points});
            return;
        }
    }

    return await refetchTrack(dispatch, getState, action);
}

async function refetchTrack(dispatch, getState, action) {
    const {type, idx, save} = action;
    const {id, route, routePOIs, routeProps, dirty, track: prevTrack, followUp} = getState();
    const refetchLegacyTrack = prevTrack?.version !== TRACK_VERSION;  // && _.includes(['loadedRoute', 'use'], type);

    if (!(dirty || refetchLegacyTrack)) {
        if (save && id) {
            dispatch({type: 'saveRoute', routeProps: {}});
        } else if (followUp) {
            dispatch({type: followUp});
        }
        return;
    }

    const uuid = uuidv4(),
        indices = getSpinningIndices(type, idx, route),
        promise = requestTrackSlice({type, idx, route, routePOIs, routeProps, track: prevTrack});
    await fetchInOrder(dispatch, getState, promise, uuid, indices, save);
}

async function importRoute(dispatch, getState, {geojson, tolerance = 0.015}) {
    const {name} = geojson.properties ?? {},
        routeProps = {
            ...(name ? {name} : {}),
            // For max accurate GPX tracking - do not care for popularity or suitability.
            ...(tolerance < 0.015 ? {popularity: 0, suitability: 0} : {})
        },
        simplified = simplify(geojson, {highQuality: true, tolerance}),
        // Crop potential height annotation from the coords.
        route = _.map(toLatLngArray(getCoords(simplified)), (coord) => _.take(coord, 2));

    dispatch({type: 'use', route, routePOIs: new Array(_.size(route)), track: {...NO_TRACK}, routeProps, dirty: true});
}

async function importInspiration(dispatch, getState, {inspiration, followUp}) {
    const {coords, bikeKind, inspirationId, name} = inspiration,
        route = toLatLngArray(coords),
        // Relax preferences, to avoid distorting original gravel inspiration, which is often driven by "circularity".
        propsAdjust = bikeKind === 'gravel' ? {suitability: 0.9, popularity: 0.5, hills: 1.2} : {},
        routeProps = {bikeKind, inspirationId, name, useFerries: false, ...propsAdjust};

    dispatch({type: 'use', route, routePOIs: new Array(_.size(route)), track: {...NO_TRACK}, routeProps, dirty: true, followUp});
    ga('inspiration_import', {action: followUp, bikeKind});
}

async function applyRoundtripRoute_(dispatch, getState, {profile, trackShape, type}) {
    function getRouteFromTrack(shape) {
        const geojson = polyline.toGeoJSON(shape, VALHALLA_PRECISION),
            routeMLS = simplify(geojson, {highQuality: true, tolerance: 0.03}),
            [x1, y1, x2, y2] = bbox(geojson),
            route = toLatLngArray(getCoords(routeMLS));

        return {route, bbox: [[y1, x1], [y2, x2]]};
    }

    async function fetchTrack() {
        const {replaced, range} = getSliceRange(type, null, getState().route),
            {route, bbox} = getRouteFromTrack(trackShape),
            track = await queryValhallaTrackDetails(profile, route, trackShape);

        return {track, replaced, range, route, bbox};
    }

    const uuid = uuidv4(),
        promise = fetchTrack();

    await fetchInOrder(dispatch, getState, promise, uuid, []);
}

async function readFromStorage(dispatch) {
    const data = await loadFromBrowserStorage();
    // 'id' would overwrite a new one set after OAuth roundtrip, resulting in saving other route then the exported/redirected to.
    data && dispatch({type: 'loadedRoute', ..._.omit(data, 'id')});
}

// Keep in sync with tarmacs.inspirations/src/components/TarmacsToolbar.js
const FOLLOW_UP_ACTIONS = ['edit', 'saveAndSendToGPS'];

function useQueryParamsAction({action, bikeKind, coords, inspirationId, name, storage, routeId}) {
    const prevRouteId = usePrevious(routeId),
        prevStorage = usePrevious(storage),
        isNew = !routeId || (routeId === 'new'),
        [type, params] = useMemo(() => {
            // Complex but ensures that the edited route is not reloaded on 1st edit (like point drag), which resulted in undoing this edit.
            if (!isNew && (prevRouteId !== routeId || (!storage && storage !== prevStorage)))
                return ['loadRoute', {routeId, followUp: action, suppressTour: true}];

            try {
                if (_.includes(FOLLOW_UP_ACTIONS, action)) {
                    if (coords && bikeKind && name) {
                        // Tour is not sensible for "export" mode as there is a modal shown. Flag as already viewed.
                        const suppressTour = action === 'saveAndSendToGPS';
                        return ['importInspiration', {inspiration: {coords: JSON.parse(coords), bikeKind, name, inspirationId}, followUp: action, suppressTour}];
                    }
                }
            } catch (e) {
            }
            return storage ? ['readFromStorage', {}] : (isNew ? ['new', {suppressTour: false}] : []);
        }, [action, coords, bikeKind, name, isNew, storage, prevStorage, prevRouteId, routeId]);
    return [type, params];
}

function isQueryParamsActionEqual([, prevType, prevParams], [, nextType, nextParams]) {
    return _.isEqual(prevType, nextType) && _.isEqual(prevParams, nextParams);
}

function useInitializeFromQueryParams(dispatchRoute, routeId) {
    const [searchParams] = useSearchParamsObject(),
        {action, coords, bike_kind: bikeKind, inspiration_id: inspirationId, name, storage} = searchParams,
        [type, params] = useQueryParamsAction(({action, bikeKind, coords, inspirationId, name, routeId, storage}));

    // Load route from Firebase on URL change. Avoid running when only dispatchRoute changed.
    useCustomCompareEffect(() => {type && dispatchRoute({type, ...params});},
        [dispatchRoute, type, params],
        isQueryParamsActionEqual
    );
}


export function useRouteReducer(routeIdPathParam, {withLocalStorage = true} = {}) {
    const navigate = useNavigate(),
        [loading, toggleLoading] = usePageLoadingToggle(),
        {user} = useContext(FirebaseAuth),
        {routeId, isNew} = useRouteId(routeIdPathParam),
        [, dispatchAuthWorkflow] = useOAuthSaga(),
        {resetCameraSource} = useViewState(),
        sendToGarmin = useSendToGarmin({routeId})

    const converter = useMemo(() => routeConverter(user), [user]),
        {refetch} = useFirebaseDocData(['routes', routeId], converter, {idField: 'id'}),
        // `routeRef` is set after first save to firebase and then set in URL. Maybe this could be simplified with routeId above.
        {mutateAsync: mutateRoute} = useFirebaseDocMutationInvalidate(['routes', routeId], converter),
        [, setUrlState] = useSearchParamsObject();


    const navigateToRouteView = useCallback(function navigateToRouteView(routeId){
        console.log(`Redirecting to /routes/view/${routeId}`);
        // Mysteriously this must be done in next tick to work reliably.
        _.delay(() => navigate(`/routes/view/${routeId}`, {saved: true}));
    }, [navigate]);

    const saveRoute = useCallback(async function saveRoute(dispatch, getState, action) {
        toggleLoading(true);
        try {
            const state = getState();
            await mutateRoute(state);
            if (action.followUp) {
                dispatch({type: action.followUp});
            } else {
                toggleLoading(false);
                navigateToRouteView(routeId);
            }
        } catch (ex) {
            toggleLoading(false);
            showError(ex);
        }
    }, [isNew, mutateRoute, navigate, routeId, toggleLoading]);

    const loadRoute = useCallback(async function loadRoute(dispatch) {
        toggleLoading(true);
        try {
            // Avoid sticking with old map view state when a new route has been loaded e.g. by clicking notification link.
            resetCameraSource();
            const {data} = await refetch({throwOnError: true});
            data && dispatch({type: 'loadedRoute', ...data});
        } finally {
            toggleLoading(false);
        }
    }, [toggleLoading, refetch, resetCameraSource]);

    const setTrack = useCallback(async function setTrack(dispatch, getState, action) {
        const state = getState();
        if (withLocalStorage) {
            await saveToBrowserStorage(state);
            setUrlState(query => ({...query, storage: true}), {replace: true});
        }
        // Flagged via updateRouteProps action for immediate saving changes in plannedStart time stamp on RouteViewer.
        action.save && state.id && dispatch({type: 'saveRoute', routeProps: {}});
        // Follow-up action provided by importInspiration
        state.followUp && dispatch({type: state.followUp});
    }, [setUrlState, withLocalStorage]);

    const sendToGPS = useCallback(function sendToGPS() {
        toggleLoading(false);
        navigate(`/routes/view/${routeId}?action=showGPSDialog`);
    }, [navigate, routeId, toggleLoading]);

    const saveToGarmin = useCallback(async function saveToGarmin(dispatch, getState, action) {
        const {id, selectedPOIs} = getState();
        try {
            await sendToGarmin({params: visiblePOIsQueryParams(selectedPOIs)});
        } finally {
            navigateToRouteView(id);
        }
    }, [navigateToRouteView, sendToGarmin, toggleLoading]);

    const cleanFollowUpActionParams = useCallback(() => {
        setUrlState((params) => _.omit(params, 'coords', 'bike_kind', 'name', 'action', 'inspiration_id'), {replace: true});
    }, [setUrlState]);

    const showGPSDialog = useCallback(function showGPSDialog(dispatch) {
        dispatch({type: 'showModal', modal: 'ExportToDeviceModal', followUp: null});
        cleanFollowUpActionParams();
    }, [cleanFollowUpActionParams]);

    const saveAndSendToGPS = useCallback(async function saveAndSendToGPS(dispatch, getState, action) {
        // Avoid overloading user with clutter before the route shown on the screen.
        if (loading) return;

        cleanFollowUpActionParams();
        // If not logged in, this will proceed through sign-up/log-in and then call saveAndSendToGPS OAuth activity.
        const modalTitle = <Trans>Sign up to send to your GPS</Trans>,
            modalOptions = {explainer: "", small: true, modalTitle};
        dispatchAuthWorkflow({type: 'trigger', activity_name: 'saveAndSendToGPS', defaultModal: 'SignUp', modalOptions});
    }, [cleanFollowUpActionParams, dispatchAuthWorkflow, loading]);

    const THUNK_ACTIONS = useMemo(() => {
        async function use(dispatch, getState, action) {
            resetCameraSource();
            await refetchTrack(dispatch, getState, action);
        }

        async function applyRoundtripRoute(dispatch, getState, action) {
            resetCameraSource();
            return await applyRoundtripRoute_(dispatch, getState, action);
        }

        return {
            saveRoute, loadRoute, readFromStorage,
            insert, showModal: _.noop,
            move: refetchTrack, delete: refetchTrack,
            stayHere: refetchTrack, scrollBack: refetchTrack,
            dirty: refetchTrack, use,
            reverse: refetchTrack, loadedRoute: refetchTrack,
            setRouteProps: refetchTrack, updateRouteProps: refetchTrack,
            proposeRoundtrip: refetchTrack,
            setTrack, applyRoundtripRoute, importRoute, importInspiration,
            saveAndSendToGPS, sendToGPS, showGPSDialog, edit: cleanFollowUpActionParams,
            saveToGarmin
        };
    }, [saveRoute, loadRoute, resetCameraSource, setTrack, saveAndSendToGPS]);

    const [routeData, dispatchRoute] = useThunkFunctionsReducer(routeReducer, NEW_ROUTE_STATE, THUNK_ACTIONS);
    useInitializeFromQueryParams(dispatchRoute, routeId);

    const saveAndSendToGPSOAuthActivity = useCallback(async function saveAndSendToGPSOAuthActivity() {
        dispatchRoute({type: 'saveRoute', routeProps: {}, followUp: 'sendToGPS'});
    }, [dispatchRoute]);

    useOAuthActivity('saveAndSendToGPS', saveAndSendToGPSOAuthActivity);

    return [routeData, dispatchRoute, routeId, cleanFollowUpActionParams];
}