import styled from '@emotion/styled';
import polyline from '@mapbox/polyline';
import {bbox, feature, featureCollection} from '@turf/turf';
import _ from 'lodash';
import maplibregl from 'maplibre-gl';
import React, {useContext, useEffect, useMemo} from 'react';
import {Layer, Map, MapProvider, Source} from 'react-map-gl';
import {useLocation, useParams} from 'react-router-dom';
import {useBoolean} from 'usehooks-ts';
import {routeConverter} from '../lib/converters';
import {useFirebaseDocData} from '../lib/db';
import {FirebaseAuth} from '../lib/firebase';
import {VALHALLA_PRECISION} from '../lib/routing';
import {useMapLibre, useMapLibreEvents} from '../lib/useMapLibre';
import {useSearchParamsObject} from '../lib/util';
import {MaplibreMap} from './MaplibreMap';
import {PointsOfInterest} from './PointsOfInterest';
import {POIPopupContextProvider} from './POIPopup';
import {MapComponents} from './RouteMapping';
import {cameraForBounds, MapViewStateProvider} from './ViewStateContext';


export const StyledThumbnailerContainer = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
`;

/**
 * Bare full-page map without any UI for thumbnailing purposes.
 * Used by serverside /routes/thumbnails/{route_id} handler, which calls thumbnailer Docker service (thumbnailer.tarmacs.app) which snapshots the page.
 *
 * Weather display is disabled because times would outdate quickly and be shown in the timezone of the snapshotting server.
 */
export function Thumbnailer() {
    const {user} = useContext(FirebaseAuth),
        {routeId} = useParams(),
        {data: routeState} = useFirebaseDocData(['routes', routeId], routeConverter(user));

    return <ThumbnailerInner routeState={routeState}/>;
}

function ThumbnailerInner({routeState}) {
    const {track, pois} = routeState ?? {};

    return <MapViewStateProvider>
        <POIPopupContextProvider>
            <StyledThumbnailerContainer>
                <MaplibreMap interactive={false}>
                    <MapComponents editable={false} showControls={false} bboxMargin={0.15} showWeather={false}
                                   {...{pois, routeState}} />
                    <PointsOfInterest pois={pois} track={track}/>
                </MaplibreMap>
                <ReadyForThumbnail/>
            </StyledThumbnailerContainer>
        </POIPopupContextProvider>
    </MapViewStateProvider>;
}

const THUMBNAIL_STYLE = {
    type: 'line',
    paint: {
        'line-color': '#000000',
        'line-width': 4,
        'line-opacity': 0.5
    }
};

/**
 * Bare full-page map only with the track shape provided in the url.
 *
 * Used by serverside suggestions handler to visualize them.
 * Shape is provided as URL hash to better benefit from page/code caching.
 */
export function Shaper() {
    const location = useLocation(),
        {hash} = location,
        [{padding = '5', color = '#000000', shape}] = useSearchParamsObject(),
        shapeStr = shape ? shape : decodeURIComponent(hash.slice(1)); // Trim '#' and unpercent.

    console.log('Shaper: ', {location, hash, padding, shapeStr});
    const [geojson, bounds] = useMemo(() => {
        const track = shapeStr ? polyline.toGeoJSON(shapeStr, VALHALLA_PRECISION) : null,
            geojson = featureCollection(track ? [feature(track)] : []),
            bounds = _.isEmpty(geojson?.features) ? null : bbox(geojson);
        return [geojson, bounds];
    }, [shapeStr]);

    useEffect(() => {console.log('Polyline: ', shapeStr);}, [shapeStr]);

    return <MapProvider>
        <POIPopupContextProvider>
            <StyledThumbnailerContainer>
                <ShaperMap bounds={bounds} geojson={geojson} padding={_.toInteger(padding)} color={color}/>
            </StyledThumbnailerContainer>
        </POIPopupContextProvider>
    </MapProvider>;
}

/**
 * Signal readiness for screenshotter, which can be awaited with wait-for-selector=.thumbnail-ready
 */
function ReadyForThumbnail() {
    // noinspection JSUnusedGlobalSymbols
    const {value: ready, setTrue: setReady} = useBoolean(),
        {loaded, sourceLoaded} = useMapLibreEvents({idle: () => setReady()});

    return loaded && sourceLoaded && ready ? <div className="d-none thumbnail-ready"/> : null;
}

function ShaperMap({bounds, geojson, padding, color}) {
    const {mapLibre, loaded, sourceLoaded} = useMapLibre(),
        viewState = useMemo(() => loaded && sourceLoaded && bounds ? cameraForBounds(mapLibre, bounds, {padding}) : {},
            [mapLibre, bounds, loaded, sourceLoaded, padding]),
        style = useMemo(() => _.merge(THUMBNAIL_STYLE, {paint: {'line-color': color}}), [color]);

    return <>
        <Map mapLib={maplibregl} mapStyle={process.env.REACT_APP_TILES_URL} id="tarmacsMap" interactive={false} {...viewState} attributionControl={false}>
            <Source id="shape" type="geojson" data={geojson}>
                <Layer {...style} id="shape-layer"/>
            </Source>
        </Map>
        <ReadyForThumbnail/>
    </>;
}
