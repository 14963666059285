import emailRegexSafe from 'email-regex-safe';
import {
    createUserWithEmailAndPassword,
    EmailAuthProvider,
    fetchSignInMethodsForEmail,
    indexedDBLocalPersistence,
    isSignInWithEmailLink,
    setPersistence,
    signInWithEmailAndPassword,
    signInWithEmailLink
} from "firebase/auth";
import _ from 'lodash';
import {memo, useContext, useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Form, Modal, Navbar, Spinner, Tab, Tabs} from "react-bootstrap";
import {Trans, useTranslation} from 'react-i18next';
import {FaEnvelopeOpenText} from 'react-icons/fa';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {FirebaseAuth} from '../../lib/firebase';
import {showSuccess} from '../../lib/toasts';
import {checkToggler, flagSetter, toHostPath, useDeviceType} from '../../lib/util';
import {ChangePassword} from './ChangePassword';
import {EmailInput} from './EmailInput';
import {GarminOAuthButton, StravaOAuthButton} from './OAuth';
import {PasswordInput} from './PasswordInput';


function MagicLinkAwaiter({email, awaiting, toggleAwaiting}) {
    const {innerRef} = useRef(),
        {isSignedIn} = useContext(FirebaseAuth),
        [show, toggleShow] = useState(false);

    useEffect(() => {
        const awaitForClickedMagicLink = async () => {
            window.localStorage.setItem('magicLinkEmail', email);
            const continueUrl = new URL(window.location.href),
                {pathname, search, anchor} = continueUrl;
            await fetch('/api/magic-links',
                {method: 'POST', json: {email, url: toHostPath({pathname: '/magic-link', searchParams: {pathname, search, anchor}})}});
        };
        awaiting && awaitForClickedMagicLink();
    }, [awaiting, toggleAwaiting, email]);

    useEffect(() => {
        toggleShow(awaiting && !isSignedIn);
        if (awaiting && isSignedIn) {
            toggleAwaiting(false);
            showSuccess("Signed in!", {}, "You have successfully signed in!");
        }
    }, [awaiting, isSignedIn, toggleAwaiting]);

    return <>
        <Modal show={show} centered backdrop="static" keyboard={false} onHide={flagSetter(toggleShow, false)} ref={innerRef} animation={false}>
            <Modal.Header closeButton><Trans>Awaiting for magic link click...</Trans></Modal.Header>
            <Modal.Body className="text-center">
                <Alert variant="primary">
                    <Trans>
                        Go to your e-mail inbox and click the magic link to sign in.
                        This alert will disappear once you are done.</Trans>
                    <br/><FaEnvelopeOpenText/>
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Spinner as="span" animation="border" role="status" className="text-center"/>
            </Modal.Footer>
        </Modal>
    </>;
}

export function MagicLinkWatcher() {
    const {t} = useTranslation();
    const {auth, isSignedIn} = useContext(FirebaseAuth),
        [show, toggleShow] = useState(false),
        [error, setError] = useState(null),
        navigate = useNavigate();

    useEffect(() => {
        async function signInWithLinkAsync() {
            const email = window.localStorage.getItem('magicLinkEmail');
            try {
                await signInWithEmailLink(auth, email, window.location.href);
            } catch (e) {
                console.error(e);
                setError(e);
            }
        }

        if (isSignInWithEmailLink(auth, window.location.href)) {
            toggleShow(true);
            // noinspection JSIgnoredPromiseFromCall
            signInWithLinkAsync();
        }
    }, [toggleShow, auth, setError]);

    function onContinueHereClick() {
        const continueUrl = new URLSearchParams(window.location.search),
            {pathname, search, anchor} = _.fromPairs([...continueUrl.entries()]);
        navigate({pathname, search, anchor});
    }

    return <>
        <Modal show={show} size="lg" centered backdrop="static" keyboard={false} animation={false}>
            <Modal.Header>{isSignedIn ? t("You have signed in!") : t("Signing in...")}</Modal.Header>
            <Modal.Body className="text-center">
                {isSignedIn ?
                    <p><Trans>You may want to set a new password now. Or close this tab (does not work in all browsers) and switch to the tab you started from. Or just continue here if you
                        want!</Trans></p> :
                    error ? <p><Trans>Error</Trans></p> : <p>...</p>
                }
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                {isSignedIn ?
                    <>
                        <ChangePassword/>
                        <Button onClick={() => window.close()}><Trans>Close this tab</Trans></Button>
                        <Button onClick={onContinueHereClick}><Trans>Continue here</Trans></Button>
                    </> :
                    <Spinner as="span" animation="border" role="status" className="text-center"><span className="sr-only">
                        <Trans>Signing in...</Trans>
                    </span></Spinner>}
            </Modal.Footer>
        </Modal>
    </>;
}

const ere = emailRegexSafe({exact: true});
function isEmailValid(email) {
    return ere.test(email);
}

const AuthenticationModal = memo(function({
    activityName, allowedLinkAuth, autoComplete, explainer, firebaseFn, hideOAuth, modalTitle,
    onError = null, onSuccess = null, options, otherButton, show, showModal, submitButtonText
}) {
    const {auth} = useContext(FirebaseAuth),
        {t} = useTranslation(),
        {isMobile} = useDeviceType(),
        [email, setEmail] = useState(''),
        [password, setPassword] = useState(''),
        [invalidEmailFeedback, setInvalidEmailFeedback] = useState(''),
        [invalidPasswordFeedback, setInvalidPasswordFeedback] = useState(''),
        {initialEmail, initialMode, small, signUpModal} = options ?? {},
        leftButton = options?.leftButton ?? otherButton,
        [mode, setMode] = useState((hideOAuth || initialMode === 'email') ? 'email' : 'oauth'),
        {data: signInMethods} = useQuery(['signin-methods', email], async () => {
            return email && isEmailValid(email) ? await fetchSignInMethodsForEmail(auth, email) : [];
        }),
        [magicLinkMode, setMagicLinkMode] = useState(false),
        [awaitingForMagicLinkClick, toggleAwaitingForMagicLinkClick] = useState(false);

    const linkAuth = allowedLinkAuth && _.includes(signInMethods, EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD),
        passAuth = _.includes(signInMethods, EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD);

    useEffect(() => {setMagicLinkMode(linkAuth && !passAuth);}, [linkAuth, passAuth]);
    useEffect(() => {
        if (show) {
            initialMode && setMode(initialMode);
            initialEmail && setEmail(initialEmail);
        }
    }, [initialEmail, initialMode, setMode, setEmail, show]);

    async function onSubmit() {
        let user;
        try {
            setInvalidEmailFeedback('');
            await setPersistence(auth, indexedDBLocalPersistence);
            if (magicLinkMode) {
                toggleAwaitingForMagicLinkClick(true);
            } else {
                const userCredential = await firebaseFn(auth, email, password);
                user = userCredential?.user;
            }
        } catch (error) {
            const handlers = {
                'auth/email-already-in-use': () => setInvalidEmailFeedback(<span className="align-middle">
                    <Trans>You have already signed up. <Button size="sm" variant="link" className="align-baseline p-0" onClick={() => showModal('SignIn', {initialMode: 'email', initialEmail: email})}>Sign in instead</Button></Trans>
                </span>),
                'auth/weak-password': () => setInvalidPasswordFeedback(t("The password is too weak. Try something stronger or right-click and pick 'Suggest strong password' menu option.")),
                'auth/wrong-password': () => setInvalidPasswordFeedback(t("Wrong password. Check your caps lock, num lock and try again.")),
                'auth/internal-error': () => _.isEmpty(password) ?
                    setInvalidPasswordFeedback(t("Type here your password.")) :
                    setInvalidPasswordFeedback(t("Temporary error. Try again.")),
                'auth/missing-email': () => setInvalidEmailFeedback(t("Type here your email address you provided on sign up.")),
                'auth/invalid-email': () => setInvalidEmailFeedback(t("The email address is badly formatted.")),
                'auth/user-not-found': () => setInvalidEmailFeedback(
                    <span>
                        <Trans>User not found. Try our other email or <Button size="sm" variant="link" className="align-baseline p-0" onClick={() => showModal(signUpModal, {initialEmail: email})}>sign up for a new account</Button>.</Trans>
                    </span>
                )
            };
            await handlers[error?.code]?.();
            console.error(error);
            onError?.(error);
            return;
        }
        user && await onSuccess?.(user);
    }

    function onEmailChange({target: {value}}) {
        setInvalidEmailFeedback('');
        setEmail(value);
    }

    function onPasswordChange({target: {value}}) {
        setInvalidPasswordFeedback('');
        setPassword(value);
    }

    const AuthModalVariant = small && isMobile ? AuthModalSmall : AuthModalLarge;

    return <AuthModalVariant {...{
        activityName, autoComplete, awaitingForMagicLinkClick, checkToggler, email, explainer, hideOAuth, invalidEmailFeedback, invalidPasswordFeedback,
        leftButton, magicLinkMode, modalTitle, mode, onEmailChange, onPasswordChange, onSubmit, passAuth, password, setMagicLinkMode, setMode,
        show, showModal, submitButtonText, toggleAwaitingForMagicLinkClick
    }}/>;
});

function AuthModalLarge({
    activityName, autoComplete, awaitingForMagicLinkClick, checkToggler, email, explainer, hideOAuth, invalidEmailFeedback, invalidPasswordFeedback,
    leftButton, magicLinkMode, modalTitle, mode, onEmailChange, onPasswordChange, onSubmit, passAuth, password, setMagicLinkMode, setMode,
    show, showModal, submitButtonText, toggleAwaitingForMagicLinkClick
}) {
    const {t} = useTranslation();

    return <>
        <Modal backdrop={true} show={show} onHide={() => showModal(null)} size="lg" centered animation={false}>
            <Modal.Header closeButton={true}>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Navbar bsPrefix="noop-context">
                    <Tabs activeKey={mode} onSelect={(mode) => setMode(mode)} className="nav nav-tabs">
                        {!hideOAuth &&
                            <Tab eventKey="oauth" title={<Trans>With ride trackers</Trans>} className="mt-3 text-center">
                                {!_.isEmpty(explainer) &&
                                    <Col xs={12} lg={{offset: 1, span: 10}}>
                                        <Alert variant="info" className="pb-0 px-3"><p className="small pb-0">{explainer}</p></Alert>
                                    </Col>
                                }
                                <div className="flex-column flex-md-row gap-5">
                                    <GarminOAuthButton activityName={activityName}/>
                                    <StravaOAuthButton activityName={activityName}/>
                                </div>
                            </Tab>
                        }
                        <Tab eventKey="email" title={<Trans>With email</Trans>} className="mt-3">
                            <EmailInput {...{email, onEmailChange, invalidEmailFeedback}} />
                            {!magicLinkMode && <PasswordInput autoComplete={autoComplete} value={password}
                                                              onChange={onPasswordChange} invalidPasswordFeedback={invalidPasswordFeedback}/>}
                            {passAuth &&
                                <Form.Check type="switch" id="link-pass-auth-switch" label={t("Forgot password or feeling lazy today? Get magic login link")}
                                            checked={magicLinkMode} onChange={checkToggler(setMagicLinkMode)}/>}
                        </Tab>
                    </Tabs>
                </Navbar>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                {leftButton}
                {mode === 'email' && <Button variant="primary" onClick={onSubmit}>{submitButtonText}</Button>}
            </Modal.Footer>
        </Modal>
        <MagicLinkAwaiter awaiting={awaitingForMagicLinkClick} toggleAwaiting={toggleAwaitingForMagicLinkClick} email={email}/>
    </>;
}

function AuthModalSmall({
    activityName, autoComplete, awaitingForMagicLinkClick, checkToggler, email, explainer, hideOAuth, invalidEmailFeedback, invalidPasswordFeedback,
    leftButton, magicLinkMode, modalTitle, mode, onEmailChange, onPasswordChange, onSubmit, passAuth, password, setMagicLinkMode, setMode,
    show, showModal, submitButtonText, toggleAwaitingForMagicLinkClick
}) {
    const {t} = useTranslation();
    return <>
        <Modal backdrop={false} size="sm" centered={false} animation={false} dialogClassName="position-fixed bottom-0 start-0 end-0 mw-100" className="h-auto"
               show={show} onHide={() => showModal(null)} >
            <Modal.Header closeButton={true} className="p-2">
                <Modal.Title as="h6">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <Navbar bsPrefix="noop-context">
                    <Tabs activeKey={mode} onSelect={(mode) => setMode(mode)} className="nav nav-tabs" fill>
                        {!hideOAuth &&
                            <Tab eventKey="oauth" title={<Trans>With ride trackers</Trans>} className="my-2 text-center">
                                {!_.isEmpty(explainer) &&
                                    <Col xs={12} lg={{offset: 1, span: 10}}>
                                        <Alert variant="info" className="pb-0 px-3"><p className="small pb-0">{explainer}</p></Alert>
                                    </Col>}
                                <div className="flex-column flex-md-row gap-5">
                                    <GarminOAuthButton activityName={activityName} tiny/>
                                    <StravaOAuthButton activityName={activityName} tiny/>
                                </div>
                            </Tab>
                        }
                        <Tab eventKey="email" title={<Trans>With email</Trans>} className="p-2">
                            <EmailInput {...{email, onEmailChange, invalidEmailFeedback}} />
                            {!magicLinkMode && <PasswordInput autoComplete={autoComplete} value={password}
                                                              onChange={onPasswordChange} invalidPasswordFeedback={invalidPasswordFeedback}/>}
                            {passAuth &&
                                <Form.Check type="switch" id="link-pass-auth-switch" label={t("Forgot password or feeling lazy today? Get magic login link")}
                                            checked={magicLinkMode} onChange={checkToggler(setMagicLinkMode)}/>}
                        </Tab>
                    </Tabs>
                </Navbar>
            </Modal.Body>
            <Modal.Footer className="justify-content-between p-0">
                {leftButton}
                {mode === 'email' && <Button variant="primary" onClick={onSubmit}>{submitButtonText}</Button>}
            </Modal.Footer>
        </Modal>
        <MagicLinkAwaiter awaiting={awaitingForMagicLinkClick} toggleAwaiting={toggleAwaitingForMagicLinkClick} email={email}/>
    </>;
}


export function SignUpModal({activityName, onError = null, onSuccess = null, options, show, showModal}) {
    const props = {
        autoComplete: "new-password",
        modalTitle: options?.modalTitle ?? <Trans>Create an account to save and share your routes</Trans>,
        explainer: options?.explainer ?? <Trans>We strongly suggest to sign up with your ride tracker if you have one. <br className="d-none d-lg-inline"/>
            We use our users' rides history to make routing better for everyone.</Trans>,
        otherButton: <Button variant="link" size="sm" onClick={() => showModal('SignIn')}><Trans>Signed up before? Sign in!</Trans></Button>,
        submitButtonText: <Trans>Sign up</Trans>,
        allowedLinkAuth: false,
        firebaseFn: (auth, email, password) => createUserWithEmailAndPassword(auth, email, password)
    };
    return <AuthenticationModal {...{activityName, onError, onSuccess, options, show, showModal, ...props}} />;
}

export function SignInModal({activityName, hideOAuth, onError = null, onSuccess = null, options, show, showModal}) {
    const props = {
        autoComplete: "current-password",
        modalTitle: <Trans>Sign in</Trans>,
        explainer: options?.explainer ?? <Trans>We strongly suggest to sign in with your ride tracker if you have one.<br/>
            We use our users' rides history to make routing better for everyone.</Trans>,
        otherButton: <Button variant="link" size="sm" onClick={() => showModal('SignUp')}><Trans>No account yet? Sign up!</Trans></Button>,
        submitButtonText: <Trans>Sign in</Trans>,
        allowedLinkAuth: true,
        firebaseFn: (auth, email, password) => signInWithEmailAndPassword(auth, email, password)
    };

    return <AuthenticationModal {...{activityName, hideOAuth, onError, onSuccess, options, show, showModal, ...props}} />;
}