import {explode, nearestPoint} from '@turf/turf';
import _ from 'lodash';
import {useCallback, useEffect} from 'react';
import {useMap} from 'react-map-gl';
import {useBoolean} from 'usehooks-ts';

/**
 * @typedef {Object} MapLibreLoaded
 * @property {MapLibre} mapLibre MapLibre object
 * @property {boolean} loaded "Map style loaded" flag. False until 'styledata' event.
 *  You can manipulate styles after it is true.
 *  Note that differs from `mapLibre.isStyleLoaded()` which can be later temporarily `false`
 *  during adjustment of layers visibility (which does NOT trigger styledata event after).
 * @property {boolean} sourceLoaded "Map source loaded" flag. False until 'load' event.
 */

/**
 * Return current mapLibre, augmented with loaded flags.
 * @return {MapLibreLoaded} MapLibre, "is style loaded", "is source loaded"
 */
export function useMapLibre(mapId = 'tarmacsMap') {
    const {[mapId]: mapLibre} = useMap(),
        {value: styleLoaded, setTrue: setStyleLoaded, setFalse: setStyleUnloaded} = useBoolean(),
        {value: sourceLoaded, setTrue: setSourceLoaded, setFalse: setSourceUnloaded} = useBoolean();

    const effectFn = useCallback((mapLibreIsFn, event, setLoaded, setUnloaded) => {
            if (mapLibreIsFn?.()) {
                setLoaded();
                return setUnloaded;
            }
            mapLibre?.once?.(event, setLoaded);
            return () => {
                mapLibre?.off?.(event, setUnloaded);
                setUnloaded();
            };
        }, [mapLibre]);

    useEffect(() => effectFn(mapLibre?.isStyleLoaded, 'styledata', setStyleLoaded, setStyleUnloaded),
        [effectFn, mapLibre, setStyleLoaded, setStyleUnloaded]);
    useEffect(() => effectFn(mapLibre?.loaded, 'load', setSourceLoaded, setSourceUnloaded),
        [effectFn, mapLibre, setSourceLoaded, setSourceUnloaded]);

    return {mapLibre, loaded: styleLoaded, sourceLoaded};
}

/**
 * Hook mapLibre events when map is mounted, unhook on unmount
 * @param events Object `{eventname: () => handler}`, or list of per-layer events:
 *  `[['eventname', 'layer', () => handler, layerIdOrListener]]`;
 * @param {string} mapId optional ID of the map (can be undefined).
 * @return {mapLibre: MapLibre, loaded: boolean, sourceLoaded: boolean} MapLibre, "is style loaded", "is source loaded"
 */
export function useMapLibreEvents(events, mapId = undefined) {
    const ml = useMapLibre(mapId),
        {mapLibre, loaded} = ml;

    useEffect(() => {
        if (!mapLibre || !loaded) return;
        if (_.isArray(events)) {
            _.each(events, ([event, layer, handler]) => mapLibre.on(event, layer, handler));
            return () => { _.each(events, ([event, layer, handler]) => mapLibre.off(event, layer, handler)); };
        }
        _.each(events, (handler, event) => mapLibre.on(event, handler));
        return () => { _.each(events, (handler, event) => mapLibre.off(event, handler)); };
    }, [events, mapLibre, loaded]);

    return ml;
}

export function distanceXY(pt1, pt2) {
    const [{x: x1, y: y1}, {x: x2, y: y2}] = [pt1, pt2];
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
}

export function pixelDistanceOnMap(mapLibre, lngLat1, lngLat2) {
    return distanceXY(mapLibre.project(lngLat1), mapLibre.project(lngLat2));
}

/** Faster than nearestPointOnLine */
export function nearestVertex(lineString, point) {
    const explodedNetwork = explode(lineString);
    return nearestPoint(point, explodedNetwork);
}