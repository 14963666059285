import {memo, useCallback, useEffect, useState} from 'react';
import {FacebookProvider, Initialize} from 'react-facebook';
import {useTranslation} from 'react-i18next';
import {useLocaleSettings} from './l10n';


const CustomerChatPlaceholder = memo(function CustomerChat_({page_id, onParse}) {
    const {t} = useTranslation(),
        greeting = t("Need help? Something wrong?");

    const options = {
        logged_in_greeting: greeting,
        logged_out_greeting: greeting
        // greeting_dialog_display: 'show',
    };

    useEffect(() => {onParse?.()});
    return <div className="fb-customerchat" {...{page_id, ...options}} />;
});

export const CustomerChat = memo(function ChatButton_({page_id}) {
    const [api, setAPI] = useState(),
        [container, setContainer] = useState();

    const onParse = useCallback(() => {api && container && api.parse(container)}, [api, container]);
    useEffect(onParse, [api, container, onParse]);

    if (process?.env?.NODE_ENV !== 'production')
        return null;

    return <div ref={(container) => setContainer(container)}>
        <Initialize onReady={(api) => setAPI(api)}>
            <CustomerChatPlaceholder page_id={page_id} onParse={onParse}/>
        </Initialize>
    </div>;
});

const FB_LOCALE_MAP = {'en': 'en_US', 'pl': 'pl_PL', 'es': 'es_ES', 'de': 'de_DE'};

export const FacebookProviderI18n = memo((props) => {
    const {locale} = useLocaleSettings();
    if (! locale)
        return null;

    return <FacebookProvider {...props} language={FB_LOCALE_MAP[locale] ?? 'en_US'} version='v14.0'/>;
});