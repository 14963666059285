import JavascriptTimeAgo from 'javascript-time-ago';
import cs from 'javascript-time-ago/locale/cs.json';
import da from 'javascript-time-ago/locale/da.json';
import de from 'javascript-time-ago/locale/de.json';
import en from 'javascript-time-ago/locale/en.json';
import es from 'javascript-time-ago/locale/es.json';
import fi from 'javascript-time-ago/locale/fi.json';
import fr from 'javascript-time-ago/locale/fr.json';
import it from 'javascript-time-ago/locale/it.json';
import nl from 'javascript-time-ago/locale/nl.json';
import no from 'javascript-time-ago/locale/no.json';
import pl from 'javascript-time-ago/locale/pl.json';
import pt from 'javascript-time-ago/locale/pt.json';
import se from 'javascript-time-ago/locale/se.json';
import sk from 'javascript-time-ago/locale/sk.json';
import sl from 'javascript-time-ago/locale/sl.json';
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import './index.scss';
import {startErrorHandler} from './lib/errors';
// import reportWebVitals from './reportWebVitals';

startErrorHandler();

// This handles TimeAgo / ReactTimeAgo locales
JavascriptTimeAgo.addDefaultLocale(en);
JavascriptTimeAgo.addLocale(cs);
JavascriptTimeAgo.addLocale(da);
JavascriptTimeAgo.addLocale(de);
JavascriptTimeAgo.addLocale(de);
JavascriptTimeAgo.addLocale(es);
JavascriptTimeAgo.addLocale(es);
JavascriptTimeAgo.addLocale(fi);
JavascriptTimeAgo.addLocale(fr);
JavascriptTimeAgo.addLocale(it);
JavascriptTimeAgo.addLocale(nl);
JavascriptTimeAgo.addLocale(no);
JavascriptTimeAgo.addLocale(pl);
JavascriptTimeAgo.addLocale(pl);
JavascriptTimeAgo.addLocale(pt);
JavascriptTimeAgo.addLocale(se);
JavascriptTimeAgo.addLocale(sk);
JavascriptTimeAgo.addLocale(sl);

const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
