import {getCoord, point} from '@turf/turf';
import CheapRuler from 'cheap-ruler';
import _ from 'lodash';
import {errorHandler} from './errors';
import {getLineStringCoords} from './util';


export function toLngLat(coord) {
    if (!coord) return coord;
    const [lat, lng, ...rest] = coord;
    return [lng, lat, ...rest];
}

export function toLngLatArray(coords) {
    return _.map(coords, ([lat, lng, ...rest]) => ([lng, lat, ...rest]));
}

// The same but will simplify getting rid of lat,lng vs lng,lat mess in future.
export const toLatLng = toLngLat;
export const toLatLngArray = toLngLatArray;

/**
 * Find the closest route segment and closest point to the provided point
 *
 * @param {coordinates, routePoints} track - existing track data with routePoints and coordinates
 * @param {Array} latlng - latitude and longitude of the point to search for
 * @returns {{segment, point, trackPointIdx, routePointIdx, distance}} -
 *      closest segment, [lat, lon] coordinates of the closest point,
 *      index of the closest track point and index of the closest route point, and distance to the closest point.
 *
 * This uses CheapRuler implementation which is much faster than turf which beats leaflet-geometry-util.
 */
export function getClosestPointAndSegment(track, latlng) {
    const {routePoints, coordinates} = track,
        snapped = closestPointAndSegment(toLngLatArray(coordinates), toLngLat(latlng));

    if (_.isEmpty(snapped))
        return null;

    const {point, segment} = snapped;
    return {
        ...snapped,
        point: toLatLng(point),
        segment: toLngLatArray(segment),
        routePointIdx: _.findIndex(routePoints, (routePointIdx) => routePointIdx >= snapped.trackPointIdx)
    };
}

/**
 * Drop-in replacement for turf. Kilometers only
 *
 * TODO: Use also instead nearestVertex/nearestPoint in volatile coord?
 */
export function nearestPointOnLine(trackLS, pt) {
    const snapped = closestPointAndSegment(getLineStringCoords(trackLS), getCoord(pt));
    if (_.isEmpty(snapped))
        return null;

    try {
        return point(snapped.point, {index: snapped.trackPointIdx, dist: snapped.distance});
    } catch (exc) {
        // Diagnostics for https://console.cloud.google.com/logs/query;query=error_groups.id%3D%22CPC5kbXq-ua7EQ%22%0AlogName:%22clouderrorreporting.googleapis.com%252Freported_errors%22%0Aresource.type%3D%22reported_errors%22%0Aresource.labels.project_id%3D%22tarmacs-app%22;cursorTimestamp=2024-08-07T18:58:15.405267087Z;duration=P7D?project=tarmacs-app
        // Error: coordinates must contain numbers:
        errorHandler.report(`${exc} snapped=${JSON.stringify(snapped)}`);
        return null;
    }
}

function closestPointAndSegment(lngLatTrack, lngLatPt) {
    if (_.size(lngLatTrack) < 2)
        return null;
    const segments = _.zip(_.initial(lngLatTrack), _.tail(lngLatTrack)),
        ruler = new CheapRuler(lngLatPt[1], 'meters'),
        snapped = ruler.pointOnLine(lngLatTrack, lngLatPt);

    const {point, index: trackPointIdx} = snapped,
        distance = ruler.distance(lngLatPt, point) / 1000.0,
        segment = segments[trackPointIdx];

    return {point, segment, trackPointIdx, distance};
}