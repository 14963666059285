import {Bytes, GeoPoint, deleteField, serverTimestamp, Timestamp} from 'firebase/firestore';
import {geohashForLocation} from 'geofire-common';
import _ from 'lodash';


function toFirebaseBytes(array) {
    return _.isEmpty(array) ? undefined : Bytes.fromUint8Array(new TextEncoder().encode(JSON.stringify(array)));
}

function fromFirebaseBytes(bytes) {
    return _.isEmpty(bytes) ? null : JSON.parse(new TextDecoder().decode(bytes.toUint8Array()));
}

export function bboxCenter(bbox) {
    return bbox && [(bbox[0][0] + bbox[1][0]) / 2, (bbox[0][1] + bbox[1][1]) / 2];
}

function centerHashForBBox(bbox) {
    return bbox && geohashForLocation(bboxCenter(bbox));
}

/**
 * Convert route (namely, arrays of coordinates) into/from formats sensible for Firebase.
 * `route` is converted to 2 separate arrays while `coordinates` into Bytes (to avoid issues with viewing long arrays on Firebase Console)
 */
export const routeConverter = (user) => ({
    key: 'routeConverter',
    toFirestore({route, track = {}, routePOIs, routeProps, created, deleted = false}) {
        const toGeoPoints = (arr) => _.map(arr, ([lat, lon]) => new GeoPoint(lat, lon));
        const routeGeoPoints = _.isEmpty(route) ? undefined : toGeoPoints(route),
            // Enumeration for `trackOut` depends on Proxy (ownKeys, getOwnPropertyDescriptor).
            {bbox, ascent, descent, distance, ...trackOut} = track,
            trackBytes = toFirebaseBytes(trackOut),
            createdTS = (created && Timestamp.fromDate(created)) ?? serverTimestamp(),
            deletedTS = deleted ? serverTimestamp() : null,
            owner = user?.uid,
            bboxGeoPoints = _.isEmpty(bbox) ? undefined : toGeoPoints(bbox),
            centerHash = centerHashForBBox(bbox);
        return _.omitBy({
            route: routeGeoPoints,
            bbox: bboxGeoPoints,
            ascent, descent, distance,
            track: trackBytes,
            created: createdTS,
            deleted: deletedTS,
            // This field is overriden only by DuplicateButton - to avoid sending "created route" notification on clone, but send after save later.
            initialClone: deleteField(),
            // Handle legacy tracks with undefined POIs and POIs with undefined fields (like `name`)
            routePOIs: _.map(routePOIs, (poi) => poi ? _.omitBy(poi, _.isUndefined) : null),
            centerHash, owner, ..._.omit(routeProps, 'created', 'owner')
        }, _.isUndefined);
    },
    fromFirestore(snapshot, options) {
        const fromGeoPoints = (arr) => _.map(arr, ({latitude, longitude}) => [latitude, longitude]);
        const {
            route: routeGeoPoints, track: trackBytes, bbox: bboxGeoPoints, routePOIs,
            created: createdTS, ascent, descent, distance,
            initialClone, // Omit that. We want to delete the flag (if present) at next write.
            ...routeProps
        } = snapshot.data(options);
        const {plannedDate: plannedDateTS} = routeProps;
        const {id} = snapshot,
            route = fromGeoPoints(routeGeoPoints),
            bbox = fromGeoPoints(bboxGeoPoints),
            created = createdTS?.toDate?.(),
            // For legacy tracks these attributes can be encoded in bytes.
            trackAttrs = _.omitBy({ascent, descent, distance, bbox}, _.isUndefined);

        let trackDetails = null;
        function ensureTrackDetails() {
            if (!trackDetails) {
                trackDetails = fromFirebaseBytes(trackBytes);
            }
            return trackDetails;
        }

        // Using proxy to avoid expensive fromFirebaseBytes for thumbnails and such.
        // Helps to maintain previously designed interface, while allow accessing track.ascent, track.descent while reading bytes only when needed.
        const track = new Proxy(trackAttrs, {
            get(target, prop, receiver) {
                if (prop in target) {
                    return Reflect.get(target, prop, receiver);
                }
                const details = ensureTrackDetails();
                return details?.[prop];
            },
            // Required for proper enumeration for `trackOut` in toFirestore:
            ownKeys(target) {
                const details = ensureTrackDetails();
                return Array.from(new Set([...Reflect.ownKeys(target), ...Reflect.ownKeys(details)]));
            },
            // Required for proper enumeration for `trackOut` in toFirestore:
            getOwnPropertyDescriptor(target, prop) {
                const details = ensureTrackDetails();
                return {
                    enumerable: true,
                    configurable: true,
                    writable: true,
                    value: prop in target ? target[prop] : details[prop]
                };
            }
        });
        return {id, route, track, created, routePOIs, routeProps: _.extend(routeProps, {plannedDate: plannedDateTS?.toDate?.()})};
    }
});

export const userConverter = {
    key: 'userConverter',
    toFirestore({birthday, display_name, ...data}) {
        return _.omitBy(
            {birthday: birthday ? Timestamp.fromDate(birthday) : undefined, ...data},
            _.isUndefined);
    },

    fromFirestore(snapshot, options) {
        const {birthday, ...data} = snapshot.data(options);
        return {
            get display_name() {
                return `${this.first_name} ${this.last_name}`;
            },
            birthday: birthday?.toDate?.(),
            ...data
        };
    }
};

/**
 * Crop activity data from unnecessary overhead.
 */
export const activityConverter = {
    key: 'activityConverter',
    toFirestore({actor, verb, action = null, target = null}) {
        return {
            created: serverTimestamp(),
            actor: _.pick(actor, 'first_name', 'last_name', 'photo_url', 'gender', 'uid'),
            verb, action, target
        };
    },

    fromFirestore(snapshot, options) {
        return snapshot.data(options);
    }
};

export const followConverter = (follower, followed) => ({
    key: 'followConverter',
    toFirestore(obj) {
        return {
            ...obj,
            created: serverTimestamp(),
            follower, followed
        };
    },

    fromFirestore(snapshot, options) {
        return snapshot.data(options);
    }
});

/**
 * Routes in feed are contained in 'object' sub-key. Need to post-process them to fit into original object format expected by RoutesListTabContent.
 */
export const activityRouteConverter = {
    key: 'activityRouteConverter',
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options),
            {ascent, descent, distance, id} = data.object,
            {created} = data;

        return {
            id,
            created: created?.toDate(),
            track: {ascent, descent, distance},
            routeProps: {..._.omit(data.object, 'ascent', 'descent', 'distance', 'id'), owner: data.actor.uid}
        };
    },

    /**
     * Required for providing converted model back to `startAfter()`.
     */
    toFirestore({created, track, routeProps}) {
        const {owner, ...object} = routeProps;
        return {
            created: Timestamp.fromDate(created),
            actor: {uid: owner},
            object: {...object, ...track}
        }
    }
};
