import {bbox, points} from '@turf/turf';
import _ from 'lodash';
import {useEffect} from 'react';
import {useDetectGeoLocation} from '../components/GeoLocationContext';
import {cameraForBounds, useViewState} from '../components/ViewStateContext';
import {errorHandler} from './errors';
import {toLngLatArray} from './getClosestPointAndSegment';
import {useMapLibre} from './useMapLibre';
import {useDeviceTypeVariant} from './util';


export const ACCORDION_HEIGHT = {mobile: 150, desktop: 200},
    BBOX_MARGIN = 0.025;

export function getPadding(mapLibre, margin, bottomPx) {
    const {width, height} = mapLibre.getCanvas(),
        [horizontal, vertical] = [width * (margin ?? 0), height * (margin ?? 0)];
    const padding = {padding: {bottom: vertical + bottomPx, top: vertical, left: horizontal, right: horizontal}};
    return padding;
}

/**
 * Initialize location of mapLibre map based on the route or geolocation when no route.
 *
 * @param {[number, number, number, number]} boundingBox bounding box of the route.
 * @param {Array} route points.
 * @param {boolean} editable should take elevation viewer into account when fitting route in bounds.
 * @param {number} margin percentage (0.0-1.0) to keep around the track when setting initial bounds.
 */
export function useInitMaplibreLocation(boundingBox, route, editable, margin) {
    const {mapLibre, loaded} = useMapLibre(),
        {geoLocation} = useDetectGeoLocation({enable: true}),
        {setViewState} = useViewState(),
        bottomPx = useDeviceTypeVariant(ACCORDION_HEIGHT);

    useEffect(function setCameraLocation() {
        if (!mapLibre || !loaded) return;

        const padding = getPadding(mapLibre, margin, editable ? bottomPx : 0);

        // Adjust map view to route's bbox if route's bbox provided and not done before.
        function setFromBBox() {
            const bounds = toLngLatArray(boundingBox);
            if (!_.every(_.flatten(bounds), _.isFinite)) {
                errorHandler.report(`useInitMaplibreLocation.setCameraLocation.setFromBBox: strange values of bounds: ${bounds}.`);
                return;
            }
            setViewState((prev) => {
                if (_.includes(['user', 'bbox'], prev.cameraSource)) {
                    return prev;
                }
                const camera = cameraForBounds(mapLibre, bounds, padding);
                return {...prev, cameraSource: 'bbox', ...camera};
            });
        }

        // Geolocate map if not set by something else before.
        function setFromGeoLocation() {
            const {lat: latitude, lng: longitude} = geoLocation;
            if (_.isNumber(latitude) && _.isNumber(longitude)) {
                setViewState((prev) => (prev.cameraSource ? prev : {...prev, cameraSource: 'geolocation', latitude, longitude}));
            }
        }

        // If a user clicked some route (even one point), fix the map viewbox to it, unless it was set already by something else.
        function setFromRoute() {
            const toLngLatObj = ([longitude, latitude]) => ({latitude, longitude}),
                camera = _.size(route) < 2 ?
                // For a single point coming from inspirations ("Plan your own route here").
                toLngLatObj(_.first(toLngLatArray(route))) :
                // Multi-point route drawn by the user.
                cameraForBounds(mapLibre, bbox(points(toLngLatArray(route))), padding);
            setViewState((prev) => (prev?.cameraSource ?
                prev :
                {...(prev ?? {}), cameraSource: 'user', ...camera}));
        }

        _.isEmpty(boundingBox) ? (_.isEmpty(route) ? setFromGeoLocation() : setFromRoute()) : setFromBBox();
    }, [bottomPx, boundingBox, editable, geoLocation, loaded, mapLibre, margin, route, setViewState]);
}
