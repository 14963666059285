import styled from '@emotion/styled';
import {useTour} from '@reactour/tour';
import classNames from 'classnames';
import _ from 'lodash';
import {memo, useCallback, useState} from 'react';
import {Accordion, Alert, Button, ButtonGroup, ButtonToolbar, Card, Col, Dropdown, Row, Stack, Table} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {FaCloudSun, FaFileImport, FaLightbulb, FaRoute, FaSave} from 'react-icons/fa';
import {GiGears} from 'react-icons/gi';
import {HiSwitchHorizontal} from 'react-icons/hi';
import {useQuery} from 'react-query';
import {useBoolean} from 'usehooks-ts';
import {ga} from '../lib/ga';
import {PopoverTrigger} from '../lib/toasts';
import {DesktopOnly, MobileOnly} from '../lib/util';
import {AuthButton} from './auth/AuthButton';
import {CompactHeader} from './CompactHeader';
import {ImportRouteModal} from './ImportRouteModal';
import {t_} from './l10n';
import {PlannerSettings} from './PlannerSettings';
import {BikeKindSelector, ClearRouteButton, RoutePropertiesCard} from './RouteMapping';
import {SURFACE_STYLES} from './Track';
import {useViewState} from './ViewStateContext';
import {WeatherSettings} from './WeatherPanel';


const SaveButton = styled(AuthButton)`
    margin: 0;
`;

const LegendTable = styled(Table)`
    font-size: small;
    font-weight: bold;

    th, td {
        padding: 0;
    }
`;

function leafletPathToSvg({color, weight, dashArray}) {
    return {stroke: color, strokeWidth: weight, strokeDasharray: dashArray, strokeLinecap: 'round', strokeLinejoin: 'round'};
}

function tileStyleToSvg({paint}, zoom = 13) {
    function cnv(item) {
        const styleDef = paint?.[item],
            style = _.find(styleDef?.stops, ([threshold]) => (threshold >= zoom))?.[1] ?? styleDef;
        return style;
    }

    const strokeWidth = cnv('line-width');
    return {stroke: cnv('line-color'), strokeWidth, strokeDasharray: _.map(cnv('line-dasharray'), (dash) => dash * strokeWidth)};
}

const MAP_TILE_STYLES = [
    [['road_tarmac_major_casing', 'road_tarmac_major'], ['road_tarmac_minor_casing', 'road_tarmac_minor']],
    [['road_paved_casing', 'road_paved'], ['road_paved_bad_casing', 'road_paved_bad']],
    [['road_gravel_casing', 'road_gravel'], ['road_gravel_bad_casing', 'road_gravel']],
    [['road_path_casing', 'road_path']]];

const LEAFLET_TRACK_STYLES = [
    [t_("Tarmac"), [SURFACE_STYLES.tarmac, SURFACE_STYLES.tarmac_rough]],
    [t_("Paved"), [SURFACE_STYLES.paved, SURFACE_STYLES.paved_rough]],
    [t_("Gravel"), [SURFACE_STYLES.gravel, SURFACE_STYLES.gravel_rough]],
    [t_("Dirt"), [SURFACE_STYLES.dirt]]
];

function SurfaceRow({title, trackStyles, mapStyles, styleDefinitions}) {
    const {zoom} = useViewState(),
        [WIDTH, HEIGHT] = [40, 12],
        MIDDLE = HEIGHT / 2,
        isDoubleCol = _.size(trackStyles) < 2,
        colspan = isDoubleCol ? 2 : 1,
        tdStyle = isDoubleCol ? {display: 'flex', alignItems: 'center', justifyContent: 'center'} : {},
        styles = _.zip(trackStyles, mapStyles);

    return <tr>
        <td>{title}</td>
        {_.map(styles, ([[, {outer, inner}], [casing, line]], key) => (<td key={key} colSpan={colspan}>
            <div style={tdStyle}>
                <div style={{alignSelf: 'center'}}>
                    <svg width={WIDTH * 2} height={HEIGHT}>
                        <line x1={5} y1={MIDDLE} x2={WIDTH - 5} y2={MIDDLE} style={leafletPathToSvg(outer)}/>
                        <line x1={5} y1={MIDDLE} x2={WIDTH - 5} y2={HEIGHT / 2} style={leafletPathToSvg(inner)}/>
                        {styleDefinitions && <>
                            <line x1={WIDTH + 5} y1={MIDDLE} x2={WIDTH * 2 - 5} y2={MIDDLE} style={tileStyleToSvg(styleDefinitions[casing], zoom)}/>
                            <line x1={WIDTH + 5} y1={MIDDLE} x2={WIDTH * 2 - 5} y2={MIDDLE} style={tileStyleToSvg(styleDefinitions[line], zoom)}/>
                        </>}
                    </svg>
                </div>
            </div>
        </td>))}
    </tr>;
}

const Legend = memo(function Legend_({}) {
    const {t} = useTranslation();
    const {data: styleDefinitions} = useQuery(['tileJson'], async () => {
        const mapStyles = _.flattenDeep(MAP_TILE_STYLES);
        const resp = await fetch(process.env.REACT_APP_TILES_URL),
            data = await resp.json();

        const styles = _.filter(data?.layers, (layer) => _.includes(mapStyles, layer.id));
        return _.fromPairs(_.map(styles, ({id, ...style}) => [id, style]));
    }, {staleTime: Infinity, cacheTime: Infinity});

    const surfacesTable = _.map(_.zip(LEAFLET_TRACK_STYLES, MAP_TILE_STYLES),
        ([[title, trackStyles], mapStyles], key) => (
            /* i18next-extract-disable-next-line */
            <SurfaceRow {...{key, title: t(title), trackStyles, mapStyles, styleDefinitions}} />
        ));

    return <LegendTable borderless size="sm" className="my-0">
        <thead>
        <tr>
            <th className="font-italic"><Trans>Legend:</Trans></th>
            <th className="text-center"><Trans>Good</Trans></th>
            <th className="text-center"><Trans>Rough</Trans></th>
        </tr>
        </thead>
        <tbody>
        {surfacesTable}
        </tbody>
    </LegendTable>;
});

function RouteControlsBar({className, onResetRoute, onReverseRoute, routeSize}) {
    const {t} = useTranslation();

    return <ButtonGroup className={classNames("ms-1", className)} variant="sm">
        <ClearRouteButton onClick={onResetRoute} disabled={!routeSize} className="flex-grow-1 flex-shrink-1"/>
        <Button title={t("Reverse route")} size="sm" className="flex-grow-1 flex-shrink-1" onClick={onReverseRoute} disabled={(routeSize || 0) < 2}><HiSwitchHorizontal/></Button>
    </ButtonGroup>;
}

// 2 cols on mobile landscape, 1 column on mobile portrait and desktop.
const DropdownMenuCard = styled(Card)`
    @media only screen and (max-height: 599px) {
        width: 30rem;
    }
    @media only screen and (min-height: 599px) {
        width: 18rem;
    }
`;

function RoutePlannerPropertiesTopbar({routeState, onSaveRoute, updateRouteProp, onResetRoute, onReverseRoute, poisLoading}) {
    const {t} = useTranslation(),
        {route, track, routeProps} = routeState,
        setBikeKind = useCallback((value) => updateRouteProp('bikeKind')(value), [updateRouteProp]),
        hasRoute = _.size(route) >= 2;

    const topButton = _.isEmpty(route) ?
        <ImportRouteButton className="w-100 h-100 p-0 p-md-2 small" size="sm" bikeKind={routeProps?.bikeKind || 'road'}
                           onImportRoute={onSaveRoute} setBikeKind={updateRouteProp('bikeKind')}><Trans>Import GPX</Trans></ImportRouteButton> :
        <SaveButton className="w-100 h-100 p-0 p-md-2" size="sm" disabled={!hasRoute}
                    activityName="save" onClick={onSaveRoute}><FaSave/> <Trans>Save</Trans></SaveButton>;

    const saveComponent = _.size(route) === 1 ?
        <PopoverTrigger heading={t("Draw your route, then save.")} tooltip={t("You need to draw your route first.")} delay={500}>{topButton}</PopoverTrigger> :
        topButton;

    return <Col xs={12} className="p-0">
        <Row className="gx-0 mx-0">
            <Col xs={10} sm={9}>
                <Row className="align-items-stretch gx-0">
                    <Col xs={12} sm={5}>
                        <RoutePropertiesCard track={track} className="small w-100 h-100"/>
                    </Col>
                    <Col xs={12} sm={7} className="d-flex">
                        <BikeKindSelector bikeKind={routeProps?.bikeKind || 'road'} id="topbar" setBikeKind={setBikeKind}
                                          className="flex flex-grow-1" buttonClassName="px-1 smaller"/>
                        <Dropdown className="flex flex-shrink-1" style={{minWidth: "2em"}}>
                            <Dropdown.Toggle size="sm" className="h-100 text-truncate w-100"><GiGears className="me-1"/><Trans>More</Trans></Dropdown.Toggle>
                            <Dropdown.Menu className="pb-0 pt-0" align="end">
                                <DropdownMenuCard>
                                    <Card.Header className="px-2 my-0 py-2 d-flex align-items-stretch">
                                        <div className="flex-grow-1 flex-shrink-1 my-auto"><Trans>Settings</Trans></div>
                                        <RouteControlsBar onResetRoute={onResetRoute} onReverseRoute={onReverseRoute} routeSize={_.size(route)} className="flex-grow-1"/>
                                    </Card.Header>
                                    <Card.Body className="p-2">
                                        <PlannerSettings routeProps={routeProps} updateRouteProp={updateRouteProp} isRoute={!_.isEmpty(route)} poisLoading={poisLoading}/>
                                    </Card.Body>
                                </DropdownMenuCard>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </Col>
            <Col xs={2} sm={3} className="ps-0 flex-shrink-1">
                {saveComponent}
            </Col>
        </Row>
    </Col>;
}

function ImportRouteButton({bikeKind, children, className, onImportRoute, setBikeKind, ...props}) {
    const {value: modalVisible, setFalse: hideModal, setTrue: showModal} = useBoolean(),
        onImportRouteCallback = useCallback(({geojson, tolerance}) => {
            hideModal();
            onImportRoute({geojson, tolerance});
        }, [hideModal, onImportRoute]);

    return <>
        <Button size="sm" className={classNames("align-baseline p-0", className)} onClick={showModal} {...props}>
            <FaFileImport className="me-1"/><span>{children}</span>
        </Button>
        <ImportRouteModal show={modalVisible} onHide={hideModal} onImportRoute={onImportRouteCallback} {...{bikeKind, setBikeKind}}/>
    </>;
}

function NoRouteSaveBox({bikeKind, onImportRoute, setBikeKind}) {
    return <>
        <Alert variant="info" className="m-0 text-center px-2 py-0">
            <small><FaLightbulb/> <Trans>Click on the map to start drawing your route or
                <ImportRouteButton {...{bikeKind, onImportRoute, setBikeKind}} variant="link">import GPX file</ImportRouteButton>.</Trans></small>
        </Alert>
    </>;
}

function OnePointSaveBox({onResetRoute}) {
    return <Stack direction="horizontal" className="align-items-stretch m-2" gap={1}>
        <Alert variant="info" className="mb-0 text-center py-2 x-small flex-grow-1"><span><FaRoute/> <Trans>Click the next points on the map.</Trans></span></Alert>
        <ClearRouteButton className="flex-grow-0 px-3" onClick={onResetRoute}/>
    </Stack>;
}

function RouteSaveBox({isExpanded, routeSize, onClick, onResetRoute, onReverseRoute}) {
    return <>
        <ButtonToolbar className="mx-2 flex-nowrap my-2">
            <AuthButton activityName="save" onClick={onClick} className="intro-save-button flex-grow-1 flex-shrink-1">
                <FaSave/> <span><Trans>Save route</Trans></span>
            </AuthButton>
            <RouteControlsBar onResetRoute={onResetRoute} onReverseRoute={onReverseRoute} routeSize={routeSize} className="flex-grow-1 flex-shrink-0"/>
        </ButtonToolbar>
        {isExpanded && <>
            <p className="text-center mt-0 mb-2 mx-2" style={{lineHeight: 1.0}}>
                <small className="pe-1" style={{fontSize: "x-small"}}><FaLightbulb/>&nbsp;<Trans>After saving the route you can send it to your bike computer.</Trans></small>
            </p>
        </>}
    </>;
}

function SaveBox({visible, isExpanded, bikeKind, routeSize, onClick, onImportRoute, onResetRoute, onReverseRoute, setBikeKind}) {
    return visible ?
        <RouteSaveBox {...{isExpanded, routeSize, onClick, onResetRoute, onReverseRoute}} /> :
        routeSize ?
            <OnePointSaveBox onResetRoute={onResetRoute}/> :
            <NoRouteSaveBox {...{bikeKind, onImportRoute, setBikeKind}}/>;
}

function RoutePlannerPropertiesSidebar({onImportRoute, onResetRoute, onReverseRoute, onSaveRoute, routeState, updateRouteProp, poisLoading}) {
    const {isOpen: isTourOpen} = useTour(),
        [expandedItems, setExpandedItems] = useState(['basic', 'route-props', 'weather-settings']),
        {routeProps, track, route, id: routeId} = routeState,
        {bikeKind, name} = routeProps;

    const onClick = useCallback((...args) => {
            ga('route_save', {item_id: routeId});
            return onSaveRoute(...args);
        }, [routeId, onSaveRoute]),
        setBikeKind = useCallback((v) => updateRouteProp('bikeKind')(v), [updateRouteProp]),
        // weather settings expect a bit different route prop updater
        updateWeatherRouteProp = useCallback((prop, value, dirty) => updateRouteProp(prop, dirty)(value), [updateRouteProp]);

    const toggler = (eventKey) => () => setExpandedItems(state => _.xor(state, [eventKey]));

    return <Col md={4} lg={3} xl={3} xxl={2} className="p-0 d-flex flex-column overflow-autom flex-shrink-1" style={{maxHeight: "100%"}}>
        <RoutePropertiesCard track={track}/>
        <Accordion flush activeKey={expandedItems} className="d-flex flex-column overflow-auto flex-fill">
            <Accordion.Item className="flex-shrink-0" eventKey="basic">
                <CompactHeader className="d-flex justify-content-between align-items-start position-relative" onClick={toggler('basic')}>
                    <span className="align-middle">{name || <Trans>Plan your route:</Trans>}</span>
                </CompactHeader>
                <Accordion.Body className="d-flex flex-column align-items-center px-3 py-1">
                    <Legend/>
                </Accordion.Body>
            </Accordion.Item>
            <Card><Card.Body className="d-flex flex-column align-items-stretch p-0">
                <SaveBox routeSize={_.size(route)} visible={_.size(route) >= 2 || isTourOpen} isExpanded={_.includes(expandedItems, 'basic')}
                         {...{onClick, onImportRoute, onResetRoute, onReverseRoute, bikeKind, setBikeKind}}/>
            </Card.Body></Card>
            <Accordion.Item className="flex-shrink-0 intro-finetune" eventKey="route-props">
                <CompactHeader onClick={toggler('route-props')}>
                    <GiGears/>&nbsp;<Trans>Settings</Trans>
                </CompactHeader>
                <Accordion.Body className="p-2">
                    <Stack>
                        <BikeKindSelector id="sidebar" className="flex-fill intro-route-type" bikeKind={bikeKind || 'road'} setBikeKind={setBikeKind}/>
                        <PlannerSettings routeProps={routeProps} updateRouteProp={updateRouteProp} isRoute={!_.isEmpty(route)} poisLoading={poisLoading}/>
                    </Stack>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="flex-shrink-0" eventKey="weather-settings">
                <CompactHeader onClick={toggler('weather-settings')}>
                    <FaCloudSun/>&nbsp;<Trans>Weather Forecast</Trans>
                </CompactHeader>
                <Accordion.Body className="p-2">
                    <WeatherSettings routeProps={routeProps} updateRouteProp={updateWeatherRouteProp}/>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </Col>;
}

export function RoutePlannerProperties({onImportRoute, onResetRoute, onReverseRoute, onSaveRoute, poisLoading, routeState, updateRouteProp}) {
    return <>
        <MobileOnly>
            <RoutePlannerPropertiesTopbar {...{onResetRoute, onReverseRoute, onSaveRoute, poisLoading, routeState, updateRouteProp}}/>
        </MobileOnly>
        <DesktopOnly>
            <RoutePlannerPropertiesSidebar
                {...{onImportRoute, onResetRoute, onReverseRoute, onSaveRoute, poisLoading, routeState, updateRouteProp}}/>
        </DesktopOnly>
    </>;
}
