import {arrayFromAsync, asyncBatch, asyncZipAll} from 'iter-tools';
import _ from 'lodash';
import {useCallback, useMemo} from 'react';
import {useInfiniteQuery} from 'react-query';


export async function* asyncRoundRobinAll(...asyncIterators) {
    const filler = {};
    for await (const items of asyncZipAll({filler}, ...asyncIterators)) {
        for (const item of items) {
            if (item !== filler) yield item;
        }
    }
}

async function fetchPageFromIterator(generatorFn, pageSize, asyncIterator) {
    if (_.isNil(asyncIterator))
        asyncIterator = asyncBatch(pageSize, generatorFn());

    const {done, value} = await asyncIterator.next(),
        page = value && await arrayFromAsync(value);

    return [page, (!done && page) ? asyncIterator : null];
}

export function useInfiniteIteratorQuery(queryKey, generatorFn, pageSize, queryOptions = {}, postprocessor = _.identity) {
    const options = useMemo(() => ({...queryOptions, getNextPageParam: ({nextPageParam}) => nextPageParam}), [queryOptions]),
        queryFn = useCallback(async function queryFn({pageParam}) {
            const [data, nextPageParam] = await fetchPageFromIterator(generatorFn, pageSize, pageParam);
            const page = _.map(data, postprocessor);
            page.nextPageParam = nextPageParam;
            return page;
        }, [generatorFn, pageSize, postprocessor]);

    return useInfiniteQuery(queryKey, queryFn, options);
}