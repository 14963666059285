import polyline from '@mapbox/polyline';
import {destination, getCoord, getCoords, length, lineString, simplify} from '@turf/turf';
import KDBush from 'kdbush';
import _ from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useCustomCompareEffect} from 'react-use';
import {useMapState} from 'rooks';
import {featureToPOI, getFeatureLayer} from '../components/POIPopup';
import {useFirebaseGeohashQuery} from './db';
import {errorHandler} from './errors';
import {toLngLat} from './getClosestPointAndSegment';
import {getPOIsPositions} from './getPOIsPositions';
import {defaultVisiblePOIs, VALHALLA_PRECISION, visiblePOIsQueryParams} from './routing';
import {useMapLibre} from './useMapLibre';
import {POI_PRIORITY_LOWEST, useRBushPOIsReducer} from './useRBushPOIsReducer';
import {getCenter, useBackendAPIQuery} from './util';


const MAX_POI_TRACK_DISTANCE_M = 250;
// These features are especially important as you can obtain basic cycling supplies there (water, food).
// This is 'class' field of the feature, not id of the layer.
// Note: If you alter the lists below, ensure the features are loaded in layers/poi/class.sql:poi_included_in_zoom function in openmaptiles.
// The order intentionally differs from Python counterpart for GPX exports - because in UI you can interactively show/hide POI master categories,
// (like supplies, tourism, services) while GPX is static.
const POI_CLASSES_PRIORITY = [
    'saddle', 'peak', 'volcano', 'railway', 'place_of_worship', 'monument', 'memorial', 'waterfall', 'castle', 'ruins', 'fortress', 'attraction',
    'drinking_water', 'cafe', 'beer', 'bar', 'spring', 'ice_cream', 'bakery', 'grocery', 'restaurant', 'fast_food', 'fuel',
    'shop', 'bicycle_rental', 'campsite', 'shelter', 'picnic_site'
];

/**
 * Calculate 1/4 km overlap radius in degrees for the place on earth specified by bbox.
 *
 * Used to find POIs near the track.
 *
 * FIXME Old comment: Source `center` is rounded to 1 deg to avoid expensive re-calculation of rbush on slight viewBBox change.
 */
function getRadiusDegrees(bbox) {
    // Calculate overlap radius for current part of earth.
    const center = bbox && getCenter(bbox);
    if (!center) return [0, 0];
    const coord = toLngLat(center),
        [lng, lat] = coord;
    const [, north] = getCoord(destination(coord, MAX_POI_TRACK_DISTANCE_M / 1000, 0));
    const [east] = getCoord(destination(coord, MAX_POI_TRACK_DISTANCE_M / 1000, 90));
    return _.mean([east - lng, north - lat]);
}

function usePositionsCache(coordinates) {
    const [cache, {setMultiple, removeAll}] = useMapState({});
    useEffect(() => { removeAll(); }, [coordinates, removeAll]);

    const getCachedPosition = useCallback(({feature}) => {
        return feature?.properties?.id && cache[feature.properties.id];
    }, [cache]);

    const updateCachedPositions = useCallback((poisWithPositions) => {
        setMultiple(_.fromPairs(_.map(poisWithPositions, ({feature, position}) => [feature.properties.id, position])));
    }, [setMultiple]);

    return [getCachedPosition, updateCachedPositions];
}

// Avoids re-triggering useCustomCompareEffect in useTrackPOIs when only cache changed (would trigger circular update).
function depsEqual(prevDeps, nextDeps) {
    const prev = _.slice(prevDeps, 0, -2),
        next = _.slice(nextDeps, 0, -2);
    // The `undefined` signals 'deep comparison' for deps arrays, then compare with identity when iterating them.
    return _.isEqualWith(prev, next, (p, n) => (p === prev) ? undefined : p === n);
}

export function useTrackPOIs(track, routePOIs, visiblePOIs) {
    const {mapLibre, loaded} = useMapLibre(),
        [pois, setPois] = useState([]),
        {coordinates, routePoints, bbox} = track ?? {},
        excluded = useMemo(() => _.map(_.filter(routePOIs), 'id'), [routePOIs]),
        radius = useMemo(() => getRadiusDegrees(bbox), [bbox]),
        filters = useMemo(() => visiblePOIsQueryParams(visiblePOIs ?? defaultVisiblePOIs(!_.isEmpty(routePOIs))), [visiblePOIs, routePOIs]),
        shape = useMemo(function buildSimplifiedPolyline() {
            // Fit shape in ~1000 chars polyline
            if (_.size(coordinates) < 2) return null;
            try {
                const coordsLS = lineString(coordinates);
                // Does not make sense to fetch POIs for a micro-track. Likely would raise `Error: invalid polygon` anyway.
                if (length(coordsLS, {units: 'kilometers'}) < 0.1) return null;
                const simplified = simplify(coordsLS, {highQuality: false, tolerance: 0.002});
                return simplified && polyline.encode(getCoords(simplified), VALHALLA_PRECISION);
            } catch (e) {
                errorHandler.report(`Mute: Could not build simplified polyline for ${JSON.stringify(coordinates)}: ${e}.`);
                return null;
            }
        }, [coordinates]),
        [getCachedPosition, updateCachedPositions] = usePositionsCache(coordinates);

    // Pass searchParams as array to allow multiple visible_pois values.
    // isFetching is set to true for refetches (when stale data are still presented) while isLoading is not.
    const {data: features, isFetching} = useBackendAPIQuery(`/api/pois-on-track`, [['shape', shape], ...filters], undefined,
        {
            enabled: !_.isEmpty(shape), keepPreviousData: true,
            // Inject sourceLayer property to be consistent with `toTurfFeature` output.
            select: ({features} = {}) => _.map(features, (feature) => ({...feature, sourceLayer: feature.properties['source-layer']}))
        });

    // Fetch POIS in the area which have been ever ranked (present in Firestore). Include them.
    const {data: rankedPOIs} = useFirebaseGeohashQuery(['pois'], bbox, 'geohash'),
        firebasePoisMap = useMemo(() =>
            _.fromPairs(_.map(rankedPOIs, (snap) => [snap.id, snap.data()])), [rankedPOIs]);

    // KDBush is an efficient spatial index for finding colliding points.
    // It is faster than RBush but with limitation: points are loaded once at start ("const").
    // Ideal for pre-filtering POIs near the track.
    // TODO use geokdbush https://github.com/mourner/geokdbush
    const trackRBush = useMemo(function _createTrackRBush() {
        const bush = new KDBush(coordinates ?? [], ([, lon]) => lon, ([lat]) => lat);
        return bush;
    }, [coordinates]);

    // RBush is an efficient spatial index for finding colliding points.
    // Using it to clean overlapping POIs.
    const reduceRBush = useRBushPOIsReducer();

    useCustomCompareEffect(() => {
        if (_.isEmpty(coordinates)) {
            setPois((pois) => _.isEmpty(pois) ? pois : []);
            return;
        }

        if (!mapLibre || !loaded)
            return;

        async function findTrackPOIs() {
            // Display top-priority POIs: prioritize features by number of thumbs given, rating and relevance. Then eliminate collisions.
            // TODO trackRBush-based 'closeEnough' filter is somewhat redunant to backend, might be subject for removal
            const closeEnough = _.filter(features, (feature) => {
                    const [lon, lat] = getCoord(feature);
                    return !_.isEmpty(trackRBush.within(lon, lat, radius));
                }),
                annotatedFeatures = _.map(closeEnough, (feature) => ({
                    feature,
                    poi: firebasePoisMap[feature.id],
                    // Features not present in POI_CLASSES_PRIORITY (-1) will be transformed to 1000 to enforce being last.
                    priority: (_.indexOf(POI_CLASSES_PRIORITY, feature.properties?.class) + 1) || POI_PRIORITY_LOWEST
                })),
                prioritized = _.sortBy(annotatedFeatures, [
                    ({poi}) => -(poi?.thumbs ?? 0),
                    ({poi}) => -(poi?.rating ?? 0),
                    _.property('priority')
                ]);

            const poisOnTrack = reduceRBush(prioritized),  // Includes the POIs out-of-canvas
                poisNotExcluded = _.filter(poisOnTrack, ({feature}) => !_.includes(excluded, feature.id)),
                poisWithPositions = getPOIsPositions(poisNotExcluded, coordinates, routePoints, getCachedPosition);

            if (!poisWithPositions)
                return;

            updateCachedPositions(poisWithPositions);

            // Sorting is important for performance optimization by useTrackMarkerPositionAdjuster.
            const routePOIs = _.sortBy(_.filter(_.map(poisWithPositions, (featurePOI) => {
                const layer = getFeatureLayer(mapLibre, featurePOI.feature);
                // Exclude features with undefined `layer`. Can happen because backend-side POI are a little bit broader, for simplicity.
                // For instance, they do not filter mountain peaks or saddles for rank == 1.
                return layer && {...featurePOI, ...featureToPOI(featurePOI.feature, layer)};
            })), 'position.trackPointIdx');

            setPois((pois) => _.isEqual(pois, routePOIs) ? pois : routePOIs);
        }

        findTrackPOIs();
    }, [
        coordinates, excluded, features, firebasePoisMap, loaded, mapLibre, radius, reduceRBush, routePoints, trackRBush,
        // Must be on the end, for depsEqual to work.
        getCachedPosition, updateCachedPositions], depsEqual);

    return {data: pois, isFetching};
}

