import styled from '@emotion/styled';
import classNames from 'classnames';
import _ from 'lodash';
import React, {memo} from 'react';
import {Badge, Button, Card, Col, Row, ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FaTrashAlt} from 'react-icons/fa';
import {FaHillRockslide} from 'react-icons/fa6';
import {GiPathDistance} from 'react-icons/gi';
import {ImArrowDownRight, ImArrowUpRight} from 'react-icons/im';
import {LiaBicycleSolid} from 'react-icons/lia';
import {NavigationControl} from 'react-map-gl';
import {BIKE_KINDS} from '../lib/bikeKinds';
import {useInitMaplibreLocation} from '../lib/useInitMaplibreLocation';
import {DesktopOnly, MobileOnly} from '../lib/util';
import {AdjustableVectorLayer} from './AdjustableVectorLayer';
import {Climbs} from './Climbs';
import {DivMarker} from './DivMarker';
import {useDetectGeoLocation} from './GeoLocationContext';
import {PointsOfInterest} from './PointsOfInterest';
import {Track} from './Track';


const GeolocationMarkerDiv = styled.div`
    border-radius: 50%;
    width: 22px;
    height: 22px;
    border: solid 2px;
    background-color: white;

    & div {
        border-radius: 50%;
        width: 12px;
        height: 12px;
    }
`;

function GeolocationMarker() {
    const {geoLocation: {lat, lng}} = useDetectGeoLocation();
    return lat && lng ? <DivMarker draggable={false} position={[lng, lat]} anchor="center">
        <GeolocationMarkerDiv className="border-info d-flex align-items-center justify-content-center">
            <div className="bg-info"></div>
        </GeolocationMarkerDiv>
    </DivMarker> : null;
}

const bikeKindIcons = {
    gravel: styled(LiaBicycleSolid)`
        border-bottom: 2px dashed;
        color: brown;`,
    road: styled(LiaBicycleSolid)`
        border: none;
        color: black`,
    mtb: styled(FaHillRockslide)`
        border: none;
        transform: scaleX(-1);
        color: slategray`
};

const bikeKindBadges = {
    gravel: styled(Badge)`
        background-color: brown !important;`,
    road: styled(Badge)`
        background-color: black !important;`,
    mtb: styled(Badge)`
        background-color: slategray !important;`
};

function BikeKindBadge({bikeKind, ...props}) {
    const Badge = bikeKindBadges[bikeKind];
    if (!Badge) return null;
    return <Badge {...props} />;
}

export function BikeKindIcon({bikeKind = 'road', ...props}) {
    const Icon = bikeKindIcons[bikeKind];
    if (!Icon) return null;
    return <Icon {...props}/>;
}

export function BikeKindEmblem({bikeKind = 'road', className, ...props}) {
    const {t} = useTranslation(),
        /* i18next-extract-disable-next-line */
        bikeKindText = t(BIKE_KINDS[bikeKind] ?? BIKE_KINDS.road);

    return <div className={classNames("d-flex align-items-center", className)} {...props}>
        <BikeKindIcon bikeKind={bikeKind} size="1.5em" className="me-1"/>
        <BikeKindBadge bikeKind={bikeKind}>{bikeKindText}</BikeKindBadge>
    </div>;
}

export function RoutePropsBody({track, bikeKind}) {
    const {t} = useTranslation();
    const {ascent = 0, descent = 0, distance = 0} = track,
        spacer = <>
            <MobileOnly>&nbsp;</MobileOnly>
            <DesktopOnly><br/></DesktopOnly>
        </>;

    return <>
        <Row className="align-items-center h-100">
            <Col className="text-center ps-0 pe-0 ps-md-1 pe-md-1"><GiPathDistance/>{spacer}{t('{{distance, number}}', {
                distance,
                style: 'unit',
                unit: 'kilometer',
                unitDisplay: 'short',
                maximumFractionDigits: 1
            })}</Col>
            <Col className="text-center ps-0 pe-0 ps-md-1 pe-md-1"><ImArrowUpRight/>{spacer}{t('{{ascent, number}}', {
                ascent,
                style: 'unit',
                unit: 'meter',
                unitDisplay: 'short',
                maximumFractionDigits: 0
            })}</Col>
            <Col className="text-center ps-0 pe-0 ps-md-1 pe-md-1"><ImArrowDownRight/>{spacer}{t('{{descent, number}}', {
                descent,
                style: 'unit',
                unit: 'meter',
                unitDisplay: 'short',
                maximumFractionDigits: 0
            })}</Col>
        </Row>
        {!!bikeKind &&
            <Row className="pt-2 mb-0">
                <Col className="text-center">
                    <BikeKindEmblem bikeKind={bikeKind}/>
                </Col>
            </Row>
        }
    </>;
}

export const RoutePropertiesCard = memo(function({track, bikeKind, className}) {
    return <Card className={className}>
        <Card.Body className="py-0">
            <RoutePropsBody track={track} bikeKind={bikeKind}/>
        </Card.Body>
    </Card>;
});

const BikeKindIconShadowStyled = styled(BikeKindIcon)`
    filter: url(#white-glow);
`;

function BikeKindIconShadow({bikeKind, ...props}) {
    return <>
        <svg width="0" height="0">
            <defs>
                <filter id="white-glow" x="-50%" y="-50%" width="250%" height="250%">
                    <feFlood result="flood" floodColor="white" floodOpacity="1"></feFlood>
                    <feComposite in="flood" result="mask" in2="SourceGraphic" operator="in"></feComposite>
                    <feMorphology in="mask" result="dilated" operator="dilate" radius="2"></feMorphology>
                    <feGaussianBlur in="dilated" result="blurred" stdDeviation="2"></feGaussianBlur>
                    <feMerge>
                        <feMergeNode in="blurred"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
            </defs>
        </svg>
        <BikeKindIconShadowStyled bikeKind={bikeKind} {...props} />
    </>;
}

export const BikeKindSelector = memo(function BikeKindSelector({bikeKind, buttonClassName, className, id, setBikeKind}) {
    const {t} = useTranslation();

    return <ToggleButtonGroup value={bikeKind} onChange={setBikeKind} className={className} type="radio" name={`${id}-bikeKind`}>
        {_.map(BIKE_KINDS, (label, bikeKind) =>  /* i18next-extract-disable-next-line */
            <ToggleButton type="radio" className={buttonClassName} variant="outline-secondary" {...{key: bikeKind, id: `${id}-${bikeKind}-bikeKind-toggle`, value: bikeKind}}>
                <BikeKindIconShadow bikeKind={bikeKind}/><span className="ms-1">{t(label)}</span>
            </ToggleButton>
        )}
    </ToggleButtonGroup>;
});

const StyledDiv = styled.div`
    background: #ffffff55;
    font-size: x-small;
    display: inline-block;
    margin-right: 0;
    margin-left: auto;
`;

export function MapAttributionControl() {
    return <StyledDiv>
        <a href="https://www.openmaptiles.org/" target="_blank" rel="noreferrer">&copy; OpenMapTiles</a>
        <div className="vr mx-1"/>
        <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">&copy; OpenStreetMap contributors</a>
    </StyledDiv>;
};

const NO_HANDLERS = {};

/**
 * Common map components used by both RouteViewer and RoutePlanner.
 * @param {Array} activeFetches active fetches. Displays spinner in route points being fetched.
 * @param {number} bboxMargin the margin (0.0-1.0) to keep around the track when setting initial bounds.
 * @param {boolean} editable is map editable; when true, also takes elevation viewer into account when fitting route in bounds.
 * @param {Object} handlers additional event handlers passed to Track and PointOfInterest (editable mode only).
 * @param pois
 * @param routeState full route state. See also: `useRouteReducer`.
 * @param setVolatileCoordinate `volatileCoordinate` state setter.
 * @param setElevationViewerSegment setter for hovered/selected climb segment. Passed to outside elevation viewer.
 * @param showControls should show navigation and attribution controls.
 * @param showWeather when false, will prevent displaying weather controls regardless user preference (for snapshotting)
 * @param {[number, number]} volatileCoordinate latLng of volatile marker on the map.
 */
export function MapComponents({
    bboxMargin, editable, handlers = NO_HANDLERS, pois, routeState,
    setElevationViewerSegment, setVolatileCoordinate, showControls = true, showWeather = true, volatileCoordinate
}) {
    const {activeFetches, route, routePOIs, routeProps, track} = routeState ?? {},
        {bbox: boundingBox} = track ?? {},
        {axisRatio, bearing, distance, plannedDate, showTrackPOIs, visiblePOIs, weatherOnRoute} = routeProps ?? {},
        isRoute = !_.isEmpty(route),
        showWarnings = _.isNil(visiblePOIs) || _.includes(visiblePOIs, 'warnings') || _.includes(visiblePOIs, 'climbs');

    useInitMaplibreLocation(boundingBox, route, editable, bboxMargin);

    return <>
        <AdjustableVectorLayer {...routeProps} isRoute={isRoute}/>
        <GeolocationMarker/>
        <PointsOfInterest {...{pois, routeProps, track, ...handlers}}/>
        <Climbs/>
        {routeState &&
            <Track editable={editable} weatherOnRoute={showWeather && weatherOnRoute} showWarnings={showTrackPOIs && showWarnings}
                   {...{
                       activeFetches, axisRatio, bearing, distance, plannedDate, ...handlers, route, routePOIs,
                       setElevationViewerSegment, setVolatileCoordinate, track, volatileCoordinate
                   }} />}

        {showControls && <>
            <NavigationControl position="top-left" showCompass={true} showZoom={true} visualizePitch={true}/>
        </>}
    </>;
}

export function ClearRouteButton({children = <FaTrashAlt/>, onClick, ...props}) {
    const {t} = useTranslation();
    return <Button title={t("Clear route")} size="sm" onClick={onClick} {...props}>
        {children}
    </Button>;
}