// if process


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyAlajiw7QLsJ-hI1os3BkLCMJIcHZCEiDU",
    authDomain: "tarmacs-app.firebaseapp.com",
    projectId: "tarmacs-app",
    storageBucket: "tarmacs-app.appspot.com",
    messagingSenderId: "676385266573",
    appId: "1:676385266573:web:3ca940da26467587c73bc4",
    measurementId: "G-TJL15ZNVQ3"
};
