/* TODO: Replace when react-icons-all have these */
import styled from '@emotion/styled';
import _ from 'lodash';
import {DateTime, Interval} from 'luxon';
import {useMemo} from 'react';
import {Badge} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {t_} from '../components/l10n';
import {ReactComponent as ClearDay} from '../images/meteocons/clear-day.svg';
import {ReactComponent as Cloudy} from '../images/meteocons/cloudy.svg';
import {ReactComponent as Drizzle} from '../images/meteocons/drizzle.svg';
import {ReactComponent as Fog} from '../images/meteocons/fog.svg';
import {ReactComponent as PartlyCloudyDayDrizzle} from '../images/meteocons/partly-cloudy-day-drizzle.svg';
import {ReactComponent as PartlyCloudyDayRain} from '../images/meteocons/partly-cloudy-day-rain.svg';
import {ReactComponent as PartlyCloudyDaySleet} from '../images/meteocons/partly-cloudy-day-sleet.svg';
import {ReactComponent as PartlyCloudyDaySnow} from '../images/meteocons/partly-cloudy-day-snow.svg';
import {ReactComponent as PartlyCloudyDay} from '../images/meteocons/partly-cloudy-day.svg';
import {ReactComponent as Rain} from '../images/meteocons/rain.svg';
import {ReactComponent as Sleet} from '../images/meteocons/sleet.svg';
import {ReactComponent as Snow} from '../images/meteocons/snow.svg';
import {ReactComponent as ThermometerC} from '../images/meteocons/thermometer-celsius.svg';
import {ReactComponent as ThermometerF} from '../images/meteocons/thermometer-fahrenheit.svg';
import {ReactComponent as ThunderstormsDayRain} from '../images/meteocons/thunderstorms-day-rain.svg';
import {ReactComponent as ThunderstormsDaySnow} from '../images/meteocons/thunderstorms-day-snow.svg';
import {ReactComponent as ThunderstormsDay} from '../images/meteocons/thunderstorms-day.svg';
import {ReactComponent as ThunderstormsRain} from '../images/meteocons/thunderstorms-rain.svg';
import {ReactComponent as ThunderstormsSnow} from '../images/meteocons/thunderstorms-snow.svg';
import {ReactComponent as Thunderstorms} from '../images/meteocons/thunderstorms.svg';
import {ReactComponent as Wind} from '../images/meteocons/wind.svg';

import {toHostPath} from './util';


const mapping = {
    "clearsky": [ClearDay, t_("Clear sky"), "clear-day"],
    "cloudy": [Cloudy, t_("Cloudy"), "cloudy"],
    "fair": [PartlyCloudyDay, t_("Fair"), "partly-cloudy-day"],
    "fog": [Fog, t_("Fog"), "fog"],
    "heavyrain": [Rain, t_("Heavy rain"), "rain"],
    "heavyrainandthunder": [ThunderstormsRain, t_("Heavy rain and thunder"), "thunderstorms-rain"],
    "heavyrainshowers": [PartlyCloudyDayRain, t_("Heavy rain showers"), "partly-cloudy-day-rain"],
    "heavyrainshowersandthunder": [ThunderstormsDayRain, t_("Heavy rain showers and thunder"), "thunderstorms-day-rain"],
    "heavysleet": [Sleet, t_("Heavy sleet"), "sleet"],
    "heavysleetandthunder": [ThunderstormsSnow, t_("Heavy sleet and thunder"), "thunderstorms-snow"],
    "heavysleetshowers": [PartlyCloudyDaySleet, t_("Heavy sleet showers"), "partly-cloudy-day-sleet"],
    "heavysleetshowersandthunder": [ThunderstormsDaySnow, t_("Heavy sleet showers and thunder"), "thunderstorms-day-snow"],
    "heavysnow": [Snow, t_("Heavy snow"), "snow"],
    "heavysnowandthunder": [ThunderstormsSnow, t_("Heavy snow and thunder"), "thunderstorms-snow"],
    "heavysnowshowers": [PartlyCloudyDaySnow, t_("Heavy snow showers"), "partly-cloudy-day-snow"],
    "heavysnowshowersandthunder": [ThunderstormsDaySnow, t_("Heavy snow showers and thunder"), "thunderstorms-day-snow"],
    "lightrain": [Drizzle, t_("Light rain"), "drizzle"],
    "lightrainandthunder": [Thunderstorms, t_("Light rain and thunder"), "thunderstorms"],
    "lightrainshowers": [PartlyCloudyDayDrizzle, t_("Light rain showers"), "partly-cloudy-day-drizzle"],
    "lightrainshowersandthunder": [ThunderstormsDay, t_("Light rain showers and thunder"), "thunderstorms-day"],
    "lightsleet": [Sleet, t_("Light sleet"), "sleet"],
    "lightsleetandthunder": [ThunderstormsSnow, t_("Light sleet and thunder"), "thunderstorms-snow"],
    "lightsleetshowers": [PartlyCloudyDaySleet, t_("Light sleet showers"), "partly-cloudy-day-sleet"],
    "lightsnow": [Snow, t_("Light snow"), "snow"],
    "lightsnowandthunder": [ThunderstormsSnow, t_("Light snow and thunder"), "thunderstorms-snow"],
    "lightsnowshowers": [PartlyCloudyDaySnow, t_("Light snow showers"), "partly-cloudy-day-snow"],
    "lightssleetshowersandthunder": [ThunderstormsDaySnow, t_("Light sleet showers and thunder"), "thunderstorms-day-snow"],
    "lightssnowshowersandthunder": [ThunderstormsDaySnow, t_("Light snow showers and thunder"), "thunderstorms-day-snow"],
    "partlycloudy": [PartlyCloudyDay, t_("Partly cloudy"), "partly-cloudy-day"],
    "rain": [Rain, t_("Rain"), "rain"],
    "rainandthunder": [ThunderstormsRain, t_("Rain and thunder"), "thunderstorms-rain"],
    "rainshowers": [PartlyCloudyDayRain, t_("Rain showers"), "partly-cloudy-day-rain"],
    "rainshowersandthunder": [ThunderstormsDayRain, t_("Rain showers and thunder"), "thunderstorms-day-rain"],
    "sleet": [Sleet, t_("Sleet"), "sleet"],
    "sleetandthunder": [ThunderstormsSnow, t_("Sleet and thunder"), "thunderstorms-snow"],
    "sleetshowers": [PartlyCloudyDaySleet, t_("Sleet showers"), "partly-cloudy-day-sleet"],
    "sleetshowersandthunder": [ThunderstormsDaySnow, t_("Sleet showers and thunder"), "thunderstorms-day-snow"],
    "snow": [Snow, t_("Snow"), "snow"],
    "snowandthunder": [ThunderstormsSnow, t_("Snow and thunder"), "thunderstorms-snow"],
    "snowshowers": [PartlyCloudyDaySnow, t_("Snow showers"), "partly-cloudy-day-snow"],
    "snowshowersandthunder": [ThunderstormsDaySnow, t_("Snow showers and thunder"), "thunderstorms-day-snow"]
};

export function YrNoSymbol({symbol, ...props}) {
    const {t} = useTranslation(),
        [Symbol, title] = mapping[symbol] ?? [];
    // i18next-extract-disable-next-line
    return Symbol ? <Symbol {...props} title={t(title)}/> : null;
}

export const WindSpeedDirection = styled(Wind)`
    transform: rotate(${({direction}) => direction + 90}deg);
`;

export function UVIndex({index, ...props}) {
    index = _.max([Math.floor(index), 1]);
    const variant = (index > 10 ? 'danger' : (index > 5 ? 'warning' : (index > 3 ? 'info' : 'success')));
    return <Badge bg={variant}>{index}</Badge>;
}

// Alternate /compact endpoint does not provide UV index.
const YRNO_API = 'https://api.met.no/weatherapi/locationforecast/2.0/complete';

const truncateSuffix = (symbol) => _.head(_.split(symbol ?? '', '_'));

function findWeatherByTime(timeseries, when) {
    const idx = _.findIndex(timeseries, ({time}) => DateTime.fromISO(time) >= when);
    if (idx < 0) return {};

    const after = timeseries[idx],
        before = timeseries[idx - 1];

    const beforeDiff = before && Interval.fromDateTimes(DateTime.fromISO(before.time), when).length('minutes'),
        afterDiff = after && Interval.fromDateTimes(when, DateTime.fromISO(after.time)).length('minutes');
    return beforeDiff ? (beforeDiff < afterDiff || afterDiff > 120 ? before : after) : (after ?? {});
}

/**
 * Fetch weather forecast from met.no (aka yr.no).
 *
 * See also: https://api.met.no/weatherapi/locationforecast/2.0/
 */
export function useYrNoForecast(lngLat, when, elevation, enabled = true) {
    const elevationParam = elevation ? {altitude: Math.round(elevation)} : {};
    const [lon, lat] = lngLat ?? [null, null],
        {data: forecast, isError, isLoading} = useQuery(["yrno", "location", [lon, lat]], async () => {
            const url = toHostPath({urlString: YRNO_API, searchParams: {lat, lon, ...elevationParam}}),
                resp = await fetch(url),
                data = await resp.json();
            return data;
        }, {enabled: enabled && !(_.isNil(lat) && _.isNil(lon)), keepPreviousData: true});

    const yrNoForecast = useMemo(() => {
        const {data, time} = findWeatherByTime(forecast?.properties?.timeseries, when),
            symbol1 = truncateSuffix(data?.next_1_hours?.summary?.symbol_code),
            symbol6 = truncateSuffix(data?.next_6_hours?.summary?.symbol_code),
            {wind_speed, wind_from_direction, air_temperature, ultraviolet_index_clear_sky, cloud_area_fraction} = data?.instant?.details ?? {};
        return {isError, isLoading, data: {time: DateTime.fromISO(time), symbol1, symbol6, wind_speed, wind_from_direction, air_temperature, ultraviolet_index_clear_sky, cloud_area_fraction}};
    }, [isError, isLoading, forecast, when]);
    return yrNoForecast;
}

export {ThermometerC, ThermometerF};