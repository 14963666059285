import {connectAuthEmulator as startAuthEmulator} from '@firebase/auth';
import {connectFirestoreEmulator as startFirestoreEmulator, initializeFirestore} from '@firebase/firestore';
import {connectStorageEmulator as startStorageEmulator} from '@firebase/storage';
import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';
import _ from 'lodash';
import {createContext} from 'react';
import {firebaseConfig} from '../settings';


export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = _.isEmpty(process.env.REACT_APP_EMULATOR_FIRESTORE) ?
    getFirestore(firebaseApp) :
    initializeFirestore(firebaseApp, {experimentalAutoDetectLongPolling: true});
export const storage = getStorage(firebaseApp);

if (process.env.REACT_APP_EMULATOR) {
    // Renamed use... to start... on top to avoid React error - use... is reserved for hooks.
    !_.isEmpty(process.env.REACT_APP_EMULATOR_AUTH) && startAuthEmulator(auth, `http://${process.env.REACT_APP_EMULATOR}:${process.env.REACT_APP_EMULATOR_AUTH}`, { disableWarnings: true });
    !_.isEmpty(process.env.REACT_APP_EMULATOR_FIRESTORE) && startFirestoreEmulator(db, process.env.REACT_APP_EMULATOR, process.env.REACT_APP_EMULATOR_FIRESTORE);
    !_.isEmpty(process.env.REACT_APP_EMULATOR_STORAGE) && startStorageEmulator(storage, process.env.REACT_APP_EMULATOR, process.env.REACT_APP_EMULATOR_STORAGE);
}

export const firebaseAuthProviderDefaultProps = {
    auth,
    isSignedIn: null, // Default null means 'undetermined yet'. Will morph to false on true after the page is loaded and provider gets from Firebase.
    providerId: null,
    user: null,
    updatePhotoURL: () => {
    }  // Necessary API to immediately redraw user-menu avatar on new photo upload.
};

// Debug log for firestore:
// import {setLogLevel} from 'firebase/firestore';
// setLogLevel('debug');
export const FirebaseAuth = createContext(firebaseAuthProviderDefaultProps);