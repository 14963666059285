import {useCallback} from 'react';
import {createReducer} from 'react-use';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

const MIDDLEWARES = process.env.NODE_ENV === 'production' ? [thunk] : [thunk, logger];
export const useThunkReducer = createReducer(...MIDDLEWARES);

/**
 * Wraps thunk function with default dispatch of action
 */
function thunkFunction(action, thunkFunctions, wrappedDispatch) {
    const {type} = action,
        thunk = thunkFunctions[type];

    return thunk && ((dispatch, getState) => {
        dispatch(action);
        return thunk(wrappedDispatch, getState, action);
    });
}

function wrapDispatch(dispatch, thunkFunctions) {
    return function wrappedDispatch(action) {
        const fn = thunkFunction(action, thunkFunctions, wrappedDispatch);
        return dispatch(fn ?? action);
    }
}

export function useThunkFunctionsReducer(reducer, initialState, thunkFunctions) {
    const [state, dispatch] = useThunkReducer(reducer, initialState);
    const dispatchWrapper = useCallback((action) => wrapDispatch(dispatch, thunkFunctions)(action), [dispatch, thunkFunctions]);
    return [state, dispatchWrapper];
}