import {TourProvider, useTour} from '@reactour/tour';
import {getCoord} from '@turf/turf';
import classNames from 'classnames';
import {DesktopOnly, MobileOnly, useDeviceType, useDeviceTypeVariant, useFillViewEffect, useSearchParamsObject} from "lib/util";
import _ from "lodash";
import {forwardRef, memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Alert, Button, Col, Container, Modal, Row, Stack} from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Helmet} from 'react-helmet';
import {Trans, useTranslation} from 'react-i18next';
import {FaMapMarked, FaMapMarkedAlt, FaRoute} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import {useMount} from 'react-use';
import {useDebounce} from 'use-debounce';
import {useBoolean, useLocalStorage, useResizeObserver} from 'usehooks-ts';
import {BIKE_KINDS} from '../lib/bikeKinds';
import {useCurrentUserData} from '../lib/db';
import {errorHandler} from '../lib/errors';
import {FirebaseAuth} from '../lib/firebase';
import {ga, onGAEvent} from '../lib/ga';
import {useHereReverseGeocodeQueries} from '../lib/here';
import {BBOX_MARGIN} from '../lib/useInitMaplibreLocation';
import {useMapLibre} from '../lib/useMapLibre';
import {loadFromBrowserStorage, useRouteReducer} from '../lib/useRouteReducer';
import {useTrackPOIs} from '../lib/useTrackPOIs';
import {ZINDEX} from '../lib/zindexes';
import {OAuthActivitiesMap} from './auth/OAuth';
import {DivMarker} from './DivMarker';
import {ClearInspirationButton, PreviewRoute, RoundtripDebugger} from './InspirationsSelector';
import {useIntroSteps} from './IntroTour';
import {LocationTypeahead} from './LocationTypeahead';
import {MapHorizontalAccordion} from './MapHorizontalAccordion';
import {MaplibreMap} from './MaplibreMap';
import {POIPopupContextProvider} from './POIPopup';
import {MapAttributionControl, MapComponents} from './RouteMapping';
import {RoutePlannerProperties} from './RoutePlannerProperties';
import {SaveRoute} from './SaveRoute';
import {MARKER_SIZE, RoutePointMarker} from "./Track";
import {MapViewStateProvider, useViewState} from './ViewStateContext';
import {ExpandableWeatherPanel} from './WeatherPanel';


function StorageLoadWatcher({callback}) {
    const [{storage = false}] = useSearchParamsObject();

    useMount(() => {
        storage && callback();
    });
    return null;
}

/**
 * Display a box on the map with sample way points.
 */
function TourBox() {
    const {isOpen, currentStep, steps} = useTour(),
        {bounds} = useViewState(),
        isActive = isOpen && steps[currentStep]?.selector === '.intro-map-points';

    function getMarkers() {
        if (!bounds) return [];
        const [[left, bottom], [right, top]] = bounds.toArray();
        return [
            [left + (right - left) * 0.4, bottom + (top - bottom) * 0.4],
            [left + (right - left) * 0.45, bottom + (top - bottom) * 0.55],
            [left + (right - left) * 0.6, bottom + (top - bottom) * 0.6]
        ];
    }

    const sampleMarkers = isActive && _.map(getMarkers(), ([lat, lon], idx) =>
        <DivMarker key={idx} className="coordinate-marker" draggable={true} size={MARKER_SIZE.ROUTEPOINT} position={[lat, lon]}>
            <RoutePointMarker radius={MARKER_SIZE.ROUTEPOINT}>{idx + 1}</RoutePointMarker>
        </DivMarker>
    );

    return <div className={classNames('intro-map-points', {'d-none': !isActive, 'd-block': isActive})}
                style={{marginTop: '33%', marginLeft: '33%', marginRight: '33%', marginBottom: '33%', width: '33%', height: '33%'}}>
        {sampleMarkers}
    </div>;
}

function TopRightMapControls({route, track, routeProps, updateRouteProp}) {
    return <div className="maplibregl-control-container maplibregl-ctrl-top-right">
        <MobileOnly>
            <Stack direction="vertical" className="align-items-end" gap={1}>
                <LocationEditBox/>
                <ExpandableWeatherPanel {...{route, track, routeProps, updateRouteProp}}/>
            </Stack>
        </MobileOnly>
        <DesktopOnly>
            <Stack direction="horizontal" className="align-items-start m-1" gap={1}>
                <ExpandableWeatherPanel {...{route, track, routeProps, updateRouteProp}}/>
                <LocationEditBox/>
            </Stack>
        </DesktopOnly>
    </div>;
}

const onSelectLocation = (location) => ga('search', {search_term: location?.label, search_id: location?.id});

const LocationEditBox = memo(function LocationEditBox() {
    const {mapLibre} = useMapLibre(),
        {updateViewState} = useViewState();

    const onSetPosition = useCallback((position) => {
        if (_.isEmpty(position)) return null;
        const [longitude, latitude] = getCoord(position);
        // 'user' source is used to prevent overwriting the position by bbox or geolocation.
        updateViewState({longitude, latitude, cameraSource: 'user', inspirationsImmediate: true});
    }, [mapLibre]);

    // Alter z-index to ensure the drop-down is not behind weather panel (which is below in mobile mode).
    return <div className="maplibregl-ctrl intro-location-box m-0" style={{zIndex: ZINDEX.LOCATION_BOX}}>
        <LocationTypeahead id="location-edit" onSetPosition={onSetPosition} onSelect={onSelectLocation} align="right"/>
    </div>;
});

function RoutePlannerMap({dispatchRoute, routeState, setPOIsLoading}) {
    const [user] = useCurrentUserData(),
        {id: routeId, base, route, routeProps, routePOIs, track} = routeState,
        {visiblePOIs, bikeKind, suggested} = routeProps,
        [preview, setPreview] = useState(null),
        handlers = useMemo(() => {
            const pointAction = (action) => onGAEvent((idx, coordinate, poi) => dispatchRoute({type: action, idx, coordinate, poi}),
                'routepoint_edit', {item_id: routeId, method: action});
            return {
                onPointInsert: pointAction('insert'),
                onPointMove: pointAction('move'),
                onPointDelete: pointAction('delete'),
            };
        }, [dispatchRoute, routeId]),
        {data: pois, isFetching: poisLoading} = useTrackPOIs(track, routePOIs, visiblePOIs);

    useEffect(() => {
        setPOIsLoading?.(poisLoading);
    }, [poisLoading, setPOIsLoading]);

    const [volatileCoordinate, setVolatileCoordinate] = useState(null),
        [elevationViewerSegment, setElevationViewerSegment] = useState(null);

    const updateRouteProp = useCallback((prop, value, dirty) => dispatchRoute({type: 'updateRouteProps', routeProps: {[prop]: value}, dirty}), [dispatchRoute]),
        proposeRoundtrip = useCallback((routeProps) => dispatchRoute({type: 'proposeRoundtrip', ...routeProps}), [dispatchRoute]);

    return <POIPopupContextProvider>
        <MapComponents editable={true} bboxMargin={BBOX_MARGIN}
                       {...{handlers, pois, routeState, setElevationViewerSegment, setVolatileCoordinate, volatileCoordinate}} />
        <PreviewRoute geojson={preview} bikeKind={bikeKind}/>
        <TourBox />
        <TopRightMapControls route={route} track={track}
                             routeProps={routeProps} updateRouteProp={updateRouteProp}/>
        {user?.is_staff && false &&
            <RoundtripDebugger route={route} routeProps={routeProps} proposeRoundtrip={proposeRoundtrip}/>}

        <MapHorizontalAccordion dispatchRoute={dispatchRoute}
                                onPreviewRoute={setPreview}
                                start={_.first(route)} size={_.size(route)}
                                {...{...routeProps, bikeKind, elevationViewerSegment, setVolatileCoordinate, suggested, track}} >
            {suggested &&
                <ClearInspirationButton className="justify-content-start" variant="light" base={base} dispatchRoute={dispatchRoute} />}
            <MapAttributionControl />
        </MapHorizontalAccordion>
    </POIPopupContextProvider>;
}

const MapPaneCol = memo(function MapPaneCol({dispatchRoute, routeState, setPOIsLoading}) {
    const mapContainerRef = useRef(null),
        size = useResizeObserver({ref: mapContainerRef, box: 'border-box'}),
        // Debouncing should prevent "Maximum update depth exceeded." which sometimes happen.
        // See also: https://github.com/juliencrn/usehooks-ts/issues/217, https://github.com/juliencrn/usehooks-ts/issues/154#issuecomment-1128807474
        {width, height} = useDebounce(size, 500),
        {mapLibre} = useMapLibre();

    useEffect(() => {
        // Avoids gray bottom part after navbar collapse in mobile mode.
        // Catch exceptions when resizing from mobile to desktop (map can be unmounted in the meanwhile).
        try {
            mapLibre?.resize();
        } catch (e) {
        }
    }, [mapLibre, width, height]);

    return <Col offset={0} xs={12} sm={12} md ref={mapContainerRef}>
        <MaplibreMap >
            <RoutePlannerMap {...{dispatchRoute, routeState, setPOIsLoading}} />
        </MaplibreMap>
    </Col>;
});

const SidebarCol = memo(function SidebarCol_({dispatchRoute, onSave, poisLoading, routeState}) {
    const {routeProps, id: routeId, track} = routeState;

    const [showSave, toggleShowSave] = useState(false),
        updateRouteProp = useCallback((routeProp, dirty = true) =>
                (value) => {
                    ga('routeprop_change', {prop: routeProp, value, item_id: routeId});
                    return dispatchRoute({type: 'updateRouteProps', routeProps: {[routeProp]: value}, dirty});
                },
            [dispatchRoute, routeId]);

    const storageLoaderCallback = useCallback(async () => {
        if (routeId) {
            // Saving existing route being edited. Avoid re-reading from storage some other crap.
            return true;
        }
        try {
            const {route, track, routePOIs, routeProps} = loadFromBrowserStorage();

            if (!_.isEmpty(route) && !_.isEmpty(track?.coordinates)) {
                await dispatchRoute({type: 'loadedRoute', route, routePOIs, track, routeProps});
                return true;
            }
        } catch (ex) {
            errorHandler.report(`WARN: could not read from storage: ${ex}.`);
        }
    }, [dispatchRoute, routeId]);

    const onImportRoute = useCallback(({geojson, tolerance}) => dispatchRoute({type: 'importRoute', geojson, tolerance}), [dispatchRoute]);

    const oauthSaveCallback = useCallback(async () => {
        await storageLoaderCallback() && _.delay(() => toggleShowSave(true));
    }, [storageLoaderCallback, toggleShowSave]);

    const onSaveRoute = useCallback(() => toggleShowSave(true), [toggleShowSave]),
        onHide = useCallback(() => toggleShowSave(false), [toggleShowSave]);

    return <>
        <RoutePlannerProperties poisLoading={poisLoading} routeState={routeState} updateRouteProp={updateRouteProp}
                                onImportRoute={onImportRoute} onSaveRoute={onSaveRoute}
                                onResetRoute={() => dispatchRoute({type: 'reset'})}
                                onReverseRoute={() => dispatchRoute({type: 'reverse', dirty: true})}
        />
        <SaveRoute routeProps={routeProps} coordinates={track?.coordinates} distance={track?.distance} show={showSave} onHide={onHide} onSave={onSave} routeId={routeId}/>
        <OAuthActivitiesMap activitiesMap={{save: oauthSaveCallback, signIn: storageLoaderCallback, signUp: storageLoaderCallback}}/>
        <StorageLoadWatcher callback={storageLoaderCallback}/>
    </>;
});

function TourStarterReal({tourQS, toggleTourViewed, setIsOpen}) {
    useMount(() => {
        !tourQS && toggleTourViewed(true);
        setIsOpen(true);
    });
}

function TourStarter({suppress}) {
    const {setIsOpen} = useTour(),
        {user} = useContext(FirebaseAuth),
        [{tour: tourQS}] = useSearchParamsObject(),
        [tourViewed, toggleTourViewed] = useLocalStorage('tourViewed', false);

    if (user || suppress || (tourViewed && !tourQS))
        return null;

    return <TourStarterReal {...{tourQS, toggleTourViewed, setIsOpen}} />;
}

function Tour({suppress, children}) {
    const {t} = useTranslation(),
        tourSteps = useIntroSteps(),
        [currentTourStep, setCurrentTourStep] = useState(0);

    const nextButton = ({currentStep, stepsLength, setIsOpen, setCurrentStep}) => {
        const last = currentStep === (stepsLength - 1);
        return (
            <Button onClick={() => {
                last ? setIsOpen(false) : setCurrentStep(s => (s === tourSteps.length - 1 ? 0 : s + 1));
            }}>
                {last ? t('Close') : t('Next')}
            </Button>
        );
    };
    const prevButton = ({currentStep, setCurrentStep}) => {
        const first = currentStep === 0;
        const next = first ? tourSteps.length - 1 : currentStep - 1;
        return !first && <Button onClick={() => setCurrentStep(next)}><Trans>Back</Trans></Button>;
    };

    // noinspection JSUnusedGlobalSymbols
    const tourStyles = {
        popover: style => ({
            ...style,
            backgroundColor: '#00000060',
            color: 'white',
            borderTopColor: 'white',
            borderTopStyle: 'solid',
            borderTopWidth: '1px'
        }),
        maskWrapper: style => ({
            ...style,
            opacity: 0.5
        }),
        close: style => ({
            ...style,
            marginRight: '-10px',
            marginTop: '-10px',
            color: 'white'
        }),
        buttons: style => ({...style, color: 'yellow'})
    };

    return <TourProvider steps={tourSteps}
                         setCurrentStep={setCurrentTourStep} currentStep={currentTourStep}
                         styles={tourStyles}
                         nextButton={nextButton}
                         prevButton={prevButton}
                         padding={{mask: [15, 15]}}
                         afterOpen={() => ga('tutorial_begin')}
                         beforeClose={() => (currentTourStep === _.size(tourSteps) - 1) ? ga('tutorial_complete') : ga('tutorial_cancel', {step: currentTourStep})}
                         onClickMask={
                             ({setCurrentStep, currentStep, setIsOpen}) => {
                                 currentStep === tourSteps.length - 1 && setIsOpen(false);
                                 setCurrentStep(s => (s === tourSteps.length - 1 ? 0 : s + 1));
                             }}>
        <TourStarter suppress={suppress}/>
        {children}
    </TourProvider>;
}

function ConfirmInsertPointModal({distance, points, scrollBack, stayHere, show, showModal}) {
    const {t} = useTranslation(),
        {mapLibre} = useMapLibre(),
        distanceFmt = t('{{distance, number}}', {distance, maximumFractionDigits: 0, style: 'unit', unit: 'kilometer', unitDisplay: 'short'});
    const locations = useHereReverseGeocodeQueries(points),
        [prevLocation, nextLocation] = _.map(locations, 'data.address.city'),
        explainer = (_.isEmpty(prevLocation) || _.isEmpty(nextLocation)) ?
            <Trans>You just clicked a route point being <strong>{{distanceFmt}}</strong> away from the previous one.</Trans> :
            <Trans>
                You just clicked a route point around <strong>{{nextLocation}}</strong>.
                It is <strong>{{distanceFmt}}</strong> away from previous one you had clicked in <strong>{{prevLocation}}</strong>.
            </Trans>,
        scrollBackMsg = _.isEmpty(prevLocation) ? <Trans>Pan back to previous location</Trans> : <Trans>Pan back to {{prevLocation}}</Trans>;

    const onScrollBackClick = useCallback(() => {
        mapLibre?.panTo(getCoord(_.first(points)), {duration: 2000});
        scrollBack();
    }, [scrollBack, mapLibre, points]);

    const onStayHereClick = useCallback(() => {
        stayHere();
    }, [stayHere]);

    return <Modal backdrop={true} show={show} onHide={() => showModal(null)} centered animation={false} size="lg">
        <Modal.Header closeButton={true}>
            <Modal.Title><Trans>Clear previous route points far away?</Trans></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{explainer}</p>
            <Alert variant="warning" className="my-0"><FaMapMarkedAlt/> <Trans>Maybe you have clicked the map accidentally?</Trans></Alert>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
            <Button onClick={onScrollBackClick}><FaRoute/> {scrollBackMsg}</Button>
            <Button onClick={onStayHereClick}><FaMapMarked/> <Trans>Draw a new route here</Trans></Button>
        </Modal.Footer>
    </Modal>;
}


export const RoutePlanner = forwardRef(function RoutePlanner() {
    // MapViewStateProvider must be elevated above RoutePlannerInner because useRouteReducer needs to call resetCameraSource.
    return <MapViewStateProvider>
        <RoutePlannerInner/>
    </MapViewStateProvider>;
})

export const RoutePlannerInner = forwardRef(function RoutePlannerInner() {
    useFillViewEffect();

    const {t} = useTranslation(),
        urlParams = useParams(),
        {isMobile} = useDeviceType(),
        {value: poisLoading, setValue: setPOIsLoading} = useBoolean();

    const [routeState, dispatchRoute] = useRouteReducer(urlParams?.routeId);
    const {routeProps: {bikeKind, name}, showModal, modalOptions, suppressTour} = routeState ?? {},
        /* i18next-extract-disable-next-line */
        bikeKindText = BIKE_KINDS[bikeKind] ? t(BIKE_KINDS[bikeKind]) : t(BIKE_KINDS.road);

    const title = _.isEmpty(name) ?
        t("Plan new cycling route: {{bikeKindText}} | Tarmacs.App", {bikeKindText}) :
        t("{{name}} | Plan cycling route: {{bikeKindText}} | Tarmacs.App", {name, bikeKindText});

    // Called by "save" dialog after setting name and description. Dialog will provide updated routeProps.
    const {scrollBack, stayHere, onSave} = useMemo(() => ({
        scrollBack: () => dispatchRoute({type: 'scrollBack'}),
        stayHere: () => dispatchRoute({type: 'stayHere'}),
        onSave: (routeProps, {saveToGarmin, selectedPOIs}) =>
            dispatchRoute({type: 'saveRoute', routeProps, ...(saveToGarmin ? {selectedPOIs, followUp: 'saveToGarmin'} : {})})
    }), [dispatchRoute]);

    return <>
        <Helmet>
            <meta name="robots" content="noindex"/>
            <title>{title}</title>
        </Helmet>
        <Container fluid>
            {!isMobile ? (<Tour suppress={suppressTour}>
                <Row className="flex-fill overflow-hidden">
                    <SidebarCol routeState={routeState} dispatchRoute={dispatchRoute} onSave={onSave} poisLoading={poisLoading}/>
                    <MapPaneCol {...{dispatchRoute, routeState, setPOIsLoading}} />
                </Row>
            </Tour>) : (<>
                <Row>
                    <SidebarCol routeState={routeState} dispatchRoute={dispatchRoute} onSave={onSave} poisLoading={poisLoading}/>
                </Row>
                <Row className="fill-view">
                    <MapPaneCol {...{dispatchRoute, routeState, setPOIsLoading}}/>
                </Row>
            </>)}
            <ConfirmInsertPointModal show={showModal === 'ConfirmInsertPoint'} showModal={(props) => dispatchRoute({type: showModal, ...props})}
                                     scrollBack={scrollBack} stayHere={stayHere} {...modalOptions}/>
        </Container>
    </>;
});
