import classNames from 'classnames';
import emailToName from 'email-to-name';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {useEffect, useState} from "react";
import {Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import DatePicker from 'react-datepicker';
import {Trans, useTranslation} from 'react-i18next';
import {FaAddressBook, FaBirthdayCake, FaFemale, FaMale} from "react-icons/fa";
import {useCurrentUserData} from '../../lib/db';
import {stateSetter, validatingHandler} from "../../lib/util";
import {GenderSelector} from '../UserProfile';


export function Onboarding({show, showModal, onSuccess = null, onError = null}) {
    const {t} = useTranslation(),
        [, setUserDoc, {user}] = useCurrentUserData({create: true, require: false}),
        // noinspection JSAnnotator
        [firstName, setFirstName] = useState(''),
        [lastName, setLastName] = useState(''),
        [birthday, setBirthday] = useState(null),
        [gender, setGender] = useState('f'),
        [validated, setValidated] = useState(false),
        maxDate = DateTime.now().minus({years: 13}).toJSDate(),
        minDate = DateTime.now().minus({years: 125}).toJSDate(),
        openToDate = DateTime.now().minus({years: 25}).startOf('day').toJSDate();

    useEffect(() => {
        if (show && user?.email) {
            const processed = emailToName.process(user.email),
                fName = _.first(_.split(processed, ' ')),
                lName = _.join(_.tail(_.split(processed, ' ')), ' ');
            setValidated(false);
            setFirstName(prev => _.isEmpty(prev) ? fName : prev);
            setLastName(prev => _.isEmpty(prev) ? lName : prev);
        }
    }, [setFirstName, setLastName, setValidated, show, user?.email]);

    async function boardUser() {
        try {
            await setUserDoc({first_name: firstName, last_name: lastName, birthday, gender}, {merge: true});
        } catch (error) {
            console.error(error);
            const handlers = {};
            handlers[error?.code]?.();
            onError?.(error);
            return;
        }
        showModal(null);
        onSuccess?.(user);
    }

    return <Modal backdrop="static" show={show} onHide={() => showModal(null)} size="lg" centered animation={false}>
        <Modal.Header closeButton={false}>
            <Modal.Title><Trans>Your profile info</Trans></Modal.Title>
        </Modal.Header>
        <Form validated={validated} noValidate onSubmit={validatingHandler(setValidated, boardUser)}>
            <Modal.Body>
                <Row className="mb-3">
                    <Col>
                        <InputGroup hasValidation>
                            <InputGroup.Text><FaAddressBook/></InputGroup.Text>
                            <Form.Control autoComplete="given-name" className="form-control" placeholder={t("Your given name")} type="text"
                                          value={firstName} onChange={stateSetter(setFirstName)} required/>
                            <Form.Control autoComplete="family-name" className="form-control" placeholder={t("Your family name")} type="text"
                                          value={lastName} onChange={stateSetter(setLastName)} required/>
                            <Form.Control.Feedback type="invalid">
                                <Trans>Please type your name.</Trans>
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="btn-group-vertical">
                        <InputGroup hasValidation className="flex-row">
                            <InputGroup.Text><FaBirthdayCake/></InputGroup.Text>
                            <InputGroup.Text><FaMale/><FaFemale/></InputGroup.Text>
                            <Form.Control as={DatePicker} openToDate={openToDate} selected={birthday} autoComplete="bday" maxDate={maxDate} minDate={minDate}
                                          onChange={setBirthday} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select"
                                          placeholderText={t("Your day of birth")} required
                                          wrapperClassName={classNames({"is-invalid" : validated && !birthday}, "flex-shrink-1 w-auto")}
                            />
                            <GenderSelector value={gender} onChange={stateSetter(setGender)} className="flex-shrink-1" />
                            <Form.Control.Feedback type="invalid">
                                <Trans>To rank you within gender and age group and ensure you are legally allowed to use the app.</Trans>
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="me-0" type="submit"><Trans>Let me in</Trans></Button>
            </Modal.Footer>
        </Form>
    </Modal>;
}
