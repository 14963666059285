import _ from 'lodash';
import {memo, useCallback, useEffect, useMemo} from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useDebouncedCallback} from 'use-debounce';
import {getElevationProfile} from '../lib/getElevationProfile';
import {TRACK_POI_SIZE} from '../lib/useRBushPOIsReducer';
import {PointOfInterestOnTrack} from './PointsOfInterest';
import {ColorSegmentArea} from './rechartsExtensions';


function CustomTooltip({active, payload, onPointSelect}) {
    const {t} = useTranslation();
    const data = _.first(payload),
        {elevation, steepness, distance} = data?.payload || {};

    useEffect(() => {active && data?.payload && onPointSelect?.(data?.payload);}, [active, data?.payload, onPointSelect]);

    const distanceKm = t('{{distance, number}}', {distance, maximumFractionDigits: 1, style: 'unit', unit: 'kilometer', unitDisplay: 'short'});
    const elevationMeters = t('Elevation: {{elevation, number}}', {elevation, style: 'unit', unit: 'meter', unitDisplay: 'short', 'maximumFractionDigits': 0});
    const maxGrade = _.isNumber(steepness) && t('Max grade: {{steepness, number}}', {steepness, style: 'unit', unit: 'percent', 'unitDisplay': 'short', 'maximumFractionDigits': 0});

    return <Card>
        <Card.Header className="p-2" as="h6">
            {distance && distanceKm}
        </Card.Header>
        <Card.Body className="p-2">
            {elevationMeters}
            {maxGrade && (<>
                <br/><span>{maxGrade}</span>
            </>)}
        </Card.Body>
    </Card>;
}

const ElevationViewerPOILabel = memo(function ElevationViewerPOILabel({cx, cy, pois, index}) {
    const {feature} = pois[index] ?? {};
    return feature ? <foreignObject x={cx - TRACK_POI_SIZE / 2} y={(cy - TRACK_POI_SIZE) / 2} width={TRACK_POI_SIZE + 10} height={TRACK_POI_SIZE + 10}>
        <PointOfInterestOnTrack size={TRACK_POI_SIZE} feature={feature}/>
    </foreignObject> : null;
});

const ELEVATION_VIEWER_MARGIN = {left: 0, top: 5, right: 5, bottom: 0};

export const ElevationViewer = function ElevationViewer({
    children,
    coordinates,
    elevation,
    elevationViewerSegment,
    fontSize,
    height,
    pois,
    setVolatileCoordinate,
    steepness: steepnessSegments,
    width
}) {
    const {t} = useTranslation();
    const {data, poisByIdx} = useMemo(
        () => getElevationProfile(coordinates, elevation, elevationViewerSegment, pois, steepnessSegments),
        [coordinates, elevation, elevationViewerSegment, pois, steepnessSegments]);

    const onPointSelect = useCallback((payload) => {
        if (!payload) return;
        const {coordinate: [lat, lng]} = payload;
        lat && lng && setVolatileCoordinate?.([lat, lng]);
    }, [setVolatileCoordinate]);
    const setPoint = useDebouncedCallback(onPointSelect, 50, {maxWait: 50});

    const axes = useMemo(() => {
        const tickFormatter = (tick) => t("{{tick, number(maximumFractionDigits: 2)}}", {tick});
        return <>
            <XAxis dataKey="distance" type="number" unit=" km" domain={['dataMin', 'dataMax']} interval="preserveStartEnd" tickFormatter={tickFormatter} tick={{fontSize}}/>
            <YAxis unit=" m" dataKey="elevation" domain={[Math.floor, Math.floor]} interval="preserveStartEnd" tick={{fontSize}}/>
        </>;
    }, [t]);

    return <>
        <ResponsiveContainer width={width} height={height}>
            <AreaChart width={width} height={height} data={data} margin={ELEVATION_VIEWER_MARGIN}>
                <CartesianGrid strokeDasharray="3 3"/>
                {axes}
                <Tooltip content={<CustomTooltip onPointSelect={setPoint}/>}/>
                <ColorSegmentArea type="monotone" dataKey="elevation" colorKey="gradeColor" isAnimationActive={false} fillOpacity={1.0}
                                  dot={<ElevationViewerPOILabel pois={poisByIdx}/>}/>
                {children}
            </AreaChart>
        </ResponsiveContainer>
    </>;
};
