import {getCoords, point} from '@turf/turf';
import getCountryISO2 from 'country-iso-3-to-2';
import _ from 'lodash';
import {useCallback, useMemo} from 'react';
import {useQueries, useQuery} from 'react-query';
import {toHostPath} from './util';


// See also: https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/endpoint-autocomplete-brief.html
const HERE_API_KEY = 'doN1UbvbQ_dGrvbwUmt9iLs8WowYwGRPcgxgFdYJoXA';  // app_id = sleeeaWC44lv2hh4yLaQ
const HERE_API = 'https://autocomplete.search.hereapi.com/v1/autocomplete';
const HERE_LOOKUP_API = 'https://lookup.search.hereapi.com/v1/lookup';
const HERE_REVGEOCODE_API = 'https://revgeocode.search.hereapi.com/v1/revgeocode';
const HERE_COUNTRY_CODES = 'countryCode:ALB,AND,ARM,AUT,AZE,BIH,BGR,CHE,CYP,CZE,DEU,DNK,ESP,EST,FIN,FRA,FRO,GBR,GEO,GIB,GRC,GRL,HRV,HUN,ISL,IRL,IMN,ITA,JEY,KAZ,KOS,LVA,LIE,LTU,LUX,MLT,MDA,MCO,MNE,NLD,NOR,POL,PRT,ROU,SMR,SRB,SVK,SVN,SJM,SWE,TUR,UKR,VAT';


export function useHereAutocompleteQuery(typedText) {
    return useQuery(["here", "autocomplete", typedText], async () => {
        if (typedText.length < 3) return [];
        const url = toHostPath({urlString: HERE_API, searchParams: {q: typedText, apiKey: HERE_API_KEY, in: HERE_COUNTRY_CODES}}),
            resp = await fetch(url),
            data = await resp.json();
        return _.map(data?.items ?? [], ({id, address: {label, countryCode} = {}}) => ({
            id, label, countryCode: getCountryISO2(countryCode)
        }));
    });
}

export function useHereLookupQuery(locationId, options) {
    const hereLookupFn = useCallback(async function () {
        if (_.isEmpty(locationId)) return null;
        const url = toHostPath({urlString: HERE_LOOKUP_API, searchParams: {id: locationId, apiKey: HERE_API_KEY}}),
            resp = await fetch(url),
            {position} = await resp.json();
        return position && point([position.lng, position.lat]);
    }, [locationId]);
    return useQuery(["here", "lookup", locationId], hereLookupFn, options);
}

async function hereReverseGeocodeQueryFn(pt) {
    if (_.isEmpty(pt)) return null;
    const [lng, lat] = getCoords(pt),
        url = toHostPath({urlString: HERE_REVGEOCODE_API, searchParams: {at: `${lat},${lng}`, resultType: 'locality', apiKey: HERE_API_KEY}}),
        resp = await fetch(url),
        data = await resp.json();

    const {items: [location, ]} = data;
    return location;
}

/**
 * Reverse-geocode provided points to location descriptions.
 *
 * @param {Array<Feature<Point>>} points turf points
 * @returns {Array[Location]}
 */
export function useHereReverseGeocodeQueries(points, enabled = true) {
    const queries = useMemo(() => !enabled ? [] :
        _.map(points, (pt) => (
        {queryKey: ["here", "reverse-geocode", pt], queryFn: () => hereReverseGeocodeQueryFn(pt)})),
        [enabled, points]);
    return useQueries(queries);
}

export function useHereReverseGeocodeQuery(pt) {
    const points = useMemo(() => ([pt]), [pt]);
    const [queryResult] = useHereReverseGeocodeQueries(points);
    return queryResult;
}