import {useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';


export function useIntroSteps() {
    const {t} = useTranslation();
    const steps = useMemo(() => ([
        {
            selector: '.intro-route-type',
            content: <p><Trans>Select the bike type to plan your route for</Trans></p>
        },
        {
            selector: '.intro-finetune',
            content: <p><Trans>Adjust the route to your preferred surface quality, hillness and popularity.</Trans></p>
        },
        {
            selector: '.intro-location-box',
            content: <p><Trans>Type in a location nearby you want to ride</Trans></p>
        },
        {
            selector: '.intro-map-points',
            content: <p><Trans>Click on the map to set the way points. Tarmacs will find the best route for you.</Trans></p>
        },
        {
            selector: '.intro-save-button',
            content: <p><Trans>After you finished designing your route, click "Save" button. Then you can export the route to your bike computer.</Trans></p>
        }
    ]), [t]);  // eslint-disable-line react-hooks/exhaustive-deps

    return steps;
}
