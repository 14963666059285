import {geohashForLocation} from 'geofire-common';
import _ from 'lodash';
import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useCustomCompareEffect} from 'react-use';
import {useGeolocation} from 'rooks';
import {useCurrentUserData} from '../lib/db';
import {useAPIQuery} from '../lib/util';


const geoLocationProps = {
    geoLocation: {lat: null, lng: null},
    browser: null,
    server: null,
    enabled: false
};

const GeoLocationContext = createContext(geoLocationProps);

export function GeoLocationContextProvider({children}) {
    const [geoLocation, setGeoLocation] = useState(geoLocationProps),
        {enabled} = geoLocation,
        // Using 'rooks' version because 'react-use' alters state in high frequency.
        browser = useGeolocation({when: enabled}),
        {data: server} = useAPIQuery('/api/where-am-i', null, null, {enabled}),
        setGeoLocationEnabled = useCallback((enabled) =>
            setGeoLocation(state => (state?.enabled === enabled ? state : {...state, enabled})), [setGeoLocation]),
        [userData, setUserDoc, {isSignedIn}] = useCurrentUserData({require: false});

    useEffect(() => {
        setGeoLocation((state) => {
            const pick = (from) => ({geoLocation: _.pick(from, 'lat', 'lng')}),
                fromBrowser = (browser && !browser.isError) ? {browser} : {},
                fromServer = (server?.lat && server?.lng) ? {server} : {},
                geoLocation = !_.isEmpty(fromBrowser) ?
                    pick(browser) :
                    !_.isEmpty(fromServer) ? pick(server) : {};

            const updated = {...state, ...fromBrowser, ...fromServer, ...geoLocation};
            return _.isEqual(updated, state) ? state : updated;
        });
    }, [browser, server]);

    function shouldUpdateUserLocation([prevGeoLoc, prevSignedIn], [nextGeoLoc, nextSignedIn]) {
        return !_.isEqual(prevGeoLoc?.geoLocation, nextGeoLoc?.geoLocation) || nextSignedIn !== prevSignedIn;
    }

    useCustomCompareEffect(function storeUserLocation() {
            const {lat, lng} = geoLocation?.geoLocation ?? {},
                location = {lat, lng},
                shouldStore = lat && lng && isSignedIn && !_.isEqual(location, userData?.location);
            shouldStore && setUserDoc?.({location, geohash: geohashForLocation([lat, lng])}, {merge: true});
        },
        [geoLocation, isSignedIn, setUserDoc, userData],
        _.negate(shouldUpdateUserLocation)
    );

    return <GeoLocationContext.Provider value={{geoLocation, setGeoLocationEnabled}}>
        {children}
    </GeoLocationContext.Provider>;
}

/**
 * Access GeoLocation context, which can fetch it from browser API or server endpoint.
 *
 * @param {boolean} enable when true, will attempt to initiate fetch; when false, will prevent; null - no effect.
 * @returns {{setGeoLocationEnabled, geoLocation}}
 */
export function useDetectGeoLocation({enable = null} = {}) {
    const {geoLocation: {geoLocation, browser, server}, setGeoLocationEnabled} = useContext(GeoLocationContext);
    useEffect(() => {!_.isNil(enable) && setGeoLocationEnabled(enable);}, [enable, setGeoLocationEnabled]);
    return {geoLocation, browser, server, setGeoLocationEnabled};
}
