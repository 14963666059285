import styled from '@emotion/styled';
import classNames from 'classnames';
import emailToName from 'email-to-name';
import {signInWithCustomToken, signOut} from 'firebase/auth';
import {collection, getDocs, writeBatch} from 'firebase/firestore';
import Anonymous from '../images/anonymous.png';
import {useUserData} from '../lib/db';
import _ from 'lodash';
import {useCallback, useContext, useMemo, useState} from 'react';
import {Button, Dropdown, Form, Modal, Nav, NavDropdown} from 'react-bootstrap';
import {ReactCountryFlag} from 'react-country-flag';
import {Trans, useTranslation} from 'react-i18next';
import {FaCheckCircle, FaSadTear, FaSignOutAlt, FaUser, FaUserPlus} from 'react-icons/fa';
import {LinkContainer} from 'react-router-bootstrap';
import {Link, useMatch, useNavigate} from 'react-router-dom';
import {useBoolean} from 'usehooks-ts';
import {useFollow, UserAvatar} from '../lib/feed';
import {auth, db, FirebaseAuth} from '../lib/firebase';
import {stateSetter, toHostPath} from '../lib/util';
import {AuthButton} from './auth/AuthButton';
import {useOAuthActivity} from './auth/OAuth';
import {t_, useLocaleSettings} from './l10n';

// Otherwise there is 2px space between dropdown button and menu, resulting in onMouseLeave when moving mouse over the space.
const POPPER_PREVENT_DROPDOWN_SPACING = {modifiers: [{name: 'offset', options: {offset: [0, 0]}}]};

// popperConfig apparently does not work in menus in Navs, so eliminate 2px space manually.
const DropdownMenuPreventSpacing = styled(Dropdown.Menu)`
    transform: translate(0, -2px);
    min-width: 3em;
`;

const ButtonWithHoverContent = styled(Button)`
    &:hover {
        span.hover {
            display: inline;
        }

        span.nohover {
            display: none;
        }
    }

    span.hover {
        display: none;
    }
`;

export function FollowingButton({followedId, allowUnfollow = true, asFollowed, as, ...props}) {
    const {user: actor} = useContext(FirebaseAuth),
        {following, follow, unfollow: confirmUnfollow} = useFollow(actor?.uid, followedId);

    useOAuthActivity('followUser', follow, 'follow_uid');

    return actor?.uid && followedId ? (
        <ButtonWithHoverContent as={following && asFollowed ? asFollowed : as} variant={following ? 'outline-success' : 'primary'} {...props}
                                disabled={following && !allowUnfollow}
                                onClick={() => !following ? follow() : allowUnfollow && confirmUnfollow()}>
            {following ?
                <>
                    <span className={classNames({nohover: allowUnfollow}, 'align-middle')}>
                        <FaCheckCircle/>&nbsp;<span className="align-middle"><Trans>Following</Trans></span>
                    </span>
                    <span className={classNames({hover: allowUnfollow, "d-none": !allowUnfollow}, 'align-middle')}>
                        <FaSadTear/>&nbsp;&nbsp;<span><Trans>Unfollow</Trans></span>
                    </span>
                </> :
                <span><FaUserPlus/>&nbsp;<span><Trans>Follow</Trans></span></span>
            }
        </ButtonWithHoverContent>
    ) : null;
}

const DropdownToggleStyled = styled(Dropdown.Toggle)`
    &:hover a.avatar {
        color: white;
    }
`;

export function User({userId, children, size, name, className, hideViewUser = false}) {
    const {user} = useContext(FirebaseAuth),
        {data: userData} = useUserData(userId),
        {following} = useFollow(user?.uid, userId);

    className = classNames("d-flex align-items-center flex-no-wrap text-wrap", className);

    return <UserButton content={children} {...{following, name, size, userData}}>
        {({show, doShow, doHide, setShow, content, isCurrentUser, isOnUserPage, userRoutesPageUrl}) =>
            isCurrentUser ?
                <Button size="sm" variant="outline-secondary" className={className} disabled={isOnUserPage}>
                    {content}
                </Button> :
                <Dropdown align="end" show={show} onMouseEnter={doShow} onMouseLeave={doHide} onToggle={setShow}>
                    <DropdownToggleStyled size="sm" variant="outline-secondary" className={className}>
                        {content}
                    </DropdownToggleStyled>
                    <Dropdown.Menu popperConfig={POPPER_PREVENT_DROPDOWN_SPACING} className="py-0">
                        <FollowingButton allowUnfollow={false} as={Dropdown.Item} asFollowed={Dropdown.Item} followedId={userId} size="sm"
                                         className="py-2 align-middle justify-content-center"></FollowingButton>
                        {!hideViewUser && !isOnUserPage &&
                            <LinkContainer to={userRoutesPageUrl}><Dropdown.Item className="flex-grow-0 small" variant="outline-secondary" size="sm">
                                <Trans>View user routes</Trans>
                            </Dropdown.Item></LinkContainer>}
                    </Dropdown.Menu>
                </Dropdown>}
    </UserButton>;
}

export function UserButton({children: callback, avatarSide = "end", userData, following, content, size = 45, name = true}) {
    const {t} = useTranslation(),
        {user} = useContext(FirebaseAuth),
        {value: show, setTrue: doShow, setFalse: doHide, setValue: setShow} = useBoolean(false),
        {uid: userId, first_name, last_name, photo_url = Anonymous} = userData ?? {},
        display_name = t("{{first_name}} {{last_name}}", {first_name, last_name}),
        userName = <>{following && <><FaCheckCircle/>&nbsp;</>}<span className="align-middle">{display_name}</span></>,
        userRoutesPageUrl = useMemo(() => `/routes/view/user/${userId}`, [userId]),
        isOnUserPage = useMatch(userRoutesPageUrl),
        userInfo = isOnUserPage ? userName :
            <Link to={userRoutesPageUrl} className="text-decoration-none align-middle avatar">{userName}</Link>,
        isCurrentUser = (userId === user?.uid);

    if (!userData || (isOnUserPage && isCurrentUser))
        return null;

    // <Stack className="d-inline-flex flex-nowrap" direction="horizontal" gap={2}>
    const avatarElem = <UserAvatar photo_url={photo_url} size={size} className="flex-grow-0 flex-shrink-0"/>,
        contentElem = <>
            {avatarSide === 'start' && avatarElem}
            <div className={classNames({'me-1': avatarSide === 'end', 'ms-1': avatarSide === 'start'}, 'flex-grow-1')}>
                {name && userInfo}{content ? <><br/>{content}</> : null}</div>
            {avatarSide === 'end' && avatarElem}
        </>;

    return callback({show, doShow, doHide, setShow, content: contentElem, isCurrentUser, isOnUserPage, userRoutesPageUrl});
}

export function CurrentUserAvatar(props) {
    const {user} = useContext(FirebaseAuth);
    return <UserAvatar photo_url={user?.photoURL} {...props}/>;
}

// ReactCountryFlag does not support `styled`, apparently.
function LanguageSelectorFlag({style, ...props}) {
    return <ReactCountryFlag {...props} style={{...style, fontSize: '1.5em'}}/>;
}

const LANG_NAMES = {
    cs: "Čeština",
    da: "Dansk",
    de: "Deutsch",
    en: "English",
    es: "Español",
    fi: "Suomi",
    fr: "Français",
    it: "Italiano",
    nl: "Nederlands",
    no: "Norsk",
    pl: "Polski",
    pt: "Português",
    se: "Svenska",
    sk: "Slovenčina",
    sl: "Slovenščina"
};

const COUNTRY_CODES = {cs: 'CZ', da: 'DK', de: 'DE', en: 'GB', es: 'ES', fi: 'FI', fr: 'FR', it: 'IT', nl: 'NL', no: 'NO', pl: 'PL', pt: 'PT', se: 'SE', sk: 'SK', sl: 'SI'};

export function LanguageSelector({updatePagePrefix}) {
    const {locale, setLocale} = useLocaleSettings(),
        {t} = useTranslation(),
        {value: show, setValue: setShow, setFalse: doHide, toggle: toggleShow} = useBoolean(false),
        countryCode = COUNTRY_CODES[locale] ?? 'GB',
        others = _.pickBy(COUNTRY_CODES, (code) => code !== countryCode),
        navigate = useNavigate();

    const onClick = useCallback((lang) => {
        updatePagePrefix && navigate(`/${lang}`);
        setLocale(lang);
    }, [setLocale, navigate, updatePagePrefix]);

    return <Dropdown as={Nav.Item} show={show} onMouseEnter={toggleShow} onMouseLeave={doHide} onToggle={setShow}>
        <Dropdown.Toggle as={Nav.Link} className="py-0 py-lg-2">
            <LanguageSelectorFlag countryCode={countryCode}/>&nbsp;<span className="d-lg-none"><Trans>Language</Trans></span>
        </Dropdown.Toggle>
        <DropdownMenuPreventSpacing id="language-selector" align="end">
            {_.map(others, (code, lang) =>
                <Dropdown.Item key={code} onClick={() => onClick(lang)}>
                    <LanguageSelectorFlag countryCode={code} className="me-1"/>
                    <span>{LANG_NAMES[lang]}</span>
                </Dropdown.Item>)}
        </DropdownMenuPreventSpacing>
    </Dropdown>;
}

export function UserMenu() {
    const {value: show, setValue: setShow, setTrue: doShow, setFalse: doHide} = useBoolean(false),
        {isSignedIn, user} = useContext(FirebaseAuth),
        avatar = <>
            <CurrentUserAvatar/>
            <span className="d-lg-none">&nbsp;{user?.displayName}</span>
        </>;

    const onSignOut = useMemo(() => async () => {
        try {
            await signOut(auth);
        } catch (e) {
            console.error('Error signing out: ', e);
        }
    }, []);

    // AuthButton component is present (but hidden) even when signed in.
    // Otherwise the post-signup saga part (eg. onboarding) would be run on unmounted component, resulting in errors.
    return <Nav className="py-0">
        <Nav.Item className="my-auto">
            <AuthButton activityName="signIn" hideWhenAuthed={true} href="#" className="me-2" variant="outline-primary" defaultModal="SignIn"><Trans>Sign in</Trans></AuthButton>
            <AuthButton activityName="signUp" hideWhenAuthed={true} defaultModal="SignUp"><Trans>Sign up</Trans></AuthButton>
        </Nav.Item>
        {isSignedIn ?
            <Dropdown as={Nav.Item} onMouseEnter={doShow} onMouseLeave={doHide} show={show} align="end" onToggle={setShow}>
                <Dropdown.Toggle as={Nav.Link} className="pt-0 pt-lg-2">{avatar}</Dropdown.Toggle>
                <DropdownMenuPreventSpacing>
                    <LinkContainer to="profile"><NavDropdown.Item><FaUser/> <Trans>Profile</Trans></NavDropdown.Item></LinkContainer>
                    {/* <NavDropdown.Item><FaPlug/> <Trans>Connected accounts</Trans></NavDropdown.Item> */}
                    <Impersonate/>
                    <FixUsersData/>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item onClick={onSignOut}><FaSignOutAlt/> <Trans>Sign out</Trans></NavDropdown.Item>
                </DropdownMenuPreventSpacing>
            </Dropdown> : null}
    </Nav>;
}

function FixUsersData() {
    const {user} = useContext(FirebaseAuth);

    async function fixUsersData() {
        const usersRef = collection(db, 'users'),
            users = await getDocs(usersRef),
            batch = writeBatch(db);

        users.forEach((userDoc) => {
            const data = userDoc.data();
            let {first_name, last_name, display_name, locale, email} = data;
            const processed = _.isEmpty(email) ? '' : emailToName.process(email);
            if (!_.some(_.map([first_name, last_name, display_name, locale], _.isEmpty))) {
                console.log("Noop", {first_name, last_name, display_name, locale});
                return;
            }

            first_name = _.isEmpty(first_name) ? _.first(_.split(processed, ' ')) : first_name;
            last_name = _.isEmpty(last_name) ? _.join(_.tail(_.split(processed, ' ')), ' ') : last_name;
            display_name = _.isEmpty(display_name) ? `${first_name} ${last_name}` : display_name;
            locale = _.isEmpty(locale) ? 'pl' : locale;

            console.log("Fixing", userDoc.ref.id, {first_name, last_name, display_name, locale});
            batch.update(userDoc.ref, {first_name, last_name, display_name, locale});
        });

        await batch.commit();
    }

    if (user?.email !== 'alek.kowalczyk@gmail.com')
        return null;

    return <NavDropdown.Item onClick={() => fixUsersData()}>Fix users data</NavDropdown.Item>;
}

function Impersonate() {
    const [impersonatedEmail, setImpersonatedEmail] = useState(''),
        {user} = useContext(FirebaseAuth),
        [show, toggleShow] = useState(false);

    async function onImpersonate() {
        const url = toHostPath({urlString: process.env.REACT_APP_API_URL, pathname: '/api/impersonate', searchParams: {email: impersonatedEmail}});
        const resp = await fetch(url),
            data = await resp.json();
        await signInWithCustomToken(auth, data.custom_token);
    }

    if (user?.email !== 'alek.kowalczyk@gmail.com')
        return null;

    return <>
        <NavDropdown.Item onClick={() => toggleShow(true)}>Impersonate...</NavDropdown.Item>
        <Modal size="sm"
               show={show}
               onHide={() => toggleShow(false)}
               aria-labelledby="example-modal-sizes-title-sm">
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control placeholder="Impersonated user's e-mail or UID" onChange={stateSetter(setImpersonatedEmail)}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" onClick={onImpersonate}>Submit</Button>
            </Modal.Footer>
        </Modal>
    </>;
}