import styled from '@emotion/styled';
import {point} from '@turf/turf';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {InputGroup} from 'react-bootstrap';
import {AsyncTypeahead, Highlighter} from 'react-bootstrap-typeahead';
import {ReactCountryFlag} from 'react-country-flag';
import {useTranslation} from 'react-i18next';
import {FaMountainCity} from 'react-icons/fa6';
import {useCounter} from 'react-use';
import {useHereAutocompleteQuery, useHereLookupQuery} from '../lib/here';
import {useMeilisearchLookupQuery} from '../lib/meilisearch';
import {CategoryIcon} from './Climbs';


function fromClimb(t, {country_code, name, via, start_point, category, _geo}) {
    const label = (start_point && via) ? t("{{name}} (from {{start_point}} through {{via}})", {name, start_point, via}) :
        start_point ? t("{{name}} (from {{start_point}})", {name, start_point}) :
            via ? t("{{name}} (through {{via}})", {name, via}) : name;
    return {countryCode: (country_code?.toUpperCase?.() === 'UK' ? 'GB': country_code), label, category, _geo};
}

const BADGE_ATTRS = {
    'SHC': 'HC',
    '5': '4'
};

const CategoryIconStyled = styled(CategoryIcon)`
    overflow-y: visible;
`;

function renderMenuItemChildren(result, props) {
    const category = `${BADGE_ATTRS[result.category] ?? result.category}`;

    return <span style={{maxWidth: "18rem"}} className="d-inline-flex text-truncate align-items-middle">
        <ReactCountryFlag countryCode={result.countryCode} className="me-2" style={{lineHeight: '1.2em'}}/>
        {result.category && <>
            {category && <CategoryIconStyled category={category} className="flex-grow-0 flex-shrink-0 me-1" size={20} />}
        </>}
        <span><Highlighter search={props.text}>{result.label}</Highlighter></span>
    </span>;
}

const AsyncTypeaheadStyled = styled(AsyncTypeahead)`
    & .dropdown-menu {
        --bs-dropdown-item-padding-x: 8px;
        --bs-dropdown-item-padding-y: 5px;
    }
`

export function LocationTypeahead({onSelect, onSetPosition, onChange, ...props}) {
    const {t} = useTranslation(),
        [typedText, setTypedText] = useState(''),
        [location, setLocation] = useState(null),
        {data: suggestions, isLoading: isHereLoading} = useHereAutocompleteQuery(typedText),
        {data: climbs, isLoading: isMeiliLoading} = useMeilisearchLookupQuery('climbs', typedText, {
            converter: (climb) => fromClimb(t, climb),
            options: {limit: 3},
            apiUrl: process.env.REACT_APP_CLIMBS_LOOKUP_URL
        }),
        options = [...(climbs || []), ...(suggestions || [])],
        // Force going back to searched location when user re-clicks already typed location.
        [forcePosition, {inc: doForcePosition}] = useCounter(0),
        {data: position = null} = useHereLookupQuery(location?.id);

    useEffect(function setPositionEffect() {
        const pt = location?._geo ? point([location._geo.lng, location._geo.lat]) : position;
        pt && onSetPosition?.(pt);
    }, [location?._geo, position, forcePosition, onSetPosition]);

    return <InputGroup>
        <InputGroup.Text id="loc-typeahead-addon" className="px-2" title={t("Type in a town or climb")}><FaMountainCity size="1.5em"/></InputGroup.Text>
        <AsyncTypeaheadStyled
            {...props}
            renderMenuItemChildren={renderMenuItemChildren}
            placeholder={t("Type in a town or climb")}
            promptText={t("Type in a town or climb")}
            searchText={t("Searching...")}
            emptyLabel={t("No matches found")}
            title={t("Type in a town or climb")}
            isLoading={isHereLoading || isMeiliLoading}
            options={options}
            onSearch={setTypedText}
            filterBy={_.stubTrue}
            onChange={([selected]) => {
                if (selected) {
                    setLocation(selected);
                    doForcePosition();
                    onSelect?.(selected);
                }
            }}
        />
    </InputGroup>;
}