import React, {useState} from 'react';
import {Button, Spinner} from 'react-bootstrap';
import {showError} from '../lib/toasts';


export function ActionButton({icon: Icon, text, tooltip, tooltipHeading, children, tiny, ...props}) {
    // Tooltips intentionally disabled - they do not look nice.
    // <PopoverTrigger heading={tooltipHeading ?? text} tooltip={tooltip}> </PopoverTrigger>

    // Vertical alignment depends on text wrapped in span and css in App.scss:13-17
    return <Button title={tiny ? text : null} {...props}>
        {Icon &&
            <Icon/>}
        {!tiny &&
            <span>{Icon ? <>&nbsp;</> : ""}{text}</span>}
        {children}
    </Button>;
}

export function SpinningButton({tiny, text, icon, onClick, tooltip, tooltipHeading, ...props}) {
    const [spinning, toggleSpinning] = useState(false);

    async function onClickSpinningWrapper(...args) {
        try {
            toggleSpinning(true);
            await onClick(...args);
        } catch (error) {
            showError(error);
        }
        toggleSpinning(false);
    }

    return <ActionButton icon={icon} text={text} tooltip={tooltip} tooltipHeading={tooltipHeading} onClick={onClickSpinningWrapper} tiny={tiny} {...props}>
        {spinning && <Spinner as="span" size="sm" className="ms-2 align-middle" animation="border" role="status" aria-hidden="true"/>}
    </ActionButton>;
}