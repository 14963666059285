import React, {useEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';


export function InfiniteScrollImproved({scrollableTarget, hasMore, isFetching, next, children, ...props}) {
    useEffect(() => {
        const notFullHeightYet = ({scrollHeight, clientHeight}) => {
            console.log({scrollHeight, clientHeight});
            return scrollHeight <= clientHeight;
        }
        const shouldFetch = scrollableTarget?
            notFullHeightYet(document.getElementById(scrollableTarget)) :
            notFullHeightYet({scrollHeight: document.body.scrollHeight, clientHeight: document.body.parentElement.clientHeight});
        console.log({hasMore, isFetching, shouldFetch})
        hasMore && !isFetching && shouldFetch && next();
    }, [hasMore, next, isFetching, scrollableTarget]);

    return <InfiniteScroll {...props} {...{scrollableTarget, hasMore, next}} >{children}</InfiniteScroll>;
}