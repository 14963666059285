import styled from '@emotion/styled';
import _ from 'lodash';
import {memo, useCallback, useMemo} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {FaRegGrinStars, FaSadTear} from 'react-icons/fa';
import {GiField, GiHorizonRoad, GiMountains} from 'react-icons/gi';
import {ImRoad} from 'react-icons/im';
import {IoIosPeople} from 'react-icons/io';
import {MdNaturePeople} from 'react-icons/md';
import {FormCheckWithPopover} from '../lib/toasts';
import {checkToggler} from '../lib/util';
import {DiscreteRangeSlider} from './DiscreteRangeSlider';
import {t_} from './l10n';
import {POIMarkersToggleButtonGroup} from './POIMarkersToggleButtonGroup';


const SURFACE_SUITABILITIES = {
    road: [[0.2, t_("Road+ - get some dirt")], [0.65, t_("Reasonable surfaces")], [0.9, t_("I am picky")], [0.97, t_("I am very picky")], [1.0, t_("High quality tarmacs")]],
    gravel: [[0.2, t_("Any surface will do")], [0.65, t_("Gravel is not MTB")], [0.9, t_("Gravel is not MTB nor Road")], [1.0, t_("Pure gravel fun!")]],
    mtb: [[0.1, t_("Any surface will do")], [0.65, t_("MTB does not like tarmac")], [0.9, t_("Prefer suitable surfaces")], [1.0, t_("Dirt roads and single trails!")]]
};
const HILLS = [[0, t_("Avoid hills")], [0.5, t_("Not into hills")], [0.75, t_("I like hills")], [1.0, t_("I really like hills")],
    [1.2, t_("I love hills!")], [1.5, t_("I am a mountain goat!")]];
const POPULARITY = [[0, t_("Cats go own ways")], [0.25, t_("Inspirations are good")], [0.5, t_("Inspirations are good")], [1.0, t_("Mainstream")]];
const MAIN_ROADS = [[0, t_("Avoid main roads")], [0.75, t_("Route me wherever sensible")], [1.0, t_("Main roads are OK")]];

const SurfaceSuitability = memo(function SurfaceSuitability({suitability, bikeKind, setSuitability, className}) {
    const {t} = useTranslation();
    const suitabilityPoints = _.map(SURFACE_SUITABILITIES[bikeKind],
        /* i18next-extract-disable-next-line */
        ([value, msg]) => [value, t(msg)]
    );

    return <DiscreteRangeSlider className={className}
                                value={suitability} setValue={setSuitability} tooltipPlacement="top"
                                label={t("Surface")}
                                points={suitabilityPoints}
                                icons={[FaSadTear, FaRegGrinStars]}/>;
});

const HillsPreference = memo(function HillsPreference({hills, setHills, className}) {
    const {t} = useTranslation();
    /* i18next-extract-disable-next-line */
    const prefPoints = _.map(HILLS, ([val, msg]) => [val, t(msg)]);

    return <DiscreteRangeSlider className={className}
                                value={hills} setValue={setHills} tooltipPlacement="bottom"
                                label={t("Hills")} points={prefPoints} icons={[GiField, GiMountains]}/>;
});

const PopularityPreference = memo(function PopularityPreference({popularity, setPopularity, className}) {
    const {t} = useTranslation();
    /* i18next-extract-disable-next-line */
    const prefPoints = _.map(POPULARITY, ([val, msg]) => [val, t(msg)]);

    return <DiscreteRangeSlider className={className}
                                value={popularity} setValue={setPopularity} tooltipPlacement="bottom"
                                label={t("Popularity")} points={prefPoints} icons={[MdNaturePeople, IoIosPeople]}/>;
});

const MainRoadsPreference = memo(function MainRoadsPreference({mainRoads, setMainRoads, className}) {
    const {t} = useTranslation();
    /* i18next-extract-disable-next-line */
    const prefPoints = _.map(MAIN_ROADS, ([val, msg]) => [val, t(msg)]);

    return <DiscreteRangeSlider className={className}
                                value={mainRoads} setValue={setMainRoads} tooltipPlacement="bottom"
                                label={t("Main roads")} points={prefPoints} icons={[GiHorizonRoad, ImRoad]}/>;
});

const FormCheckWithPopoverNoMargin = styled(FormCheckWithPopover)`
    margin-botttom: 0;
    min-height: 1rem;
    font-size: small;
`;

const MapStyleAdjuster = memo(function MapStyleAdjuster({adjustStyle, isRoute, poisLoading, showPopularity, showTrackPOIs = true, showTrails, updateRouteProp, visiblePOIs}) {
    const {t} = useTranslation();

    const onSetAdjustStyle = useCallback((v) => checkToggler(updateRouteProp('adjustStyle', false))(v), [updateRouteProp]),
        onSetShowTrails = useCallback((v) => checkToggler(updateRouteProp('showTrails', false))(v), [updateRouteProp]),
        onSetShowPopularity = useCallback((v) => checkToggler(updateRouteProp('showPopularity', false))(v), [updateRouteProp]),
        onSetShowTrackPOIs = useCallback((v) => checkToggler(updateRouteProp('showTrackPOIs', false))(v), [updateRouteProp]),
        onSetVisiblePOIs = useCallback((v) => updateRouteProp('visiblePOIs', false)(v), [updateRouteProp]);

    return <>
        <Col xs={12} sm={6} lg={12}>
            <FormCheckWithPopoverNoMargin id="adjust-to-surfaces" control={Form.Switch} label={t("Adjust to preferred surfaces")}
                                          checked={!!adjustStyle} onChange={onSetAdjustStyle}>
                <Trans>Adjusts map style to the bike kind and surfaces suitable for this bike.<br/>
                    For example, with “picky” surface setting and road bike, the map will hide gravel and rough paved roads.</Trans>
            </FormCheckWithPopoverNoMargin>

            <FormCheckWithPopoverNoMargin id="show-trails-switch" control={Form.Switch} label={t("Show biking trails")}
                                          checked={!!showTrails} onChange={onSetShowTrails}>
                <Trans>Shows bike trails as colorful dotted lines. Colors match the in-terrain signs marked on trees and poles.</Trans>
            </FormCheckWithPopoverNoMargin>
        </Col>
        <Col xs={12} sm={6} lg={12}>
            <FormCheckWithPopoverNoMargin id="popularity-style-switch" control={Form.Switch} label={t("Roads width by popularity")}
                                          checked={!!showPopularity} onChange={onSetShowPopularity}>
                <Trans>When enabled, displays road widths according to their popularity among riders.</Trans>
            </FormCheckWithPopoverNoMargin>
            <FormCheckWithPopoverNoMargin id="pois-switch" control={Form.Switch} label={t("Highlight POIs on the track")}
                                          checked={!!showTrackPOIs} onChange={onSetShowTrackPOIs}>
                <Trans>When enabled, highlights relevant points of interest (like cafes, water sources and attractions) along the route.</Trans>
            </FormCheckWithPopoverNoMargin>
        </Col>
        <Col xs={12} className="d-flex flex-horizontal">
            <POIMarkersToggleButtonGroup isRoute={isRoute} poisLoading={poisLoading} setVisiblePOIs={onSetVisiblePOIs} visiblePOIs={visiblePOIs}
                                         className="flex-fill align-items-stretch"/>
        </Col>
    </>;
});

export function PlannerSettings({isRoute, poisLoading, routeProps, updateRouteProp}) {
    const {t} = useTranslation(),
        {
            bikeKind, suitability = 0.9, hills = 0.5, popularity = 1.0, mainRoads = 1.0, useFerries = true,
            adjustStyle, showTrails, showPopularity, showTrackPOIs, visiblePOIs
        } = (routeProps ?? {});

    const {setSuitability, setHills, setPopularity, setMainRoads, setUseFerries} = useMemo(() => ({
        setSuitability: updateRouteProp('suitability'),
        setHills: updateRouteProp('hills'),
        setPopularity: updateRouteProp('popularity'),
        setMainRoads: updateRouteProp('mainRoads'),
        setUseFerries: (v) => checkToggler(updateRouteProp('useFerries'))(v)
    }), [updateRouteProp]);

    // 2 columns in drop-down menu on landscape mobile. 1 column on portrait mobile and desktop.
    return <Row className="smaller gx-0">
        <Col xs={12} sm={6} lg={12}><SurfaceSuitability bikeKind={bikeKind} suitability={suitability} setSuitability={setSuitability}/></Col>
        <Col xs={12} sm={6} lg={12}><HillsPreference hills={hills} setHills={setHills}/></Col>
        <Col xs={12} sm={6} lg={12}><PopularityPreference popularity={popularity} setPopularity={setPopularity}/></Col>
        <Col xs={12} sm={6} lg={12}><MainRoadsPreference mainRoads={mainRoads} setMainRoads={setMainRoads}/></Col>
        <Col xs={12}>
            <FormCheckWithPopoverNoMargin id="adjust-to-surfaces" control={Form.Switch} label={t("Use ferries to shorten the route")}
                                          checked={!!useFerries} onChange={setUseFerries}>
                <Trans>Turn off to avoid using ferries whenever possible.<br/>Especially useful when planning routes around Garda, Como or Balaton.</Trans>
            </FormCheckWithPopoverNoMargin>
        </Col>
        <MapStyleAdjuster {...{adjustStyle, isRoute, poisLoading, showPopularity, showTrackPOIs, showTrails, updateRouteProp, visiblePOIs}}/>
    </Row>;
}