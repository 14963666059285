import styled from '@emotion/styled';
import {bbox, feature, featureCollection, getCoords, lineChunk, point} from '@turf/turf';
import classNames from 'classnames';
import _ from 'lodash';
import {Fragment, memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Badge, Button, Card, Image, Spinner, Stack} from 'react-bootstrap';
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import {Trans, useTranslation} from 'react-i18next';
import {FaCaretRight} from 'react-icons/fa';
import {FcNext, FcPrevious} from 'react-icons/fc';
import {TbMapX} from 'react-icons/tb';
import {Layer, Source} from 'react-map-gl';
import {useUnmount} from 'react-use';
import {useDebouncedCallback} from 'use-debounce';
import {useBoolean, useResizeObserver} from 'usehooks-ts';
import logo150 from '../images/logo150.png';
import {useCurrentUserData, useUserData} from '../lib/db';
import {UserAvatar} from '../lib/feed';
import {toLngLat} from '../lib/getClosestPointAndSegment';
import {useHereReverseGeocodeQueries} from '../lib/here';
import {PopoverTrigger} from '../lib/toasts';
import TRACK_STYLES from '../lib/trackStyles.json';
import {ACCORDION_HEIGHT, BBOX_MARGIN, getPadding} from '../lib/useInitMaplibreLocation';
import {ROUTE_PROPOSALS, useInspirations} from '../lib/useInspirations';
import {useMapLibre} from '../lib/useMapLibre';
import {calcNoGoArea} from '../lib/useRouteReducer';
import {consumeProps, DesktopOnly, MobileOnly, useDeviceTypeVariant} from '../lib/util';
import {ZINDEX} from '../lib/zindexes';
import {Signet} from './Brand';
import {StyledRangeSlider} from './DiscreteRangeSlider';
import {HorizontalAccordionItem} from './MapHorizontalAccordion';
import {ClearRouteButton} from './RouteMapping';
import {getTrackSegmentGeoJSON, TrackSegmentsLayer} from './Track';
import {User} from './User';
import {useAnchor, useViewState} from './ViewStateContext';


const _arrowWidth = _.property('arrowWidth'),
    _cardWidth = _.property('cardWidth'),
    _innerWidth = _.property('innerWidth'),
    _thumbnailSize = _.property('thumbnailSize');

// ScrollMenu components does not pass through explicit classNames so need to apply styles on the wrapping component.
// noinspection CssUnusedSymbol,CssUnresolvedCustomProperty
const ScrollWrapperCardBody = styled(Card.Body, consumeProps('arrowWidth', 'cardWidth', 'innerWidth', 'thumbnailSize'))`
    z-index: 905;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;

    .react-horizontal-scrolling-menu--wrapper, .react-horizontal-scrolling-menu--inner-wrapper {
        height: 100%;
    }

    .react-horizontal-scrolling-menu--item {
        height: 100%;

        .card {
            position: relative;
            width: ${_cardWidth};

            img {
                width: ${_thumbnailSize};
                height: ${_thumbnailSize};
                object-fit: cover;
                flex: 1 1 auto;
            }
        }
    }

    .react-horizontal-scrolling-menu--arrow-right, .react-horizontal-scrolling-menu--arrow-left {
        button {
            width: ${_arrowWidth};
        }
    }

    .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
        display: none;
    }

    .react-horizontal-scrolling-menu--scroll-container {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        height: 100%;
        /* Tried --radix-accordion-content-width before, but it is not recalculated properly on resize.*/
        width: calc(${_innerWidth} + 2 * ${_arrowWidth});
    }
`;

function InspirationsSetup({proposeRoundtrip}) {
    const [roundtrip, setRoundtrip] = useState({bearing: 30, ellipse_ratio: 2.0, ideal_snap: 0.3, distance: 50000});
    const [avoidRidden, setAvoidRidden] = useState(0.5);

    const propose = useCallback(function propose(what) {
        setRoundtrip((prev) => ({...prev, ...what}));
    }, [setRoundtrip]);

    useEffect(() => {
        roundtrip && avoidRidden && proposeRoundtrip({roundtrip, avoidRidden});
    }, [roundtrip, avoidRidden, proposeRoundtrip]);

    return <div className="maplibregl-control-container">
        <Card className="maplibregl-ctrl maplibregl-ctrl-topleft" style={{width: '18rem'}}>
            <StyledRangeSlider min={25000} max={150000} step={1000} value={roundtrip.distance} setValue={(distance) => propose({distance})}
                               parseValue={parseInt} label="Distance" tooltipLabel={_.toString}/>
            <StyledRangeSlider min={-360} max={360} step={1} value={roundtrip.bearing} setValue={(bearing) => propose({bearing: (bearing < 0 ? bearing + 360 : bearing)})}
                               parseValue={parseInt} label="Bearing" tooltipLabel={_.toString}/>
            <StyledRangeSlider min={0.5} max={5} step={0.5} value={roundtrip.ellipse_ratio} setValue={(ellipse_ratio) => propose({ellipse_ratio})} parseValue={parseFloat}
                               label="Axis ratio" tooltipLabel={_.toString}/>
            <StyledRangeSlider min={0.0} max={1} step={0.1} value={roundtrip.ideal_snap} setValue={(ideal_snap) => propose({ideal_snap})} parseValue={parseFloat}
                               label="Snap" tooltipLabel={_.toString}/>
            <StyledRangeSlider min={0.0} max={1.0} step={0.1} value={avoidRidden} setValue={(avoidRidden) => setAvoidRidden(avoidRidden)} parseValue={parseFloat}
                               label="Avoid ridden" tooltipLabel={_.toString}/>
        </Card>
    </div>;
}

export function RoundtripEllipse({start, distance, bearing, axisRatio}) {
    const {ideal, noGo} = useMemo(() => (calcNoGoArea({start, distance, bearing, axisRatio}) ?? {}), [start, distance, bearing, axisRatio]);
    const geojson = featureCollection([ideal, noGo]);
    const style = {type: 'line', 'line-color': '#000000'};

    return <Source type="geojson" data={geojson}>
        <Layer {...style} />
    </Source>;
}

export function RoundtripDebugger({route, routeProps, proposeRoundtrip}) {
    return <>
        <RoundtripEllipse start={_.first(route)} {...routeProps} />
        <InspirationsSetup proposeRoundtrip={proposeRoundtrip} {...routeProps} />
    </>;
}

function sampleLocations(track, distance, withEnds) {
    const chunks = lineChunk(track, distance / 4, {units: 'kilometers'}),
        innerPoints = _.map(_.tail(chunks.features), (l) => _.first(getCoords(l))),
        points = withEnds ? [_.first(getCoords(track)), ...innerPoints, _.last(getCoords(track))] : innerPoints;
    return _.map(points, (coord) => point(coord));
}

export function useFetchRoundtripLocationNames(track, distance, withEnds, enabled = true) {
    const points = useMemo(() => (enabled && track && distance) ? sampleLocations(track, distance, withEnds) : [], [track, distance, withEnds, enabled]);
    const results = useHereReverseGeocodeQueries(points, enabled && !_.isEmpty(points));
    const cities = useMemo(() => _.sortedUniq(_.map(
        _.filter(_.map(results, 'data.address.city')),
        (city) => _.trim(city?.replace(/\([^)]*\)/g, ""))
    )), [results]);
    return cities;
}

function InspirationTitle({onClick, route, title, distance, className}) {
    return title ?
        <TitleButton className={className} onClick={onClick}>{title}</TitleButton> :
        <RoundtripTitleButton className={className} distance={distance} onClick={onClick} route={route}/>;
}

function TitleButton({children, ...props}) {
    return <Button variant="link" size="sm" {...props}>{children}</Button>;
}

function RoundtripTitleButton({route, distance, ...props}) {
    // Intentionally rolling out trackFeature (lazy getter) so deeply, to avoid expensive fromFirebaseBytes when unnecessary.
    const locationNames = useFetchRoundtripLocationNames(route.trackFeature, distance, false),
        routeTitle = useMemo(() => _.map(locationNames, (locName, idx) => {
            return <Fragment key={idx}>{!!idx && <FaCaretRight className="mx-0 mx-md-1"/>}{locName}</Fragment>;
        }), [locationNames]);

    return <TitleButton {...props} >{routeTitle}</TitleButton>;
}

const InspirationTitleMobile = styled(InspirationTitle)`
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    width: calc(100% - 0.5rem);
`;

function InspirationThumbnailCardContents({children, distance, mobileChildren = null, route, title, thumbnail, onSelect}) {
    return <>
        <DesktopOnly>
            <Card.Header className={classNames("mb-0 mx-lg-0 p-1")}>
                <InspirationTitle route={route} title={title} distance={distance} onClick={onSelect}
                                  className="overflow-hidden stretched-link text-truncate px-0 py-0 small w-100 lh-1"/>
            </Card.Header>
            <Card.Body className="h-100 flex-grow-1 p-0 smaller lh-1 clearfix overflow-hidden">
                <Stack direction="horizontal" className="align-items-stretch h-100">
                    <InspirationThumbnailMap src={thumbnail} distance={distance} onClick={onSelect} className="flex-grow-0 flex-shrink-0 h-100 p-1"/>
                    <Stack direction="vertical" gap={2} className="flex-grow-1 flex-shrink-1 align-items-stretch p-1 ps-0">
                        {children}
                    </Stack>
                </Stack>
            </Card.Body>
        </DesktopOnly>
        <MobileOnly>
            <Card.Body className="h-100 flex-grow-1 p-0 smaller lh-1 clearfix overflow-hidden position-relative">
                <InspirationThumbnailMap src={thumbnail} distance={distance} onClick={onSelect} className="flex-grow-0 flex-shrink-0 p-1"/>
                {mobileChildren}
                <InspirationTitleMobile route={route} title={title} distance={distance} onClick={onSelect}
                                  className="stretched-link position-absolute bottom-0 left-0 m-1 x-small bg-opacity-50 bg-dark text-light px-1 py-0"/>
            </Card.Body>
        </MobileOnly>
    </>;
}

const ROUNDTRIP_PREVIEW_DELAY = 500,
    ROUNDTRIP_PREVIEW_DEBOUNCE_OPTIONS = {maxWait: ROUNDTRIP_PREVIEW_DELAY * 3, leading: false, trailing: true};

function useOnSelectRoundtripRoute({dispatchRoute, onSelect, route}) {
    return useCallback(() => {
        route && dispatchRoute({type: 'applyRoundtripRoute', ..._.pick(route, 'profile', 'route', 'trackShape')});
        onSelect?.(route);
    }, [dispatchRoute, onSelect, route]);
}

function InspirationThumbnailAIGeneratedRoute({dispatchRoute, onSelect, route}) {
    const {distance, keynote, name, thumbnail} = route ?? {},
        onSelectRoundtripRoute = useOnSelectRoundtripRoute({dispatchRoute, onSelect, route});

    return <InspirationThumbnailCardContents {...{distance, thumbnail}} onSelect={onSelectRoundtripRoute} title={name}>
        <h6 className="small fw-bold text-center">{keynote}</h6>
        <InspirationThumbnailActions route={route} onClick={onSelectRoundtripRoute} className="mb-1"/>
    </InspirationThumbnailCardContents>;
}

function InspirationThumbnailDynamicRoute({dispatchRoute, onSelect, route}) {
    const {distance, thumbnail} = route ?? {},
        onSelectRoundtripRoute = useOnSelectRoundtripRoute({dispatchRoute, onSelect, route});
    return <InspirationThumbnailCardContents {...{distance, route, thumbnail}} onSelect={onSelectRoundtripRoute}>
        <InspirationThumbnailActions route={route} onClick={onSelectRoundtripRoute}/>
    </InspirationThumbnailCardContents>;
}

const EXCLUDED_PROPS = ['id', 'owner', 'starred', 'created',
    // Avoid redrawing of the map layers with the new style. User possibly wants to preserve viewing preferences anyway.
    'adjustStyle', 'showPopularity', 'showTrails'];

function UseButton({className, route, onClick}) {
    const {t} = useTranslation();

    return <PopoverTrigger heading={route?.name} tooltip={t("Click to use this route as the base for your own.")} delay={500} placement="right">
        <Button variant="primary" className={classNames("flex-shrink-0", className)} onClick={onClick}><Trans>Use route</Trans></Button>
    </PopoverTrigger>;
}

function InspirationThumbnailActions({className, onClick, route, user}) {
    return <Stack direction="horizontal" className={classNames("prevent-stretched-link align-items-center mx-auto", className)} gap={2}>
        <UseButton className="p-1 mx-auto" route={route} onClick={onClick}/>
    </Stack>;
}

function InspirationThumbnailUserRoute({dispatchRoute, onSelect, route}) {
    const {distance, name, owner, thumbnail} = route ?? {},
        [user] = useCurrentUserData(),
        {data: ownerData} = useUserData(owner),
        onSelectUserRoute = useCallback(() => {
            const {id, track, route: routePoints, routeProps: origProps} = route,
                routeProps = {..._.omit(origProps, EXCLUDED_PROPS), clonedFrom: id, suggested: true};
            // 'dirty' flag is necessary to force saving used inspiration to local storage.
            // This is required to properly maintain state when a user picsk inspiration and then through garmin OAuth
            // which is often part of onboarding user journey experience.
            dispatchRoute({type: 'use', dirty: true, track, route: routePoints, routeProps});
            onSelect?.(route);
        }, [dispatchRoute, onSelect, route]);

    return <InspirationThumbnailCardContents
        {...{distance, route, thumbnail}} title={name} onSelect={onSelectUserRoute}
        mobileChildren={owner ? <UserAvatar className="position-absolute top-0 end-0 m-2" photo_url={ownerData?.photo_url} size={30}/> : null}
    >
        <h6 className="small fw-bold text-center"><Trans>Route of Tarmacs user</Trans></h6>
        <InspirationThumbnailActions route={route} onClick={onSelectUserRoute} user={user}/>
        {owner ?
            <User userId={owner} size={30} className="small w-100 prevent-stretched-link" hideViewUser/> :
            <Signet src={logo150}/>
        }
    </InspirationThumbnailCardContents>;
}

function InspirationThumbnailMap({className, onClick, src, distance, style}) {
    const {t} = useTranslation(),
        distanceFmt = distance && t("{{distance, number}}", {distance, style: 'unit', unit: 'kilometer', unitDisplay: 'short', maximumFractionDigits: 0});

    return <div className={classNames(className, 'position-relative')} style={style}>
        {distanceFmt && <Badge bg="success" className="position-absolute top-0 left-0 m-1">{distanceFmt}</Badge>}
        <Image src={src} onClick={onClick}/>
    </div>;
}

const THUMBNAIL_CONTENTS = {
    [ROUTE_PROPOSALS.AI_INSPIRATION]: InspirationThumbnailAIGeneratedRoute,
    [ROUTE_PROPOSALS.USER]: InspirationThumbnailUserRoute,
    [ROUTE_PROPOSALS.DYNAMIC_ROUNDTRIP]: InspirationThumbnailDynamicRoute
};

const InspirationThumbnail = memo(function InspirationThumbnail({dispatchRoute, onPreview, onPreviewReset, route}) {
    const {value: hover, setValue: toggleHover} = useBoolean(false),
        {setViewState} = useViewState(),
        setHover = useCallback((hover) => {
            const geojson = featureCollection(route.trackFeature ? [feature(route.trackFeature)] : []);
            toggleHover(hover);
            onPreview?.(hover ? geojson : null);
        }, [onPreview, route, toggleHover]),
        InspirationThumbnailContents = THUMBNAIL_CONTENTS[route?.proposal],
        onSelectRoute = useCallback(() => {
            // Reset previewing state to avoid sticking it after inspiration is selected, which did happen.
            // Calls immediate onPreviewReset (not debounced).
            setViewState(prev => prev.previewingViewState ? _.omit(prev, 'previewingViewState') : prev);
            onPreviewReset?.();
        }, [onPreviewReset]);
    useUnmount(() => {
        toggleHover(false);
        onPreview?.(null);
    });

    if (!InspirationThumbnailContents) return null;

    return <Card border={(hover && route) ? "primary" : ""} className="h-100"
                 onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <InspirationThumbnailContents onSelect={onSelectRoute} route={route} dispatchRoute={dispatchRoute}>
        </InspirationThumbnailContents>
    </Card>;
});

function LeftArrow() {
    const {isFirstItemVisible, scrollPrev, visibleElements, initComplete} = useContext(VisibilityContext),
        [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible));

    useEffect(() => {
        visibleElements.length && setDisabled(isFirstItemVisible);
    }, [isFirstItemVisible, visibleElements]);

    return <Button disabled={disabled} variant="outline-secondary" size="sm" onClick={() => scrollPrev()}><FcPrevious/></Button>;
}

function RightArrow({pushNewItems, isFetchingNextPage}) {
    const {getNextElement, isLastItem, isLastItemVisible, scrollNext, visibleElements, items} = useContext(VisibilityContext),
        nextItem = getNextElement(),
        isPrevLastItemVisible = !!nextItem?.key && isLastItem(nextItem.key),
        {value: disabled, setValue: setDisabled} = useBoolean(_.isEmpty(visibleElements) || isLastItemVisible);

    useEffect(() => {
        if ((isLastItemVisible || isPrevLastItemVisible) && !isFetchingNextPage) {
            pushNewItems();
        }
        setDisabled(isLastItemVisible);
    }, [isFetchingNextPage, isLastItemVisible, isPrevLastItemVisible, items, pushNewItems, setDisabled]);

    const icon = useMemo(() => (isFetchingNextPage && isLastItemVisible ?
        <Spinner as="span" size="sm" animation="border" role="status" aria-hidden="true"/> :
        <FcNext/>), [isFetchingNextPage, isLastItemVisible]);

    return <Button disabled={disabled} variant="outline-secondary" size="sm" onClick={() => scrollNext()}>{icon}</Button>;
}

const StyledSpinner = styled(Spinner)`
    --bs-spinner-width: 0.7rem;
    --bs-spinner-height: 0.7rem;
    --bs-spinner-animation-speed: 1.7s;
`;

function InspirationsSelector({
    active, bikeKind, dispatchRoute, hasRoute, hills, mainRoads, onPreviewRoute, popularity, ridingSpeed, start, suitability
}) {
    const {anchor, isMapIdle, previewingViewState, setViewState, mapLibre} = useAnchor({start}),
        // Fetch even in background when the user has no route drawn yet. Otherwise, fetch only if the user opens the accordion.
        // Do not fetch when the map is not idle (loading tiles).
        enabled = !!isMapIdle && !_.isEmpty(anchor?.start) && (!!active || !hasRoute) && !!bikeKind,
        {pages, fetchNextPage, refetch, isFetchingNextPage, isFetching, isSuccess} = useInspirations(
            {bikeKind, hills, mainRoads, popularity, ridingSpeed, suitability, ...anchor},
            5, {enabled}),
        inspirations = useMemo(() => _.flatten(pages), [pages]),
        pushNewItems = useCallback(() => enabled && fetchNextPage({cancelRefetch: false}), [enabled, fetchNextPage]),
        scrollContainerRef = useRef(null),
        {width} = useResizeObserver({ref: scrollContainerRef, box: 'content-box'}),
        [cardWidth, thumbnailSize] = useDeviceTypeVariant({desktop: ['322px', '156px'], mobile: ['146px', '136px']}),
        bottomMarginPx = useDeviceTypeVariant(ACCORDION_HEIGHT);

    const [onPreview_, onPreviewReset] = useMemo(() => {
            function onPreviewReset({moveBack} = {}) {
                onPreviewRoute(null);
                moveBack && previewingViewState && mapLibre.easeTo({
                    center: {lng: previewingViewState.longitude, lat: previewingViewState.latitude},
                    ..._.pick(previewingViewState, 'bearing', 'pitch', 'zoom')
                });
                setViewState(prev => (prev.previewingViewState ? {...prev, ...(moveBack ? prev.previewingViewState : {}), previewingViewState: null} : prev));
            }

            function onPreview(geojson) {
                if (!geojson)
                    return onPreviewReset({moveBack: true});
                onPreviewRoute(geojson);
                // Using plain mapLibre.cameraForBounds as easeTo accepts this format.
                const previewBounds = mapLibre.cameraForBounds(bbox(geojson), getPadding(mapLibre, BBOX_MARGIN, bottomMarginPx));
                mapLibre.easeTo(previewBounds);
                setViewState(prev => ({
                    ...prev, previewingViewState: prev.previewingViewState ?? _.pick(prev, 'bearing', 'latitude', 'longitude', 'padding', 'pitch', 'zoom')
                }));
            }

            return [onPreview, onPreviewReset];
        }, [mapLibre, onPreviewRoute, previewingViewState, setViewState]),
        onPreview = useDebouncedCallback(onPreview_, ROUNDTRIP_PREVIEW_DELAY, ROUNDTRIP_PREVIEW_DEBOUNCE_OPTIONS);

    // Initiate fetch after 1st enabling.
    useEffect(() => {enabled && !isSuccess && refetch();}, [enabled, isSuccess, refetch]);

    return <Card className="h-100">
        {!_.isEmpty(inspirations) &&
            <ScrollWrapperCardBody arrowWidth="30px" cardWidth={cardWidth} thumbnailSize={thumbnailSize} ref={scrollContainerRef} innerWidth={`${width}px`}>
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={<RightArrow pushNewItems={pushNewItems} isFetchingNextPage={isFetchingNextPage || isFetching}/>}>
                    {_.map(inspirations, (route, idx) =>
                        <InspirationThumbnail key={idx} onPreview={onPreview} onPreviewReset={onPreviewReset} {...{dispatchRoute, route, start}}/>
                    )}
                </ScrollMenu>
            </ScrollWrapperCardBody>}
        {(isFetching && !isFetchingNextPage && enabled) && (
            <Card.ImgOverlay className="vstack justify-content-center align-items-center bg-white bg-gradient bg-opacity-75" style={{zIndex: ZINDEX.ROUNDTRIP_SPINNER}}>
                <StyledSpinner as="span" animation="grow" size="sm" role="status" className="text-center mx-auto text-secondary mb-2"/>
                <h6><Trans>Loading inspirations...</Trans></h6>
                <p className="text-center"><Trans>Start drawing your own route or wait until we have suggestions ready for you.</Trans></p>
            </Card.ImgOverlay>
        )}
    </Card>;
}

export function ClearInspirationButton({base, className, dispatchRoute, ...props}) {
    const {mapLibre} = useMapLibre();

    function onClick(evt) {
        base && mapLibre?.easeTo({center: toLngLat(_.first(base))});
        dispatchRoute({type: 'reset', keepStart: !_.isEmpty(base)});
    }

    return <ClearRouteButton className={classNames("small", className)} onClick={onClick} {...props}>
        <TbMapX/>&nbsp;<Trans>Clear inspiration</Trans>
    </ClearRouteButton>;
}

export function InspirationsSelectorAccordionItem({
    active, bikeKind, dispatchRoute, eventKey, hasRoute, header, hills, mainRoads, popularity, onPreviewRoute, ridingSpeed, start, suitability
}) {
    return <HorizontalAccordionItem header={header} eventKey={eventKey}>
        <InspirationsSelector
            {...{active, bikeKind, dispatchRoute, hasRoute, hills, mainRoads, popularity, onPreviewRoute, ridingSpeed, start, suitability}} />
    </HorizontalAccordionItem>;
}

const PREVIEW_STYLES = _.map(TRACK_STYLES, (style) => _.merge({}, style, {paint: {'line-opacity': 0.5}}));

/**
 * Preview hovered route inspiration on the map.
 */
export const PreviewRoute = memo(function PreviewRoute({geojson, bikeKind}) {
    const {mapLibre} = useMapLibre(),
        surface = {road: 'tarmac', gravel: 'compacted', mtb: 'dirt'}[bikeKind],
        features = featureCollection(_.map(geojson?.features, feat =>
            getTrackSegmentGeoJSON(mapLibre, 0, getCoords(feat), {class: 'track_segment', popularity_class: 6, surface})));

    if (_.isEmpty(features)) return null;
    return <TrackSegmentsLayer features={features} styles={PREVIEW_STYLES} id="preview"/>;
});