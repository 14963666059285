export const ZINDEX = {
    STEEPNESS_MARKER: 800,
    VOLATILE_MARKER: 805,
    // Above the volatile marker to allow hovering
    POI_MARKER: 810,
    GATE_MARKER: 815,
    WEATHER_MARKER: 820,
    // Above the volatile marker to avoid adding point when clicking orbit. Above weather to avoid preventing move.
    ROUTE_POINT_MARKER: 821,
    WEATHER_PANEL: 825,
    POI_POPUP: 900,
    MAP_ACCORDION: 905,
    ROUNDTRIP_SELECTOR: 910,
    ROUNDTRIP_SPINNER: 911,
    LOCATION_BOX: 915,
};