import logo192 from 'images/logo192.png';
import {Breadcrumbs, useDeviceType} from 'lib/util';
import React, {useCallback, useContext} from 'react';
import {Button, Modal, Nav, Navbar, Spinner} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {BsFillGrid3X2GapFill} from 'react-icons/bs';
import {FaBell, FaRoute} from 'react-icons/fa';
import {LinkContainer} from 'react-router-bootstrap';
import {Route, Routes} from 'react-router-dom';
import {useBoolean} from 'usehooks-ts';
import {ErrorBoundary} from '../lib/errors';
import {FirebaseAuth} from '../lib/firebase';
import {AuthenticatedRoute} from './auth/AuthenticatedRoute';
import {Logotype, Signet} from './Brand';
import {NewNotificationsBadge, Notifications, useNotificationsQuery} from './Notifications';
import {PageLoadingContext, PageLoadingProvider} from './PageLoading';
import {RoutePlanner} from "./RoutePlanner";
import {RoutesList, RoutesTabBreadcrumb, UserRoutesTabBreadcrumb} from './RoutesList';
import {RouteViewer} from './RouteViewer';
import {LanguageSelector, UserMenu} from './User';
import {UserProfile} from './UserProfile';


function NavLink(props) {
    return <Nav.Link {...props} className="pt-0 pb-0 pt-lg-2 pb-lg-2"/>;
}

function NotificationsNavBarToggle() {
    const {areNew} = useNotificationsQuery();

    return <Navbar.Toggle aria-controls="responsive-navbar-nav justify-content-end">
        {areNew && <>
            <FaBell/>
            <NewNotificationsBadge/>
        </>}
    </Navbar.Toggle>;
}

function MainNavbar() {
    const {t} = useTranslation();
    const {isSignedIn} = useContext(FirebaseAuth),
        brand = <Logotype>Tarmacs</Logotype>,
        {isMobile} = useDeviceType(),
        notificationsShowState = useBoolean(false),
        {setValue: setNotificationsVisible} = notificationsShowState,
        {areNew: areNewNotifications} = useNotificationsQuery();

    const breadcrumbs = [{
        path: '/routes',
        breadcrumb: null,
        children: [{
            path: 'view',
            breadcrumb: null,
            children: [{
                index: true,
                breadcrumb: t("Routes")
            }, {
                path: 'user',
                breadcrumb: null,
                children: [{
                    path: ':userId',
                    breadcrumb: UserRoutesTabBreadcrumb
                }]
            }, {
                path: 'tab',
                children: [{
                    path: ':tab',
                    breadcrumb: RoutesTabBreadcrumb
                }]
            }, {
                path: ':routeId',
                breadcrumb: t("View route")
            }]
        }, {
            path: 'edit/new',
            breadcrumb: t("New route")
        }, {
            path: 'edit/:routeId',
            breadcrumb: t("Edit route")
        }, {
            path: 'profile',
            breadcrumb: t("Your profile")
        }]
    }];

    const onToggle = useCallback((expanded) => setNotificationsVisible(expanded && areNewNotifications), [areNewNotifications, setNotificationsVisible]);

    return <Navbar collapseOnSelect expand="lg" onToggle={onToggle}
                   variant="dark" bg="dark" style={{lineHeight: '30px'}}
                   className="pb-0 pt-0 pe-0 ps-0 ps-md-1 flex-row align-items-center">
        <Nav className="pb-0 pt-0 pe-0 ps-0 ps-md-1 flex-row align-items-center overflow-hidden flex-nowrap flex-grow-1">
            <Signet src={logo192}/>
            <Breadcrumbs routes={breadcrumbs} brand={brand}/>
            <NotificationsNavBarToggle/>
        </Nav>
        <Navbar.Collapse id="responsive-navbar-nav" className="ps-2">
            <Nav className="me-auto"/>
            <Nav className="ms-auto" style={{lineHeight: '32px'}}>
                {isSignedIn &&
                    <LinkContainer to="/routes/view"><NavLink><BsFillGrid3X2GapFill/> <Trans>My routes</Trans></NavLink></LinkContainer>}
                <Nav.Item className="my-auto">
                    <LinkContainer to="/routes/edit/new">
                        {isMobile ?
                            <NavLink><FaRoute/> <Trans>Plan new route</Trans></NavLink> :
                            <Button className="me-2" variant="outline-light"><FaRoute/> <Trans>Plan new route</Trans></Button>}
                    </LinkContainer>
                </Nav.Item>
                <Notifications showState={notificationsShowState}/>
                <LanguageSelector/>
                <UserMenu/>
            </Nav>
        </Navbar.Collapse>
    </Navbar>;
}

export default function Main() {
    // Intentionally not using useRouteMatch(); and `${path}` in the sub-switch because we handle multiple base path segments by this component.

    // Note: To make map visible and fill the viewport properly, there are plenty of styles defined in App.scss
    // Adjust them if you add/change something in the main app layout.
    return <div id="main">
        <MainNavbar/>
        <main>
            <PageLoadingProvider>
                <PageLoadingContext.Consumer>{({loading}) =>
                    <Modal
                        show={loading}
                        animation={false}
                        centered
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header><Trans>Loading...</Trans></Modal.Header>
                        <Modal.Body className="text-center">
                            <Spinner as="span" animation="border" role="status" className="text-center"/>
                        </Modal.Body>
                    </Modal>
                }</PageLoadingContext.Consumer>
                <Routes>
                    <Route path="edit/:routeId" element={<ErrorBoundary><RoutePlanner/></ErrorBoundary>}/>
                    <Route path="view/:routeId" element={<ErrorBoundary><RouteViewer/></ErrorBoundary>}/>
                    <Route path="view/*" element={<AuthenticatedRoute unauthedPath={null}><ErrorBoundary><RoutesList/></ErrorBoundary></AuthenticatedRoute>}/>
                    <Route exact path="profile" element={<AuthenticatedRoute><ErrorBoundary><UserProfile/></ErrorBoundary></AuthenticatedRoute>}/>
                </Routes>
            </PageLoadingProvider>
        </main>
    </div>;
};