import fetchIntercept from 'fetch-intercept';
import {getIdToken, onAuthStateChanged, signInWithEmailAndPassword, signOut, updateProfile} from 'firebase/auth';
import {errorHandler} from '../../lib/errors';
import {auth, FirebaseAuth, firebaseAuthProviderDefaultProps} from '../../lib/firebase';
import _ from 'lodash';
import React, {Children, cloneElement, isValidElement, PureComponent, useEffect} from "react";
import {useTranslation} from 'react-i18next';


const {
    Provider: FirebaseAuthContextProvider,
    Consumer: FirebaseAuthContextConsumer
} = FirebaseAuth;

function renderAndAddProps(renderable, props) {
    if (typeof renderable === "function") {
        return renderable(props);
    } else if (Children.count(renderable) > 0) {
        return Children.map(renderable, child =>
            cloneElement(child, props)
        );
    } else if (isValidElement(renderable)) {
        return cloneElement(renderable, props);
    }
    return null;
}

export class FirebaseAuthProvider extends PureComponent {
    constructor(props) {
        super(props);

        // See https://reactjs.org/docs/context.html#updating-context-from-a-nested-component for the design pattern.
        this.state = {
            ...firebaseAuthProviderDefaultProps,
            updatePhotoURL: async (photoURL) => {
                try {
                    await updateProfile(this.state.user, {photoURL});
                } catch (e) {
                    console.warn(e);
                }

                this.setState(({user, ...state}) => ({...state, user: {...user, photoURL}}));
            },
            // Exposed for Playwright tests
            signInWithEmailAndPassword: async (email, password) => {
                return await signInWithEmailAndPassword(this.state.auth, email, password);
            },
            signOut: async () => {
                return await signOut(this.state.auth);
            }
        };
    }

    stopListeningToAuth = () => null;

    listenToAuth() {
        const onAuthStateChangeHandler = (user) => {
            try {
                // After log-out it might be good to have last logged user, so keep it.
                (user?.email || user?.uid) && errorHandler.setUser(user?.email ?? user?.uid);
            } catch (e) {
                console.error('Error setting user in errorReporter.', e);
            }
            const authState = (user === null) ? {
                    isSignedIn: false,
                    providerId: "none"
                } : (user.isAnonymous === true) ? {
                    isSignedIn: true,
                    providerId: "anonymous"
                } : (user?.providerData?.[0] || user?.providerId === 'firebase') ? {
                    isSignedIn: true,
                    providerId: user?.providerData?.[0]?.providerId || user?.providerId || "unknown"
                } : null,
                authEmission = authState && {user, ...authState};
            if (authEmission !== null) {
                console.log('Firebase auth state change to: ', authEmission);
                this.setState(state => ({...state, ...authEmission}));
            } else {
                console.error("Something unexpected happened with ", user);
            }
        };
        this.stopListeningToAuth = onAuthStateChanged(auth, onAuthStateChangeHandler);
    };

    componentDidMount() {
        this.listenToAuth();
    }

    componentWillUnmount() {
        this.stopListeningToAuth?.();
    }

    render() {
        const {children} = this.props;
        return (
            <FirebaseAuthContextProvider value={this.state}>
                {renderAndAddProps(children, {})}
            </FirebaseAuthContextProvider>
        );
    }
}

export function FirebaseAuthConsumer({children}) {
    return (
        <FirebaseAuthContextConsumer>
            {(authState) => renderAndAddProps(children, authState)}
        </FirebaseAuthContextConsumer>
    );
}

/**
 * Setup the Authorization header based on Firebase ID Token to authenticate in the backend.
 *
 * Intended to be wrapped by <FirebaseAuthConsumer> to provide Firebase context params.
 * See also:
 *   * https://fastapi.tiangolo.com/tutorial/security/first-steps/
 *   * https://firebase.google.com/docs/reference/js/v9/auth#getidtoken
 *   * https://firebase.google.com/docs/auth/admin/verify-id-tokens#web
 */
function FirebaseFetchInterceptorSetup({user, isSignedIn}) {
    const {i18n: {language}} = useTranslation();
    useEffect(() => {
        const unregisterFns = [];

        function _wrapInterceptor(interceptorFn) {
            async function wrappedInterceptor(reqUrl, config) {
                // Coalesce to object as url might be both string and Request object, surprisingly.
                let url = reqUrl.url ?? reqUrl,
                    altered = false;
                [url, config, altered] = await interceptorFn(url, config);
                if (!reqUrl.url) {
                    return [url, config];
                }

                return altered ? [new Request(url, reqUrl), config] : [reqUrl, config];
            }

            return wrappedInterceptor;
        }

        const isApiRequest = url => _.startsWith(url, process.env.REACT_APP_API_URL) || _.startsWith(url, process.env.REACT_APP_BACKEND_URL);

        // Order is important because fetch interceptor calls intercepts in order opposite to registration.
        // Intercept authentication headers.
        if (isSignedIn) {
            const unregister = fetchIntercept.register({
                request: _wrapInterceptor(async (url, config) => {
                    const alter = isApiRequest(url);
                    if (alter) {
                        const idToken = await getIdToken(user);
                        config.headers = {
                            ...(config.headers || {}),
                            Authorization: `Bearer ${idToken}`
                        };
                    }
                    return [url, config, alter];
                })
            });
            unregisterFns.push(unregister);
        }

        // Intercept convenient shortcuts for netloc-relative URLs and json
        const unregister = fetchIntercept.register({
            request: _wrapInterceptor(async (url, config = {}) => {
                // Exclude hot updates as they should call the dev-server port, not API port.
                const alterUrl = _.startsWith(url, '/') && !_.startsWith(url, '//') && !_.endsWith(url,'.hot-update.json');
                if (alterUrl) {
                    // For netloc-relative urls ('/api/something') but not scheme-relative (//server.com/something)
                    // Assume it's API ajax url
                    url = `${process.env.REACT_APP_API_URL}${url}`;
                }
                const alterJson = !_.isUndefined(config.json) && _.isUndefined(config.body);
                if (alterJson) {
                    const headers = isApiRequest(url) ?
                        {...(config.headers ?? {}), 'Content-Type': 'application/json'} :
                        config.headers;
                    config = {..._.omit(config, 'json'), body: JSON.stringify(config.json), headers};
                }
                const alterAcceptLanguage = isApiRequest(url) && !!language;
                if (alterAcceptLanguage) {
                    const headers = {...(config.headers ?? {}), 'Accept-Language': language};
                    config = {...config, headers};
                }
                // Knowing the viewstate helps debugging errors on the backend.
                // Otherwise, we would have only https://tarmacs.app because the backends are on subdomains.
                // See also: https://developer.mozilla.org/en-US/docs/Web/API/RequestInit#referrerpolicy
                const alterReferer = isApiRequest(url) || _.endsWith(new URL(url).host, 'tarmacs.app');
                if (alterReferer) {
                    config = {...config, referrer: window.location.href, referrerPolicy: 'unsafe-url'};
                }
                return [url, config, alterUrl || alterJson || alterAcceptLanguage || alterReferer];
            })
        });
        unregisterFns.push(unregister);

        return () => _.each(unregisterFns, (fn) => fn());
    }, [user, isSignedIn, language]);
    return null;
}

export function FirebaseFetchInterceptor() {
    return <FirebaseAuthConsumer>
        <FirebaseFetchInterceptorSetup/>
    </FirebaseAuthConsumer>;
}