import styled from '@emotion/styled';
import {getSteepnessAttrs} from '../lib/steepness';
import {consumeProps} from '../lib/util';


const UphillDropShadow = styled.div`
    filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
`;

// Note: Safari does not support transform: scale(%,%)
const ContentDiv = styled.div`
    font-weight: bold;
    transform-origin: top right;
    transform: rotate(-45deg) scale(1.41, 1.2) translatey(-3px);
    text-align: center;
    font-size: ${({fontSize}) => fontSize}px;
    padding-top: -2px;
`;

export const STEEPNESS_MARKER_SIZE = 50,
    STEEPNESS_MARKER_SUB_SIZE = 15,
    STEEPNESS_MARKER_FONT_SIZE = 14;

const UphillDiv = styled.div(({steepness, size = STEEPNESS_MARKER_SIZE}) => {
    const {color: background, labelColor: color} = getSteepnessAttrs(steepness) ?? {};
    return {
        clipPath: 'polygon(100% 5%,0% 100%,100% 100%);',
        background,
        color,
        width: `${size}px`,
        height: `${size}px`,
        paddingBottom: '0px'
    };
});

export function Uphill({steepness, size = STEEPNESS_MARKER_SIZE, fontSize = STEEPNESS_MARKER_FONT_SIZE, ...props}) {
    return <div {...props}>
        <UphillDropShadow><UphillDiv steepness={Math.ceil(steepness)} size={size}>
            <ContentDiv fontSize={fontSize}>{Math.ceil(steepness)}<span style={{fontSize: 'xx-small', verticalAlign: 'middle'}}>%</span></ContentDiv>
        </UphillDiv></UphillDropShadow>
    </div>;
}

const SteepnessSVGText = styled('text', consumeProps('size', 'fontSize'))`
    font-weight: bold;
    transform-origin: ${props => props.size}px 0;
    transform: rotate(-45deg) translateX(${props => props.size / 4}px) translateY(${props => -props.size * 3 / 4}px);
    text-align: center;
    font-size: ${props => props.fontSize}px;
    padding-top: 2px;
    text-anchor: middle;
    vertical-align: middle;
`;

const RangeSVGText = styled.text`
    font-variant: small-caps;
    font-size: ${props => props.fontSize}px;
    font-weight: bold;
    text-anchor: middle;
    dominant-baseline: middle;
`;

export function UphillSVG({range, steepness, size = STEEPNESS_MARKER_SIZE, fontSize = STEEPNESS_MARKER_FONT_SIZE, subSize = STEEPNESS_MARKER_SUB_SIZE}) {
    const {color, labelColor} = getSteepnessAttrs(Math.ceil(steepness)),
        points = `${size},0 0,${size} 0,${size + subSize} ${size},${size + subSize}`;

    return <>
        <defs>
            <filter id="dropShadow" x="-20%" y="-20%" width="140%" height="140%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
                <feOffset dx="-1" dy="6" result="offsetblur"/>
                <feComponentTransfer>
                    <feFuncA type="linear" slope="0.5"/>
                </feComponentTransfer>
                <feMerge>
                    <feMergeNode/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
        </defs>
        <polygon points={points} fill={color} filter="url(#dropShadow)"/>
        <line x1={4} y1={size} x2={size - 4} y2={size} stroke={labelColor} strokeWidth="1" strokeDasharray="1,1"/>
        <SteepnessSVGText x={0} y={size} size={size} fontSize={fontSize * 1.41} fill={labelColor}>{Math.ceil(steepness)}%</SteepnessSVGText>
        <RangeSVGText fill={labelColor} x={size / 2} y={size + subSize / 2} fontSize={subSize * 0.6}>{range}</RangeSVGText>
    </>;
}