import {useFirestoreDocument, useFirestoreDocumentData, useFirestoreDocumentDeletion, useFirestoreDocumentMutation, useFirestoreQuery, useFirestoreQueryData} from '@react-query-firebase/firestore';
import {collection, collectionGroup, doc, endAt, getDocs, getDocsFromCache, getDocsFromServer, limit, orderBy, query, startAfter, startAt} from "firebase/firestore";
import {deleteObject, getDownloadURL, ref as storageRef, uploadBytes} from "firebase/storage";
import {geohashQueryBounds} from 'geofire-common';
import _ from 'lodash';
import {useCallback, useContext, useMemo} from 'react';
import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from 'react-query';
import {useLocalStorage} from 'usehooks-ts';
import {userConverter} from './converters';
import {db, FirebaseAuth, storage} from './firebase';
import {getCenter} from './util';


export function isQueryEnabled(path) {
    return !_.some(_.map(path, (pathItem) => _.isNil(pathItem) || _.some(pathItem, _.isNil)));
}

function _stringifyPath(firebasePath) {
    return _.map(firebasePath, String);
}

export function newDocPath(...firebasePath) {
    const isNew = _.isNil(_.last(firebasePath));
    return isNew ? [..._.initial(firebasePath), doc(collection(db, ..._.initial(firebasePath))).id] : firebasePath;
}

function _getRef(refKind, firebasePath, converter, queryOptions) {
    queryOptions = {...(queryOptions ?? {}), enabled: queryOptions?.enabled ?? isQueryEnabled(firebasePath)};
    const converted = converter ? ((doc) => doc.withConverter(converter)) : _.identity,
        isNewDoc = refKind === doc && _.isNil(_.last(firebasePath)),
        cleanedPath = _stringifyPath(isNewDoc ? _.initial(firebasePath) : firebasePath),
        // This is the way to autogenerate ID for new doc - `doc(collection(db, 'collection-name'))`
        refParams = isNewDoc ? [collection(db, ...cleanedPath)] : [db, ...cleanedPath];

    const ref = queryOptions.enabled ? converted(refKind(...refParams)) : null;
    // Replace last path component with autogenerated ID for purpose of create mutations.
    const path = ref && isNewDoc ? [...cleanedPath, ref.id] : cleanedPath;
    return [ref, path, queryOptions];
}

function _getCollectionRef(firebasePath, queryConstraints, converter, queryOptions, keyParts = {}) {
    const isCollectionGroup = _.first(firebasePath) === '*';
    const [refKind, actualPath] = isCollectionGroup ? [collectionGroup, _.tail(firebasePath)] : [collection, firebasePath];
    const [ref, path, options] = _getRef(refKind, actualPath, converter, queryOptions);
    const queryRef = (queryConstraints && options?.enabled && ref) ? query(ref, ...queryConstraints) : ref;
    return [queryRef, [...path, {isCollectionGroup, query: queryConstraints ?? [], ...keyParts, ...(converter ? {converter: converter.key} : {})}], options];
}

function _getDocRef(firebasePath, converter, queryOptions) {
    const [ref, path, options] = _getRef(doc, firebasePath, converter, queryOptions);
    return [ref, path, options];
}

export function useFirebaseDoc(firebasePath, converter, firestoreOptions, queryOptions) {
    const [docRef, path, options] = _getDocRef(firebasePath, converter, queryOptions);
    return useFirestoreDocument(path, docRef, firestoreOptions, options);
}

export function useFirebaseDocData(firebasePath, converter, firestoreOptions, queryOptions) {
    const [docRef, path, options] = _getDocRef(firebasePath, converter, queryOptions);
    return useFirestoreDocumentData(path, docRef, firestoreOptions, options);
}

export function useFirebaseDocMutation(firebasePath, converter, setOptions, mutationOptions, createOptions) {
    const create = useMemo(() => (createOptions?.create ?? true), [createOptions?.create]);
    setOptions = {...(setOptions ?? {}), merge: setOptions?.merge ?? true};
    // Determine `enabled`, allowing to insert new record; undefined means 'allow when path has ID', ie 'update'.
    // Tanstack v3 does not support `queryOptions.enabled` so provide noop mutation when disabled.
    const [docRef, , options] = useMemo(() => _getDocRef(firebasePath, converter, mutationOptions), [firebasePath, converter, create]),
        fbMutation = useFirestoreDocumentMutation(docRef, setOptions, options),
        noopMutation = useMutation(_.noop, options);

    const mutation = useMemo(() => ({...(options?.enabled ? fbMutation : noopMutation), ref: docRef}), [options?.enabled, fbMutation, noopMutation]);
    return mutation;
}

export function useFirebaseDocDeletion(firebasePath, mutationOptions) {
    const [docRef] = useMemo(() => _getDocRef(firebasePath), [firebasePath]);
    return useFirestoreDocumentDeletion(docRef, mutationOptions);
}

export function useFirebaseCollection(firebasePath, queryConstraints, converter, firestoreOptions, queryOptions) {
    const [queryRef, path, options] = _getCollectionRef(firebasePath, queryConstraints, converter, queryOptions);
    return useFirestoreQuery(path, queryRef, firestoreOptions, options);
}

export function useFirebaseCollectionData(firebasePath, queryConstraints, converter, firestoreOptions, queryOptions) {
    const [queryRef, path, options] = _getCollectionRef(firebasePath, queryConstraints, converter, queryOptions);
    return useFirestoreQueryData(path, queryRef, firestoreOptions, options);
}

const _FIREBASE_QUERY_SNAPSHOT_GETTERS = {
    cache: getDocsFromCache,
    server: getDocsFromServer
};

async function getQuerySnapshot(query, source) {
    return await (_FIREBASE_QUERY_SNAPSHOT_GETTERS[source] ?? getDocs)(query);
}

/**
 * Rewrite of useFirestoreInfiniteQuery because of https://github.com/invertase/react-query-firebase/issues/41
 */
function useFirestoreInfiniteQueryBugfix(key, initialQuery, getNextQuery, firestoreOptions, useInfiniteQueryOptions) {
    const {queryKey, ...options} = useInfiniteQueryOptions;
    return useInfiniteQuery({
        queryKey: queryKey ?? key,
        queryFn: async (ctx) => await getQuerySnapshot(ctx.pageParam ?? initialQuery, firestoreOptions?.source),
        getNextPageParam: (snapshot) => getNextQuery(snapshot),
        ...options
    });
}

function useFirestoreInfiniteQueryDataBugfix(key, initialQuery, getNextQuery, firestoreOptions, useInfiniteQueryOptions) {
    const {queryKey, ...options} = useInfiniteQueryOptions,
        {idField, serverTimestamps} = firestoreOptions ?? {},
        withIdField = idField ? (doc) => ({...doc.data({serverTimestamps}), [idField]: doc.id}) : (doc) => doc.data({serverTimestamps});
    return useInfiniteQuery({
        queryKey: queryKey ?? key,
        queryFn: async (ctx) => {
            const snapshot = await getQuerySnapshot(ctx.pageParam ?? initialQuery, firestoreOptions?.source);
            const ret = snapshot.docs.map((doc) => withIdField(doc));
            // startAfter does not accept pure data, so need to annotate array with the last snapshot.
            ret._lastSnapshot = _.last(snapshot.docs);
            return ret;
        },
        getNextPageParam: (data) => getNextQuery(data),
        ...options
    });
}

function _getInifiniteCollectionRef(firebasePath, queryConstraints, converter, pageSize, queryOptions) {
    const [queryRef, path, options] = _getCollectionRef(firebasePath, [...(queryConstraints ?? []), limit(pageSize)], converter, queryOptions);
    return [queryRef, path, options];
}

// Force little page size to simplify finding errors when fetching next page in dev env.
const DEFAULT_PAGE_SIZE = process.env.NODE_ENV === 'production' ? 12 : 4;

export function useFirebaseInfiniteQuery(firebasePath, queryConstraints, {converter = null, pageSize = DEFAULT_PAGE_SIZE, firestoreOptions = {idField: 'id'}, queryOptions = {}}) {
    const {keyParts = {}} = firestoreOptions ?? {};
    const [queryRef, path, options] = _getInifiniteCollectionRef(firebasePath, queryConstraints, converter, pageSize, queryOptions, keyParts);
    const nextPageCallback = useCallback((snapshot) => _.size(snapshot.docs) === pageSize ? query(queryRef, startAfter(_.last(snapshot.docs))) : undefined, [queryRef, pageSize]);
    const {data, hasNextPage, fetchNextPage, isFetching} = useFirestoreInfiniteQueryBugfix(path, queryRef, nextPageCallback, firestoreOptions, options);
    return [data?.pages ?? [], hasNextPage, fetchNextPage, isFetching];
}

export function useFirebaseInfiniteQueryData(firebasePath, queryConstraints, {converter = null, pageSize = DEFAULT_PAGE_SIZE, firestoreOptions = {idField: 'id'}, queryOptions = {}} = {}) {
    const {keyParts = {}} = firestoreOptions ?? {};
    const [queryRef, path, options] = _getInifiniteCollectionRef(firebasePath, queryConstraints, converter, pageSize, queryOptions, keyParts);
    const nextPageCallback = useCallback((lastPage) => {
        return lastPage?._lastSnapshot && _.size(lastPage) === pageSize ? query(queryRef, startAfter(lastPage._lastSnapshot)) : undefined;
    }, [queryRef, pageSize]);
    const {data, hasNextPage, fetchNextPage, isFetching} = useFirestoreInfiniteQueryDataBugfix(path, queryRef, nextPageCallback, firestoreOptions, options);
    return [data?.pages ?? [], hasNextPage, fetchNextPage, isFetching];
}

function queryKeyPath(query) {
    return _.reject(query?.queryKey, _.isObject);
}

function useInvalidationCallback(firebasePath, mutationOptions, invalidationPredicate) {
    const queryClient = useQueryClient(),
        {onSuccess} = mutationOptions ?? {};
    const invalidateOnSuccess = useCallback(async (data_, variables, context) => {
        const docKey = _stringifyPath(firebasePath),
            collectionKey = _.initial(docKey);
        // Default predicate invalidates the mutated element and containing collection.
        const defaultPredicate = (query) => {
            const cleaned = queryKeyPath(query);
            const {isCollectionGroup} = _.findLast(query?.queryKey, _.isObject) ?? {};
            return _.isEqual(cleaned, collectionKey) || _.isEqual(cleaned, docKey) || (isCollectionGroup && _.last(cleaned) === _.last(collectionKey));
        };
        // `predicate` can be called twice for each item: first by `queryClient.invalidateQueries`, then by inner refetchQueries.
        const predicate = invalidationPredicate ? ((query) => invalidationPredicate(query, defaultPredicate)) : defaultPredicate;
        await queryClient.invalidateQueries({predicate});
        if (onSuccess)
            return await onSuccess(data_, variables, context);
    }, [firebasePath, onSuccess, invalidationPredicate, queryClient]);

    return useMemo(() => ({...(mutationOptions ?? {}), onSuccess: invalidateOnSuccess}), [mutationOptions, invalidateOnSuccess]);
}

export function useFirebaseDocMutationInvalidate(firebasePath, converter, setOptions, mutationOptions, invalidationPredicate, createOptions) {
    const mutationOptions_ = useInvalidationCallback(firebasePath, mutationOptions, invalidationPredicate);
    return useFirebaseDocMutation(firebasePath, converter, setOptions, mutationOptions_, createOptions);
}

export function useFirebaseDocDeletionInvalidate(firebasePath, mutationOptions, invalidationPredicate) {
    const mutationOptions_ = useInvalidationCallback(firebasePath, mutationOptions, invalidationPredicate);
    return useFirebaseDocDeletion(firebasePath, mutationOptions_);
}

export function useUserData(uid, {require = true} = {}) {
    return useFirebaseDocData(['users', uid], userConverter, {idField: 'uid'}, {
        onError: (err) => {
            console.error('useUserData: Error accessing doc in Firebase', err);
            throw new Error('useUserData: Could not read user data. Is Firebase emulator on?');
        },
        onSuccess: (result) => {
            if (!result && uid) {
                if (require)
                    throw new Error(`useUserData: Could not find user data. Have firebase functions worked correctly on user creation? uid=${uid}`);
                console.error(`useUserData: Could not find user data. Have firebase functions worked correctly on user creation? uid=${uid}, not required`);
            }
        }
    });
}

export function useCurrentUserMutation(setOptions, mutationOptions, invalidationPredictate, createOptions = {create: false}) {
    const {user} = useContext(FirebaseAuth);
    return useFirebaseDocMutationInvalidate(['users', user?.uid], userConverter, setOptions, mutationOptions, invalidationPredictate, createOptions);
}

export function useCurrentUserData(createOptions = {create: false, require: null}) {
    const auth = useContext(FirebaseAuth),
        uid = auth?.user?.uid;
    const query = useUserData(uid, {require: (createOptions.require ?? !createOptions.create)}),
        {data: userData} = query;
    const mutation = useCurrentUserMutation(null, null, null, createOptions),
        {mutate: setUserDoc} = mutation;

    return [userData, setUserDoc, auth, query, mutation];
}

export async function uploadImage(imageBlob, path, contentType = 'image/png') {
    const arrayBuffer = await imageBlob.arrayBuffer(),
        thumbnailImage = new Uint8Array(arrayBuffer);

    const imgRef = storageRef(storage, path);
    if (process.env.REACT_APP_EMULATOR_STORAGE) {
        // Local emulator has a bug that object overwrite silently does not work. Workaround by deleting before.
        try {
            await deleteObject(imgRef);
        } catch (ex) {
        }
    }
    await uploadBytes(imgRef, thumbnailImage, {contentType});
    const downloadUrl = await getDownloadURL(imgRef);
    return {bucket: imgRef.bucket, fullPath: imgRef.fullPath, downloadUrl};
}

function geohashQueryBoundsForBBox(bbox) {
    if (_.isEmpty(bbox)) return null;
    const trackCenter = getCenter(bbox),
        [[bottom, left], [top, right]] = bbox,
        radius = _.max([(top - bottom) / 2, (right - left) / 2]);
    return geohashQueryBounds(trackCenter, radius);
}

export function useCollectionRef(firebasePath, queryConstraints, converter, queryOptions, keyParts = {}) {
    const ret = useMemo(
        () => _getCollectionRef(firebasePath, queryConstraints, converter, queryOptions, keyParts),
        [firebasePath, queryConstraints, converter, queryOptions, keyParts]
    );
    return ret;
}

export function useFirebaseGeohashQuery(firebasePath, bbox, geohashField) {
    // We can't just generate useFirebaseQuery hooks because number of queries is variable, determined by size of `bounds` list.
    // See also: https://firebase.google.com/docs/firestore/solutions/geoqueries
    const bounds = useMemo(() => geohashQueryBoundsForBBox(bbox), [bbox]),
        [queryRef, path] = useCollectionRef(firebasePath, null, null, null, {type: 'geohash', bounds}),
        enabled = isQueryEnabled(path);

    const fetchGeohashes = useCallback(async function _fetchGeohashes(context) {
        const promises = _.map(bounds, async ([start, end]) => {
            const ref = query(queryRef, orderBy(geohashField), startAt(start), endAt(end));
            const snap = await getDocs(ref);
            return snap.docs;
        });
        // Might contain a few false positives outside the box, but they do not harm.
        const results = await Promise.all(promises);
        const pois = _.flatten(results);
        return pois;
    }, [queryRef, geohashField, bounds]);

    const result = useQuery(path, fetchGeohashes, {enabled, staleTime: Infinity, cacheTime: Infinity});
    return result;
}

export function useCurrentUserProp(pref, dflt) {
    const [userData, setUserData] = useCurrentUserData(),
        [anonPrefValue, setAnonPrefValue] = useLocalStorage(pref, dflt),
        prefValue = useMemo(() => userData?.[pref] ?? anonPrefValue ?? dflt, [anonPrefValue, dflt, pref, userData]),
        setPrefValue = useCallback((prefValue) => {
            setAnonPrefValue(prefValue);
            setUserData({[pref]: prefValue}, {merge: true});
        }, [setAnonPrefValue, setUserData]);

    return [prefValue, setPrefValue];
}

