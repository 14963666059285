import classNames from 'classnames';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {Badge, Col, Modal, Row, Spinner} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {FaEnvelope, FaFileExport} from 'react-icons/fa';
import {RiGpsLine} from 'react-icons/ri';
import {TbPlug, TbPlugOff} from 'react-icons/tb';
import {useBoolean} from 'usehooks-ts';
import connectWithGarmin from '../images/connect_logo_white.png';
import {FirebaseAuth} from '../lib/firebase';
import {ga} from '../lib/ga';
import {exportGPX, visiblePOIsQueryParams} from '../lib/routing';
import {showSuccess} from '../lib/toasts';
import {useGarminAccount, useSendToGarmin} from '../lib/useSendToGarmin';
import {flagSetter, useBackendAPIMutation, useDeviceType} from '../lib/util';
import {ActionButton, SpinningButton} from './ActionButton';
import {AuthButton} from './auth/AuthButton';
import {GarminOAuthButton, OAuthActivitiesMap} from './auth/OAuth';
import {TrackPOIsSelector} from './POIMarkersToggleButtonGroup';


export function ExportGPXButton({exportPOIs, routeId, onSuccess}) {
    const {t} = useTranslation();

    async function onExportGPXButtonClick() {
        await exportGPX(routeId, exportPOIs);
        onSuccess?.();
    }

    return <SpinningButton icon={FaFileExport} text={t("Get GPX track")}
                           tooltip={t("Download GPX track file. You can open it with our the companion app of your GPS bike computer.")}
                           onClick={onExportGPXButtonClick} tiny={false}/>;
}

export function ExportGPXButtonWithModal({exportPOIs, routeId, ...props}) {
    const {t} = useTranslation(),
        {value: show, setTrue: showExportGPXModal, setFalse: hideExportGPXModal} = useBoolean(false);

    return <>
        <ActionButton icon={FaFileExport} routeId={routeId} exportPOIs={exportPOIs} onClick={showExportGPXModal} {...props}
                      text={t("Get GPX track")} tooltip={t("Having Wahoo, Karoo or other bike computer? Download GPX file and start riding!")}/>
        <ExportGPXModal exportPOIs={exportPOIs} routeId={routeId} show={show} onHide={hideExportGPXModal}/>
    </>;
}

export function ExportToDeviceButtonWithModal({routeId, tiny, exportPOIs, ...props}) {
    const {value: show, setTrue: doShow, setValue: toggleShow} = useBoolean(false),
        {t} = useTranslation();

    return <>
        <ActionButton icon={FaFileExport} text={t("Export to device")} tooltip={t("Having a GPS bike computer? Export your route and start riding!")}
                      onClick={doShow} tiny={tiny} {...props}/>

        <ExportToDeviceModal show={show} toggleShow={toggleShow} routeId={routeId} exportPOIs={exportPOIs}/>
    </>;
}

export function ExportGPXModal({onHide, exportPOIs, show, routeId}) {
    const [selectedPOIs, setSelectedPOIs] = useState([]);

    return <Modal size="lg" show={show} onHide={onHide} centered animation={false}>
        <Modal.Header closeButton>
            <Modal.Title><Trans>Get GPX File for your Tracking Device</Trans></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <TrackPOIsSelector className="m-2 mx-auto" direction="vertical" gap={1} show={show}
                               exportPOIs={exportPOIs} setSelectedPOIs={setSelectedPOIs}/>
        </Modal.Body>
        <Modal.Footer>
            <ExportGPXButton exportPOIs={selectedPOIs} routeId={routeId} onSuccess={onHide} className="my-2"/>
        </Modal.Footer>
    </Modal>;
}

export function ExportToDeviceModal({exportPOIs, show, toggleShow, routeId}) {
    const {user} = useContext(FirebaseAuth),
        [selectedPOIs, setSelectedPOIs] = useState([]),
        // This dialog can be displayed on mobile on from-inspiration path (saveAndSendGPX). Skip irrelevant "email me" and make elements smaller.
        {isMobile} = useDeviceType(),
        yourEmail = user?.email;

    // We want to re-open the dialog on the "Garmin Connect" tab after user goes through off-page auth process and comes back to this page.
    const exportToGarminCallback = useCallback(() => toggleShow(true), [toggleShow]),
        activitiesMap = useMemo(() =>
            ({exportToGarmin: exportToGarminCallback}), [exportToGarminCallback]);

    return <>
        <OAuthActivitiesMap activitiesMap={activitiesMap}/>
        <Modal size="lg" show={show} onHide={flagSetter(toggleShow, false)} centered animation={false}>
            <Modal.Header closeButton>
                <Modal.Title as={isMobile? "h6" : "h4"}><Trans>Export to your GPS tracking device</Trans></Modal.Title>
            </Modal.Header>
            <Modal.Body className={classNames({smaller: isMobile})}>
                <Row className="align-items-center">
                    <ExportToGarminRow routeId={routeId} activityName="exportToGarmin" exportPOIs={selectedPOIs} tiny={isMobile}/>
                </Row>
                {!isMobile && <>
                    <hr/>
                    <Row className="align-items-center">
                        <Col xs={4} className="text-center">
                            <SendEmailWithGPXButton routeId={routeId} exportPOIs={selectedPOIs}/>
                            {yourEmail &&
                                <div><small className="xx-small"><Trans>Send to: {{yourEmail}}</Trans></small></div>}
                        </Col>
                        <Col>
                            <h6><Trans>Email to yourself</Trans></h6>
                            <div><Trans>Open the e-mail on your mobile. Download the GPX file to the companion app of your Wahoo, Hammerhead, Lezyne or Bryton.</Trans></div>
                        </Col>
                    </Row>
                </>}
                <hr/>
                <Row className="align-items-center">
                    <Col xs={4} className="text-center">
                        <ExportGPXButton routeId={routeId} exportPOIs={selectedPOIs}/>
                    </Col>
                    <Col>
                        <h6><Trans>Download GPX track</Trans></h6>
                        <div><Trans>Grab it now and push wherever you want</Trans></div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={isMobile ? "p-1" : "p-3"}>
                <TrackPOIsSelector className="mx-auto" direction={isMobile ? "vertical": "horizontal"} gap={isMobile? 1 : 3} show={show}
                                   exportPOIs={exportPOIs} setSelectedPOIs={setSelectedPOIs} />
            </Modal.Footer>
        </Modal>
    </>;
}

function GarminConnectedBadge({hasGarmin, className}) {
    return hasGarmin ?
        <Badge className={className} bg="success"><TbPlug/>&nbsp;<Trans>Connected</Trans></Badge> :
        <Badge className={className} bg="warning"><TbPlugOff/>&nbsp;<Trans>Not connected yet</Trans></Badge>;
}

function ExportToGarminRow({activityName, exportPOIs, onSuccess, routeId, tiny}) {
    const [hasGarmin] = useGarminAccount();
    return <>
        <Col xs={4} className="text-center">
            {hasGarmin ?
                <ConnectWithGarminButton exportPOIs={exportPOIs} routeId={routeId} onSuccess={onSuccess} className="my-2" tiny={tiny} /> :
                <GarminOAuthButton activityName={activityName} tiny={tiny}/>}
        </Col>
        <Col>
            <h6><Trans>Directly to your Garmin</Trans></h6>
            <div>
                {hasGarmin ?
                    <Trans>Tap the button to push to Garmin Connect.</Trans> :
                    <Trans>Connect to your Garmin account</Trans>}
            </div>
            <GarminConnectedBadge hasGarmin={hasGarmin}/>
        </Col>
    </>;
}

export function SendEmailWithGPXButton({exportPOIs, routeId, tiny, ...props}) {
    const {t} = useTranslation();

    const params = useMemo(() => visiblePOIsQueryParams(exportPOIs), [exportPOIs]),
        {isLoading: isEmailSending, mutate: launchOnClickMutation} = useBackendAPIMutation(`/api/routes/send-gpx/${routeId}`, params, null, {
            onSuccess: () => showSuccess(t("GPX track has been sent to your email."), {},
                <Trans>Launch your mobile email app and open the GPX attachment with our GPS device companion app.</Trans>)
        });

    const onClick = useCallback(() => {
        launchOnClickMutation();
        ga('route_export', {'method': 'email', 'content_type': 'gpx', 'item_id': routeId});
    }, [routeId, launchOnClickMutation]);

    return <AuthButton as={ActionButton} icon={FaEnvelope} text={t("Email me GPX")} activityName="SendEmailWithGPXButton"
                       tooltip={t("We will send you an e-mail with the GPX route. You can open it on your mobile phone and push to your GPS bike computer.")}
                       onClick={onClick} tiny={tiny} {...props}>
        {isEmailSending && <Spinner as="span" size="sm" className="ms-2 align-middle" animation="border" role="status" aria-hidden="true"/>}
    </AuthButton>;
}

function ConnectWithGarminButton({exportPOIs, routeId, tiny, onClick, onSuccess, className}) {
    const {t} = useTranslation(),
        sendToGarmin = useSendToGarmin({exportPOIs, routeId, onSuccess});

    const text = tiny ?
        t("Send to Garmin") :
        <img style={{maxWidth: '160px'}} alt={t("Send to Garmin")} src={connectWithGarmin}/>;
    return <SpinningButton text={text} tooltip={t("The route will be synced directly to your connected Garmin devices.")}
                           tooltipHeading={tiny ? null : t("Send to Garmin")} className={className}
                           icon={tiny && RiGpsLine} onClick={onClick ?? (async () => await sendToGarmin())} tiny={false}/>;
}

export function ExportToGarminButtonWithModal({exportPOIs, routeId, tiny}) {
    const {value: show, setTrue: showGarminExportModal, setFalse: hideGarminExportModal} = useBoolean(false),
        [hasGarmin] = useGarminAccount(),
        [selectedPOIs, setSelectedPOIs] = useState([]),
        activitiesMap = useMemo(() => ({exportToGarmin: showGarminExportModal}), [showGarminExportModal]);

    return <>
        <ConnectWithGarminButton routeId={routeId} exportPOIs={exportPOIs} tiny={tiny} onClick={showGarminExportModal}/>
        <OAuthActivitiesMap activitiesMap={activitiesMap}/>

        <Modal size="lg" show={show} onHide={hideGarminExportModal} centered animation={false}>
            <Modal.Header closeButton>
                <Modal.Title><Trans>Export to your Garmin device</Trans></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GarminConnectedBadge hasGarmin={hasGarmin} className="mb-2"/>
                {hasGarmin ?
                    <>
                        <TrackPOIsSelector className="m-2 mb-3 mx-auto" direction="vertical" gap={1}
                                           exportPOIs={exportPOIs} setSelectedPOIs={setSelectedPOIs} show={show}/>
                        <small><Trans>Tap the button to push the track to Garmin Connect:</Trans></small>
                    </> :
                    <p><Trans>You need to connect your Garmin account to Tarmacs.<br/>
                        Then can push your routes to Garmin with a single click.</Trans></p>}
            </Modal.Body>
            <Modal.Footer>
                {hasGarmin ?
                    <ConnectWithGarminButton exportPOIs={selectedPOIs} routeId={routeId} onSuccess={hideGarminExportModal} className="my-2"/> :
                    <GarminOAuthButton activityName="exportToGarmin"/>
                }
            </Modal.Footer>
        </Modal>
    </>;
}