import _ from 'lodash';
import {toLngLatArray} from './getClosestPointAndSegment';
import {coordinatesToDistanceMarks, unpackSegments} from './routing';
import {getSteepnessAttrs} from './steepness';


export function getElevationProfile(coordinates, elevation, elevationViewerSegment = null, pois, steepnessSegments) {
    const distanceMarks = coordinatesToDistanceMarks(toLngLatArray(coordinates)),
        steepness = [{grade: 0}, ..._.initial(unpackSegments(steepnessSegments))],
        annotatedData = _.zip(coordinates, distanceMarks, elevation, steepness),
        [rangeStart, rangeEnd] = elevationViewerSegment ?? [0, _.size(annotatedData)],
        dataRange = _.slice(annotatedData, rangeStart, rangeEnd),
        reduceFactor = _.round(_.max([_.size(dataRange) / 1000, 1])),
        poisByIdx = _.keyBy(pois, ({position}) => Math.floor((position.trackPointIdx - rangeStart) / reduceFactor)),
        resampledData = _.filter(dataRange, (point, idx) => !(idx % reduceFactor)),
        data = _.map(resampledData, ([coordinate, distance, elevation, steepness]) => ({
            coordinate, elevation, distance, steepness: steepness?.grade,
            gradeColor: (getSteepnessAttrs(steepness?.grade).chartColor ?? 'green')
        }));
    return {data, poisByIdx};

}