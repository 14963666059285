import {getCoord} from '@turf/turf';
import _ from 'lodash';
import {getClosestPointAndSegment, toLatLng} from './getClosestPointAndSegment';


/**
 * Get positions on track for provided POIs.
 *
 * @param pois - array of POIs with feature property, to be enriched with position property
 * @param coordinates - track coordinates
 * @param routePoints - route points on track (to identify leg/segment index).
 * @param getCachedPosition - function accepting a feature and returning cached position on track
 * @returns {(*&{position: {segment, point, trackPointIdx, routePointIdx, distance}})[]}
 */
export function getPOIsPositions(pois, coordinates, routePoints, getCachedPosition) {
    const poisPositions = _.map(pois, (featurePOI) => {
        const position = getCachedPosition(featurePOI) ?? getClosestPointAndSegment({coordinates, routePoints}, toLatLng(getCoord(featurePOI.feature)));
        return {...featurePOI, position};
    });

    // Shit happens that position is null, crashing ElevationViewer
    return _.filter(poisPositions, ({position}) => _.isObject(position));
}

