import {Formik} from 'formik';
import _ from 'lodash';
import React, {useContext} from 'react';
import {Button, Col, FloatingLabel, Form, Modal, Row} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {MdOutlinePassword} from 'react-icons/md';
import {useBoolean} from 'usehooks-ts';
import * as yup from 'yup';
import {FirebaseAuth} from '../../lib/firebase';
import {updatePassword} from 'firebase/auth';
import {showError, showSuccess} from '../../lib/toasts';
import {PasswordInput} from './PasswordInput';


export function ChangePassword({...props}) {
    const {t} = useTranslation(),
        {user} = useContext(FirebaseAuth),
        {value: show, setTrue: doShow, setFalse: doHide} = useBoolean(),
        msg8 = t("Password must have at least 8 characters");

    const validationSchema = yup.object().shape({
        password: yup.string().required(msg8).min(8, msg8)
    });

    async function setPassword({password}) {
        try {
            await updatePassword(user, password);
            showSuccess(t("Your password has been changed."), null, <Trans>Update it in your browser or password manager.</Trans>);
            doHide();
        } catch (ex) {
            if (ex.code === 'auth/requires-recent-login') {
                // Display specific instructions for 'credential-too-old' error
                showError(ex, {
                    message: t("You logged in too long time ago. Relogin and come back again."),
                    hint: t("Please sign out and sign in again first. Use magic link if you forgot the old password."),
                    heading: t("Changing the password requires recent sign in.")
                });
            } else {
                // For all other errors, display the error message
                showError(ex, {heading: t("Cloud error: could not change the password.")});
            }
        }
    }

    return <>
        <Button onClick={doShow} {...props}><MdOutlinePassword/> <Trans>Change Password</Trans></Button>
        <Modal size="lg" show={show} onHide={doHide} centered animation={false}>
            <Formik initialValues={{password: "", confirmPassword: ""}} onSubmit={setPassword} validationSchema={validationSchema}>{
                ({handleSubmit, handleChange, values, errors}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title><Trans>Change Password</Trans></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PasswordInput autoComplete="new-password" name="password" value={values.password} onChange={handleChange}
                                           invalidPasswordFeedback={t("Please type a new password, 8 characters at least.")}
                                           isInvalid={!!errors.password}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" size="lg" className="me-0" type="submit"><Trans>Set Password</Trans></Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    </>;

}
