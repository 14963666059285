import {Children, cloneElement} from 'react';
import {Form, OverlayTrigger, Popover, Toast} from 'react-bootstrap';
import {isMobile} from 'react-device-detect';
import {toast} from 'react-hot-toast';
import {Trans, useTranslation} from 'react-i18next';
import {FaCloud, FaLightbulb, FaMapSigns, FaTools} from 'react-icons/fa';
import {errorHandler} from './errors';

const toStringToast = function() {
    return `${this.toStringOriginal?.() ?? this.message} (toasts.showError)`;
}

export function showError(error, options, children) {
    const {duration = 5000, message} = options ?? {};
    const errorMsg = error.message;
    try {
        if (error.toString && !error.toStringOriginal) {
            error.toStringOriginal = error.toString;
            error.toString = toStringToast;
        }
        errorHandler.report(error);
    } catch (e) {
        console.error('Exception when reporting error', error, e);
    }
    toast.custom((t) => {
        return <ErrorToastTemplate show={t.visible} error={error} message={message ?? errorMsg} onClose={() => toast.dismiss(t.id)} {...options} >
            {children}
        </ErrorToastTemplate>;
    }, {duration});
}

export function showSuccess(heading, options, children) {
    toast.custom((t) => (
        <ToastTemplate show={t.visible} heading={heading} onClose={() => toast.dismiss(t.id)} {...options} >
            {children}
        </ToastTemplate>)
    );
}

export function showRoutingError(error) {
    return showError(error, {
        icon: <FaMapSigns/>,
        heading: <Trans>Routing service error</Trans>,
        hint: <Trans>Try to drag the point to other position or delete the point.</Trans>
    });
}

function ErrorToastTemplate({
    error,
    show, onClose,
    icon = <FaCloud/>,
    heading,
    hint,
    message,
    className = "bg-danger text-light",
    children
}) {
    const {t} = useTranslation(),
        details = t("Technical details: {{message}}", {message: message ?? error?.message});
    return <ToastTemplate show={show} {...{className, heading, icon, onClose}} heading={heading ?? t("Cloud service error")}>
        <p><FaLightbulb/><span className="ms-1">{
            hint ?? t("Please try again in a while.")
        }</span></p>
        <small><FaTools/><span className="ms-1">{details}</span></small>
        {children}
    </ToastTemplate>;
}

function ToastTemplate({
    show, onClose,
    heading, children,
    icon = <FaCloud/>,
    className = ""
}) {
    // Animations, show and autohide are handled by outer react-hot-toast.
    return <Toast show={show} onClose={onClose} animation={true}>
        <Toast.Header className={className}>
            {icon}&nbsp;
            <strong className="me-auto ms-1">{heading}</strong>
        </Toast.Header>
        <Toast.Body>{children}</Toast.Body>
    </Toast>;
}

// Workaround for error-level warning about missing mobile hover for dev env.
const HOVER_TRIGGER = process.env.REACT_APP_HOVER_EVENT || ['hover', 'hover'];

export function PopoverTrigger({heading, headingClassName, tooltip, children, trigger = HOVER_TRIGGER, placement = 'auto', ...props}) { // [hover,hover] disables warning
    const popover = <Popover style={{width: '25rem'}}>
        <Popover.Header as="h3"><span>{heading}</span></Popover.Header>
        <Popover.Body><small>{tooltip}</small></Popover.Body>
    </Popover>;

    return <OverlayTrigger trigger={isMobile ? [] : trigger} placement={placement} overlay={popover} defaultShow={false} {...props}>
        {({ref, ...triggerHandler}) => {
            try {
                const elem = Children.only(children);
                return cloneElement(elem, {ref, ...triggerHandler});
            } catch (error) {
                console.error("Single component accepted. Ensure there are no spaces/newlines between PopoverTrigger and the component provided.");
                return children;
            }
        }}
    </OverlayTrigger>;
}

export function FormCheckWithPopover({label, children, control: Control = Form.Check, ...props}) {
    return <PopoverTrigger heading={label} tooltip={children}>
        <span><Control label={label} {...props}/></span>
    </PopoverTrigger>;
}