import polyline from '@mapbox/polyline';
import {distance, getCoord, length, lineString, point} from '@turf/turf';
import ContentDisposition from 'content-disposition-attachment';
import {saveAs} from 'file-saver';
import _ from 'lodash';
import {errorHandler} from './errors';
import {nearestPointOnLine} from './getClosestPointAndSegment';
import {packSegments} from './util';


async function handleRoutingServiceError(resp) {
    async function getTextResponse() {
        try {
            return await resp.text();
        } catch (ex) {
            return `${ex}`;
        }
    }

    try {
        const resp2 = resp.clone();
        const err = await resp2.json(),
            detail = err?.error || err?.detail,
            status = err.error_code ? `${err.error_code} - HTTP ${resp2.status}` : `HTTP ${resp2.status}`;
        return detail ? `${detail} (${status})` : JSON.stringify(err);
    } catch (ex) {
        const body = await getTextResponse();
        return `${ex}: ${body}`;
    }
}

async function fetchRoutingService(url, options) {
    const input = options.body ?? JSON.stringify(options.json);
    let resp;
    try {
        resp = await fetch(url, options);
        if (resp.ok) return resp;
    } catch (ex) {
        const connErr = `Error connecting to route service: "${ex}".`;
        await errorHandler.report(`${ex} ${url}. Input:\n${input}`);
        throw new Error(connErr);
    }
    const err = await handleRoutingServiceError(resp);
    await errorHandler.report(`${err} ${url} from route service. Input:\n${input}`);
    throw new Error(err);
}

// // Penalties for specific surfaces. Added to cost factor, impact might be steered by avoid_bad_roads
// const std::unordered_map<BicycleType, std::vector<float>> kSurfacePenalties = {
//   // Road bikes mostly like kTarmac > kTarmacRough > kPaved > kPavedRough, sometimes kCompacted
//   {BicycleType::kRoad, {0.0f, 0.1f, 0.5f, 1.0f, 4.5f, 7.0f, 7.0f}},
//   // Gravel bikes mostly like kPaved and kCompacted, then kPavedRough and kGravel.
//   // Strict gravel preference (high avoid_bad_roads) should avoid tarmacs.
//   {BicycleType::kCross, {1.0f, 1.0f, 0.0f, 0.2f, 0.0f, 0.2f, 2.0f}},
//   // Trekking bikers/back-packers usually prefer good surfaces when possible, but are less sensitive than roadies.
//   {BicycleType::kHybrid, {0.0f, 0.05f, 0.0f, 0.05f, 1.0f, 2.5f, 4.5f}},
//   // mtbees are not happy on tarmacs, especially the major/smooth ones.
//   {BicycleType::kMountain, {2.5f, 1.0f, 1.0f, 1.0f, 0.0f, 0.0f, 0.0f, 0.0f}}
// };

const BIKE_PENALTIES = {
    'road': [
        [0, [0.0, 0.5, 0.5, 1.0, 4.5, 7.0, 7.0]],
        [0.65, [0.0, 0.5, 0.5, 2.0, 4.5, 10.0, 10.0]],
        [0.9, [0.0, 0.5, 1.0, 4.0, 4.5, 16.0, 16.0]],
        [0.98, [0.0, 4.0, 4.0, 4.0, 12.0, 16.0, 16.0]]],
    'gravel': [
        [0, [1.0, 0.5, 0.0, 0.2, 0.0, 0.2, 1.0]],
        [0.65, [1.0, 0.5, 0.0, 0.2, 0.0, 0.2, 4.0]],
        [0.9, [2.5, 2.5, 0.2, 0.2, 0.0, 0.2, 7.0]],
        [1.0, [2.5, 2.5, 0.5, 1.0, 0.0, 0.2, 7.0]]
    ]
};

// Keep in sync with gpx.py
export const VALHALLA_PROFILES = {
    'road': {
        bicycle_type: 'Road',
        use_hills: 0.5,
        use_roads: 0.0,
        use_living_streets: 1.0,
        avoid_bad_surfaces: 0.95,
        maneuver_penalty: 3,  // 12 hours (43200) is the max allowed.
        cycling_speed: 30,
        // Hey, we are in Shengen
        country_crossing_penalty: 0,
        country_crossing_cost: 0,
        surface_penalties: [0.0, 0.2, 1.0, 2.0, 4.5, 7.0, 7.0]
    },
    'gravel': {
        bicycle_type: 'Cross',
        use_hills: 0.9,
        use_roads: 0.6,
        use_living_streets: 0.6,
        avoid_bad_surfaces: 0.95,
        cycling_speed: 25,
        // Hey, we are in Shengen
        country_crossing_penalty: 0,
        country_crossing_cost: 0,
        surface_penalties: [2.5, 2.5, 0.0, 0.0, 0.0, 0.2, 2.0]
    },
    'mtb': {
        bicycle_type: 'Mountain',
        use_hills: 1.0,
        use_roads: 0.3,
        use_living_streets: 0.5,
        avoid_bad_surfaces: 0.3,
        cycling_speed: 20,
        // Hey, we are in Shengen
        country_crossing_penalty: 0,
        country_crossing_cost: 0,
        surface_penalties: [2.5, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0, 0.0]
    }
};

export const VALHALLA_PRECISION = 6;  // https://valhalla.readthedocs.io/en/latest/decoding/

export function getSurfacePenalties(bikeKind, suitability) {
    const profile = VALHALLA_PROFILES[bikeKind] ?? VALHALLA_PROFILES['road'],
        basePenalties = BIKE_PENALTIES[bikeKind] ?? [[0.0, profile.surface_penalties]],
        [, penalties] = _.findLast(basePenalties, ([s]) => (suitability >= s));

    return _.map(penalties, (penalty) => penalty * (1 + suitability));
}

// For purpose of calculation of total ascent and descent we used to resample the height data.
// Otherwise the totals would be overshoot even twice as compared to other routers.
// This is not necessary anymore after `smoothElevationProfile()` was introduced, so the thresholds are set to 0.
// See also: https://github.com/valhalla/valhalla/issues/3249
export function calculateAscentDescent(ranges, elevation) {
    const VERTICAL_THRESHOLD = 0.0,
        HORIZONTAL_THRESHOLD = 0.0;

    // Trackpoint distance threshold.
    // See also: https://www.gpsvisualizer.com/tutorials/track_filters.html
    // Linear interpolation (e.g 20m) is tempting but it would take 10s to calculate.
    const {result: resampledPairs} = _.transform(_.zip(ranges, elevation), (accumulator, [range, height]) => {
        const {result, anchor} = accumulator;
        if (range - anchor >= HORIZONTAL_THRESHOLD) {
            result.push([range, height]);
            accumulator.anchor = range;
        }
    }, {result: [], anchor: -HORIZONTAL_THRESHOLD});
    const [, resampled] = _.unzip(resampledPairs);

    // Apply trackpoint elevation threshold.
    // See also: https://www.gpsvisualizer.com/tutorials/elevation_gain.html
    const [ascent, descent] = _.reduce(resampled, ([ascent, descent, anchor], height) =>
            (Math.abs(height - anchor) > VERTICAL_THRESHOLD ?
                [ascent + (height > anchor ? (height - anchor) : 0), descent + (height < anchor ? (anchor - height) : 0), height] :
                [ascent, descent, anchor])
        , [0.0, 0.0, _.first(elevation)]);

    return {ascent, descent};
}

export function flattenLegs(legs) {
    // Merge coordinates of all legs, removing dupe points on ends/starts.
    const
        [decodedLegs, legLengths] = _.chain(legs)
            // Remove dupe 1st point (except initial leg).
            .map((coords, idx) => idx ? _.tail(coords) : coords)
            .map((coords) => [coords, _.size(coords)])
            .unzip().value();
    const
        values = _.flatten(decodedLegs),
        // Note indices of points which are the route points (ends/starts of legs).
        routePoints = [0, ..._(legLengths).transform((accumulator, legLength) => {
            const prev = _.last(accumulator) || 0;
            accumulator.push(prev + legLength);
        }, []).map((len) => len - 1).value()];
    return {values, routePoints};
}

export function getBBox(coordinates) {
    if (_.isEmpty(coordinates))
        return null;
    const bottomLeft = [_.minBy(coordinates, '[0]')[0], _.minBy(coordinates, '[1]')[1]],
        topRight = [_.maxBy(coordinates, '[0]')[0], _.maxBy(coordinates, '[1]')[1]];
    return [bottomLeft, topRight];
}


const ROUTE_POINTS_MATCH_DISTANCE_THRESHOLD_KM = 2,
    ROUTE_POINTS_MATCH_DISTANCE_THRESHOLD_PCT = 500;
/**
 * Re-match route points to nearest point on the track generated by `/trace_attributes`
 * The track might differ from original track from `/route` so need to employ turf instead of using point indices returned by /route.
 */
function matchRoutePoints(route, coordinates) {
    if (_.isEmpty(route)) return [];

    function finder(accumulator, pt, ptIdx) {
        const routeIdx = ptIdx + 1; // Jump over count the first point which is always on coordinates[0].
        if (routeIdx === _.size(route) - 1) { // Last route point is always on last coordinate - no need to search it.
            accumulator.indices.push(_.size(coordinates) - 1);
            return;
        }
        if (_.size(accumulator.coordinates) === 1) {
            errorHandler.report(`matchRoutePoints: single coordinate left at route point ${routeIdx} of ${_.size(route)}: 
                route=${JSON.stringify(route)} coords=${JSON.stringify(coordinates)}
                result=${JSON.stringify(accumulator)}`);
            accumulator.indices.push(_.size(coordinates) - 1);
            return;
        }
        if (_.isEmpty(accumulator.coordinates)) {
            errorHandler.report(`matchRoutePoints: exceeded coordinates at route point ${routeIdx} of ${_.size(route)}: 
                route=${JSON.stringify(route)} coords=${JSON.stringify(coordinates)}
                result=${JSON.stringify(accumulator)}`);
            // This is best we can do except for hard failing. Most of the time this will still work.
            accumulator.indices.push(_.size(coordinates) - 1);
            return;
        }

        /**
         * Returns index on lineCoords track of the nearest point matching `pt`.
         *
         * Provides a protection against loops, where turf.nearestPointOnLine might find a coordinate in next pass of the loop:
         * if the distance measured along the track is too far from the straight distance between the points, search again in the first half of the track.
         */
        function findNearestPoint(lineCoords, startCoord) {
            const fragment = lineString(lineCoords),
                currPt = point(pt),
                {properties: {index}} = nearestPointOnLine(fragment, currPt);
            if (index === 0) // Can happen when next point clicked almost exactly the same place. lineString below would fail on a single point.
                return index;

            const prevIdx = startCoord - 1,
                prevPt = coordinates[prevIdx],
                legLine = lineString(_.slice(lineCoords, 0, index + 1)),
                distanceAlong = length(legLine),
                // Using prevPt, not routes[routeIdx] as a reference point to calculate distance along the leg.
                // This should help against a point clicked on middle of the lake or inaccessible mountain, matched to the nearest point on the shore.
                routePointsDistance = distance(prevPt, currPt),
                distanceDiff = distanceAlong - routePointsDistance;
            if (distanceDiff > ROUTE_POINTS_MATCH_DISTANCE_THRESHOLD_KM && distanceDiff / routePointsDistance > (ROUTE_POINTS_MATCH_DISTANCE_THRESHOLD_PCT / 100.0)) {
                // A loop or track overlap and 'nearest' point is found too far. Find something better in first half of the leg.
                console.warn('Loop or overlap', {index, prevIdx, routeIdx, prevPt, currPt, legLine, routePointsDistance, distanceAlong, distanceDiff});
                const half = _.slice(lineCoords, 0, _.toInteger((index / 2) + 1));
                return findNearestPoint(half, startCoord);
            }
            return index;
        }
        const index = findNearestPoint(accumulator.coordinates, accumulator.startCoord);
        accumulator.indices.push(accumulator.startCoord + index);
        accumulator.startCoord = accumulator.startCoord + index + 1;
        accumulator.coordinates = _.slice(accumulator.coordinates, index + 1);
    }

    // The first route point is always on coordinates[0]; no need to search it so _.tail(route) and pre-initiated accumulator.
    const {indices} = _.transform(_.tail(route), finder, {indices: [0], coordinates: _.slice(coordinates, 1), startCoord: 1});
    return indices;
}

export function coordinatesToDistanceMarks(lonLatArray) {
    const distanceMarks = _.transform(lonLatArray, (accumulator, coord, idx) => {
        if (idx) {
            const prev = lonLatArray[idx - 1],
                prevDistance = _.last(accumulator),
                distance = length(lineString([prev, coord]));
            accumulator.push(distance + prevDistance);
        }
    }, [0]);
    return distanceMarks;
}

/**
 * Group segments of the track with coordinates.
 * Will merge same-attribute segments before to help avoiding unpleasant breaks of dashed lines (see TrackSegment).
 * @returns array of arrays with [[coordinates], value, start idx, end idx] tuples
 *
 * @param coordinates - array of coordinates: [lat, lon]
 * @param segmentDefs - array of segment definitions [start idx, end idx, attribute value]
 */
export function coordinateSegments(coordinates, segmentDefs) {
    const mergedByVal = _.transform(segmentDefs, (merged, [start, end, val]) => {
        const last = _.last(merged) ?? {};
        if (_.isEqual(last.val, val))
            last.end = end;
        else
            merged.push({start, end, val});
    }, []);
    return _.map(mergedByVal, ({start, end, val}) => [_.slice(coordinates, start, end + 1), val, start, end]);
}

/**
 * Combine two lists of route segmentDefs, each containing information about other property, into one list of segments, split on every property change.
 *
 * For instance for the following arrays:
 * surfaces = [[0, 3, "tarmac"], [3, 5, "gravel"]]
 * traffic = [[0, 2, "high"], [2, 5, "low"]].
 * will create combined list with segments split for two or more properties, for instance:
 * [[0, 2, ["tarmac", "high"]], [2, 3, ["tarmac", "low"]], [3, 5, ["gravel", "low"]]]
 *
 * @param segmentDefs - Array of arrays: [[segmentStart, segmentEnd, property_value], ...].
 */
export function combineSegments(...segmentDefs) {
    if (_.isEmpty(segmentDefs) || _.isEmpty(_.first(segmentDefs)))
        return [];

    const [, routeEnd] = _.last(_.first(segmentDefs));

    function* generateValChanges() {
        const convertListsToMappings = (segDef, idx) => _.map(segDef, ([start, , val]) => ([start, {[idx]: val}]));
        const convertKeysToInts = ([segIdx, ...rest]) => ([_.toInteger(segIdx), ...rest]);
        const orderByIntIndex = (mapping) => _.sortBy(_.map(_.toPairs(mapping), convertKeysToInts), '0');

        const segmentDefsMappings = _.map(segmentDefs, convertListsToMappings),
            segmentDefsByStart = _.groupBy(_.flatten(segmentDefsMappings), '0');
        const changedPropsAggregatedBySegmentIdx = orderByIntIndex(segmentDefsByStart);

        let currentPropsMapping = {};
        for (const [start, changedPropsMappings] of changedPropsAggregatedBySegmentIdx) {
            _.assign(currentPropsMapping, ..._.map(changedPropsMappings, '1'));
            const props = _.map(orderByIntIndex(currentPropsMapping), '1');
            yield [start, props];
        }
    }

    const valChanges = [...generateValChanges()];

    return _.map(_.zip(valChanges, _.tail(valChanges)),
        ([[segStart, segVals], [segEnd] = [null]]) => ([segStart, (segEnd ?? routeEnd), segVals]));
}

/**
 * Unpack segments from [rangeStart, rangeEnd, value] into per-index flat array.
 *
 * For instance, steepness segments [[0, 4, 10], [4, 6, 2]] would be unpacked to flat array of steepness per index
 * [10, 10, 10, 10, 2, 2]
 */
export function unpackSegments(segmentDefs) {
    return _.flatten(_.map(segmentDefs, ([start, end, val]) => _.fill(new Array(end - start), val)));
}

export function getValhallaCostingProfile({bikeKind = 'road', suitability = 0.5, hills = 0.5, popularity = 0.5, mainRoads = 0.9, ridingSpeed = 30, roundtrip, avoidRidden, useFerries = true}) {
    const baseProfile = VALHALLA_PROFILES[bikeKind] ?? VALHALLA_PROFILES['road'],
        suitabilityUpdate = _.isNil(suitability) ? {} : {avoid_bad_surfaces: suitability},
        hillsUpdate = _.isNil(hills) ? {} : {use_hills: hills},
        popularityUpdate = _.isNil(popularity) ? {} : {use_popularity: popularity},
        mainRoadsUpdate = _.isNil(mainRoads) ? {} : {use_roads: mainRoads, use_living_streets: 1.0 - mainRoads / 5.0},
        ridingSpeedUpdate = _.isNil(ridingSpeed) ? {} : {cycling_speed: ridingSpeed},
        useFerriesUpdate = {use_ferry: useFerries ? 0.5 : 0.0},
        // Default is burnt in Valhalla (bicyclecost.cc): kDefaultAvoidAlreadyRidden = 0.75
        // Do not use it for non-roundtrip routes because this makes routes unstable:
        // the routing differs when the whole route is fetched or only a segment (in incremental mode).
        roundtripUpdate = _.isNil(roundtrip) ? {avoid_ridden: 0.0} : {roundtrip, avoid_ridden: avoidRidden, use_ferry: 0};

    const penalties = getSurfacePenalties(bikeKind, suitability),
        profile = {
            ...baseProfile, surface_penalties: penalties, ...suitabilityUpdate, ...hillsUpdate,
            ...popularityUpdate, ...mainRoadsUpdate, ...ridingSpeedUpdate, ...useFerriesUpdate, ...roundtripUpdate
        };
    return profile;
}

export async function queryValhallaTrack(route, breaks, options, url = `${process.env.REACT_APP_ROUTER_URL}/route`) {
    const profile = getValhallaCostingProfile(options);

    async function fetchTrack() {
        const requestData = {
            locations: _.map(_.zip(route, breaks), ([[lat, lon], isBreak], idx) => ({
                lat, lon, type: isBreak ? 'break' : 'via',
                node_snap_tolerance: 150, rank_candidates: false
            })),
            costing: 'bicycle',
            costing_options: {bicycle: profile},
            units: 'km',
            // 'none' would save some bytes transferred, but does not return info about ferries while we need it.
            directions_type: 'maneuvers'
        };

        const resp = await fetchRoutingService(url, {method: 'POST', json: requestData});

        const data = await resp.json(),
            {trip, thumbnail} = data ?? {},
            {legs, status, summary} = trip ?? {};

        if (!data || status !== 0) {
            throw new Error('Error in routing service response. Not setting the track.');
        }
        const decodedLegs = _.map(legs ?? [],
                ({shape}) => polyline.decode(shape, VALHALLA_PRECISION)),
            {values: coordinates} = flattenLegs(decodedLegs),

            trackShape = polyline.encode(coordinates, VALHALLA_PRECISION);
        return {bbox: getBBox(coordinates), trackShape, summary, thumbnail};
    }

    const {summary, bbox, trackShape, thumbnail} = await fetchTrack();
    return {profile, bbox, trackShape, summary, distance: summary?.length, thumbnail};
}

// Increment on every change in serialized tracks format.
// Older tracks will be re-fetched from routing service to conform the current format.
// Last change: 6 - store `ferries` (similar like tunnels) and `useFerries` flag.
// Keep in sync with handlers.inspirations.TRACK_VERSION
export const TRACK_VERSION = 6;

// Include a warning marker for these edge ends.
const GATE_EDGE_ENDS = ['gate', 'bollard', 'toll_booth', 'border_control'];

export async function queryValhallaTrackDetails(profile, route, trackShape) {
    /**
     * Fetch additional details of the route.
     */
    async function fetchTrack(trackShape) {
        const queryString = JSON.stringify({
            encoded_polyline: trackShape, shape_format: 'polyline6',
            // This shape_match mode avoids prolonging 1st and last edge beyond route start and end points.
            shape_match: 'map_snap',
            turn_penalty_factor: 0.5,
            search_radius: 10,
            costing: 'bicycle',
            costing_options: {bicycle: profile},
            // Exclude unnecessary attributes to save on payload
            filters: {
                action: 'exclude',
                attributes: [
                    'matched.point', 'matched.type', 'matched.edge_index', 'matched.begin_route_discontinuity', 'matched.end_route_discontinuity',
                    'matched.distance_along_edge', 'matched.distance_from_trace_point',
                    'edge.begin_heading', 'edge.end_heading', 'edge.density', 'edge.drive_on_right', 'edge.internal_intersection', 'edge.lane_count', 'edge.names',
                    'edge.travel_mode', 'edge.shoulder', 'edge.traversability', 'edge.speed_limit', 'edge.sac_scale', 'edge.bicycle_network', 'edge.bicycle_type', 'edge.id',
                    'edge.vehicle_type', 'edge.truck_route', 'edge.toll',
                    'node.intersecting_edge.begin_heading', 'node.intersecting_edge.from_edge_name_consistency', 'node.intersecting_edge.to_edge_name_consistency',
                    'node.intersecting_edge.driveability', 'node.intersecting_edge.cyclability', 'node.intersecting_edge.walkability',
                    'node.intersecting_edge.use', 'node.intersecting_edge.road_class', 'node.intersecting_edge.lane_count', 'node.admin_index', 'node.fork',
                    'admin.state_code', 'admin.state_text', 'admin.country_text', 'admin.country_code'
                ]
            }
        });

        const resp = await fetchRoutingService(`${process.env.REACT_APP_ROUTER_URL}/trace_attributes`, {method: 'POST', body: queryString});
        const data = await resp.json(),
            edges = data?.edges;

        if (!edges) {
            throw new Error('Error in routing service response. Not setting the track.');
        }

        function buildSegments(attrGetter) {
            return _.map(edges, ({begin_shape_index: start, end_shape_index: end, ...edge}) => [start, end, attrGetter(edge)]);
        }

        function buildEndNodes(edges, attrGetter) {
            return _.map(edges, ({end_shape_index, end_node}) => ([end_shape_index, attrGetter(end_node)]));
        }

        // Note: changes here usually require changes for proper merging of track slices when updating fragment of route only:
        // in `mergeTrackSlice`, `buildLegs`, `unwrapLegs`.
        // Also keep in sync with handlers.inspirations.inspirations.fetch_valhalla_track_from_shape
        const track = {
            ferries: buildSegments(({use}) => use === 'ferry'),
            gates: buildEndNodes(_.filter(edges, ({end_node}) => _.includes(GATE_EDGE_ENDS, end_node?.type)), _.property('type')),
            popularity: buildSegments(_.property('popularity')),
            // Track often returns a different shape (more detailed) than the original one.
            shape: data.shape,
            steepness: buildSegments(({weighted_grade, length}) => ({grade: weighted_grade, length})),
            surface: buildSegments(_.property('surface')),
            times: buildEndNodes(edges, _.property('elapsed_time')),
            tunnels: buildSegments(_.property('tunnel')),
            waytypes: buildSegments(_.property('road_class')),
            version: TRACK_VERSION
        };
        return track;
    }

    /**
     * Finetune elevation profile, reducing chops and up&downs
     *
     * Inspired by https://www.potter.ca/Biking/smoother/gpxsmoother.html (Smooth Values + Flatten Values)
     * pointsNum is a compromise, but seem ideally should vary ...
     *   5 - Kaszuby
     *   10 - Dolomites
     */
    function smoothElevationProfile(elevationData, pointsNum = 5) {

        /**
         * Smooth elevation data with moving average.
         */
        function smooth(elevationData, pointsNum) {
            const extractWindow = (elevationData, idx) =>
                _.slice(elevationData, Math.max(0, idx - pointsNum), Math.min(_.size(elevationData), idx + pointsNum));
            const movingWindow = ([dist], idx) => (
                [dist, extractWindow(elevationData, idx)]);
            const movingAverage = ([dist, slice]) => (
                [dist, _.meanBy(slice, ([, ele]) => ele)]);
            const smoothedData = _.map(_.map(elevationData, movingWindow), movingAverage);
            return smoothedData;
        }

        const smoothed = smooth(elevationData, pointsNum);
        return smoothed;
    }

    async function fetchHeight(encodedPolyline) {
        async function _doFetchHeight() {
            const queryString = {
                encoded_polyline: encodedPolyline, shape_format: 'polyline6',
                range: true,
                height_precision: 2,  // 0-2 is available
                costing: 'bicycle',
                costing_options: {bicycle: profile}
            };

            const resp = await fetchRoutingService(`${process.env.REACT_APP_ROUTER_URL}/height`, {method: 'POST', body: JSON.stringify(queryString)});
            const data = await resp.json(),
                rangeHeight = data?.range_height;

            if (!data || !rangeHeight) {
                throw new Error('Error in routing service response. Not setting the track.');
            }

            const [ranges, elevation] = _.unzip(smoothElevationProfile(rangeHeight));
            return {ranges, elevation};
        }

        const {ranges, elevation} = await _doFetchHeight();
        const {ascent, descent} = calculateAscentDescent(ranges, elevation);
        return {ranges, elevation, ascent, descent};
    }

    const {shape, ...track} = await fetchTrack(trackShape),
        {ranges, elevation, ascent, descent} = await fetchHeight(shape),
        coordinates = polyline.decode(shape, VALHALLA_PRECISION);

    // /trace_attributes often returns more detailed shape with more points. Use it as basis for coordinates and indices of route points.
    try {
        const routePoints = matchRoutePoints(route, coordinates);
        return {...track, ascent, coordinates, descent, elevation, ranges, routePoints};
    } catch (ex) {
        console.error(`matchRoutePoints failed: ${ex}.`);
        const details = JSON.stringify({route, coordinates});
        await errorHandler.report(`matchRoutePoints failed: ${ex}. ${details}`);
        throw ex;
    }
}

export async function queryValhallaFullTrack(route, breaks, options) {
    const {profile, distance, bbox, trackShape} = await queryValhallaTrack(route, breaks, options);
    const result = await queryValhallaTrackDetails(profile, route, trackShape);
    return {...result, bbox, distance};
}

export function defaultVisiblePOIs(isRoute) {
    return ['supplies', 'touristic', 'service', 'warnings', ...(isRoute ? [] : ['transport'])];
}

export function visiblePOIsQueryParams(visiblePOIs) {
    return _.map(visiblePOIs ?? defaultVisiblePOIs(), (filter) => (['visible_pois', filter]));
}

export async function exportGPX(routeId, exportPOIs) {
    const params = new URLSearchParams([...visiblePOIsQueryParams(exportPOIs), ['t', Math.random()]]),
        // Using REACT_APP_BACKEND_URL to avoid OOM issues with GAE.
        resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/routes/gpx/${routeId}?${params}`, {
            method: 'GET',
            // Set non-whitelisted content type to force preflight, to allow passing content-disposition in the response.
            headers: {'content-type': 'application/xml'}
        });
    const contentDisposition = ContentDisposition.parse(resp.headers.get('content-disposition')),
        name = contentDisposition?.filename;

    if (!resp.ok) {
        const error = await resp?.json();
        throw new Error(error?.detail ?? (resp?.text && await resp?.text?.()));
    }
    const blob = await resp.blob();
    console.log(resp);
    saveAs(blob, name);
}

export const NO_TRACK = {coordinates: null, elevation: null, ranges: null, routePoints: null, bbox: null, steepness: null, surface: null, waytypes: null};

/**
 * Transforms each element in the `legs` array based on a rebasing function.
 * The rebasing function adjusts each element relative to a base value calculated for each leg.
 * It is used for both "zero-basing" (converting absolute to leg-relative values) and rebasing (converting leg-relative to absolute)
 *
 * @param {Array} legs - An array of legs, each leg being an array of elements.
 * @param {Function} rebaserFn - A function to apply to each element of a leg. It receives the base value and returns a function that accepts and transforms each element.
 * @param {Function} baseFn - A function to calculate the base value for each leg. It accumulates values across legs and returns the base for the current leg.
 * @returns {Array} - An array of legs with each element transformed by the rebaser function.
 */
function rebase(legs, rebaserFn, baseFn) {
    if (_.isEmpty(legs))
        return legs;
    const
        legBaseValues = [undefined, ..._.map(_.initial(legs), _.last)],
        bases = _.transform(legBaseValues,
            (cumulated, base, legIdx) => cumulated.push(baseFn(cumulated, base, legIdx)), []);
    return _.map(_.zip(legs, bases), ([leg, base]) => _.map(leg, rebaserFn(base)));
}

/**
 * Adjusts the times in each leg of a journey so that they start from zero. It's a specific implementation of `rebase` for time data.
 *
 * @param {Array} legs - An array of legs, each leg containing time data.
 * @returns {Array} - An array of legs with time data rebased to start from zero.
 */
function makeTimesLegRelative(legs) {
    const baseFn = (cumulated, [end, time] = [0, 0]) => ([end, time]);
    const rebaserFn = ([baseEnd, baseTime]) => ([end, time]) => ([end - baseEnd, time - baseTime]);
    return rebase(legs, rebaserFn, baseFn);
}

/**
 * Adjusts the times in each leg of a journey based on cumulative values. It's a specific implementation of `rebase` for cumulative time data.
 *
 * @param {Array} legs - An array of legs, each leg containing time data.
 * @returns {Array} - An array of legs with time data rebased cumulatively.
 */
function makeTimesAbsolute(legs) {
    const baseFn = (cumulated, [end, time] = [0, 0]) => {
        const [cumEnd, cumTime] = _.last(cumulated) ?? [0, 0];
        return [cumEnd + end, cumTime + time];
    };
    const rebaserFn = ([baseEnd, baseTime]) => ([end, time]) => ([end + baseEnd, time + baseTime]);
    return rebase(legs, rebaserFn, baseFn);
}

/**
 * Adjusts the gate coordinates in each leg of a journey to start from zero. This function is used to rebase gate coordinates relative to the start of each leg.
 *
 * @param {Array} legStarts - An array containing the starting index for each leg.
 * @param {Array} legs - An array of legs, each leg containing gate data.
 * @returns {Array} - An array of legs with gate data rebased to start from zero.
 */
function makeGatesLegRelative(legStarts, legs) {
    const baseFn = (c, lb, legIdx) => legStarts[legIdx];
    const rebaserFn = (legStart) => ([coordIdx, gate]) => ([coordIdx - legStart, gate]);
    const zeroBasedGates = rebase(legs, rebaserFn, baseFn);
    return zeroBasedGates;
}

/**
 * Adjusts the gate coordinates in each leg of a journey based on the length of the legs. This function rebases gate coordinates cumulatively across legs.
 *
 * @param {Array} legLens - An array containing the length of each leg.
 * @param {Array} legs - An array of legs, each leg containing gate data.
 * @returns {Array} - An array of legs with gate data rebased cumulatively.
 */
function makeGatesAbsolute(legLens, legs) {
    // Calculate base index for each leg. Subtract 1 from each leg length because they have one shared coordinate.
    const legBaseCoordIndexFn = (cumulated, legBase, legIdx) => !legIdx ? 0 : (_.last(cumulated) + legLens[legIdx - 1] - 1);
    const rebaserFn = (legBase) =>
        ([end, gate]) =>
            ([legBase + end, gate]);
    const rebasedGates = rebase(legs, rebaserFn, legBaseCoordIndexFn);
    return rebasedGates;
}

/**
 * Adjusts the range values in each leg to start from zero. This function is used for rebasing range data in each leg of the journey.
 *
 * @param {Array} legs - An array of legs, each leg containing range data.
 * @returns {Array} - An array of legs with range data rebased to start from zero.
 */
function makeRangesLegRelative(legs) {
    return _.map(legs, (leg) => {
        const base = _.first(leg);
        return _.map(leg, (r) => r - base);
    });
}

/**
 * Adjusts the range values in each leg based on cumulative values.
 * This function is used for cumulative rebasing of range data across leg, ensuring they are monotonic.
 *
 * @param {Array} legs - An array of legs, each leg containing range data.
 * @returns {Array} - An array of legs with range data rebased cumulatively.
 */
function makeRangesAbsolute(legs) {
    const rebaserFn = (base) => (r) => r + base;
    const rangesBaseFn = ((cumulated, end = 0) => end + (_.last(cumulated) ?? 0));
    return rebase(legs, rebaserFn, rangesBaseFn);
}

/**
 * Constructs the legs of a track from various types of track data. This function segments the track into legs and applies specific transformations to each type of track data.
 *
 * @param {Object} track - An object containing various types of track data like coordinates, elevation, gates, etc.
 * @returns {Object|null} - An object containing the segmented and transformed track data for each leg, or null if `routePoints` is not present.
 */
function buildLegs(track) {
    const {coordinates, elevation, ferries, gates, popularity, ranges, routePoints, steepness, surface, times, tunnels, waytypes} = track ?? {};
    if (!routePoints)
        return null;

    const legRanges = _.size(routePoints) > 1 ? _.zip(_.initial(routePoints), _.tail(routePoints)) : [[0, _.size(coordinates)]],
        legStarts = _.map(legRanges, '0');

    function legBuilder(trackPoints, margin = 0) {
        return trackPoints && _.map(legRanges,
            ([first, last]) => trackPoints.slice(first, last + margin));
    }

    function nodesLegBuilder(trackRanges) {
        const perLegLists = {
            // Pre-fill per-leg lists with empty lists, in case some are non-present in trackRanges.
            ..._.fromPairs(_.map(legRanges, (lr, idx) => [idx, []])),
            ..._.groupBy(trackRanges, ([end]) =>
                _.findIndex(legRanges, ([legStart, legEnd]) => end >= legStart && end <= legEnd))
        };
        const orderedLegLists = _.sortBy(_.toPairs(perLegLists), ([legIdx]) => _.parseInt(legIdx));
        const legs = _.map(orderedLegLists, ([, leg]) => leg);
        return legs;
    }

    return {
        coordinates: legBuilder(coordinates, +1),
        elevation: legBuilder(elevation, +1),
        ferries: legBuilder(unpackSegments(ferries)),
        gates: makeGatesLegRelative(legStarts, nodesLegBuilder(gates)),
        popularity: legBuilder(unpackSegments(popularity)),
        ranges: makeRangesLegRelative(legBuilder(ranges, +1)),
        steepness: legBuilder(unpackSegments(steepness)),
        surface: legBuilder(unpackSegments(surface)),
        times: makeTimesLegRelative(nodesLegBuilder(times)),
        tunnels: legBuilder(unpackSegments(tunnels)),
        waytypes: legBuilder(unpackSegments(waytypes))
    };
}

function unwrapLegs(legs) {
    if (!legs)
        return NO_TRACK;
    const {coordinates, elevation, ferries, gates, popularity, ranges, steepness, surface, times, tunnels, waytypes} = legs;

    // Using flattenLegs for flattening points to remove dupe item on legs connection - e.g. for [a, b, c], [c, d, e] we want 'c' only once.
    // For flattening ranges just use ordinary flatten.
    const {values, routePoints, legLengths} = flattenLegs(coordinates);
    return {
        coordinates: values,
        elevation: flattenLegs(elevation).values,
        ferries: packSegments(_.flatten(ferries)),
        gates: _.flatten(makeGatesAbsolute(_.map(coordinates, _.size), gates)),
        popularity: packSegments(_.flatten(popularity)),
        ranges: flattenLegs(makeRangesAbsolute(ranges)).values,
        routePoints,
        steepness: packSegments(_.flatten(steepness)),
        surface: packSegments(_.flatten(surface)),
        times: _.flatten(makeTimesAbsolute(times)),
        tunnels: packSegments(_.flatten(tunnels)),
        waytypes: packSegments(_.flatten(waytypes))
    };
}

function replaceLegs(oldLegs, newLegs, replaced) {
    if (!replaced)
        return newLegs;

    const [start, end] = replaced;
    return [...oldLegs.slice(0, start), ...(newLegs ?? []), ...oldLegs.slice(end - 1, _.max([end, _.size(oldLegs)]))];
}

export function mergeTrackSlice(track, trackSlice, replaced) {
    const oldLegs = buildLegs(track),
        newLegs = buildLegs(trackSlice);

    const legs = oldLegs ? {
        coordinates: replaceLegs(oldLegs.coordinates, newLegs?.coordinates, replaced),
        elevation: replaceLegs(oldLegs.elevation, newLegs?.elevation, replaced),
        ferries: replaceLegs(oldLegs.ferries, newLegs?.ferries, replaced),
        gates: replaceLegs(oldLegs.gates, newLegs?.gates, replaced),
        popularity: replaceLegs(oldLegs.popularity, newLegs?.popularity, replaced),
        ranges: replaceLegs(oldLegs.ranges, newLegs?.ranges, replaced),
        steepness: replaceLegs(oldLegs.steepness, newLegs?.steepness, replaced),
        surface: replaceLegs(oldLegs.surface, newLegs?.surface, replaced),
        times: replaceLegs(oldLegs.times, newLegs?.times, replaced),
        tunnels: replaceLegs(oldLegs.tunnels, newLegs?.tunnels, replaced),
        waytypes: replaceLegs(oldLegs.waytypes, newLegs?.waytypes, replaced)
    } : newLegs;

    const unwrapped = unwrapLegs(legs);
    const newTrack = {
        ...unwrapped,
        ...calculateAscentDescent(unwrapped.ranges, unwrapped.elevation),
        bbox: getBBox(unwrapped.coordinates),
        distance: _.sum(_.map(legs?.ranges ?? [], (range) => _.size(range) ? (_.last(range) - _.first(range)) : 0)) / 1000,
        version: (track?.version ?? trackSlice?.version)
    };
    return newTrack;
}

// noinspection JSUnusedGlobalSymbols
export function getSliceRange(action, idx, route) {
    idx = idx ?? (_.size(route) - 1);

    const start = _.max([0, idx - 1]),
        end = _.min([_.size(route), idx + 2]);

    // noinspection JSUnusedGlobalSymbols
    const rangeFns = {
        insert: () => ([start, end]),
        move: () => ([start, end]),
        delete: () => ([start, end])
    };
    const getRangeFn = rangeFns[action] ?? (() => [0, _.size(route)]);
    const range = getRangeFn();

    // noinspection JSUnusedGlobalSymbols
    const replacedRangeFns = {
        insert: () => ([start, end - 1]),
        move: () => ([start, end]),
        delete: () => ([start, end + 1])
    };

    const getReplacedRange = replacedRangeFns[action] ?? (() => null);
    return {replaced: getReplacedRange(), range};
}

export function featureCoords(feature) {
    try {
        const [lng, lat] = getCoord(feature);
        return {lat, lng};
    } catch (e) {
        return null;
    }
}