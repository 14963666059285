import _ from 'lodash';
import {useQuery} from 'react-query';
import {getDataOrThrow} from './errors';


const LOOKUP_API_URL = process.env.REACT_APP_EMULATOR ? `http://${process.env.REACT_APP_EMULATOR}:7700` : 'https://lookup.tarmacs.app';
const LOOKUP_API_KEY = '3ee9cfda-a56f-4d89-a936-e5a86f57691e';

export function useMeilisearchLookupQuery(index, typedText, {filter, converter, options, apiUrl} = {}) {
    const queryState = useQuery(["meilisearch-lookup", index, typedText], async () => {
        if (!_.size(typedText)) return [];
        const baseUrl = apiUrl ?? LOOKUP_API_URL;
        const resp = await fetch(`${baseUrl}/indexes/${index}/search`, {
            method: 'POST', json: {q: typedText, ...(options || {})},
            headers: {'Authorization': `Bearer ${LOOKUP_API_KEY}`, 'Content-Type': 'application/json'}
        });
        const {hits} = await getDataOrThrow(resp),
            filtered = filter?.(hits) ?? hits,
            converted = converter ? _.map(filtered, converter) : filtered;
        return converted;
    });
    return queryState;
}