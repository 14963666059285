import styled from '@emotion/styled';
import {expression, featureFilter, v8} from '@maplibre/maplibre-gl-style-spec';
import {feature} from '@turf/turf';
import {documentId, limit, orderBy, serverTimestamp, where} from 'firebase/firestore';
import {geohashForLocation} from 'geofire-common';
import _ from 'lodash';
import memoize from 'lru-memoize';
import React, {createContext, memo, useCallback, useContext, useEffect, useMemo} from 'react';
import {Alert, Badge, Button, ButtonGroup, ButtonToolbar, Card, Dropdown, Stack, ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {FaLightbulb, FaPlayCircle, FaStopCircle} from 'react-icons/fa';
import {MdThumbDown, MdThumbUp} from 'react-icons/md';
import {Popup} from 'react-map-gl';
import {useQuery} from 'react-query';
import {userConverter} from '../lib/converters';
import {useFirebaseCollection, useFirebaseDocData, useFirebaseDocMutationInvalidate} from '../lib/db';
import {ErrorBoundary, errorHandler} from '../lib/errors';
import {UserAvatar} from '../lib/feed';
import {FirebaseAuth} from '../lib/firebase';
import {ga} from '../lib/ga';
import {getClosestPointAndSegment} from '../lib/getClosestPointAndSegment';
import {featureCoords} from '../lib/routing';
import {SpriteSheet} from '../lib/Spritesheet';
import {useMapLibre, useMapLibreEvents} from '../lib/useMapLibre';
import {toHostPath, useDelayedSetState} from '../lib/util';
import {ZINDEX} from '../lib/zindexes';
import {t_} from './l10n';
import {ImageIconContainer, OverlayContainer} from './OverlayIcon';


const EMPTY_STATE = [{}];

export const POI_POPUP_DELAY = 800;

const POIContext = createContext(EMPTY_STATE);

export function POIPopupContextProvider({children}) {
    // Important to use top-level constants to avoid expensive redraws of the elevation viewer.
    const poiPopup = useDelayedSetState(POI_POPUP_DELAY, EMPTY_STATE);

    return <POIContext.Provider value={poiPopup}>
        {children}
    </POIContext.Provider>;
}

/**
 *
 * @returns {[{feature: Object, lngLat: {lng: number, lat:number}, isRoutePoint: boolean}, function, function, function]} state of the current popup with point of interest.
 */
export function usePOIPopup() {
    const poiPopup = useContext(POIContext);
    return poiPopup;
}

export const StyledPopup = styled(Popup)`
    z-index: ${ZINDEX.POI_POPUP};

    .maplibregl-popup-content {
        margin: 0;
        padding: 0;
        width: 100%;

        .card {
            border-radius: 3px;
        }
    }
`;

// Must use min+max to force override of default maplibre maxWidth=240.
const POIStyledPopup = styled(StyledPopup)`
    min-width: 324px;
    max-width: 324px;
`;

export function toTurfFeature(maplibreFeature, srcLayer) {
    const {id, geometry, properties, sourceLayer = srcLayer} = maplibreFeature;
    const feat = feature(geometry, {...properties}, {id});
    return {...feat, sourceLayer};
}

export function featureToPOI(feature, layer) {
    const {id, properties} = feature;
    return {id, layer: layer.id, name: properties?.name, icon: getIconFromLayer(layer), feature};
}

export function routePOI(maplibreFeature, srcLayer, featLayer) {
    // Convert to turf feature which is json-serializable, while we might want to save features with route.
    const feature = toTurfFeature(maplibreFeature, srcLayer);
    return featureToPOI(feature, maplibreFeature.layer ?? featLayer);
}

export function POIMarker({icon, ...props}) {
    return <SpriteSheet sprite={icon} {...props}/>;
}

export const MapPopup = memo(function MapPopup({routeProps, ...props}) {
    const [poi, , cancelFeature] = usePOIPopup(),
        {feature, PopupComponent = POIPopup} = poi,
        mapLibreEvents = useMemo(() => ({
            // zoomstart: () => cancelFeature({clear: true}),
            resize: () => cancelFeature({clear: true})
        }), [cancelFeature]);

    useMapLibreEvents(mapLibreEvents);
    useEffect(() => feature && ga('poi_view', {item_id: routeProps?.id, poi: feature.id}), [feature, routeProps?.id]);

    return <ErrorBoundary silent resetKeys={[poi]}>
        <PopupComponent poi={poi} cancelFeature={cancelFeature} {...props}/>
    </ErrorBoundary>;
});

function POIPopup({cancelFeature, onPointInsert, poi, track}) {
    const {feature, lngLat, isRoutePoint} = poi,
        whereToInsert = useMemo(function whereToInsertPOIOnTheRoute() {
            if (!lngLat || _.size(track?.routePoints) < 2) {
                return null;
            }
            const {lng, lat} = lngLat,
                closestPt = getClosestPointAndSegment(track, [lat, lng]);
            if (!closestPt) {
                return null;
            }
            const {routePointIdx, trackPointIdx} = closestPt,
                isClosestToEnd = (_.last(track.routePoints) - trackPointIdx) < (trackPointIdx - _.nth(track.routePoints, -2));
            return isClosestToEnd ? null : routePointIdx;
        }, [lngLat, track]);

    // TODO effect to center map on opened point? https://maplibre.org/maplibre-gl-js-docs/example/center-on-symbol/

    const onAddToRoute = useCallback((method) => {
        const {lng, lat} = lngLat;
        const shouldAppend = method === 'append' || _.size(track?.routePoints) < 2;
        // Skip 'point' - unnecessary and non-serializable by Firebase ("Unsupported field value: a custom Object object").
        onPointInsert(shouldAppend ? null : whereToInsert, [lat, lng], _.omit(poi, 'point'));
        cancelFeature({clear: true});
    }, [onPointInsert, cancelFeature, lngLat, poi, track?.routePoints, whereToInsert]);

    const eventHandlers = useMemo(() => ({
        popupclose: () => console.debug('NoMarginPopup close')
    }), []);

    if (!feature)
        return null;

    const {lng, lat} = lngLat;
    // Vertical offset is set empirically. Not sure how to deduce it.
    return <POIStyledPopup longitude={lng} latitude={lat} className="maplibregl-container-reboot p-0" minWidth={320} maxWidth={320}
                           eventHandlers={eventHandlers} closeButton={false}>
        <PointOfInterestCard isRoutePoint={isRoutePoint} cancelFeature={cancelFeature} feature={feature} whereToInsert={whereToInsert}
                             onAddToRoute={onPointInsert && !isRoutePoint ? onAddToRoute : null}/>
    </POIStyledPopup>;
}

function POIRecentRatings({id}) {
    const {data: recentThumbs} = useFirebaseCollection(['pois', id, 'thumbs'], [orderBy('given', 'desc'), limit(5)],
            null, null, {staleTime: Infinity, cacheTime: Infinity}),
        recentUsers = _.map(recentThumbs?.docs, 'id'),
        {data: users} = useFirebaseCollection(['users'], [where(documentId(), 'in', recentUsers)],
            userConverter, undefined, {enabled: !_.isEmpty(recentUsers), staleTime: Infinity, cacheTime: Infinity}),
        usersMap = _.keyBy(users?.docs, 'id');

    if (_.isEmpty(users)) return null;

    return <>
        <small className="ms-auto me-2"><Trans>Recent ratings:</Trans> </small>
        {_.map(recentThumbs?.docs, (snap) =>
                usersMap[snap.id] && <ImageIconContainer key={snap.id}>
                    <UserAvatar photo_url={usersMap[snap.id].data().photo_url}/>
                    <OverlayContainer>
                        {snap.get('direction') === 'up' ? <ThumbUpOverlay/> : <ThumbDownOverlay/>}
                    </OverlayContainer>
                </ImageIconContainer>
        )}
    </>;
}

function POIRecommendations({feature}) {
    const {t} = useTranslation(),
        {id} = feature ?? {},
        {user} = useContext(FirebaseAuth);

    function invalidationPredicate(query, defaultPredicate) {
        return defaultPredicate(query) && _.last(query?.queryKey)?.type !== 'geohash';
    }

    const {data: poi} = useFirebaseDocData(['pois', id]),
        {mutate: mutatePOI} = useFirebaseDocMutationInvalidate(['pois', id], null, null, null, invalidationPredicate),
        {data: thumb} = useFirebaseDocData(['pois', id, 'thumbs', user?.uid]),
        {mutate: mutateThumb} = useFirebaseDocMutationInvalidate(['pois', id, 'thumbs', user?.uid]);

    if (!id)
        return null;

    const {direction = ''} = thumb ?? {},
        onChange = (direction) => {
            const {lng, lat} = featureCoords(feature),
                poiData = poi ?? {thumbsUp: 0, thumbsDown: 0, thumbs: 0, thumbsSum: 0, rating: 0, coordinates: [lat, lng], geohash: geohashForLocation([lat, lng])};
            poiData.thumbs += _.isNil(thumb?.direction) ? 1 : 0;

            const _update = (dir, delta) => {
                poiData[(dir === 'up') ? 'thumbsUp' : 'thumbsDown'] += delta;
                poiData.thumbsSum += {'up': 1, 'down': -1}[dir] * delta;
            };

            thumb?.direction && _update(thumb?.direction, -1);
            _update(direction, +1);

            poiData.rating = poiData.thumbsUp / poiData.thumbs * 5.0;
            mutateThumb({direction, given: (thumb?.given ?? serverTimestamp())});
            mutatePOI(poiData);
            ga('poi_rate', {poi: id, rate: direction});
        };

    return <>
        <ToggleButtonGroup size="sm" name="poi-thumbs" className="ms-auto" type="radio" value={direction} onChange={onChange}>
            <ToggleButton name="poi-thumbs" id="poi-thumbs-down" value="down" variant="outline-primary" title={t("Not into it? You can downvote it.")}><MdThumbDown/></ToggleButton>
            <ToggleButton name="poi-thumbs" id="poi-thumbs-up" value="up" variant="outline-primary" title={t("Liked this place? Recommend to others!")}><MdThumbUp/></ToggleButton>
        </ToggleButtonGroup>
    </>;
}

export const CardCloseButton = styled(Button)`
    position: absolute;
    top: 0;
    right: -2px;
`;

const PointOfInterestCard = memo(function PointOfInterestCard({cancelFeature, feature, isRoutePoint, onAddToRoute, whereToInsert}) {
    const {t} = useTranslation(),
        {layer, icon} = useMapLibreIcon(feature),
        {wikidata, image, name} = feature?.properties ?? {};
    const {url, extract} = usePointOfInterestInfo(wikidata, image) ?? {};

    if (!layer || !feature?.id) return null;

    // i18next-extract-disable-next-line
    const layerName = t(POI_WITH_POPUPS[layer.id]),
        [addNextButton, putOnWayButton] = [
            {title: t("Add next point"), method: 'append', icon: <FaStopCircle/>},
            {title: t("Put on the way"), method: 'pull-up', icon: <FaPlayCircle/>}
        ],
        main = _.isNil(whereToInsert) ? addNextButton : putOnWayButton;

    const mainButton = <Button size="sm" onClick={() => onAddToRoute(main.method)}>{main.icon} {main.title}</Button>;
    const addToRouteButtons = onAddToRoute && (
        _.isNil(whereToInsert) ? mainButton :
            <Dropdown as={ButtonGroup} size="sm">
                {mainButton}
                <Dropdown.Toggle split id="route-here-dropdown-toggle"/>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onAddToRoute(addNextButton.method)}>{addNextButton.icon} {addNextButton.title}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
    );
    const routePointMsg = isRoutePoint &&
        <Alert variant="info" className="flex-shrink-1 flex-grow-0 me-2 mb-0 p-2 lh-1" style={{fontSize: 'small'}}>
            <FaLightbulb/>&nbsp;<Trans>Click the route point for actions. Drag it to move elsewhere.</Trans>
        </Alert>;

    return <Card>
        <Card.Header className="mt-0 me-0">
            <Stack direction="horizontal" gap={1} className="pe-1">
                <POIMarker sprite={icon} size={21} className="flex-grow-0 flex-shrink-0"/>
                {/* i18next-extract-disable-next-line */}
                <span className="flex-1">{name || layerName}</span>
                {/* i18next-extract-disable-next-line */}
                {name && <Badge bg="secondary" className="ms-auto flex-grow-0">{layerName}</Badge>}
            </Stack>
            <CardCloseButton className="btn-close pt-0" onClick={() => cancelFeature({clear: true})}/>
        </Card.Header>
        {url && <Card.Img src={url}/>}
        <Card.Body className="pb-0">
            <ButtonToolbar className="align-items-center flex-nowrap">
                {addToRouteButtons}
                {routePointMsg}
                <POIRecommendations feature={feature}/>
            </ButtonToolbar>
            <Stack direction="horizontal" className="mt-2 me-2 mb-0">
                <POIRecentRatings id={feature?.id}/>
                {/* <POIRating id={feature?.id} className="ms-auto text-secondary"/> */}
            </Stack>
        </Card.Body>
        <Card.Body style={{fontSize: 'x-small', lineHeight: 1}} className="py-0 my-0">
            <p>{extract}</p>
        </Card.Body>
    </Card>;
});

/**
 * Parse `image` property, constructed by `poi_image` in `openmaptiles/layers/poi/class.sql`
 *
 * @todo support Flickr and Mapiliary.
 */
function parseImageProp(image) {
    const [prefix, url] = image.split(':', 1);
    return prefix === 'image' && url ? {url, source: prefix} : null;
}

/**
 * Extract image URL from `image` or `wikidata` (through wikidata webservice) tags.
 */
function usePointOfInterestInfo(wikidata, image) {
    const
        {i18n: {language} = {}} = useTranslation(),
        {data} = useQuery(['poi-image:', wikidata, image], async () => {
            const {url, source} = (image && parseImageProp(image)) || {};
            if (!wikidata)
                return {url, source};

            const wdUrl = `https://www.wikidata.org/wiki/Special:EntityData/${wikidata}.json`,
                resp = await (await fetch(wdUrl)).json(),
                entity = resp?.entities?.[wikidata],
                claim = entity?.claims?.P18 ?? [{}],
                commonsMedia = _.first(claim)?.mainsnak?.datavalue?.value,
                // `https://commons.wikimedia.org/wiki/File:${commonsMedia}` would load the page, not image. Using Special:Redirect to get image directly.
                mediaUrl = !_.isEmpty(commonsMedia) ? `https://commons.wikimedia.org/wiki/Special:Redirect/file?wptype=file&wpvalue=${commonsMedia}&width=320` : null,
                sitelinks = entity?.sitelinks,
                sitelink = sitelinks?.[`${language}wiki`] ?? sitelinks?.enwiki ?? sitelinks?.commonswiki;

            if (sitelink?.url && sitelink?.title) {
                const searchParams = {
                        format: 'json', action: 'query', prop: 'extracts', 'explaintext': 1, 'exintro': 1, titles: sitelink.title,
                        // Required to return CORS headers. https://forum.freecodecamp.org/t/the-wikipedia-api-does-not-support-cors-requests/16595/2
                        origin: '*'
                    },
                    wikiExtractQueryUrl = toHostPath({
                        urlString: sitelink.url, pathname: '/w/api.php', searchParams
                    }),
                    json = await (await fetch(wikiExtractQueryUrl, {headers: {origin: '*'}})).json(),
                    extract = _.first(_.values(json?.query?.pages ?? {}))?.extract;

                return {url: mediaUrl, source: 'wikidata', extract, title: sitelink.title};
            }

            return {url: mediaUrl, source: 'wikidata'};
        }, {staleTime: Infinity});

    return data;
}

export const POI_WITH_POPUPS = {
    'poi_beer': t_("Pub"), 'poi_bar': t_("Bar"), 'poi_cafe': t_("Cafe"), 'poi_bakery': t_("Bakery"),
    'poi_drinking_water': t_("Potable water source"), 'poi_spring': t_("Spring"), 'poi_fuel': t_("Gas station"),
    'poi_grocery': t_("Grocery store"), 'poi_ice_cream': t_("Ice cream"), 'poi_restaurant': t_("Restaurant"),
    'poi_bicycle_rental': t_("Bicycle rental"), 'poi_alcohol_shop': t_("Beverages shop"),
    'poi_bicycle_sports_shop': t_("Sports shop"), 'poi_fast_food': t_("Fast food"),
    'poi_attraction': t_("Tourist attraction"), 'poi_shelter': t_("Shelter"),
    'poi_toilets': t_("Toilets"), 'poi_lodging': t_("Lodging facility"), 'poi_church': t_("Church"), 'poi_church_major': t_("Church"),
    'poi_camp_site': t_("Camping site"), 'poi_fireplace': t_("Fireplace"), 'poi_waterfall': t_("Waterfall"),
    'poi_viewpoint': t_("View point"), 'poi_memorial_monument': t_("Monument"), 'poi_cave': t_("Cave"), 'poi_castle': t_("Castle"),
    'mountain_peak': t_("Mountain peak"), 'mountain_volcano': t_("Volcano"), 'mountain_saddle': t_("Mountain pass"),
    'transport_railway_halt': t_("Train stop"), 'transport_railway': t_("Train station")
};

const getFeatureLayersMap = memoize(100)((mapLibre) => {
    // This happens to raise for some mapLibre instances. Intentionally caught upstream instead of fallback, to avoid memoizing the fallback.
    const layers = mapLibre?.getStyle?.()?.layers;
    const layersMap = _.groupBy(layers, 'source-layer');
    return layersMap;
});

export const getFeatureLayerMemoized = memoize(100)(function getFeatureLayerMemoized(mapLibre, feature) {
    if (!feature) return null;
    const layersMap = getFeatureLayersMap(mapLibre),
        // Handle filter with ["==", "$type", "Point"] or similar,
        // when feature passed here is not mapLibre but cleansed geojson/turf.
        // See also: maplibre-style-spec/expression/evaluation_context.ts and maplibre-style-spec/feature_filter/index.ts
        featureEmu = {...feature, type: feature.geometry.type};
    // noinspection JSUnusedLocalSymbols
    const layer = _.find(layersMap[feature.sourceLayer], ({id, filter}) => {
        return filter && featureFilter(filter).filter({zoom: 16}, featureEmu);
    });
    return layer;
});

export function getFeatureLayer(mapLibre, feature) {
    try {
        return getFeatureLayerMemoized(mapLibre, feature);
    } catch (ex) {
        // Handles the following exceptions. Report other exceptions, just in case they are legit.
        // TypeError: Cannot read properties of undefined (reading 'version') when resizing browser window md<->sm
        // TypeError: undefined is not an object (evaluating 'this.stylesheet.version')
        !_.includes(ex?.message, "version") && errorHandler.report(ex);
        return null;
    }
}

function evaluateStyleJsonExpression(exprJson) {
    const expr = expression.createPropertyExpression(exprJson, v8.layout_symbol['icon-image']);
    return expr.value.evaluate({zoom: 16});
}

function getIconFromLayer(layer) {
    const iconImage = layer?.layout?.['icon-image'],
        iconObj = (_.isNil(iconImage) || typeof iconImage === 'string' || iconImage.name) ? iconImage : evaluateStyleJsonExpression(iconImage),
        icon = iconObj?.name ?? iconObj;
    return icon;
}

export function getMapLibreIcon(mapLibre, feature) {
    try {
        const layer = getFeatureLayer(mapLibre, feature),
            icon = getIconFromLayer(layer);
        return {layer, icon};
    } catch (e) {
        return {layer: null, icon: null};
    }
}

export function useMapLibreIcon(feature) {
    const {mapLibre} = useMapLibre();
    const icon = useMemo(() => getMapLibreIcon(mapLibre, feature), [mapLibre, feature]);
    return icon;
}

const ThumbUpOverlay = styled(MdThumbUp)`
    position: absolute;
    bottom: -0.5em;
    right: -0.5em;
`;
const ThumbDownOverlay = styled(MdThumbDown)`
    position: absolute;
    bottom: -0.5em;
    right: -0.5em;
`;
