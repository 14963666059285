/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {getCoord} from '@turf/turf';
import classNames from 'classnames';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {forwardRef, memo, useMemo, useRef} from 'react';
import {Button, CloseButton, Col, Container, FormCheck, ListGroup, Row, Stack} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {Trans, useTranslation} from 'react-i18next';
import {FaRocket, FaSpinner} from 'react-icons/fa';
import {FaFlagCheckered} from 'react-icons/fa6';
import {GiSnail} from 'react-icons/gi';
import {MdEditCalendar} from 'react-icons/md';
import {useBoolean} from 'usehooks-ts';
import {toLngLat} from '../lib/getClosestPointAndSegment';
import {checkToggler, useDeviceType} from '../lib/util';
import {ThermometerC, useYrNoForecast, UVIndex, WindSpeedDirection, YrNoSymbol} from '../lib/weathericons';
import {ZINDEX} from '../lib/zindexes';
import {StyledRangeSlider} from './DiscreteRangeSlider';
import {DivMarker} from './DivMarker';
import {useLocaleSettings} from './l10n';
import {useAnchor} from './ViewStateContext';


const SYMBOL_SIZE = 60;

function TripStartShortcut({children, className, ...props}) {
    return <Button size="sm" variant="link" className={classNames(className, 'py-0 px-1 x-small')} {...props}><small className="x-small">{children}</small></Button>;
}

function humanizeDate(date, t, locale) {
    date = date.setLocale(locale);
    const now = DateTime.now();
    if (date.startOf('day') === now.startOf('day'))
        return t('Today {{fmt}}', {fmt: date.toFormat('t')});
    // noinspection JSAnnotator
    if (date.startOf('day') === now.startOf('day').plus({days: 1}))
        return t('Tomorrow {{fmt}}', {fmt: date.toFormat('t')});
    // noinspection JSAnnotator
    if (date.startOf('day') > now.startOf('day').plus({days: 7}) && date.startOf('day') < now.startOf('day').plus({days: 14}))
        return t("Next {{fmt}}", {fmt: date.toFormat("EEE t")});
    return date.toFormat("EEE dd MMM t");
}

const TripStartSelectorBtn = forwardRef(function({date, onClick}, ref) {
    const {t} = useTranslation(),
        {locale} = useLocaleSettings();
    return <Button size="sm" variant="outline-primary" className="py-0 px-1" ref={ref} onClick={onClick}>
        <small className="fw-bold">{humanizeDate(date, t, locale)}</small>
        <MdEditCalendar size="2em"/>
    </Button>;
});

const TripStartSelector = memo(function({date, setDate}) {
    const {t} = useTranslation(),
        {isMobile} = useDeviceType();
    if (!date) return null;
    // portalId works around distorted content (by map's parent styling) and scroll wheel.
    return <DatePicker selected={date.toJSDate()} onChange={(d) => setDate(d)} showDate showTimeSelect
                       withPortal portalId="trip-start-selector"
                       timeCaption={t("Start")} calendarClassName={classNames({mobile: isMobile})}
                       customInput={<TripStartSelectorBtn date={date}/>}/>;
});

const MAX_TEMP = 25,
    ROTATE_RANGE = 120;

// Adjust color of mercury to temperature.
// Invert color of "C" text. use[xlink|href=] does not work (not sure how/where to define @namespace)
const ThermometerStyled = styled(ThermometerC)`
    filter: hue-rotate(-${({rotate}) => rotate}deg);

    use:first-of-type {
        filter: invert(1);
    }
`;

function TemperatureSymbol({temperature, ...props}) {
    const rotate = useMemo(() => _.min([_.max([(MAX_TEMP - temperature) * ROTATE_RANGE / MAX_TEMP, 0]), ROTATE_RANGE]), [temperature]);

    return <ThermometerStyled rotate={rotate} {...props} />;
}

function WeatherIcons({
    cloud_area_fraction, air_temperature, symbol1, symbol6, ultraviolet_index_clear_sky, wind_speed, wind_from_direction,
    className, onClick, size, tiny, time, when
}) {
    const {t} = useTranslation(),
        {locale} = useLocaleSettings(),
        now = DateTime.now(),
        {value: hover, setTrue: setHover, setFalse: clearHover, toggle: toggleHover} = useBoolean(false);

    if (_.isNil(air_temperature) && _.isEmpty(symbol1) && _.isEmpty(symbol6) && _.isNil(wind_speed))
        return <Container fluid className={classNames("gx-0", className)}>
            <Row><Col className="text-white small text-center"><Trans>No weather forecast yet</Trans></Col></Row>
        </Container>;

    const windSpeed = _.isNumber(wind_speed) ? t("{{wind_speed, number}}m/s", {wind_speed, maximumFractionDigits: 0}) : '',
        temperature = _.isNumber(air_temperature) ? (tiny ?
            t("{{air_temperature, number}}℃", {air_temperature, maximumFractionDigits: 0}) :
            t("{{air_temperature, number}}", {air_temperature, maximumFractionDigits: 0})) : '',
        displayUV = (!cloud_area_fraction || cloud_area_fraction < 60) && _.isNumber(ultraviolet_index_clear_sky),
        height = size;

    function onTouchClick(evt) {
        if (onClick) {
            onClick(evt);
        } else {
            evt.preventDefault();
            evt.stopPropagation();
            toggleHover();
        }
    }

    const forecastTime = when ?? time,
        timeHeader = forecastTime && forecastTime > now ?
            forecastTime.setLocale(locale).set({minute: (forecastTime.minute - forecastTime.minute % 10)}).toFormat('t') :
            t("Now");

    return <Container fluid className={classNames("gx-0", className)} onMouseEnter={setHover} onMouseLeave={clearHover} onClick={onTouchClick}>
        {(!tiny || hover) && timeHeader &&
            <Row><Col className="text-center text-white">{timeHeader}</Col></Row>}
        <Row className="align-items-center text-center pb-0 gx-0 flex-nowrap">
            {!tiny && <Col><TemperatureSymbol height={height} temperature={air_temperature}/></Col>}
            <Col><YrNoSymbol symbol={symbol1 || symbol6} className="flex" style={tiny ? {marginTop: '-4px'} : {}} height={height}/></Col>
            <Col><WindSpeedDirection mps={wind_speed} height={height} direction={wind_from_direction}/></Col>
        </Row>
        {(!tiny || hover) &&
            <Row className="align-items-center text-center pt-1 gx-0 text-white" style={{marginTop: '-10px'}}>
                <Col><strong>{temperature}</strong></Col>
                {!tiny &&
                    <Col className="d-flex align-items-center justify-content-center">
                        {displayUV && <><strong>UV:&nbsp;</strong><UVIndex index={ultraviolet_index_clear_sky} height="3em"/></>}
                    </Col>}
                <Col><strong>{windSpeed}</strong></Col>
            </Row>}
    </Container>;
}

const SPEED_POINTS = {
    'road': [15, 40, 30],
    'gravel': [10, 40, 25],
    'mtb': [10, 30, 20]
};

function calcTripStart(plannedDate) {
    // noinspection JSAnnotator
    if (plannedDate && DateTime.fromJSDate(plannedDate) > DateTime.now().minus({hours: 12}))
        return DateTime.fromJSDate(plannedDate);
    const now = DateTime.now();
    // noinspection JSAnnotator
    const tripStart = now.hour >= 18 ? now.plus({days: 1}).startOf('day').set({hour: 9}) : now.plus({hours: 2}).startOf('hour');
    return tripStart;
}

const WeatherMapPanel = styled(WeatherPanel)`
    max-width: 280px;
    z-index: ${ZINDEX.WEATHER_PANEL};
    font-size: small !important;
`;

export const ExpandableWeatherPanel = memo(function ExpandableWeatherPanel({routeProps, updateRouteProp, route}) {
    const {value: expanded, toggle: toggleExpanded} = useBoolean();

    return <WeatherMapPanel {...{route, expanded, toggleExpanded, routeProps, updateRouteProp}}/>;
});

const WeatherPanelContents = memo(function WeatherPanelContents({
    className, expanded, forecast, routeProps, toggleExpanded, tripStart, updateRouteProp, ...props
}) {
    const {t} = useTranslation(),
        ref = useRef(),
        {locale} = useLocaleSettings(),
        {isMobile} = useDeviceType(),
        size = SYMBOL_SIZE * (expanded ? 1 : (isMobile ? 0.5 : 0.66));

    // noinspection JSAnnotator
    return <ListGroup className={classNames(className, "maplibregl-ctrl maplibregl-container-reboot small")} {...props} ref={ref}>
        <ListGroup.Item className="py-0 px-1 bg-dark bg-opacity-75">
            <WeatherIcons {...forecast} size={size} className={expanded ? "small" : "x-small"} onClick={toggleExpanded} time={tripStart}/>
        </ListGroup.Item>
        {!expanded &&
            <ListGroup.Item className={classNames("align-items-center text-center py-0 gx-0 bg-dark bg-opacity-75 text-white", isMobile ? 'x-small' : 'smaller')}
                            onClick={toggleExpanded}>
                <Button variant="link" className="x-small text-info py-0 my-0">
                    <FaFlagCheckered className="me-1 text-light"/><span>{humanizeDate(tripStart, t, locale)}</span>
                </Button>
            </ListGroup.Item>}
        {expanded &&
            <WeatherSettings routeProps={routeProps} tripStart={tripStart} updateRouteProp={updateRouteProp} onCloseClick={toggleExpanded}/>}
    </ListGroup>;
});

export const WeatherSettings = memo(function WeatherSettings({onCloseClick, routeProps, tripStart, updateRouteProp}) {
    const {t} = useTranslation(),
        NINE_AM = {hour: 9, minute: 0, second: 0},
        {bikeKind = 'road', plannedDate, ridingSpeed, weatherOnRoute} = routeProps,
        [minSpeed, maxSpeed, defaultSpeed] = SPEED_POINTS[bikeKind],
        tripStartDT = useMemo(() => (tripStart ?? calcTripStart(plannedDate)), [plannedDate, tripStart]);

    const {setPlannedDate, setRidingSpeed, setWeatherOnRoute} = useMemo(() => ({
        setPlannedDate: (value) => updateRouteProp('plannedDate', value, false),
        setRidingSpeed: (value) => updateRouteProp('ridingSpeed', value, true),
        setWeatherOnRoute: (value) => updateRouteProp('weatherOnRoute', value, false)
    }), [updateRouteProp]);

    return <>
        <ListGroup.Item className={classNames('px-2 small')}>
            {onCloseClick && <CloseButton className="float-end" css={css`margin-top: -8px;
                margin-right: -8px;`} onClick={onCloseClick}/>}
            <Stack direction="horizontal" gap={1} className="py-0 align-items-center justify-content-center flex-wrap">
                <small><Trans>Forecast for trip start:</Trans></small>
                <TripStartSelector date={tripStartDT} setDate={setPlannedDate}/>
                <Stack direction="horizontal" gap={0} className="py-0 align-items-center">
                    <TripStartShortcut onClick={() => setPlannedDate(DateTime.now().plus({hours: 2.5}).startOf('hour').toJSDate())}>
                        <Trans>in 2h</Trans>
                    </TripStartShortcut>
                    <div className="vr"/>
                    <TripStartShortcut onClick={() => setPlannedDate(DateTime.now().plus({days: 1}).startOf('day').set(NINE_AM).toJSDate())}>
                        <Trans>tomorrow 9:00</Trans>
                    </TripStartShortcut>
                    <div className="vr"/>
                    {/* `plus` parts ensure that we set next saturday/sunday if we are already after it this week. */}
                    <TripStartShortcut onClick={() => setPlannedDate(DateTime.now().plus({days: 1, hours: 24 - 9}).set({weekday: 6, ...NINE_AM}).toJSDate())}>
                        <Trans>sat 9:00</Trans>
                    </TripStartShortcut>
                    <div className="vr"/>
                    <TripStartShortcut onClick={() => setPlannedDate(DateTime.now().plus({hours: 24 - 9}).set({weekday: 7, ...NINE_AM}).toJSDate())}>
                        <Trans>sun 9:00</Trans>
                    </TripStartShortcut>
                </Stack>
            </Stack>
        </ListGroup.Item>
        <ListGroup.Item className={classNames('px-2')}>
            <Stack direction="horizontal" className="py-0 align-items-end flex-wrap justify-content-center smaller" gap={0}>
                <FormCheck type="switch" label={t("Show weather on route")}
                           checked={weatherOnRoute} onChange={checkToggler(setWeatherOnRoute)}/>
                <StyledRangeSlider tooltipPlacement="bottom" label={t("Your speed on flat")} className="flex-shrink-1 flex-grow-0"
                                   min={minSpeed} max={maxSpeed} step={1}
                                   tooltipLabel={(speed) => t("{{speed}} km/h", {speed})}
                                   value={ridingSpeed ?? defaultSpeed}
                                   setValue={setRidingSpeed} style={{maxWidth: '130px'}}
                                   icons={[GiSnail, FaRocket]}/>
            </Stack>
        </ListGroup.Item>
    </>;

});

export function WeatherPanel({className, expanded = true, route, routeProps, toggleExpanded, updateRouteProp, ...props}) {
    const {plannedDate} = routeProps,
        tripStart = useMemo(() => calcTripStart(plannedDate), [plannedDate]),
        {anchor, isMapIdle} = useAnchor({start: _.head(route)}),
        forecastLngLat = toLngLat(anchor?.start),
        {data: forecast, isError, isLoading} = useYrNoForecast(forecastLngLat, tripStart, null, isMapIdle);

    if (isError) return null;
    if (isLoading)
        return <div className="maplibregl-ctrl px-2 py-2 bg-dark bg-opacity-75 text-white">
            <FaSpinner/> <Trans>Fetching weather...</Trans>
        </div>;

    return <WeatherPanelContents {...{className, expanded, forecast, routeProps, toggleExpanded, tripStart, updateRouteProp, ...props}} />;
}

// Generally we want marker at each hour.
const WEATHER_MARKER_THRESHOLD = 60 * 60;
// 20 minutes climbs (~300m up) is reasonable to display markers, as weather conditions can differ.
const CLIMB_THRESHOLD = 60 * 20;

function getPeaksAndValleys(climbs) {
    // Identify peaks and valleys of the climbs
    climbs = _.filter(climbs, ({endTime, startTime}) => endTime - startTime > CLIMB_THRESHOLD);
    const peaks = _.map(climbs, ({end, endTime, endElevation, coordinates}) => ({idx: end, time: endTime, coord: toLngLat(_.last(coordinates)), elevation: endElevation})),
        valleys = _.map(climbs, ({start, startTime, startElevation, coordinates}) => ({idx: start, time: startTime, coord: toLngLat(_.first(coordinates)), elevation: startElevation}));

    return _.sortBy([...peaks, ...valleys], 'time');
}

function getWeatherMarkersTimes(climbs, coordinates, elevation, times) {
    if (_.isEmpty(times))
        return [];
    const peaksAndValleys = getPeaksAndValleys(climbs),
        [, finishSec] = _.last(times),
        threshold = (finishSec < WEATHER_MARKER_THRESHOLD * 5) ? WEATHER_MARKER_THRESHOLD : WEATHER_MARKER_THRESHOLD * 2;
    const eachHour = _.uniqBy(times, ([, time]) => Math.floor(time / threshold)),
        withCoords = _.map(eachHour, ([idx, time]) => ({idx, time, coord: coordinates[idx] && toLngLat(coordinates[idx]), elevation: elevation[idx]})),
        timesWithCoords = _.filter(withCoords, ({coord}) => !_.isNil(coord));
    const joined = _.uniqBy([...peaksAndValleys, ...timesWithCoords], ({time}) => Math.floor(time / CLIMB_THRESHOLD));
    return _.tail(_.sortBy(joined, ({time}) => time));
}

function calcMarkersPositions({enabled, climbs, coordinates, elevation, times}) {
    // 1. find top/bottoms of the climbs
    // 2. equal time markers
    // 3. filter out when little weather
    if (!enabled) return null;
    const positions = getWeatherMarkersTimes(climbs, coordinates, elevation, times);
    return positions;
}

function WeatherMarker({coord, elevation, markerCoord, positionAdjuster, tripStart, time, ...props}) {
    // noinspection JSAnnotator
    const when = tripStart.plus({seconds: time}),
        {data: forecast, isError, isLoading} = useYrNoForecast(coord, when, elevation);
    if (isLoading || isError || _.isNil(forecast?.air_temperature)) return null;

    return <DivMarker position={getCoord(positionAdjuster(coord))} {...props}>
        <WeatherIcons {...forecast} className="x-small bg-dark bg-opacity-75 rounded-1 px-1" size={24} tiny={true} when={when}/>
    </DivMarker>;
}

// Extracted as global constant to avoid rerender reason which did happen with style={{zIndex: ...}}.
const WEATHER_ZINDEX_STYLE = {zIndex: ZINDEX.WEATHER_MARKER};

export const WeatherMarkers = memo(function WeatherMarkers({climbs, coordinates, elevation, enabled = true, plannedDate, positionAdjuster, times}) {
    const tripStart = useMemo(() => calcTripStart(plannedDate), [plannedDate]),
        markerPositions = useMemo(
            () => calcMarkersPositions({enabled, climbs, coordinates, elevation, times}),
            [enabled, climbs, coordinates, elevation, times]);

    if (_.isEmpty(markerPositions)) return null;

    // maplibre Marker does not support className, so pass zindex as style.
    return _.map(markerPositions, ({time, coord, elevation}, idx) =>
        <WeatherMarker key={`${Math.floor(time / 60)}-${idx}`} time={time} coord={coord} style={WEATHER_ZINDEX_STYLE}
                       elevation={elevation} positionAdjuster={positionAdjuster} tripStart={tripStart}/>
    );
});
