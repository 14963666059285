import styled from '@emotion/styled';
import classNames from 'classnames';
import {arrayRemove, arrayUnion, orderBy, where} from 'firebase/firestore';
import _ from 'lodash';
import React, {forwardRef, memo, useCallback, useMemo} from 'react';
import {Badge, Button, ButtonGroup, Card, Dropdown, Form, Stack} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {BsRocketTakeoff, BsRocketTakeoffFill} from 'react-icons/bs';
import {FaRegStar, FaStar} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {down, up} from 'styled-breakpoints';
import {useBoolean} from 'usehooks-ts';
import {routeConverter} from '../lib/converters';
import {useCurrentUserData, useFirebaseCollectionData, useFirebaseDocData, useFirebaseDocMutationInvalidate} from '../lib/db';
import {FormCheckWithPopover, PopoverTrigger} from '../lib/toasts';
import {TimeAgo} from './Format';
import {BikeKindIcon, RoutePropertiesCard} from './RouteMapping';
import {FollowingButton, User, UserButton} from './User';


export function RouteCard({route, hideUserBox, ...props}) {
    const {created, id: routeId, routeProps: {name, owner, thumbnail, bikeKind}} = route;
    return (
        <Card {...props} className="my-3">
            <Card.Header className="p-0 position-relative">
                {/* `position-static` class is applied on the div to 'stretch' inner link outside the Col, up to the nearest `position:relative` element, being the Card.
                    See also: https://getbootstrap.com/docs/4.5/utilities/stretched-link/ */}
                <Card.Img variant="top" src={thumbnail?.downloadUrl ?? '/static/Tarmacs.App.Splash.png'} style={{maxHeight: 300, objectFit: 'cover'}} className="mb-2"/>
                <ButtonGroup className="position-absolute top-0 end-0 gx-0 me-1 mt-1 prevent-stretched-link">
                    <FavToggler routeId={routeId} variant="outline-primary" className="lh-1 align-middle"/>
                    <PraiseToggler routeId={routeId} className="lh-1 align-middle"/>
                </ButtonGroup>
                <div className="mx-0 my-1 d-flex flex-wrap align-items-center">
                    <div className="position-static ms-2 me-1 flex-grow-1">
                        <Link className="stretched-link" to={`/routes/view/${routeId}`}>{name}</Link>
                        &nbsp;<BikeKindIcon bikeKind={bikeKind} size="1.5em" className="align-bottom ms-2"/><br/>
                        <small style={{fontSize: 'smaller'}}>
                            <Trans i18nKey="Designed <timeago/>" components={{timeago: <TimeAgo date={created}/>}}/>
                        </small>
                    </div>
                    {!hideUserBox &&
                        <div className="mx-1 prevent-stretched-link flex-grow-1">
                            <User userId={owner} size={30} className="ms-auto"/>
                        </div>
                    }
                </div>
            </Card.Header>
            <Card.Body className="p-0">
                <Stack className="justify-content-start">
                    <RoutePropertiesCard track={route?.track} className=""/>
                </Stack>
            </Card.Body>
        </Card>
    );
}

export function PrivateToggler({isPrivate, togglePrivate}) {
    const {t} = useTranslation();
    return <FormCheckWithPopover id="toggle-route-private" control={Form.Switch} label={t("Hide the route in other users' feeds")}
                                 checked={!!isPrivate} onChange={({target: {checked}}) => togglePrivate(checked)}>
        <Trans>Hides this route in feeds. The route is still visible on your profile and you can share its link to others.</Trans>
    </FormCheckWithPopover>;
}

export function FavToggler({routeId, variant = "link", size = "lg", ...props}) {
    const {t} = useTranslation();
    return <RouteUidToggler {...{routeId, variant, size, ...props}} field="starred" canUntoggle={true} canToggleOwn={true} counter={false}
                            headings={[t("Add to starred routes"), t("Starred route")]}
                            tooltips={[
                                t("Click the star icon to add this route to your `Starred routes` collection."),
                                t("You have added this route to your `Starred routes` collection.")]}
                            icons={[FaRegStar, FaStar]}/>;
}

export function PraiseToggler({routeId, size = "lg", ...props}) {
    const {t} = useTranslation();
    const header = <><BsRocketTakeoffFill className="text-danger align-middle me-1"/><Trans>Users who praised this route</Trans>:</>;

    return <RouteUidToggler {...{routeId, size, ...props}} field="praised" canUntoggle={false} canToggleOwn={false} counter={true}
                            headings={[t("Praise!"), t("Praises")]}
                            header={header}
                            variants={['outline-danger', 'danger']}
                            tooltips={[
                                t("Praise this route!"),
                                t("Users who praised this route")
                            ]}
                            icons={[BsRocketTakeoff, BsRocketTakeoffFill]}/>;
}

function UserDidThat({userData, className}) {
    return <UserButton userData={userData} size={24} avatarSide="start">
        {({show, doShow, doHide, setShow, content, isCurrentUser, isOnUserPage, userRoutesPageUrl}) =>
            <Stack direction="horizontal" gap={1} className={classNames("align-items-center", className)}>
                <div className="d-flex flex-row align-items-middle flex-grow-1 flex-shrink-1">{content}</div>
                {!isCurrentUser &&
                    <div className="ms-auto text-end flex-shrink-0">
                        <FollowingButton allowUnfollow={false} followedId={userData.uid} size="sm"
                                         className="py-0 align-middle small"/>
                    </div>}
            </Stack>
        }
    </UserButton>;
}

const WhoDidThat = forwardRef(function WhoDidThat({routeId, header, verb}, ref) {
    const {data: activities} = useFirebaseCollectionData(['feed', 'notifications', 'indices'],
        [
            where('object.id', '==', routeId), where('verb', '==', verb),
            orderBy('created', 'desc')]);

    return <Card ref={ref}>
        <Card.Header>
            {header}
        </Card.Header>
        <Card.Body>
            {_.map(activities, ({actor}) =>
                <UserDidThat key={actor.uid} userData={actor} className="small"/>
            )}
        </Card.Body>
    </Card>;
});

export const WhoDidThatDropdownMenuStyled = styled(Dropdown.Menu)`
    max-height: 300px;
    overflow-y: auto;
    transform: translate(0, -2px);
    font-size: small;

    ${down('md')} {
        min-width: 85vw;
    }

    ${up('md')} {
        min-width: 30em;
    }
`;

const DropdownWrapper = forwardRef(function DropdownWrapper({children, onClick, header, routeId, onToggle, show, verb, ...props}, ref) {
    return <Dropdown ref={ref} onToggle={onToggle} show={show} as={ButtonGroup}>
        <Dropdown.Toggle {...props}>
            {children}
        </Dropdown.Toggle>
        <WhoDidThatDropdownMenuStyled id="route-dropdown-menu" className="pb-0 pt-0 w-100" align="end">
            <WhoDidThat routeId={routeId} verb={verb} header={header}/>
        </WhoDidThatDropdownMenuStyled>
    </Dropdown>;
});

const RouteUidToggler = memo(function RouteUidToggler({
    children, counter, routeId, field, headings, tooltips, iconSize = "1em", icons, canUntoggle, canToggleOwn, variants, variant = "link", size = "lg", ...props
}) {
    const [user] = useCurrentUserData(),
        {data: route} = useFirebaseDocData(['routes', routeId], routeConverter(user), null, {keepPreviousData: true}),
        // Intentionally no routeConverter because it would annotate other fields resulting in permissions error.
        {mutate: mutateRoute} = useFirebaseDocMutationInvalidate(['routes', routeId]),
        [isStarred, numStars] = useMemo(() => ([user?.uid && _.includes(route?.routeProps?.[field], user.uid), _.size(route?.routeProps?.[field])]),
            [user?.uid, route?.routeProps?.[field]]),
        cannotToggleOwn = !(canToggleOwn || user?.uid !== route?.routeProps?.owner),
        canToggle = !cannotToggleOwn && (!isStarred || canUntoggle),
        toggleFav = useCallback((evt) => {
            const newState = !isStarred;
            canToggle && mutateRoute({[field]: newState ? arrayUnion(user.uid) : arrayRemove(user.uid)});
            evt.stopPropagation();
            evt.preventDefault();
        }, [canToggle, canUntoggle, mutateRoute, isStarred, user]),
        {value: showPopover, setValue: setShowPopover} = useBoolean(),
        {value: showDropdown, setValue: setShowDropdown} = useBoolean();

    if (!user)
        return null;

    const [heading, tooltip, ButtonIcon] = _.map([headings, tooltips, icons], (isStarred || cannotToggleOwn) ? '[1]' : '[0]'),
        btnVariant = cannotToggleOwn ? 'outline-secondary' : (variants ? variants[isStarred ? 1 : 0] : variant),
        ButtonComponent = (canToggle || !numStars) ? Button : DropdownWrapper,
        dropdownAttrs = (canToggle || !numStars) ? {} : {onToggle: setShowDropdown, show: showDropdown, routeId},
        disabled = !canToggle && !numStars;

    return <PopoverTrigger heading={heading} tooltip={tooltip} delay={{show: 2000, hide: 0}} onToggle={setShowPopover} show={showPopover && !showDropdown}>
        <ButtonComponent variant={btnVariant} size={size} onClick={toggleFav} verb={field} disabled={disabled}
                         {...{...props, ...dropdownAttrs}}>
            <ButtonIcon size={iconSize}/>{children}
            {counter && numStars > 0 && <Badge bg="secondary" className="x-small ms-1">{numStars}</Badge>}
        </ButtonComponent>
    </PopoverTrigger>;
});

