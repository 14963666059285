// Based on https://www.w3schools.com/howto/howto_css_image_overlay_icon.asp
import styled from '@emotion/styled';


export const ImageIconContainer = styled.div`
    position: relative;
`;

export const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: .3s ease;
  background-color: none;    
`;

