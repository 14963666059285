import {t_} from '../components/l10n';


export const BIKE_KINDS = {
    road: t_("Road"),
    gravel: t_("Gravel"),
    mtb: t_("MTB")
};

export const BIKE_KIND_TITLES = {
    road: t_("Road cycling route"),
    gravel: t_("Gravel cycling route"),
    mtb: t_("MTB cycling route")
}

export const EPIC_CYCLING_ROUTES = {
    road: t_("Epic road cycling route"),
    gravel: t_("Epic gravel cycling route"),
    mtb: t_("Epic MTB cycling route")
}