import {getCoords} from '@turf/turf';
import _ from 'lodash';
import percentile from 'percentile';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Breadcrumb, Spinner} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import {useMutation, useQuery} from 'react-query';
import {useMediaQuery} from 'react-responsive';
import {LinkContainer} from 'react-router-bootstrap';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import {useCounter, useTimeoutFn} from 'react-use';
import {off, on} from 'react-use/lib/misc/util';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {getDataOrThrow} from './errors';
import {showError} from './toasts';


export function stateSetter(setterFn, converter = ((v) => v)) {
    return ({target: {value}}) => setterFn(converter(value));
}

export function checkToggler(setterFn) {
    return ({target: {checked}}) => setterFn(checked);
}

export function flagSetter(setterFn, flag = true) {
    return () => setterFn(flag);
}

/**
 * Wraps handler with prevention of form submit through HTTP POST.
 */
export function preventSubmit(handler) {
    return (evt, ...args) => {
        try {
            handler(evt, ...args);
        } catch (e) {
            console.error(e);
        }
        evt.preventDefault();
        evt.stopPropagation();
        return false;
    };
}

export function validatingHandler(setValidated, handler) {
    return preventSubmit((evt, ...args) => {
        if (evt.currentTarget.checkValidity()) {
            handler(evt, ...args);
        }
        setValidated(true);
    });
}

export function packSegments(values) {
    const mergeSameValues = (packed, val, idx) => {
        const last = _.last(packed) ?? {},
            {val: lastVal} = last;
        if (!idx || lastVal !== val)
            packed.push({start: idx, end: idx, val});
        else
            last.end = idx;
    };
    return _.map(_.transform(values, mergeSameValues, []), ({start, end, val}) => ([start, end + 1, val]));
}

export function mergeSameEdges(values) {
    const mergeTransformer = (packed, [start, end, ...val], idx) => {
        const last = _.last(packed) ?? [],
            [, , ...lastVal] = last;
        if (!idx || lastVal !== val)
            packed.push([start, end, ...val]);
        else
            last[1] = end;
    };
    return _.transform(values, mergeTransformer, []);
}

/**
 * Worarkound for "Warning: Invalid value for prop `navigate` on `<li>` tag. Either remove it from the element,
 * or pass a string or number value to keep it in the DOM. For details, see https://reactjs.org/link/attribute-behavior"
 * in li in ./node_modules/react-bootstrap/esm/BreadcrumbItem.js
 */
function BreadcrumbItem({navigate, ...props}) {
    return <Breadcrumb.Item {...props} />;
}

export function Breadcrumbs({brand, routes}) {
    const breadcrumbs = useBreadcrumbs(routes, {disableDefaults: true});

    if (_.isEmpty(breadcrumbs))
        return null;

    return <Breadcrumb className="align-items-center flex text-nowrap flex-shrink-1 flex-wrap flex-grow-1"
                       listProps={{className: 'bg-transparent pb-1 pt-1 mb-0 mt-0 ps-0 align-items-center flex-wrap flex-md-nowrap'}}>
        <LinkContainer key="root" to="/routes/view">
            <BreadcrumbItem className="col-xs-auto" linkProps={{className: 'text-light'}}>{brand}</BreadcrumbItem>
        </LinkContainer>
        {_.map(breadcrumbs, ({key, match, breadcrumb}) => {
                return <LinkContainer key={key} to={match.pathname}>
                    <BreadcrumbItem className="col-xs-auto" linkProps={{className: 'text-light'}}>{breadcrumb}</BreadcrumbItem>
                </LinkContainer>;
            }
        )}
    </Breadcrumb>;
}

export function toHostPath({urlString: url = null, pathname = null, searchParams = null} = {}) {
    url = new URL(url ?? window.location.href);
    url.search = '';
    url.hash = '';
    if (!_.isNil(pathname))
        url.pathname = pathname;
    if (!_.isEmpty(searchParams))
        url.search = new URLSearchParams(searchParams).toString();
    return url.toString();
}

// Exactly like bootstrap breakpoints.
const _BREAKPOINTS = {xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400};

export function useMediaBreakpoint(breakpoints, breakpoint, query) {
    const width = breakpoints[breakpoint];
    return useMediaQuery({query: `(${query}: ${width}px)`});
}

export function useMinMediaBreakpoint(breakpoint) {
    return useMediaBreakpoint(_BREAKPOINTS, breakpoint, 'min-width');
}

export function useMaxMediaBreakpoint(breakpoint) {
    const [bps, widths] = _.unzip(_.toPairs(_BREAKPOINTS)),
        // Follow what bootstrap does. See also: https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
        maxWidths = _.map(widths, w => w - 0.02),
        breakpoints = {..._.zipObject(_.initial(bps), _.tail(maxWidths)), xxl: 9999};
    return useMediaBreakpoint(breakpoints, breakpoint, 'max-width');
}

export function useDeviceType() {
    const isDesktop = useMinMediaBreakpoint('lg'),
        isMobile = useMaxMediaBreakpoint('md');
    return {isMobile, isDesktop};
}

export function useDeviceTypeVariant({mobile, desktop}) {
    const {isMobile} = useDeviceType();
    return useMemo(() => (isMobile ? mobile : desktop), [isMobile, mobile, desktop]);
}

export function MediaBreakpoint({min = 'xs', max = 'xxl', children}) {
    const [minOK, maxOK] = [useMinMediaBreakpoint(min), useMaxMediaBreakpoint(max)];
    return minOK && maxOK && children;
}

export function MobileOnly({children}) {
    return <MediaBreakpoint max="md">{children}</MediaBreakpoint>;
}

export function DesktopOnly({children}) {
    return <MediaBreakpoint min="lg">{children}</MediaBreakpoint>;
}

export function generateId() {
    const dec2hex = (dec) => dec.toString(16).padStart(2, "0");
    var arr = new Uint8Array((40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
}

export function median(values, getter) {
    return percentile(50, values, getter);
}

export function SpinningLoader() {
    // Fixed height (more than 2rem of the spinner)is necessary to avoid shaking scroll when spinner spins.
    return <div className="w-100 d-flex justify-content-center" style={{height: "3rem"}}>
        <Spinner animation="border" role="status">
            <span className="visually-hidden"><Trans>Loading...</Trans></span>
        </Spinner>
    </div>;
}

export function useForceUpdate() {
    const [, {inc}] = useCounter(0);
    return inc;
}

export function getCenter(bbox, roundTo = _.identity) {
    if (_.isEmpty(bbox))
        return null;
    const [[bottom, left], [top, right]] = bbox;

    return [roundTo((top + bottom) / 2), roundTo((left + right) / 2)];
}

export async function fetchJSON(baseUrl, pathname, searchParams, fetchOptions) {
    const {method = 'GET'} = fetchOptions ?? {},
        url = toHostPath({pathname, searchParams, urlString: baseUrl}),
        resp = await fetch(url, {...fetchOptions, method}),
        data = await getDataOrThrow(resp);
    return data;
}

export async function fetchBackendAPI(pathname, searchParams, fetchOptions) {
    return await fetchJSON(process.env.REACT_APP_BACKEND_URL, pathname, searchParams, fetchOptions);
}

function useFetchJSONQuery(baseUrl, pathname, searchParams, fetchOptions, queryOptions) {
    const {enabled = true} = queryOptions ?? {};

    const query = useQuery([pathname, searchParams], async function fetchJsonQuery() {
        return await fetchJSON(baseUrl, pathname, searchParams, fetchOptions);
    }, {...(queryOptions ?? {}), enabled: !!enabled});
    return query;
}

export function useAPIQuery(pathname, searchParams, fetchOptions, queryOptions) {
    return useFetchJSONQuery(process.env.REACT_APP_API_URL, pathname, searchParams, fetchOptions, queryOptions);
}

export function useBackendAPIQuery(pathname, searchParams, fetchOptions, queryOptions) {
    return useFetchJSONQuery(process.env.REACT_APP_BACKEND_URL, pathname, searchParams, fetchOptions, queryOptions);
}

export function useDataAPIQuery(pathname, searchParams, fetchOptions, queryOptions) {
    return useFetchJSONQuery(process.env.REACT_APP_DATA_URL, pathname, searchParams, fetchOptions, queryOptions);
}

export function useFetchMutation(baseUrl, pathname, searchParams, fetchOptions, mutationOptions) {
    const {method = 'POST', json = {}} = fetchOptions ?? {};
    const {onError = ((error) => showError(error))} = mutationOptions ?? {};

    const mutation = useMutation(
        async function fetchMutation_(variables= {}) {
            const {json: jsonOverride = {}, params = searchParams} = variables ?? {};
            return await fetchJSON(baseUrl, pathname, params, {...(fetchOptions ?? {}), method, json: {...json, ...jsonOverride}});
        }, {...(mutationOptions ?? {}), onError});
    return mutation;
}

export function useAPIMutation(pathname, searchParams, fetchOptions, mutationOptions) {
    return useFetchMutation(process.env.REACT_APP_API_URL, pathname, searchParams, fetchOptions, mutationOptions);
}

export function useBackendAPIMutation(pathname, searchParams, fetchOptions, mutationOptions) {
    return useFetchMutation(process.env.REACT_APP_BACKEND_URL, pathname, searchParams, fetchOptions, mutationOptions);
}

/**
 * State setting the actual state value after specified delay. If a new value is set before, the previous value is skipped and delay timer is reset.
 * @returns [state, setDelayedState, cancel, setImmediateState]
 */
export function useDelayedSetState(delay, initial) {
    const [state, setImmediateState] = useState(initial),
        [pending, setPending] = useState(initial);
    const setStateFn = useCallback(() => setImmediateState((s) => _.isEqual(s, pending) ? s : pending), [pending, setImmediateState]),
        [, cancelTimeout, resetTimeout] = useTimeoutFn(setStateFn, delay),
        cancel = useCallback(({clear} = {}) => {
            cancelTimeout();
            clear && setImmediateState(initial);
        }, [cancelTimeout, setImmediateState, initial]),
        setDelayedState = useCallback((state) => {
            setPending((s) => _.isEqual(s, state) ? s : state);
            resetTimeout();
        }, [setPending, resetTimeout]),
        toggleImmediateState = useCallback(
            (state) => setImmediateState((prev) => _.isEqual(prev, state) ? initial : state),
            [initial, setImmediateState]);

    const delayedSetState = useMemo(
        () => ([state, setDelayedState, cancel, setImmediateState, toggleImmediateState]),
        [state, setDelayedState, cancel, setImmediateState, toggleImmediateState]);
    return delayedSetState;
}

/**
 * Mark as full-screen view on mount, with callback to unmark on dismount.
 */
export function useFillViewEffect(enabled = true) {
    useEffect(() => {
        document.documentElement.classList.toggle('fill-view', enabled);
        return () => {
            document.documentElement.classList.toggle('fill-view', false);
        };
    }, [enabled]);
}


/**
 * React.router's useSearchParams but handling params as object, not URLSearchParams.
 */
export function useSearchParamsObject(defaultInit) {
    const [params, setParams] = useSearchParams(defaultInit);
    const paramsObject = useMemo(() => Object.fromEntries(params), [params]);

    const setParamsObject = useCallback((paramsOrSetterFn, navigateOptions) => {
        // setParamsObjectFn does not base upon `params` param because it is memoized, resulting in stale values when going through redux thunk sequence.
        // we use `params` only to signal "no change" (same object must be returned).
        function setParamsObjectFn(params) {
            const currentParams = new URLSearchParams(window.location.search);
            const paramsObj = Object.fromEntries(currentParams);
            const newParamsObj = paramsOrSetterFn(paramsObj);
            // Return original params if paramsOrSetterFn returned original paramsObj to comply functional update protocol.
            const result = _.isEqual(newParamsObj, paramsObj) ? params : createSearchParams(newParamsObj);
            return result;
        }

        const newSearchParamsOrSetterFn = typeof paramsOrSetterFn === "function" ?
            setParamsObjectFn : createSearchParams(paramsOrSetterFn);
        return setParams(newSearchParamsOrSetterFn, navigateOptions);
    }, [setParams]);

    return [paramsObject, setParamsObject];
}

/**
 * Ensures the props are consumed by styled and not forwarded to the component (via shouldForwardProp).
 */
export function consumeProps(...props) {
    return {shouldForwardProp: (prop) => !_.includes(props, prop)};
}

/**
 * Return flat list of coordinates from a feature being LineString or MultiLineString.
 */
export function getLineStringCoords(feature) {
    return feature && feature.geometry?.type === 'MultiLineString' ? _.flatten(getCoords(feature)) : getCoords(feature);
}