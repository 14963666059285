import styled from '@emotion/styled';
import {bearing, featureCollection, getCoord, getCoords, point} from '@turf/turf';
import _ from 'lodash';
import {memo, useMemo} from 'react';
import {Layer, Source} from 'react-map-gl';
import {toLngLat} from '../lib/getClosestPointAndSegment';
import {pixelDistanceOnMap, useMapLibre, useMapLibreEvents} from '../lib/useMapLibre';
import {TRACK_POI_SIZE} from '../lib/useRBushPOIsReducer';
import {useTrackMarkerPositionAdjuster} from '../lib/useTrackMarkerPositionAdjuster';
import {ZINDEX} from '../lib/zindexes';
import {DivMarker} from './DivMarker';
import {MapPopup, POI_WITH_POPUPS, POIMarker, routePOI, useMapLibreIcon, usePOIPopup} from './POIPopup';
import {useViewState} from './ViewStateContext';


export function PointsOfInterest({pois, routeProps, track, onPointInsert}) {
    // Handles two kinds of POIs:
    //  * MapLibrePOIs - hooks events to the features on the base vector layer of the map
    //  * TrackPOIs - display react-map-gl Markers for POIs near the route.
    // POIPopup component is common for both. Displays a popup with more information and some controls.
    return <>
        {routeProps?.showTrackPOIs !== false &&
            <TrackPOIs pois={pois} track={track}/>}
        <MapLibrePOIsEvents/>
        <MapPopup {...{routeProps, track, onPointInsert}} />
    </>;
}

function LineStringDebug({feature, uid = ''}) {
    const collection = feature?.type === 'FeatureCollection' ? feature : featureCollection([feature]);

    return <Source data={collection} id={`debug-sadasddsad-${uid}`} type="geojson">
        <Layer id={`debug-sadasddsad-layer-${uid}`} type="line" paint={{'line-color': '#FF0000', 'line-width': 3}}></Layer>
    </Source>;
}

function TrackPOI({poi, positionAdjuster, zooming}) {
    const {feature} = poi,
        markerPos = useMemo(() => positionAdjuster(getCoord(feature)), [feature, positionAdjuster]);

    return feature && <POI poi={poi} markerPos={markerPos} zooming={zooming}/>;
}

export const TrackPOIs = memo(function TrackPOIs({pois, track, debug = false}) {
    const {bounds, zooming} = useViewState(),
        [positionAdjuster, bufferedTrack, poly] = useTrackMarkerPositionAdjuster(track?.coordinates, TRACK_POI_SIZE * 0.75),
        visiblePOIs = useMemo(() => {
            return _.filter(pois, ({feature}) => getCoord(feature) && bounds?.contains?.(getCoord(feature)));
        }, [bounds, pois]);

    return <>
        {debug &&
            <><LineStringDebug feature={poly} uid="1"/><LineStringDebug feature={bufferedTrack} uid="2"/></>}
        {_.map(visiblePOIs, (poi) =>
            poi.feature && <TrackPOI key={poi.feature.id} poi={poi} positionAdjuster={positionAdjuster} zooming={zooming}/>)}
    </>;
});

const POILine = styled.div`
    position: absolute;
    height: 2px;
    transform-origin: top left;
    top: 50%;
    left: 50%;
    background-color: grey;
    opacity: 75%;
`;

const POIIcon = styled(PointOfInterestOnTrack)`
    position: relative;
    z-index: 1;
`;

const PointOfInterestLine = memo(function PointOfInterestLine({start, end}) {
    const {mapLibre} = useMapLibre(),
        angle = bearing(start, end),
        distancePx = pixelDistanceOnMap(mapLibre, getCoord(start), getCoord(end)),
        style = {transform: `rotate(${angle - 90}deg)`, width: `${distancePx}px`};

    // Using inline style for dynamic arguments - see https://emotion.sh/docs/best-practices#use-the-style-prop-for-dynamic-styles
    return <POILine style={style}/>;
});

const PointOfInterestIcon = memo(function PointOfInterestIcon({size, feature}) {
    return <POIIcon size={size} feature={feature}/>;
});

const POI_ZINDEX_STYLE = {zIndex: ZINDEX.POI_MARKER};

const POI = memo(function POI({poi: {feature, position}, markerPos, zooming}) {
    const {geometry} = feature,
        trackPos = useMemo(() => point(toLngLat(position.point)), [position]);

    if (!geometry)
        return null;

    // zIndex 500 is between 0 (volatile marker) and 900 (markers of route points).
    return <DivMarker key={feature.id} size={TRACK_POI_SIZE} position={getCoords(markerPos)}
                      style={POI_ZINDEX_STYLE} draggable={false}>
        {!zooming &&
            <PointOfInterestLine start={markerPos} end={trackPos}/>}
        <PointOfInterestIcon size={TRACK_POI_SIZE} feature={feature}/>
    </DivMarker>;
});

export function getPOIFeature(maplibreFeature, evt, isRoutePoint, layer) {
    if (!maplibreFeature) return null;
    const poi = routePOI(maplibreFeature, undefined, layer),
        featureCoords = getCoord(poi.feature),
        // Prefer exact coordinates of POI over where mouse was.
        // Not sure if coordinates are always a single point (could be a polygon?), so fallback to mouse coordinates.
        lngLat = _.size(featureCoords) === 2 ? {lng: featureCoords[0], lat: featureCoords[1]} : evt?.lngLat;
    return {...poi, point: evt?.point, lngLat, isRoutePoint};
}

export function PointOfInterestOnTrack({size, feature, isRoutePoint = false, ...props}) {
    const {layer, icon} = useMapLibreIcon(feature),
        [, setFeature, cancelFeature, , toggleFeatureNow] = usePOIPopup();

    // noinspection JSUnusedGlobalSymbols
    const events = useMemo(() => ({
        onMouseOver(evt) {
            if (evt?.buttons) return; // Avoid showing popups when dragging.
            const poi = getPOIFeature(feature, evt, isRoutePoint, layer);
            setFeature?.(poi);
        },
        onMouseOut(event) {
            cancelFeature?.();
        },
        onClick(evt) {
            const poi = getPOIFeature(feature, evt, isRoutePoint, layer);
            toggleFeatureNow(poi);
            evt?.preventDefault();
            evt?.stopPropagation();
        }
    }), [cancelFeature, feature, layer, setFeature, toggleFeatureNow, isRoutePoint]);

    if (!icon) return null;
    return <POIMarker size={size} icon={icon} {...events} {...props}/>;
}

/**
 * Hooks events to the features on the base vector layer of the map
 */
const MapLibrePOIsEvents = memo(function MapLibrePOIs() {
    const [{feature: currentFeature}, setFeature, cancelFeature] = usePOIPopup(),
        {zoom} = useViewState(),
        mapLibreEvents = useMemo(() => {
            const mouseOverHandler = (event) => {
                if (event?.originalEvent?.buttons) return; // Avoid showing popups when dragging.
                const feature = _.first(event?.features),
                    poi = getPOIFeature(feature, event, false);
                poi && setFeature?.(poi);
            };
            return [
                ..._.map(POI_WITH_POPUPS, (title, poi) => ['mouseover', poi, mouseOverHandler]),
                ..._.map(POI_WITH_POPUPS, (title, poi) => ['mouseleave', poi, () => { cancelFeature?.(); }]),
                ['mouseout', () => { cancelFeature?.(); }],
                ['click', (event) => {
                    console.debug(`mapEvent.click (MapLibrePOIs): currentFeature=${currentFeature}`, currentFeature, event?.features, event?.originalEvent?.features, event, event?.originalEvent, event?.originalEvent?.target?.classList);
                    if (currentFeature) {
                        // Cancel current feature (but only if truthy, to avoid race with Climbs.onClickDisplayPopup)
                        cancelFeature?.({clear: true});
                        // This is necessary to prevent drawing the next route point when user clicks the map intending to close a popup.
                        event?.originalEvent?.stopImmediatePropagation?.();
                    }
                }]
            ];
            // TODO: verify if need to intentionally invalidate on zoom change, as we need to rehook events.
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [setFeature, cancelFeature, currentFeature, zoom]);

    useMapLibreEvents(mapLibreEvents);
    return null;
});

