import _ from 'lodash';
import {createContext, useCallback, useContext, useState} from 'react';


export const PageLoadingContext = createContext({loading: false, toggleLoading: _.noop});

export function PageLoadingProvider({children}) {
    const [loading, toggleLoading_] = useState(false);

    const toggleLoading = useCallback((state) => {
        console.log('toggleLoading', state);
        return toggleLoading_(state);
    }, [toggleLoading_]);
    return <PageLoadingContext.Provider value={{loading, toggleLoading}}>{children}</PageLoadingContext.Provider>;
}

export function usePageLoadingToggle() {
    const {loading, toggleLoading} = useContext(PageLoadingContext);
    return [loading, toggleLoading];
}