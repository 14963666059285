import _ from 'lodash';
import {Form, InputGroup} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FaLock} from 'react-icons/fa';


export function PasswordInput({autoComplete, isInvalid, name, value, onChange, invalidPasswordFeedback}) {
    const {t} = useTranslation();
    return <InputGroup className="mb-3" hasValidation>
        <InputGroup.Text><FaLock/></InputGroup.Text>
        <Form.Control autoComplete={autoComplete} className="form-control" name={name}
                      placeholder={t("Password")} type="password" value={value}
                      onChange={onChange} isInvalid={isInvalid ?? !_.isEmpty(invalidPasswordFeedback)}/>
        <Form.Control.Feedback type="invalid">
            {invalidPasswordFeedback}
        </Form.Control.Feedback>
    </InputGroup>;
}