import classNames from 'classnames';
import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {Alert, FormCheck, Spinner, Stack, ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {MdOutlineWarningAmber} from 'react-icons/md';
import {useSet} from 'react-use';
import {useCurrentUserProp} from '../lib/db';
import {defaultVisiblePOIs} from '../lib/routing';
import {checkToggler, useDeviceType} from '../lib/util';
import {t_} from './l10n';
import {POIMarker} from './POIPopup';


const POIMARKERS_VISIBILITY_TOGGLES = [
    ['cafe_15', 'supplies', t_("Supplies"), t_("Food, beverages, water sources")],
    ['attraction_15', 'touristic', t_("Touristic"), t_("Castles, view points, caves, waterfalls")],
    ['railway_15', 'transport', t_("Transport"), t_("Bus stops, train stations, parkings"), {marginTop: '1px'}],
    ['bicycle_15', 'service', t_("Services"), t_("Lodging, bike repair, gas stations")]
];

export function POIMarkersToggleButtonGroup({className, disabled, id='', isRoute, poisLoading, visiblePOIs, setVisiblePOIs}) {
    const {t} = useTranslation(),
        [spinning, {add: addSpinning, reset: resetSpinning}] = useSet(new Set());
    visiblePOIs = visiblePOIs ?? defaultVisiblePOIs(isRoute);

    // TODO: Debug why <PopoverTrigger tooltip={t(tooltip)} heading={t(heading)} placement="bottom"> prevents toggling buttons :/
    const buttons = _.map(POIMARKERS_VISIBILITY_TOGGLES, ([icon, value, heading, tooltip, style]) =>
        <ToggleButton type="checkbox" variant="outline-primary" size="sm"
                      key={value} value={value} title={`${t(heading)}: ${t(tooltip)}`} disabled={disabled}
                      id={`${id}poi-marker-visibility-${value}`} className="text-center pt-1 pb-0">{
            poisLoading && spinning.has(value) ?
                <Spinner as="span" size="sm" className="ms-2 align-middle" animation="border" role="status" aria-hidden="true"/> :
                <POIMarker icon={icon} className="d-inline-block" style={style} size={24}/>
        }</ToggleButton>
    );

    function onChange(val, {target}) {
        target?.value && addSpinning(target.value);
        setVisiblePOIs(val);
    }

    useEffect(() => {!poisLoading && resetSpinning();}, [resetSpinning, poisLoading]);

    return <ToggleButtonGroup onChange={onChange} value={visiblePOIs}
                              type="checkbox" className={className} variant="outline-primary">
        {buttons}
        <ToggleButton type="checkbox" variant="outline-primary" size="sm" disabled={disabled}
                      value={'warnings'} title={`${t("Warnings")}: ${t("Tunnels, barriers, cones")}`}
                      id={`${id}poi-marker-visibility-warnings`} className="text-center pt-1 pb-0">
            <MdOutlineWarningAmber size="1.66em"/>
        </ToggleButton>
    </ToggleButtonGroup>;
}

function useSelectedPOIs(show, pois) {
    const [sendPOIs, toggleSendPOIs] = useCurrentUserProp('send_pois', true),
        [visiblePOIs, setVisiblePOIs] = useState(pois ?? defaultVisiblePOIs(true)),
        selectedPOIs = useMemo(() => (sendPOIs ? visiblePOIs : []), [sendPOIs, visiblePOIs]);

    useEffect(() => {show && setVisiblePOIs(pois ?? defaultVisiblePOIs(true));}, [setVisiblePOIs, show, pois]);

    return {visiblePOIs, sendPOIs, setVisiblePOIs, toggleSendPOIs, selectedPOIs};
}

export function TrackPOIsSelector({className, gap = 3, exportPOIs, id='', setSelectedPOIs, show, infoBox = true, ...props}) {
    const {t} = useTranslation(),
        {isMobile} = useDeviceType(),
        {visiblePOIs, sendPOIs, setVisiblePOIs, toggleSendPOIs, selectedPOIs} = useSelectedPOIs(show, exportPOIs);

    useEffect(() => {setSelectedPOIs(selectedPOIs);}, [selectedPOIs]);

    return <Stack direction="vertical">
        <Stack {...props} className={classNames({smaller: isMobile}, className)} gap={gap}>
            <FormCheck type="switch" label={t("Include course points on the track:")} id="${id}include-course-points"
                       checked={sendPOIs} onChange={checkToggler(toggleSendPOIs)}/>
            <POIMarkersToggleButtonGroup isRoute={true} disabled={!sendPOIs} id={id}
                                         setVisiblePOIs={setVisiblePOIs} visiblePOIs={visiblePOIs}/>
        </Stack>
        {infoBox &&
            <Alert variant="info" className={classNames("small text-center p-2", isMobile ? "m-0 mt-2" : "m-3")}>
                <Trans>GPS will notify 100m before a course point. Some may be up to 200m off track:<br/>←«‹ ›»→ arrows indicate side and distance from track.</Trans>
            </Alert>
        }
    </Stack>;
}