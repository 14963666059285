import _ from 'lodash';
import React, {forwardRef} from 'react';
import {Button} from 'react-bootstrap';
import {useOAuthActivity, useOAuthSaga} from './OAuth';


const AuthRequired = forwardRef(function AuthRequired_({activityName, onClick, as: Control, children, defaultModal, disabled, hideWhenAuthed, ...props}, ref) {
    // Saga drives the app through the state changes for various steps of onboarding process.
    const [authWorkflow, dispatchAuthWorkflow] = useOAuthSaga(),
        {isSignedIn} = authWorkflow,
        showControl = (isSignedIn === false) || !hideWhenAuthed,
        isDisabled = _.isNil(isSignedIn) ? true : disabled;
    const onAction = () => dispatchAuthWorkflow({type: 'trigger', activity_name: activityName, defaultModal});
    useOAuthActivity(activityName, onClick);

    return <>
        {showControl && <Control onClick={onAction} disabled={isDisabled} {...props} ref={ref}>
            {children}
        </Control>}
    </>;
})

/**
 * The button with pre-authentication if the user is not logged in.
 *
 * @param {object} props passed to the inner button, except others defined here
 * @param props.onClick final onClick handler, with the logged in user as the first parameter.
 * @param props.activityName unique activity name. Button uses it to carry over through redirects of OAuth flow.
 * @param props.children
 * @param props.defaultModal ID of default modal dialog ('SignUp' or 'SignIn')
 * @param props.hideWhenAuthed Should the button be hidden once the user is signed in (eg "Sign in" button).
 *                                 Use this flag instead of conditional component rendering.
 * @returns {JSX.Element}
 */
export const AuthButton = forwardRef(function AuthButton_({onClick, activityName, children, defaultModal = 'SignUp', hideWhenAuthed = false, ...props}, ref) {
    return <AuthRequired as={Button} {...{onClick, activityName, children, defaultModal, hideWhenAuthed, ...props}} ref={ref}>
        {children}
    </AuthRequired>;
});