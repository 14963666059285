import _ from 'lodash';
import {useEffect} from 'react';
import {defaultVisiblePOIs, getSurfacePenalties} from '../lib/routing';
import {useMapLibre} from '../lib/useMapLibre';

// List of tarmacs.json style layers for each of road types.
const ROAD_LAYERS = [['road_tarmac_major', 'road_tarmac_minor', 'road_tarmac'], [], ['road_paved'], ['road_paved_bad'], ['road_gravel'], ['road_gravel_bad'], ['road_path']];
const VISIBLE_SUITABILITY = 5;

const POI_BY_CATEGORY = {
    'supplies': [
        'poi_toilets', 'poi_fast_food', 'poi_grocery', 'poi_alcohol_shop', 'poi_bakery', 'poi_ice_cream', 'poi_spring',
        'poi_restaurant', 'poi_beer', 'poi_bar', 'poi_cafe', 'poi_drinking_water'],
    'touristic': [
        'poi_church', 'poi_attraction', 'poi_viewpoint', 'poi_waterfall', 'poi_cave', 'mountain_saddle', 'mountain_peak', 'mountain_volcano',
        'poi_church_major', 'poi_castle', 'poi_memorial_monument', 'cablecar', 'cablecar-dash',
        'poi_bench', 'poi_guidepost', 'poi_fireplace', 'poi_map', 'poi_board', 'poi_hut',
    ],
    'service': ['poi_hospital', 'poi_police', 'poi_bicycle_station', 'poi_lodging', 'poi_tourist_info', 'poi_bicycle_sports_shop', 'poi_bicycle_rental', 'poi_fuel',
        'poi_camp_site', 'poi_picnic_site',  'poi_shelter'],
    'transport': ['transport_bus', 'transport_airport', 'transport_airfield', 'poi_parking', 'transport_railway_halt', 'transport_railway']
};

function adjustMaplibreStyle(mapLibre, bikeKind, adjustStyle, showPopularity, showTrails, suitability, visiblePOIs) {
    if (!mapLibre) return;

    const penalties = getSurfacePenalties(bikeKind, suitability);
    const visibility = (visible) => visible ? 'visible' : 'none';
    const mapLibreMap = mapLibre.getMap();  // Methods unsafe to react state are hidden behind getMap proxy.
    
    _.each(_.zip(ROAD_LAYERS, penalties), ([layers, penalty]) => {
        const visible = !adjustStyle || ((penalty * suitability) < VISIBLE_SUITABILITY);

        _.each(layers, (layer) => {
            // Set visibility of basic road layers.
            mapLibre.getLayer(layer) && mapLibreMap.setLayoutProperty(layer, 'visibility', visibility(visible && !showPopularity));
            mapLibre.getLayer(`${layer}_casing`) && mapLibreMap.setLayoutProperty(`${layer}_casing`, 'visibility', visibility(visible && !showPopularity));
            // Set visibility of popularity-based road layers.
            mapLibre.getLayer(`p${layer}`) && mapLibreMap.setLayoutProperty(`p${layer}`, 'visibility', visibility(visible && showPopularity));
            mapLibre.getLayer(`p${layer}_casing`) && mapLibreMap.setLayoutProperty(`p${layer}_casing`, 'visibility', visibility(visible && showPopularity));
        });
    });

    !_.isNil(visiblePOIs) && _.each(POI_BY_CATEGORY, (layers, category) => {
        const visible = visibility(_.includes(visiblePOIs, category));
        _.each(layers, (layer) => mapLibre.getLayer(layer) && mapLibreMap.setLayoutProperty(layer, 'visibility', visible));
    });

    const TRAILS = ['bicycle_local_casing', 'bicycle_local', 'bicycle_longdistance_casing', 'bicycle_longdistance', 'bicycle_way_icon', 'bicycle_label_local', 'bicycle_label_longdistance'];
    _.map(TRAILS, (layer) => mapLibreMap.setLayoutProperty(layer, 'visibility', showTrails ? 'visible' : 'none'));
}

export function AdjustableVectorLayer({bikeKind = 'road', adjustStyle, showPopularity, showTrails, suitability, visiblePOIs, isRoute = true}) {
    const {mapLibre, loaded} = useMapLibre();
    suitability = suitability ?? 0.5;
    visiblePOIs = visiblePOIs ?? defaultVisiblePOIs(isRoute);

    useEffect(() => {
        // When a user clicks checkbox twice quickly, style might have been not re-loaded yet - schedule change in such a case.
        mapLibre && loaded && adjustMaplibreStyle(mapLibre, bikeKind, adjustStyle, showPopularity, showTrails, suitability, visiblePOIs);
    }, [adjustStyle, bikeKind, loaded, showPopularity, showTrails, suitability, visiblePOIs, mapLibre]);

    return null;
}