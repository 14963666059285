import 'bootstrap/dist/css/bootstrap.min.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import {FirebaseAuthProvider, FirebaseFetchInterceptor} from "components/auth/FirebaseAuth";
import {OAuthActivitiesMap, OAuthCallback, OAuthProvider} from 'components/auth/OAuth';
import RootPage, {IndexPage, PrivacyPolicy} from 'components/RootPage';
import _ from 'lodash';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import {Toaster} from 'react-hot-toast';
import {IconContext} from 'react-icons';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.scss';
import {MagicLinkWatcher} from './components/auth/AuthenticationModal';
import {CustomerChat, FacebookProviderI18n} from './components/Facebook';
import {GeoLocationContextProvider} from './components/GeoLocationContext';
import {LocaleProvider, RedirectToLocaleRoute} from './components/l10n';
import Main from './components/Main';
import {Shaper, Thumbnailer} from './components/Thumbnailer';
import {ErrorBoundary} from './lib/errors';
import {gaInit, GAPageViewTracker} from './lib/ga';


gaInit();

const queryClient = new QueryClient({defaultOptions: {queries: {staleTime: Infinity, cacheTime: Infinity}}});

function App() {
    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <FirebaseAuthProvider>
                    <IconContext.Provider value={{className: 'react-icons'}}>
                        <BrowserRouter>
                            <FirebaseFetchInterceptor/>
                            <GAPageViewTracker/>
                            <GeoLocationContextProvider>
                                <LocaleProvider> {/* Order important: oauth gets locale to store at user data.*/}
                                    <OAuthProvider>  {/* OAuthProvider employs useLocation - so must be in the BrowserRouter.*/}
                                        <OAuthActivitiesMap activitiesMap={{signIn: _.noop, signUp: _.noop}}/>
                                        <FacebookProviderI18n appId="413071196983940" chatSupport>
                                            <OAuthCallback/>
                                            <Toaster position="top-center"/>
                                            <Routes>
                                                <Route exact path="/" element={<RedirectToLocaleRoute/>}/>
                                                <Route path="routes/*" element={<Main/>}/>
                                                <Route element={<RootPage/>}>
                                                    <Route path=":lang/*" element={<IndexPage/>}/>
                                                    <Route path="/privacy-policy/*" element={<PrivacyPolicy/>}/>
                                                </Route>
                                                <Route path="/magic-link" element={<MagicLinkWatcher/>}/>
                                                <Route path="/thumbnailer/:routeId" element={<Thumbnailer/>}></Route>
                                                <Route path="/shaper" element={<Shaper/>}></Route>
                                            </Routes>
                                            <CustomerChat page_id="110088464826639"/>
                                        </FacebookProviderI18n>
                                    </OAuthProvider>
                                </LocaleProvider>
                            </GeoLocationContextProvider>
                        </BrowserRouter>
                    </IconContext.Provider>
                    <ReactQueryDevtools initialIsOpen={false}/>
                </FirebaseAuthProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

export default App;
