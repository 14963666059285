import * as toGeoJSON from '@tmcw/togeojson';
import _ from 'lodash';
import React, {useCallback, useState} from 'react';
import {Alert, Badge, Col, Modal, Row} from 'react-bootstrap';
import {FileUploader} from 'react-drag-drop-files';
import {Trans, useTranslation} from 'react-i18next';
import {TbCloudPin, TbMapPinDown} from 'react-icons/tb';
import {DiscreteRangeSlider} from './DiscreteRangeSlider';
import {t_} from './l10n';
import {BikeKindSelector} from './RouteMapping';


const IMPORT_EXTENSIONS = ['gpx'];

const IMPORT_TOLERANCE = [
    [0.025, t_("Let Tarmacs redesign the route")],
    [0.015, t_("Reasonably accurate snapping")],
    [0.0015, t_("Stick to the original track")]];

function TolerancePreference({tolerance, setTolerance, className}) {
    const {t} = useTranslation();
    /* i18next-extract-disable-next-line */
    const prefPoints = _.map(IMPORT_TOLERANCE, ([val, msg]) => [val, t(msg)]);

    return <DiscreteRangeSlider className={className} descending
                                value={tolerance} setValue={setTolerance} tooltipPlacement="bottom" labelClassName="mb-1"
                                label={t("How strongly to snap to GPX track:")} points={prefPoints} icons={[TbCloudPin, TbMapPinDown]}/>;
}

/**
 * Display import route modal, handle and convert imported file to shape format.
 * @param onImportRoute callback, accepting imported geojson
 * @param onClose close modal;
 */
export function ImportRouteModal({onImportRoute, onHide, bikeKind, setBikeKind, show}) {
    const {t} = useTranslation(),
        [tolerance, setTolerance] = useState(0.015);
    const onFileImport = useCallback(async (file) => {
        const gpxBytes = await file.arrayBuffer(),
            decoder = new TextDecoder("utf-8"),
            gpxString = decoder.decode(gpxBytes),
            parser = new DOMParser(),
            gpxDoc = parser.parseFromString(gpxString, "application/xml");

        const geojson = toGeoJSON.gpx(gpxDoc),
            feature = _.first(geojson.features);
        if (geojson.properties?.name || _.isEmpty(feature.properties?.name)) {
            feature.properties.name = geojson.properties?.name;
        }
        onImportRoute({geojson: feature, tolerance});
    }, [onImportRoute, tolerance]);

    return <Modal backdrop={true} centered animation={false} show={show} onHide={onHide}>
        <Modal.Header closeButton={true}>
            <Modal.Title><Trans>Import route from GPX file</Trans></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h6 className="text-center"><Badge variant="success" className="me-2">1</Badge><Trans>Select route type:</Trans></h6>
            <Row><Col xs={{offset: 2, span: 8}}>
                <BikeKindSelector id="import" className="w-100" bikeKind={bikeKind || 'road'} setBikeKind={setBikeKind}/>
            </Col></Row>
            <Row><Col xs={{offset: 2, span: 8}}>
                <TolerancePreference tolerance={tolerance} setTolerance={setTolerance} className="w-100 my-2 small" />
            </Col></Row>
            <Alert variant="info" className="x-small">
                <Trans>Move the slider right if you want to stick to the original track.<br/>
                Keep the slider in the middle or move left to let Tarmacs optimize the route for surface, popularity and other preferences.</Trans>
            </Alert>
            <hr/>
            <h6 className="text-center"><Badge variant="success" className="me-2">2</Badge><Trans>Drag or click to select your GPX file:</Trans></h6>
            <FileUploader handleChange={onFileImport} name="file" types={IMPORT_EXTENSIONS}
                          label={t("Select or drop a GPX file to import and optimize your route")}/>
        </Modal.Body>
    </Modal>;
}