import {useContext, useEffect} from 'react';
import {Trans} from 'react-i18next';
import {Navigate, useLocation} from 'react-router-dom';
import {FirebaseAuth} from '../../lib/firebase';
import {useOAuthSaga} from './OAuth';


function AuthenticateWall() {
    const [authWorkflow, dispatchAuthWorkflow] = useOAuthSaga(),
        {isSignedIn} = authWorkflow;
    useEffect(() => {
        !isSignedIn && dispatchAuthWorkflow({type: 'trigger', activity_name: '', defaultModal: 'SignIn'})
    }, [isSignedIn, dispatchAuthWorkflow]);
    return null;
}

export function AuthenticatedRoute({children, unauthedPath = '/routes/edit/new'}) {
    const location = useLocation(),
        {isSignedIn} = useContext(FirebaseAuth);

    if (isSignedIn === false) {
        if (unauthedPath) {
            children = <Navigate to={{pathname: unauthedPath, state: {from: location}}}/>;
        } else {
            children = <AuthenticateWall/>;
        }
    } else if (isSignedIn === null) // on page load - FirebaseAuth might have not finished sign-in process yet.
        return <h3><Trans>Loading...</Trans></h3>;

    return <>{children}</>;
}
