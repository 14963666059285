import styled from '@emotion/styled';
import {getCoord, point} from '@turf/turf';
import classNames from 'classnames';
import {orderBy, where} from 'firebase/firestore';
import _ from 'lodash';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Alert, Button, Col, Container, Dropdown, Form, Nav, NavDropdown, Row, Stack} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import {Trans, useTranslation} from 'react-i18next';
import {FaLock, FaMapPin, FaRegFile, FaRegStar, FaUserCheck, FaUserFriends, FaUserTag} from 'react-icons/fa';
import {LinkContainer} from 'react-router-bootstrap';
import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import {useUnmount} from 'react-use';
import {useLocalStorage} from 'usehooks-ts';
import {activityRouteConverter, routeConverter} from '../lib/converters';
import {useCurrentUserData, useFirebaseCollectionData, useFirebaseInfiniteQueryData, useUserData} from '../lib/db';
import {useFollow, UserAvatar} from '../lib/feed';
import {useRoutesInVicinity} from '../lib/fetchRoutes';
import {FirebaseAuth} from '../lib/firebase';
import {toLngLat} from '../lib/getClosestPointAndSegment';
import {useHereReverseGeocodeQuery} from '../lib/here';
import {SpinningLoader, useDeviceType} from '../lib/util';
import {useDetectGeoLocation} from './GeoLocationContext';
import {InfiniteScrollImproved} from './InfiniteScroll';
import {UsersTypeahead} from './Invitations';
import {t_} from './l10n';
import {LocationTypeahead} from './LocationTypeahead';
import {RouteCard} from './RouteCard';
import {FollowingButton} from './User';


function RoutesListTab({firebaseWhere, title, userId, whenEmpty}) {
    const {user} = useContext(FirebaseAuth),
        queryConstraints = [...firebaseWhere, orderBy('created', 'desc')],
        [pages, hasNextPage, fetchNextPage] = useFirebaseInfiniteQueryData(['routes'], queryConstraints, {converter: routeConverter(user)});

    return <>
        {title && <Helmet><title>{title} | Tarmacs.App</title></Helmet>}
        <RoutesListTabContent pages={pages} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} userId={userId} whenEmpty={whenEmpty}/>;
    </>
}

function NoRoutesAround({location}) {
    return <Alert variant="info">
        <Alert.Heading><Trans>No routes around {{location}}</Trans></Alert.Heading>
        <Trans>Type other location or just plan your own route!</Trans>
        <hr/>
        <div className="d-flex justify-content-end">
            <LinkContainer to="/routes/edit/new">
                <Button className="me-2"><FaRegFile/> <Trans>Plan new route</Trans></Button>
            </LinkContainer>
        </div>
    </Alert>;
}

function RoutesInVicinityTab() {
    const {t} = useTranslation(),
        {geoLocation: {lat, lng}} = useDetectGeoLocation({enable: true}),
        geolocated = (lat && lng) ? point([lng, lat]) : null,
        [position, setPosition] = useState(null),
        pt = position ?? geolocated,
        // Reconcile position: use selected in location box, then geolocated.
        {data: location} = useHereReverseGeocodeQuery(pt),
        [pages, hasNextPage, fetchNextPage] = useRoutesInVicinity(pt && toLngLat(getCoord(pt)), null);

    const {address} = location ?? {},
        addrTitle = _.join(_.uniq(_.reject(_.pick(address, 'district', 'city', 'county'), _.isNil)), ', '),
        title = t("Routes Nearby: {{title}}", {title: (_.isEmpty(addrTitle) ? location?.title : addrTitle) ?? ''});

    return <>
        <Helmet><title>{title}</title></Helmet>
        <h1 className="border-bottom fs-2">{title}</h1>
        <Form>
            <Form.Group className="d-inline-flex flex-row align-items-center justify-content-start flex-wrap">
                <Form.Label htmlFor="location-on-routes-in-vicinity-list" className="me-2 my-1 flex-grow-1">
                    <Trans>Want to check other location? Type it: </Trans>
                </Form.Label>
                <div className="flex flex-shrink-1 flex-grow-1 my-1">
                    <LocationTypeahead id="location-on-routes-in-vicinity-list" onSetPosition={setPosition}/>
                </div>
            </Form.Group>
        </Form>
        <RoutesListTabContent {...{pages, hasNextPage, fetchNextPage}}
                              whenEmpty={<NoRoutesAround location={title}/>}
        />
    </>;
}

function FollowSomeoneAlert() {
    // margin-bottom avoids double scroll when users search menu is open.
    return <><Alert variant="info" style={{marginBottom: '300px'}}>
        <Alert.Heading><Trans>Nothing here?</Trans></Alert.Heading>
        <Trans>Search your fellow riders and follow them to see their routes!</Trans>
        <hr/>
        <SearchUsersToFollow id="invite-friends-edit-in-empty-alert"/>
    </Alert>
    </>;
}

function FollowSomeoneMoreAlert() {
    return <><Alert variant="info">
        <Alert.Heading><Trans>That is all we have for you!</Trans></Alert.Heading>
        <Trans>Search your fellow riders and follow them to see more routes!</Trans>
        <hr/>
        <SearchUsersToFollow id="invite-friends-edit-in-empty-alert" dropup={true}/>
    </Alert>
    </>;
}

function SearchUsersToFollow({id, placeholder, ...props}) {
    const {t} = useTranslation(),
        {user: actor} = useContext(FirebaseAuth),
        [{id: friendUid}, setFriend] = useState({}),
        {data: follows} = useFirebaseCollectionData(['follows'], [where('follower', '==', actor?.uid)]),
        alreadyFollowed = useMemo(() => new Set(_.map(follows, 'followed')), [follows]),
        {follow} = useFollow(actor?.uid, friendUid);

    useEffect(() => {actor?.uid && friendUid && follow();}, [actor?.uid, friendUid, follow]);

    return <UsersTypeahead id={id} {...props}
                           placeholder={placeholder ?? t("Type your fellow rider's name")} searchText={t("Searching...")}
                           emptyLabel={t("No users found.")} newSelectionPrefix={t("Fellow rider's e-mail: ")}
                           filter={(hits) => _.reject(hits, ({id}) => alreadyFollowed.has(id))}
                           onSetFriend={setFriend} />;
}

function RoutesFollowedTab() {
    const {t} = useTranslation(),
        {user} = useContext(FirebaseAuth),
        queryConstraints = [where('followers', 'array-contains', user.uid), orderBy('created', 'desc')],
        [pages, hasNextPage, fetchNextPage, isFetching] = useFirebaseInfiniteQueryData(['feed', 'routes', 'indices'], queryConstraints,
            // Clear idField in firestoreOptions. Otherwise the id set by activityConverter would be overwritten by index id (xx..yy).
            {converter: activityRouteConverter, firestoreOptions: {}}),
        [isEmpty, setEmpty] = useState(),
        title = t("Routes of Followed Users | Tarmacs.App");

    return <>
        <Helmet><title>{title}</title></Helmet>
        <h1 className="border-bottom fs-2"><Trans>Followed Users</Trans></h1>
        {!isEmpty &&  /* When empty, the "search users" is displayed in alert, so hide this one. */
            <Form>
                <Form.Group className="d-inline-flex flex-row align-items-center justify-content-start flex-wrap" controlId="follow-user-on-followed-routes-list">
                    <Form.Label className="me-2 my-1 flex-grow-1">
                        <Trans>Search and follow your friends:</Trans>
                    </Form.Label>
                    <div className="flex flex-shrink-1 flex-grow-1 my-1">
                        <SearchUsersToFollow id="invite-friends-edit"/>
                    </div>
                </Form.Group>
            </Form>}
        <RoutesListTabContent pages={pages} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} isFetching={isFetching}
                              onEmpty={setEmpty}
                              whenEmpty={<FollowSomeoneAlert/>}
                              whenFinished={<FollowSomeoneMoreAlert/>}
        />
    </>;
}

function EmptyAlert() {
    return <Alert variant="info">
        <Alert.Heading><Trans>You have no routes yet</Trans></Alert.Heading>
        <Trans>Plan your first route or star routes shared by someone else!</Trans>
        <hr/>
        <div className="d-flex justify-content-end">
            <LinkContainer to="/routes/edit/new">
                <Button className="me-2"><FaRegFile/> <Trans>Plan new route</Trans></Button>
            </LinkContainer>
        </div>
    </Alert>;
}

function UserHasNoRoutesAlert({userId}) {
    const {t} = useTranslation(),
        {data: user} = useUserData(userId),
        {user: actor} = useContext(FirebaseAuth),
        {first_name = '', gender} = user ?? {},
        {following} = useFollow(actor?.uid, user?.uid);

    return <Alert variant="info">
        <Alert.Heading><Trans>{{first_name}} has no routes yet</Trans></Alert.Heading>
        {!following ?
            t("Follow {{first_name}} to see the user's routes in future!", {first_name, context: gender}) :
            <>
                <Trans tOptions={{context: gender}}>How about suggesting {{first_name}} one of your routes?</Trans>
                <br/><Trans>Or search for more fellow riders and follow them to see their routes!</Trans><br/>
                <SearchUsersToFollow id="invite-friends-edit-in-empty-alert" dropup={true} className="pt-2"
                                     placeholder={t("Type your another fellow rider's name")}/>
            </>}
        <hr/>
        <div className="d-flex justify-content-end">
            {!following ?
                <FollowingButton allowUnfollow={false} followedId={userId} size="sm" className="align-items-middle"/> :
                <LinkContainer to="/routes/view">
                    <Button className="me-2"><FaUserTag/> <Trans>My Routes</Trans></Button>
                </LinkContainer>
            }
        </div>

    </Alert>;
}

function FinishedAlert() {
    return <Alert variant="info">
        <Alert.Heading><Trans>No more routes!</Trans></Alert.Heading>
        <Trans>Plan your next route or star routes shared by someone else!</Trans>
        <hr/>
        <div className="d-flex justify-content-end">
            <LinkContainer to="/routes/edit/new">
                <Button className="me-2"><FaRegFile/> <Trans>Plan new route</Trans></Button>
            </LinkContainer>
        </div>
    </Alert>;
}

function RoutesListTabContent({pages, hasNextPage, fetchNextPage, onEmpty, userId, whenEmpty = <EmptyAlert/>, whenFinished = <FinishedAlert/>}) {
    const routeCards = useMemo(() => _.flatten(_.map(pages,
        (page) => _.map(page, (route) => (
            <Col key={route.id} xs={12} md={6} xl={4}>
                <RouteCard route={route} hideUserBox={userId === route.routeProps.owner}/>
            </Col>)
        ))), [pages, userId]);

    const isEmpty = _.isEmpty(routeCards);

    useEffect(() => {onEmpty?.(isEmpty);}, [isEmpty, onEmpty]);

    const endMessage = isEmpty ?
        <div className="d-flex justify-content-center mt-4">
            {whenEmpty}
        </div> :
        <>
            <hr className="my-4 w-50 m-auto"/>
            <div className="d-flex justify-content-center mt-4">
                {whenFinished}
            </div>
        </>;

    return <Container fluid="lg" className="h-100">
        <InfiniteScrollImproved dataLength={routeCards.length} next={() => fetchNextPage()}
                        hasMore={hasNextPage !== false  /* undefined when loading 1st page */} loader={<SpinningLoader/>} endMessage={endMessage}>
            <Row>
                {routeCards}
            </Row>
        </InfiniteScrollImproved>
    </Container>;
}

const RECENT_USERS_LENGTH = 10;
const ROUTE_TAB_TEXTS = {
    'tab/followed': t_("Followed Users"),
    'tab/favorites': t_("Starred Routes"),
    'tab/invited': t_("Invitations to Routes"),
    'tab/vicinity': t_("Routes Around"),
    'tab/all': t_("All Routes")
};

export function RoutesTabBreadcrumb({match}) {
    const {t} = useTranslation();
    // i18next-extract-disable-next-line
    return t(ROUTE_TAB_TEXTS[`tab/${match?.params?.tab}`]);
}

export function UserRoutesTabBreadcrumb({match}) {
    const {t} = useTranslation(),
        {user: me} = useContext(FirebaseAuth),
        {userId} = match?.params,
        {data: viewedUser} = useUserData(userId || null),
        {display_name = ''} = viewedUser ?? {};
    return me?.uid === userId ? t("My routes") : display_name;
}

function RoutesNavItem({to, children, ...props}) {
    return <Nav.Item><LinkContainer to={to}><Nav.Link href="#" {...props}>{children}</Nav.Link></LinkContainer></Nav.Item>;
}

function RoutesNavDropdownItem({to, children, ...props}) {
    return <LinkContainer to={to}><NavDropdown.Item {...props}>{children}</NavDropdown.Item></LinkContainer>;
}

function RoutesNavPill({to, as: Component = RoutesNavItem, icon: Icon, image, title}) {
    const {t} = useTranslation(),
        {'*': path} = useParams(),
        icon = Icon ? <Icon/> : image;

    // i18next-extract-disable-next-line
    title = title ?? t(ROUTE_TAB_TEXTS[to]);
    return <Component className="d-flex align-items-center px-2 flex-nowrap" to={to} active={to === path}>
        <span className="flex-grow-0 flex-shrink-0 pe-1">{icon}</span><span className="flex-grow-1 flex-shrink-1 text-wrap">{title}</span>
    </Component>;
}

function RecentUsersNavItems({recentUsers, who}) {
    return _.map(recentUsers, (user) =>
        <RoutesNavPill key={`user/${user.uid}`} as={RoutesNavDropdownItem} to={`user/${user.uid}`} active={user.uid === who}
                       image={<UserAvatar photo_url={user.photo_url} size="1.5em"/>} title={user.display_name}/>
    );
}

function RecentUsersDropdown({who}) {
    const {user} = useContext(FirebaseAuth),
        {data: viewedUser} = useUserData(who || null),
        [recentlyViewedUsers, setRecentlyViewedUsers] = useLocalStorage('recentlyViewedUsers', []),
        recentUids = useMemo(() => new Set(_.map(recentlyViewedUsers, 'uid')), [recentlyViewedUsers]);

    const updateRecentUsers = useCallback((u) =>
        u && u.uid !== user?.uid && setRecentlyViewedUsers((recentUsers) =>
            _.take([_.pick(u, 'uid', 'display_name', 'photo_url'), ..._.reject(recentUsers, ({uid}) => uid === u.uid)], RECENT_USERS_LENGTH)
        ), [setRecentlyViewedUsers, user?.uid]);

    useEffect(() => {updateRecentUsers(viewedUser);}, [viewedUser, updateRecentUsers]);

    return <Dropdown as={Nav.Item} className="d-flex align-items-center px-0 w-100">
        <Dropdown.Toggle as={Nav.Link} className="px-2 d-flex align-items-center flex-grow-1 flex-no-wrap text-wrap" active={who && recentUids.has(who)}>
            <span className="flex-grow-0 flex-shrink-0 pe-1"><FaUserFriends/></span><span className="flex-grow-1 flex-shrink-1 text-wrap"><Trans>Recently viewed users</Trans></span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <RecentUsersNavItems recentUsers={recentlyViewedUsers} who={who}/>
        </Dropdown.Menu>
    </Dropdown>;
}

function FollowedUsersSearch({onSetFriend}) {
    const {t} = useTranslation();
    return <UsersTypeahead id="search-followed-users" align="left"
                           placeholder={t("Routes of ...")} searchText={t("Searching...")}
                           emptyLabel={t("No users found.")} newSelectionPrefix={t("Fellow rider's e-mail: ")}
                           onSetFriend={onSetFriend}/>;
}

function UserRoutesListTab({setWho}) {
    const {t} = useTranslation(),
        {isMobile} = useDeviceType(),
        {user: current} = useContext(FirebaseAuth),
        params = useParams(),
        {userId} = params,
        {data: user} = useUserData(userId),
        myRoutes = current.uid === userId,
        title = myRoutes ? t("My routes") : t("Routes of {{display_name}}", {display_name: user?.display_name});

    useEffect(() => {setWho(userId);}, [userId, setWho]);
    useUnmount(() => {setWho(null);});

    const whenEmpty = myRoutes ? <EmptyAlert/> : <UserHasNoRoutesAlert userId={userId}/>;

    if (!user) return null;
    return <>
        <Container fluid>
            <Stack direction="horizontal">
                <UserAvatar photo_url={user.photo_url} size={isMobile ? 40 : 120}/>
                <Container>
                    <h2>{user?.display_name || ""}</h2>
                </Container>
                {!myRoutes &&
                    <FollowingButton followedId={userId} className="flex-grow-1 flex-shrink-0" size="lg"/>}
            </Stack>
        </Container>

        <RoutesListTab firebaseWhere={[
            where('owner', '==', user.uid),
            where('deleted', '==', null)
        ]} userId={user.uid} whenEmpty={whenEmpty} title={title} />
    </>;
}

const FlexBreak = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export function RoutesList() {
    const {t} = useTranslation(),
        [user] = useCurrentUserData(),
        [who, setWho] = useState(),
        params = useParams(),
        {'*': path} = params,
        my = user?.uid && `user/${user?.uid}`,
        navigate = useNavigate(),
        {isMobile} = useDeviceType();

    useEffect(() => {
        _.isEmpty(path) && !_.isEmpty(my) && navigate(`/routes/view/${my}`);
    }, [path, my, navigate]);

    if (!user)
        return null;

    return <Container fluid>
        <Row className="pt-3">
            <Col md={3} lg={2}>
                <Nav variant="pills"
                     className={classNames(isMobile ? 'flex-row flex-wrap align-items-center mb-2 pb-2 border-bottom' : 'flex-column w-100')}>
                    <RoutesNavPill to={my} icon={FaUserTag} title={t("My Routes")}/>
                    <FollowedUsersSearch onSetFriend={(user) => navigate(`/routes/view/user/${user.uid}`)}/>
                    <RoutesNavPill to="tab/followed" icon={FaUserCheck}/>
                    <RoutesNavPill to="tab/vicinity" icon={FaMapPin}/>
                    <RoutesNavPill to="tab/favorites" icon={FaRegStar}/>
                    <RoutesNavPill to="tab/invited" icon={FaUserFriends}/>
                    {user.is_staff &&
                        <RoutesNavPill to="tab/all" icon={FaLock}/>}
                    {isMobile && <FlexBreak/>}
                    <RecentUsersDropdown who={who}/>
                </Nav>
            </Col>
            <Col className>
                <Routes>
                    <Route path="user/:userId" element={<UserRoutesListTab setWho={setWho}/>}/>
                    <Route path="tab/followed" element={<RoutesFollowedTab/>}/>
                    <Route path="tab/vicinity" element={<RoutesInVicinityTab/>}/>
                    <Route path="tab/favorites" element={
                        <RoutesListTab firebaseWhere={[
                            where('starred', 'array-contains', user.uid),
                            where('deleted', '==', null)
                        ]} title={t(ROUTE_TAB_TEXTS['tab/favorites'])} />
                    }/>
                    <Route path="tab/invited" element={
                        <RoutesListTab firebaseWhere={[
                            where('invited', 'array-contains', user.uid),
                            where('deleted', '==', null)
                        ]} title={t(ROUTE_TAB_TEXTS['tab/invited'])} />
                    }/>
                    {user.is_staff &&
                        <Route path="tab/all" element={
                            <RoutesListTab firebaseWhere={[
                                where('deleted', '==', null)
                            ]} title={t(ROUTE_TAB_TEXTS['tab/all'])} />
                        }/>}

                </Routes>
            </Col>
        </Row>
    </Container>;
}