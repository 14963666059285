import ReactTimeAgo from 'react-time-ago';
import {ErrorBoundary} from '../lib/errors';
import {useLocaleSettings} from './l10n';


export function TimeAgo({date, ...props}) {
    const {locale} = useLocaleSettings();  // Rerender timeagos on locale change.
    return date ? <ErrorBoundary silent={true} resetKeys={[date, locale]}>
            <ReactTimeAgo date={date} locale={locale} {...props} />
    </ErrorBoundary>: null;
}