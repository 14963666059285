import styled from "@emotion/styled";
import {along, distance, featureCollection, getCoord, length, lineString, nearestPoint, point} from '@turf/turf';
import binarySearch from 'binary-search';
import classNames from 'classnames';
import _ from "lodash";
import percentile from 'percentile';
import {Component, memo, useCallback, useEffect, useMemo, useState} from "react";
import {Spinner} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {FaRecycle, FaTrash} from "react-icons/fa";
import {Layer, Source} from 'react-map-gl';
import {useBoolean} from 'usehooks-ts';
import {v4 as uuidv4} from 'uuid';
import {ErrorBoundary, errorHandler} from '../lib/errors';
import {getClosestPointAndSegment, toLatLng, toLngLat, toLngLatArray} from '../lib/getClosestPointAndSegment';
import {combineSegments, coordinateSegments} from '../lib/routing';
import {getSteepnessAttrs, STEEPNESS} from '../lib/steepness';
import TRACK_STYLES from '../lib/trackStyles.json';
import {distanceXY, nearestVertex, pixelDistanceOnMap, useMapLibre, useMapLibreEvents} from '../lib/useMapLibre';
import {TRACK_POI_SIZE, useRBushPOIsReducer} from '../lib/useRBushPOIsReducer';
import {useTrackMarkerPositionAdjuster} from '../lib/useTrackMarkerPositionAdjuster';
import {useDeviceTypeVariant, useForceUpdate} from "../lib/util";
import {ZINDEX} from '../lib/zindexes';
import {ClickableCircularMenu} from './CircularMenu';
import {DivMarker} from './DivMarker';
import {Barrier, Bollard, Ferry, Tunnel} from './IconsSVG';
import {getPOIFeature, PointOfInterestOnTrack} from './PointsOfInterest';
import {getFeatureLayer, usePOIPopup} from './POIPopup';
import {Uphill} from './Uphill';
import {useViewState} from './ViewStateContext';
import {WeatherMarkers} from './WeatherPanel';


const ROUTEPOINT_POI_BORDER = 4;

export const MARKER_SIZE = {
    // Size is hand-matched with font-size, font-weight and padding in .coordinate-marker
    ROUTEPOINT: {mobile: 30, desktop: 20},
    ROUTEPOINT_POI: TRACK_POI_SIZE + ROUTEPOINT_POI_BORDER,
    VOLATILE: 20,
    ORBIT_BTN: TRACK_POI_SIZE + ROUTEPOINT_POI_BORDER
};

/**
 * Coordinate marker, supporting dragging.
 *
 * Implemented as class component to employ shouldComponentUpdate because re-rendering terminates dragging.
 * On the other hand, functional 'useMemo' hook would remember old (pre-drag) 'idx' value
 */
export class CoordinateMarker extends Component {
    /**
     * Detects necessity for component re-render. This includes also props affecting components rendered by CoordinateMarkerRenderer.
     */
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const indexChanged = _.isNumber(this.props.idx) && this.props.idx !== nextProps.idx,
            coordChanged = nextProps.coordinate !== this.props.coordinate,
            hasRendered = nextState.rendered !== this.state?.rendered,
            // Workaround - otherwise render() will not be called and the subcomponent will not receive 'open' change.
            openStateChanged = nextProps.open !== this.props.open,
            draggingChanged = nextProps.dragging !== this.props.dragging,
            contentChanged = nextProps.centerContent !== this.props.centerContent,
            classNameChanged = nextProps.className !== this.props.className;
        return hasRendered || indexChanged || coordChanged || openStateChanged || draggingChanged || contentChanged || classNameChanged;
    }

    dragstart(evt) {
        const {lng, lat} = evt.lngLat;
        console.log('dragstart', evt, this.props, this);
        this.props.onDragging(this.props.idx, [lat, lng]);
    }

    dragend(evt) {
        // Skip dupe events which sometimes happen.
        if (!_.isNil(this.props.idx)) {
            const {lng, lat} = evt.lngLat;
            this.props.onPointMove(this.props.idx, [lat, lng]);
        }
    };

    componentDidMount() {
        this.setState({rendered: true});
    }

    render() {
        const {children, className = 'coordinate-marker', coordinate, size, style = {}, draggable = true, ...props} = this.props,
            eventHandlers = {
                onDragStart: _.bind(this.dragstart, this),
                onDragEnd: _.bind(this.dragend, this)
            };

        // High zIndex ensures the marker is rendered above POIs and steepness markers.
        // Leaflet sets markers' zindex by longitude, not sure about maplibre.
        return <DivMarker className={className} style={{zIndex: ZINDEX.ROUTE_POINT_MARKER, ...style}}
                          draggable={draggable} size={size} position={toLngLat(coordinate)} {...props}
                          {...eventHandlers}>
            {children}
        </DivMarker>;
    }
}

function ToHereButton({radius, onPointInsert, coordinate, ...mouseEvents}) {
    const {t} = useTranslation(),
        onClick = useCallback((evt) => {
            evt.stopPropagation();
            onPointInsert(null, coordinate);
        }, [onPointInsert, coordinate]);

    return <div className="coordinate-marker" style={{height: radius, width: radius}}
                title={t("Route back to this point")}
                onClick={onClick} {...mouseEvents}>
        <FaRecycle/>
    </div>;
}

function DeleteRoutePointButton({idx, radius, onDelete, ...mouseEvents}) {
    const {t} = useTranslation();
    return <div className="coordinate-marker" style={{height: radius, width: radius}}
                title={t("Delete this point")}
                onClick={onDelete} {...mouseEvents}><FaTrash/></div>;
}

export function RoutePointMarker({radius, children, className, draggable = true, ...props}) {
    const {t} = useTranslation();
    return <div className={classNames("coordinate-marker", className)} style={{height: radius, width: radius}}
                title={draggable ? t("Drag to move this point. Click for more options.") : undefined}
                {...props}>{children}</div>;
}

// Margins are empirical :/
const RoutePointPOI = styled(PointOfInterestOnTrack)`
    border-style: solid;
    border-color: blue;
    border-radius: 50%;
    margin-left: -8px;
    margin-top: -8px;
`;

const RoutePoint = memo(function RoutePoint({
    coordinate, draggable, dragging, idx, isFinish, mapLibre, onDragging, onPointDelete, onPointInsert, onPointMove, onSetOpenIdx,
    openIdx, poi, setVolatileCoordinate, spinning
}) {
    const radius = useDeviceTypeVariant(MARKER_SIZE.ROUTEPOINT),
        [, , , setFeatureNow] = usePOIPopup(),
        onToHere = useCallback((idx, coordinate) => {
            onSetOpenIdx(null);
            onPointInsert(null, coordinate);
        }, [onPointInsert, onSetOpenIdx]),
        open = !dragging && openIdx === idx;

    function onDelete(evt) {
        evt.stopPropagation();
        onPointDelete(idx);
        onSetOpenIdx(null);
    }

    function onClose() {
        onSetOpenIdx(null);
    }

    function onClick(evt) {
        if (!dragging && draggable) {
            const layer = poi?.feature && getFeatureLayer(mapLibre, poi?.feature),
                poiFeature = layer && getPOIFeature(poi.feature, evt, true, layer);
            onSetOpenIdx(openIdx === idx ? null : idx);
            poiFeature && setFeatureNow(poiFeature);
        }
    }

    const onMouseEnter = useCallback(() => setVolatileCoordinate?.(null), [setVolatileCoordinate]);

    // Not too beautiful but at least no hardcoded paddings and margins.
    const orbitBtnSize = MARKER_SIZE.ORBIT_BTN,
        [size, centerSize, menuRadius] = poi?.icon ?
            [MARKER_SIZE.ROUTEPOINT_POI, MARKER_SIZE.ROUTEPOINT_POI - ROUTEPOINT_POI_BORDER * 2, 2.5] :
            [radius, radius, 2],
        iconAnchor = [orbitBtnSize / 2, orbitBtnSize / 2],
        className = classNames('coordinate-marker', {'start-marker': idx === 0, 'finish-marker': idx && isFinish});

    const centerContent = poi?.icon ?
        // `borderWidth` is explicit because must be subtracted from size after transformations.
        <RoutePointPOI feature={poi?.feature} isRoutePoint={true}
                       size={size} borderWidth={ROUTEPOINT_POI_BORDER}/> :
        <RoutePointMarker draggable={draggable} radius={size} onMouseEnter={onMouseEnter} className={className}>
            {spinning ? <Spinner animation="border" variant="light" size="sm"/> : <div>{idx + 1}</div>}
        </RoutePointMarker>;

    // Note: to ensure proper re-render of inner children, include their props in wrapping CoordinateMarker and in shouldComponentUpdate.
    return <CoordinateMarker className={className} idx={idx} coordinate={coordinate} open={open}
                             draggable={draggable} dragging={dragging} size={size} centerContent={centerContent}
                             iconAnchor={iconAnchor} onPointMove={onPointMove} onDragging={onDragging}>
        {draggable ?
            <ClickableCircularMenu startAngle={0} rotationAngle={360} rotationAngleInclusive={false} open={open} onClose={onClose} onOpen={onClick}
                                   menuToggleElement={centerContent} itemSize={2} radius={menuRadius} centerSize={centerSize}>
                <DeleteRoutePointButton idx={idx} radius={orbitBtnSize} onDelete={onDelete} onMouseEnter={onMouseEnter}/>
                <ToHereButton idx={idx} coordinate={coordinate} onPointInsert={onToHere} radius={orbitBtnSize} onMouseEnter={onMouseEnter}/>
            </ClickableCircularMenu> :
            centerContent
        }
    </CoordinateMarker>;
});

function GateMarker({feature}) {
    const gate = feature.properties.gate,
        [icon, height, title] = (gate === 'bollard') ?
            [Bollard, 40, <Trans>Be careful and slow down to not hit the bollard</Trans>] :
            [Barrier, 54, <Trans>Be careful and slow down when passing the gate.</Trans>];

    return <WarningMarker feature={feature} height={height} title={title} icon={icon}/>;
}

function Gates({coordinates, gates}) {
    const reduceRBush = useRBushPOIsReducer();
    // Convert to POI format expected by RBush reducer. Reduce overlapping ones.
    const markers = _.map(gates, ([endIdx, gate]) => ({feature: point(toLngLat(coordinates?.[endIdx]), {gate})})),
        reducedMarkers = reduceRBush(markers);

    return _.map(reducedMarkers, ({feature}, idx) => <GateMarker key={idx} feature={feature}/>);
}

function WarningMarker({feature, height, icon: Icon, title}) {
    const position = getCoord(feature);

    function onClick(evt) {
        evt?.originalEvent?.preventDefault();
        evt?.originalEvent?.stopPropagation();
    }

    return <DivMarker size={4} position={position} style={{zIndex: ZINDEX.GATE_MARKER}} onClick={onClick}>
        <Icon height={height} title={title}/>
    </DivMarker>;
}

function WarningMarkers({coordinates, height, icon, markers, title}) {
    const reduceRBush = useRBushPOIsReducer(),
        features = _.map(_.filter(markers, '2'), ([startIdx, endIdx]) => ({feature: point(toLngLat(coordinates?.[startIdx]))})),
        reducedMarkers = reduceRBush(features);

    return _.map(reducedMarkers, ({feature}, idx) => <WarningMarker key={idx} feature={feature} height={height} icon={icon} title={title}/>);
}

function Ferries({coordinates, markers}) {
    return <WarningMarkers coordinates={coordinates} markers={markers} icon={Ferry} height={30}
                           title={<Trans>Check the ferry timetable, or toggle "no ferries" switch if you want to stick to land</Trans>}/>;
}

function Tunnels({coordinates, markers}) {
    return <WarningMarkers coordinates={coordinates} markers={markers} icon={Tunnel} height={25}
                           title={<Trans>Bring lighting to be clearly visible in the tunnel</Trans>}/>;
}

function RoutePoints({activeFetches, draggable, dragging, onDragging, onPointDelete, onPointInsert, onPointMove, route, routePOIs, setVolatileCoordinate}) {
    const [openIdx, setOpenIdx] = useState(),
        {mapLibre} = useMapLibre(),
        spinningIndices = useMemo(() => _.uniq(_.flatten(_.map(activeFetches, 'indices'))), [activeFetches]),
        finishIdx = _.size(route) - 1;

    // Close planet menu if dragging started or ended
    useEffect(() => {
        setOpenIdx(null);
    }, [dragging]);

    const markers = useMemo(() => _.map(_.zip(route, routePOIs), ([coordinate, poi], idx) =>
        ((draggable || idx === 0 || idx === finishIdx) ?
            <RoutePoint {...{coordinate, draggable, dragging, idx, mapLibre, openIdx, onDragging, onPointDelete, onPointInsert, onPointMove, poi, setVolatileCoordinate}}
                        key={idx} onSetOpenIdx={setOpenIdx} spinning={_.includes(spinningIndices, idx)} isFinish={idx === finishIdx}/>
            : null)
    ), [draggable, dragging, finishIdx, mapLibre, onDragging, onPointDelete, onPointInsert, onPointMove, openIdx, route, routePOIs, setVolatileCoordinate, spinningIndices]);

    return (<>{markers}</>);
}

// Note: If your creativity makes you to add some more advanced styling, the legend requires them in `leafletPathToSvg()`.
export const SURFACE_STYLES = {
    tarmac: [['tarmac'],
        {outer: {color: 'black', weight: 10}, inner: {color: 'white', weight: 2, dashArray: '6 6'}}],
    tarmac_rough: [['tarmac_rough'],
        {outer: {color: 'darkgray', weight: 10}, inner: {color: 'black', weight: 6, dashArray: '1 0'}}],
    paved: [['paved'],
        {outer: {color: 'slategray', weight: 10}, inner: {color: 'darkgray', weight: 6, dashArray: '1 0'}}],
    paved_rough: [['paved_rough'],
        {outer: {color: 'slategray', weight: 10}, inner: {color: 'darkgray', weight: 6, dashArray: '6 8'}}],
    gravel: [['compacted'],
        {outer: {color: 'maroon', weight: 10}, inner: {color: 'khaki', weight: 6, dashArray: '1 0'}}],
    gravel_rough: [['gravel'],
        {outer: {color: 'maroon', weight: 10}, inner: {color: 'khaki', weight: 6, dashArray: '6 8'}}],
    dirt: [['dirt'],
        {outer: {color: 'saddlebrown', weight: 10}, inner: {color: 'sienna', weight: 6, dashArray: '6 8'}}],
    others: [null,
        {outer: {color: 'khaki', weight: 10}, inner: {color: 'khaki', weight: 6, dashArray: '1 0'}}]
};

const getSteepnessMarkerPos = (coords) => {
    if (_.isEmpty(coords))
        return null;
    const segmentLS = lineString(coords),
        halfSegmentPt = along(segmentLS, length(segmentLS, {units: 'kilometers'}) / 2, {units: 'kilometers'});
    return halfSegmentPt;
};

const STEEPNESS_MAPLIBREGL_STYLES = _.map(STEEPNESS, ([[min, max], {color}], idx) => ({
        "id": `grade${min}-${max}`,
        "type": "line",
        "filter": ["all", [">=", "grade", min], ["<=", "grade", max], ["==", "class", "steepness_segment"]],
        "layout": {
            "line-cap": "round",
            "line-join": "miter",
            "visibility": "visible",
            "line-miter-limit": 4,
            "line-round-limit": 1.05
        },
        "paint": {
            "line-blur": 0,
            "line-width": [
                "interpolate", ["linear"], ["zoom"], 6, 2, 14, 8
            ],
            "line-offset": 0,
            "line-opacity": 1,
            "line-color": color
        }
    })
);

const STEEPNESS_POLYLINE_DETAIL_THRESHOLD = 1;

export function SteepnessTrackLayer({segments}) {
    // Memo here fixes "Warning: Maximum update depth exceeded. This can happen when a component calls setState inside useEffect"
    const coordsWithGrade = useMemo(() => _.map(segments, ({coords, grade}) => ([coords, {grade}])), [segments]),
        features = useSimplifiedCoordinates(coordsWithGrade, 'steepness_segment', STEEPNESS_POLYLINE_DETAIL_THRESHOLD);
    return <TrackSegmentsLayer features={features} styles={STEEPNESS_MAPLIBREGL_STYLES}/>;
}

function SteepnessMarker({coordinates, coordinatesRange, dragging, idx, positionAdjuster, segments, setVolatileCoordinate, setElevationViewerSegment, steepnessRange}) {
    const {value: showPolyline, setTrue: doShowPolyline, setFalse: doHidePolyline, toggle: togglePolyline} = useBoolean(false),
        lngLat = getSteepnessMarkerPos(toLngLatArray(coordinates)),
        positionPt = lngLat && positionAdjuster(getCoord(lngLat)),
        position = positionPt && getCoord(positionPt);

    if (!position)
        return null;

    const onMouseOver = () => {
        if (dragging)
            return;  // Avoid drag termination when volatile marker is dragged over the steepness marker.
        doShowPolyline();
        setVolatileCoordinate?.(null);
        setElevationViewerSegment?.(coordinatesRange);
    };
    const onMouseOut = () => {
        doHidePolyline();
        setElevationViewerSegment?.(null);
    };

    function onClick(evt) {
        console.log(`segments for ${idx}:`, segments);
        console.log(maxGrade(segments));
        evt.originalEvent.preventDefault();
        evt.originalEvent.stopPropagation();
        togglePolyline();
    }

    return <>
        <DivMarker size={4} position={position} onClick={onClick} style={{zIndex: ZINDEX.STEEPNESS_MARKER}}>
            <Uphill steepness={steepnessRange?.max} onMouseOver={onMouseOver} onMouseOut={onMouseOut}/>
        </DivMarker>
        {showPolyline &&
            <SteepnessTrackLayer segments={segments} idx={idx}/>}
    </>;
}

function simplifyCoordinatesByThreshold(mapLibre, coordinates, threshold) {
    const first = _.first(coordinates),
        lastIdx = _.size(coordinates) - 1,
        {result: simplifiedPolyline} = _.transform(coordinates, (accumulator, coord, idx) => {
            const {result, anchor} = accumulator,
                point = mapLibre.project(coord);
            if (coord && (idx === lastIdx || distanceXY(anchor, point) >= threshold)) {
                result.push(coord);
                accumulator.anchor = point;
            }
        }, {result: [first], anchor: mapLibre.project(first), points: [mapLibre.project(first)], filtered: [mapLibre.project(first)]});
    return simplifiedPolyline;
}

const POLYLINE_DETAIL_THRESHOLD = 10; // For simplification of displayed polylines using trackpoint threshold algorithm.

export function getTrackSegmentGeoJSON(mapLibre, idx, coordinates, props, threshold = POLYLINE_DETAIL_THRESHOLD) {
    if (_.size(coordinates) < 2) {
        console.debug(`TrackSegment: Skipping - no coordinates for ${idx}:`, coordinates);
        return null;
    }

    // Simplify polyline to draw faster and look nicer - especially fixes the dirty dashed segments discontinuity.
    // TODO can we use turf.simplify here instead of the custom simplifyCoordinatesByThreshold algo?
    const simplifiedCoordinates = simplifyCoordinatesByThreshold(mapLibre, coordinates, threshold);
    return lineString(simplifiedCoordinates, {...props, idx});
}

// Finetune merging of two climbs with inner non-climb:
const MAX_INNER_NONCLIMB_LENGTH = 0.2;
const MAX_INNER_NONCLIMB_RATIO = 0.05;
const MINIMUM_STEEPNESS_OF_SURROUNDING_CLIMBS = 3;
const MINIMUM_STEEPNESS_SEGMENT_LENGTH = 0.2;
const PERCENTILE_LENGTH = [[9, 95], [11, 90], [12, 75], [13, 70]];
const PERCENTILE_GRADE = [[9, 95], [11, 95], [12, 85], [13, 70]];

function maxGrade(segments) {
    const [max] = _.reduce(segments, ([max, stack], {grade, length}) => {
        if (grade > max) {
            if (length >= MINIMUM_STEEPNESS_SEGMENT_LENGTH)
                return [grade, []];

            stack = _.map(_.filter(stack, (seg) => (grade >= seg.grade)), (seg) => ({...seg, length: seg.length + length}));
            const steepestLongEnough = _.last(_.filter(stack, (seg) => seg.grade > max && seg.length >= MINIMUM_STEEPNESS_SEGMENT_LENGTH));
            if (steepestLongEnough)
                return [steepestLongEnough.grade, []];

            if (grade > _.last(stack)?.grade ?? 0)
                stack.push({grade, length});
        }
        return [max, stack];
    }, [0, []]);
    return max;
}

function timeAt(times, index) {
    if (_.isEmpty(times)) return null;
    const at = Math.abs(binarySearch(times, index, ([idx, time]) => idx - index));
    const [, time] = times[at] ?? [];
    return time;
}

function findClimbingSegments(coordinates, steepness, elevation, times, percentileLength, percentileGrade) {
    if (!(percentileLength && percentileGrade))
        return null;

    function mergeSegments(merged, ...segments) {
        merged.coordinates.push(..._.flatten(_.map(segments, 'coordinates')));
        merged.length += _.sum(_.map(segments, 'length'));
        merged.segments.push(..._.flatten(_.map(segments, 'segments')));
        merged.min = _.minBy([merged, ...segments], 'min')?.min;
        merged.max = _.maxBy([merged, ...segments], 'max')?.max;
        merged.end = _.last(segments).end;
        return merged;
    }

    const allSegments = coordinateSegments(coordinates, steepness),
        grouped = _.transform(allSegments, (result, [coords, {grade, length}, start, end]) => {
            const isClimb = grade > 0 || (elevation[end] > elevation[start]);
            const prev = _.last(result),
                continued = prev?.isClimb === isClimb,
                next = {segments: [{coords, grade, length}], min: grade, max: grade, coordinates: coords, isClimb, prev, length, start, end};
            if (continued) {
                mergeSegments(prev, next);
            } else {
                result.push(next);
            }
        }),
        merged = _.transform(grouped, (result, climb) => {
            const [innerDescent = null, prevClimb = null] = _.reverse(_.takeRight(result, 2));
            const isInnerDescent = climb.isClimb && prevClimb?.isClimb,
                isInnerSmallDescent = isInnerDescent && (innerDescent.length < MAX_INNER_NONCLIMB_LENGTH || (
                    innerDescent.length < (climb.length + prevClimb.length) * MAX_INNER_NONCLIMB_RATIO &&
                    (climb.max > MINIMUM_STEEPNESS_OF_SURROUNDING_CLIMBS || prevClimb.max > MINIMUM_STEEPNESS_OF_SURROUNDING_CLIMBS)));

            if (isInnerSmallDescent) {
                mergeSegments(prevClimb, innerDescent, climb);
                result.pop();
            } else {
                result.push(climb);
            }
        }),
        smoothed = _.map(merged, (climb) => {
            const maxOld = _.maxBy(climb.segments, 'grade')?.grade,
                max = maxGrade(climb.segments),
                min = _.minBy(climb.segments, 'grade')?.grade;
            return {...climb, max, min, maxOld};
        }),
        climbs = _.filter(smoothed, 'isClimb'),
        lengthThreshold = percentile(percentileLength, climbs, ({length}) => length)?.length,
        gradeThreshold = percentile(percentileGrade, climbs, ({max}) => max)?.max,
        remarkableClimbs = _.filter(climbs, ({max, length}) =>
            (length >= lengthThreshold || max >= gradeThreshold) && getSteepnessAttrs(max)?.markers !== false);

    return _.map(remarkableClimbs, (climb) =>
        ({...climb, startTime: timeAt(times, climb.start), endTime: timeAt(times, climb.end), startElevation: elevation[climb.start], endElevation: elevation[climb.end]}));
}

const SteepnessMarkers = memo(function SteepnessMarkers({climbs, dragging, positionAdjuster, setVolatileCoordinate, setElevationViewerSegment}) {
    return _.map(climbs, ({coordinates, min, max, segments, start, end}, idx) =>
        <SteepnessMarker {...{coordinates, dragging, idx, positionAdjuster, segments, setElevationViewerSegment, setVolatileCoordinate}}
                         steepnessRange={{min, max}} coordinatesRange={[start, end]}
                         key={`steepness-marker-${idx}`}/>
    );
});

/**
 * Simplify track for faster and nicer displaying.
 * Improves performance and, even more important, appearance of dashed road segements.
 *
 * @param segments
 * @param layerClass
 * @param {int} threshold - Threshold for simplification of track segments.
 */
export function useSimplifiedCoordinates(segments, layerClass, threshold) {
    const {mapLibre} = useMapLibre(),
        // Force simplified track recalculation on zoom change.
        {zoom, zooming} = useViewState(),
        [collection, setCollection] = useState(null);

    useEffect(() => {
        if (zooming) return; // Optimize rendering during zoom-in/zoom-out.
        const polylines = _.flatten(_.map(segments, ([coordinates, properties], idx) => {
            if (_.size(coordinates) < 2)
                return [];
            return [getTrackSegmentGeoJSON(mapLibre, idx, toLngLatArray(coordinates), {class: layerClass, ...properties}, threshold)];
        }));
        setCollection(featureCollection(polylines));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapLibre, segments, layerClass, setCollection, threshold, zoom, zooming]);
    return collection;
}

export function interpolateExponential(input, base, stops) {
    const index = _.findIndex(stops, ([stop, val]) => (stop > input));
    if (index === 0) {
        return _.first(stops)[1];
    }
    if (index === -1) {
        return _.last(stops)[1];
    }

    const [lowerStop, lowerValue] = stops[index - 1],
        [upperStop, upperValue] = stops[index],
        scale = (input - lowerStop) / (upperStop - lowerStop),
        exponent = Math.pow(scale, base);

    return _.toInteger(lowerValue + (upperValue - lowerValue) * exponent);
}

export const POPULARITY_MAPPINGS = {
    'dirt': [1.1, [[192, 1], [255, 6]]],
    'gravel': [1.1, [[192, 1], [255, 6]]],
    'compacted': [1.1, [[192, 1], [255, 6]]],
    'paved': [1.3, [[192, 1], [255, 6]]],
    'paved_rough': [1.3, [[192, 1], [255, 6]]],
    // Intentionally mismatches from the base on the map layer (1.4). Better looks at popularities around 245-250.
    'tarmac': [2.5, [[192, 1], [255, 6]]],
    'tarmac_rough': [2.5, [[192, 1], [255, 6]]]
};

const TrackSegments = memo(function TrackSegments({coordinates, popularities, surfaces}) {
    const segments = useMemo(() => {

        function getLineStyle([start, end, [surface, popularity]]) {
            try {
                const [base, stops] = POPULARITY_MAPPINGS[surface] ?? [];
                return [start, end, {surface, popularity_class: interpolateExponential(popularity, base, stops)}];
            } catch (exc) {
                console.error(`TrackSegments: Failed to interpolate popularity for ${surface} between ${start},${end}: ${exc}\n ${coordinates} ${surfaces}`);
                return getLineStyle([start, end, ['tarmac', popularity ?? 0]]);
            }
        }

        const segs = _.map(combineSegments(surfaces, popularities), getLineStyle);
        return coordinates && coordinateSegments(coordinates, segs);
    }, [coordinates, popularities, surfaces]);
    const features = useSimplifiedCoordinates(segments, 'track_segment');
    return <TrackSegmentsLayer id="track" features={features} styles={TRACK_STYLES}/>;
});

const DIRECTION_ARROW_STYLE = {
    "type": "symbol",
    "filter": ["all", ["==", "class", "track_segment"]],
    "layout": {
        "symbol-placement": "line",
        "symbol-spacing": 250,
        "icon-allow-overlap": true,
        // The icon is included in custom spritesheet
        "icon-image": "direction-arrow",
        "icon-size": 1,
        "visibility": "visible"
    }
};

export function TrackSegmentsLayer({features, styles, id, ...props}) {
    // Essential to suffix id with uuidv4, as there might be multiple layers using same style definitions.
    const [uid] = useState(() => uuidv4());

    return <Source type="geojson" data={features} {...props} >
        {_.map(styles, ({id, ...style}) => <Layer key={id} id={`${id}-${uid}`} {...style} />)}
        <Layer {...DIRECTION_ARROW_STYLE} id={`direction-arrow-${uid}`}/>
    </Source>;
}

function TrackMarkers({
    coordinates, dragging, elevation, plannedDate, setElevationViewerSegment,
    setVolatileCoordinate, showWarnings, steepness, times, uuid, weatherOnRoute
}) {
    const {zoom} = useViewState(),
        // Moved out from findClimbingSegments to avoid markers rerender on irrelevant zoom changes.
        [percentileLength, percentileGrade] = useMemo(() => {
            if (!zoom || zoom < 9) return [null, null];
            const [, percentileLength] = _.findLast(PERCENTILE_LENGTH, ([z]) => (zoom >= z)),
                [, percentileGrade] = _.findLast(PERCENTILE_GRADE, ([z]) => (zoom >= z));
            return [percentileLength, percentileGrade];
        }, [zoom]),
        climbs = useMemo(() => findClimbingSegments(coordinates, steepness, elevation, times, percentileLength, percentileGrade),
            [coordinates, steepness, elevation, times, percentileLength, percentileGrade]),
        [positionAdjuster] = useTrackMarkerPositionAdjuster(coordinates, 40);

    return <>
        {showWarnings &&
            <ErrorBoundary silent resetKeys={[uuid, climbs]}>
                <SteepnessMarkers {...{climbs, dragging, positionAdjuster, setVolatileCoordinate, setElevationViewerSegment}} />
            </ErrorBoundary>}
        <ErrorBoundary silent resetKeys={[uuid, times]}>
            <WeatherMarkers enabled={weatherOnRoute} {...{climbs, coordinates, elevation, plannedDate, positionAdjuster, times}} />
        </ErrorBoundary>
    </>;
}

const VolatileStyledMarker = styled.div`
    width: ${MARKER_SIZE.VOLATILE}px;
    height: ${MARKER_SIZE.VOLATILE}px;
`;

function getEllipse({start, distance: distance_m, bearing, axisRatio}) {
    if (!start || !distance_m || _.isNil(bearing) || !axisRatio)
        return null;
    start = point(toLngLat(start));
    const [startLng, startLat] = getCoord(start);

    // Replicate Valhalla Ellipse definition
    const angle = 90.0 - bearing,
        angleRad = angle * Math.PI / 180.0,
        c = Math.cos(angleRad),
        s = Math.sin(angleRad);

    // Calculate axes lengths of the ellipse
    const a_m = distance_m / (Math.PI * (1.5 * (1.0 + 1.0 / axisRatio) - 1 / Math.sqrt(axisRatio))),
        b_m = a_m / axisRatio;

    // Calculate ellipse center from start, taking to account the angle
    const tmp = point([startLng - 1.0, startLat]),
        km_per_deg = distance(start, tmp, {units: 'kilometers'}),
        m_per_deg = km_per_deg * 1000.0,
        metersPerDegreeLat = 110567.0,
        dx = a_m * c / m_per_deg,
        dy = a_m * s / metersPerDegreeLat;

    // Complex way to calculate a, b in degrees: axes have different m/deg factors.
    const a = Math.sqrt(dx ** 2 + dy ** 2),
        b = Math.sqrt((b_m * s / m_per_deg) ** 2 + (b_m * c / metersPerDegreeLat) ** 2);

    // Find k1_, k2_, k3_ - define when a point x,y is on the ellipse
    // https://rohankjoshi.medium.com/the-equation-for-a-rotated-ellipse-5888731da76
    const k1_ = (c / a) ** 2 + (s / b) ** 2,
        k2_ = 2 * s * c * ((1 / (a ** 2)) - (1 / (b ** 2))),
        k3_ = (s / a) ** 2 + (c / b) ** 2;

    const [centerLng, centerLat] = [startLng + dx, startLat + dy];

    function distanceFromEllipseEdge(pt) {
        const [x, y] = toLngLat(pt),
            dx = x - centerLng,
            dy = y - centerLat;
        // Plug in equation for ellipse, If evaluates to < 0 then the point is in the ellipse.
        // For 0, it is on ellipse edge. For > 0 it is outside the ellipse.
        return (k1_ * (dx ** 2)) + (k2_ * dx * dy) + (k3_ * (dy ** 2)) - 1;
    }

    return {distanceFromEllipseEdge, centerLng, centerLat, c, s, startLng, startLat, tmp, km_per_deg, a_m, b_m, a, b, dx, dy, k1_, k2_, k3_};
}

function VolatileMarker({coordinate, route, editable, onDragging, onPointInsert, setVolatileCoordinate, track}) {
    const [markerIdx, setMarkerIdx] = useState(null),
        {coordinates} = track,
        {mapLibre} = useMapLibre();

    const mapLibreEvents = useMemo(() => ({
        mousemove: (evt) => {
            const {lngLat: {lng, lat}, originalEvent} = evt,
                currentPoint = point([lng, lat]),
                {buttons, originalTarget} = originalEvent ?? {};

            // 'jail-mouse-move' prevents TrackPolyline from handling mouse moves originating from map overlays (like elevation viewer).
            // Cannot use stopPropagation because TrackPolyline receives the event first.
            let jailMouseMove;
            try {  // Permission denied can happen if the originalTarget has been dismounted from DOM in the meanwhile.
                jailMouseMove = originalTarget?.closest('.jail-mouse-move');
            } catch (ex) { ; }
            if (!buttons && _.size(route) && _.size(coordinates) >= 2 && !jailMouseMove) {
                try {
                    const routePoints = featureCollection(_.map(route, (p) => point(toLngLat(p)))),
                        closestOnTrack = nearestVertex(lineString(toLngLatArray(coordinates)), currentPoint),
                        closestOnRoute = nearestPoint(currentPoint, routePoints),
                        dragMarkerOnRoute = closestOnTrack && nearestPoint(closestOnTrack, routePoints),
                        onExistingMarker = pixelDistanceOnMap(mapLibre, getCoord(closestOnRoute), getCoord(currentPoint)) < 10 || (
                            dragMarkerOnRoute && pixelDistanceOnMap(mapLibre, getCoord(closestOnTrack), getCoord(dragMarkerOnRoute)) < 10);
                    setVolatileCoordinate?.(closestOnTrack && !onExistingMarker ? toLatLng(getCoord(closestOnTrack)) : null);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }), [coordinates, mapLibre, route, setVolatileCoordinate]);
    useMapLibreEvents(mapLibreEvents);

    const volatileHandlers = useMemo(() => ({
        onDragging(_idx, latlng) {
            // Find index of nearest segment on the track.
            // Then find index of the route point in the routePoints mapping provided by the routing service.
            const {routePointIdx} = getClosestPointAndSegment(track, latlng);

            console.log('onVolatileMarkerDragging', routePointIdx, latlng);
            !_.isNil(routePointIdx) && setMarkerIdx(routePointIdx);
            onDragging(routePointIdx, latlng);
        },
        onPointMove(idx, latlng) {
            console.log('onVolatileMarkerDragged', idx, markerIdx, latlng);
            onPointInsert(markerIdx, latlng);
            setMarkerIdx(null);
        },
        onPointInsert
    }), [markerIdx, onDragging, onPointInsert, setMarkerIdx, track]);

    if (!coordinate) return null;

    return <CoordinateMarker draggable={editable} coordinate={coordinate} idx={markerIdx} size={MARKER_SIZE.VOLATILE}
                             {...volatileHandlers} style={{zIndex: ZINDEX.VOLATILE_MARKER}}>
        <VolatileStyledMarker className="coordinate-marker"/>
    </CoordinateMarker>;
}

function TrackPolyline({dragging, plannedDate, setElevationViewerSegment, setVolatileCoordinate, showWarnings, track, weatherOnRoute}) {
    const {coordinates, elevation, popularity, steepness, surface, times, uuid} = track;

    return <>
        <TrackSegments {...{coordinates, popularities: popularity, surfaces: surface}}/>
        <ErrorBoundary silent resetKeys={[uuid, times]}>
            <TrackMarkers {...{
                coordinates, dragging, elevation, plannedDate, setElevationViewerSegment,
                setVolatileCoordinate, showWarnings, steepness, times, uuid, weatherOnRoute
            }} />
        </ErrorBoundary>
    </>;
}

function preventIfBubbled(event) {
    const {originalEvent} = event;
    const isBubbled = _.includes(originalEvent?.target?.classList ?? [], 'maplibregl-canvas');
    if (isBubbled) {
        console.log(`skipping bubbled click`, event);
        originalEvent?.preventDefault?.();
        return false;
    }
    return true;
}

export const Track = memo(function Track({
    activeFetches, editable = true, onPointDelete, onPointInsert, onPointMove, plannedDate,
    route, routePOIs, setElevationViewerSegment, setVolatileCoordinate, showWarnings, track, volatileCoordinate, weatherOnRoute
}) {
    const [dragging, setDragging] = useState(false),
        forceUpdate = useForceUpdate(),
        [poi] = usePOIPopup(),
        isPoiPopup = !!poi?.feature,
        {mapLibre} = useMapLibre();
    const {coordinates, ferries, gates, tunnels} = track;

    const mapLibreEvents = useMemo(() => ({
        click: (event) => {
            if (!editable) return;
            const {lngLat: {lng, lat}, originalEvent} = event,
                // Ugly way to prevent drawing a point when a user clicked over climb_poi.
                // See also: https://github.com/mapbox/mapbox-gl-js/issues/9875 ,
                //           https://stackoverflow.com/questions/68286107/how-can-i-stop-a-map-click-event-from-firing-when-clicking-on-a-layer
                features = mapLibre?.queryRenderedFeatures?.(event.point, {layers: ['climb_poi']}),
                hasFeature = !!_.size(features);
            console.debug(`mapEvent.click: dragging=${dragging} isPoiPopup=${isPoiPopup} hasFeature=${hasFeature}`, features, event, originalEvent, originalEvent?.target?.classList);
            !dragging && !isPoiPopup && !hasFeature && onPointInsert(null, [lat, lng]);
        },
        dblclick: (event) => {
            if (!preventIfBubbled(event)) return false;
        }
    }), [dragging, editable, isPoiPopup, mapLibre, onPointInsert]);

    useMapLibreEvents(mapLibreEvents);

    const handlers = useMemo(() => {
        if (!editable)
            return {};

        // A click event from marker sometimes leaks to map event, in spite of stopPropagation.
        // This happens when the dragged marker lags after a user's quick mouse move button release.
        // https://gis.stackexchange.com/a/190062
        // That's why 'dragging' state is cleaned in timeout here.
        function stopDragging() {
            _.delay(() => {
                setDragging(false);
                forceUpdate();
            }, 10);
        }

        return {
            onDragging: () => { setDragging(true); },
            onPointInsert: (idx, latlng) => {
                onPointInsert(idx, latlng);
                stopDragging();
            },
            onPointMove: (idx, latlng) => {
                onPointMove(idx, latlng);
                stopDragging();
            }
        };
    }, [editable, onPointInsert, onPointMove, forceUpdate, setDragging]);

    return <>
        {coordinates && route && <>
            <TrackPolyline {...{dragging, plannedDate, setElevationViewerSegment, setVolatileCoordinate, showWarnings, track, weatherOnRoute}} />
            <VolatileMarker coordinate={volatileCoordinate} {...{editable, ...handlers, route, setVolatileCoordinate, track}}/>
        </>}
        <RoutePoints draggable={editable}
                     {...{activeFetches, dragging, onPointDelete, route, routePOIs, setVolatileCoordinate, ...handlers}}/>
        {showWarnings && <ErrorBoundary silent>
            <Gates gates={gates} coordinates={coordinates}/>
            <Tunnels markers={tunnels} coordinates={coordinates}/>
            <Ferries markers={ferries} coordinates={coordinates}/>
        </ErrorBoundary>}
    </>;
});

