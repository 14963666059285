import _ from 'lodash';
import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary';
import StackdriverErrorReporter from 'stackdriver-errors-js';


const enable = process.env.REACT_APP_ERROR_REPORTING === '1';

function localhostErrorReporter() {
    return {
        report: async (err) => {
            console.error('(Stub errorHandler)', err?.toString?.() ?? err)
        },
        start: () => null,
        setUser: () => null
    };
}

export const errorHandler = enable ? new StackdriverErrorReporter() : localhostErrorReporter();

export function startErrorHandler() {
    errorHandler.start({
        key: 'AIzaSyBxSxJs8w7ecUiX0Za9jDiXcTg_XNM8nyI',
        projectId: 'tarmacs-app',
        reportUncaughtExceptions: true,
        reportUnhandledPromiseRejections: true,
        version: process.env.REACT_APP_DEPLOY_VERSION
    });
}

export async function getDataOrThrow(resp) {
    async function getTextResponse() {
        try {
            return await resp?.text();
        } catch (e) {
            return `${e}`;
        }
    }
    let data,
        heading = 'Response status';
    try {
        // Cloning to allow fallback to text for diagnostics (avoid "body has been used")
        data = await resp.clone().json();
        if (resp.ok)
            return data;
    } catch (ex) {
        heading = `${ex}`;
    }
    const details = data?.message || data?.detail || await getTextResponse(),
        message = `${heading}: ${resp.status} ${resp.statusText} ${resp.url}: ${details}`;
    throw new Error(message);
}

const AT_PREFIX = '    at ';

function prodErrorMessageFormatter(error, componentStack) {
    const lines = _.filter(componentStack.split('\n')),
        components = _.map(lines, (line) => {
            const [comp, loc] = (line ?? '').split('@', 2),
                locStr = loc ? ` (${loc})` : '',
                atComp = _.startsWith(comp, AT_PREFIX) ? comp : `${AT_PREFIX}${comp || 'Anonymous'}`;
            return `${atComp}${locStr}`;
        }).join('\n');
    return (error?.message ?? '<missing>') + ':\n' + components + '\n    at ____ActualStackTraceBelow____';
}

function devErrorMessageFormatter(error, componentStack) {
    const lines = _.filter(componentStack.split('\n')),
        components = _.map(lines, (line) => {
            const [comp] = (line ?? '').split('@', 2);
            return comp || '<anonymous>';
        }).join(' > ');
    return (error?.message ?? '<missing>') + ':\n' + components;
}

function ErrorFallback({error}) {
    return <h1>Application error: {error?.message}. To fix, click "Refresh" in your browser toolbar to reload the page.</h1>;
}

function SilentErrorFallback() {
    return null;
}

export function reportErrorWithComponentStack(error, errorInfo) {
    const {componentStack} = (errorInfo ?? {});
    if (componentStack) {
        try {
            error.message = process.env.NODE_ENV === 'production' ? prodErrorMessageFormatter(error, componentStack) : devErrorMessageFormatter(error, componentStack);
        } catch (e) {
            console.error('Could not set error.message: ', e, componentStack);
        }
        console.log('Logged component context: ', errorHandler?.context?.reportLocation);
    } else
        errorHandler.context = _.omit(errorHandler.context ?? {}, 'reportLocation');
    errorHandler.report(error);
}

export function ErrorBoundary({silent, children, resetKeys}) {
    const fallback = silent ? SilentErrorFallback : ErrorFallback;
    return <ReactErrorBoundary FallbackComponent={fallback} onError={reportErrorWithComponentStack} resetKeys={resetKeys}>
        {children}
    </ReactErrorBoundary>;
}
