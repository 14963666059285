import {distance, point} from '@turf/turf';
import _ from 'lodash';
import {LngLat} from 'maplibre-gl';
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {MapProvider} from 'react-map-gl';
import {useCustomCompareEffect} from 'react-use';
import {useDebouncedCallback} from 'use-debounce';
import {useBoolean} from 'usehooks-ts';
import {useMapLibreEvents} from '../lib/useMapLibre';


const DEFAULT_CENTER = {latitude: 50.884842, longitude: 15.654144};
const defaultViewState = {
    bearing: 0,
    bounds: null,
    ...DEFAULT_CENTER,
    padding: {top: 0, bottom: 0, left: 0, right: 0},
    pitch: 0,
    zoom: 10
};

const ViewStateContext = createContext({
    ...defaultViewState,
    setViewState: _.noop,
    updateViewState: _.noop,
    resetCameraSource: _.noop
});

/**
 * Workaround for mapLibre.getBounds() not updated timely enough when viewState is updated.
 *
 *
 * This allows to calculate bounds reliably inside the setState callback
 * and avoid unnecessary refetch of useInspirations caused by distance param instability.
 */
function useMapLibreGetBounds(mapLibre) {
    return useCallback((viewState) => {
        const mapTransformer = mapLibre?.getMap?.()?.transform,
            transformer = mapTransformer?.clone?.(),
            {bearing, latitude, longitude, padding, pitch, zoom} = viewState;
        if (!transformer) {
            return undefined;
        }
        transformer.center = new LngLat(longitude, latitude);
        transformer.bearing = bearing;
        transformer.padding = padding;
        transformer.pitch = pitch;
        transformer.zoom = zoom;
        const bounds = transformer.getBounds();
        return bounds;
    }, [mapLibre]);
}

export const ViewStateProvider = ({children}) => {
    const [viewState, setViewState_] = useState(defaultViewState),
        // Special state to allow suppressing some expensive recalculation.
        {value: zooming, setTrue: zoomstart, setFalse: zoomend} = useBoolean(false),
        zoomEvents = useMemo(() => ({zoomstart, zoomend}), [zoomstart, zoomend]),
        {mapLibre} = useMapLibreEvents(zoomEvents),
        getBounds = useMapLibreGetBounds(mapLibre),
        setViewState = useCallback((updates) => {
            const updater = (typeof updates === 'function') ?
                prevState => {
                    const nextState = updates(prevState);
                    return (prevState === nextState) ? prevState : {...nextState, bounds: getBounds(nextState)};
                } : {...updates, bounds: getBounds(updates)};
            setViewState_(updater);
        }, [setViewState_, getBounds]),
        updateViewState = useCallback((updates) => setViewState(
            prevState => {
                // fetchInspirations is set by LocationEditBox only. InspirationsSelector recognize can fetch locations immediately (no need for debouncing on map move).
                const nextState = {..._.omit(prevState, 'inspirationsImmediate'), ..._.omitBy(updates, _.isNil)};
                return _.isEqual(prevState, nextState) ? prevState : nextState;
            }), [setViewState]),
        resetCameraSource = useCallback(() => {
            // 'current' instead of removing cameraSource to avoid flickering to geolocated position when clicking inspiration.
            setViewState(prev => prev?.cameraSource ? {...prev, cameraSource: 'current'} : prev);
        }, [setViewState]),
        contextValue = useMemo(() => ({...viewState, resetCameraSource, setViewState, updateViewState, zooming}),
            [resetCameraSource, setViewState, updateViewState, viewState, zooming]);

    // Necessary to calculate bounds for the initial viewState for "geolocation disabled" scenario.
    // Otherwise, would be null until user moves the map or clicks a route point.
    useEffect(() => {!viewState.bounds && setViewState(defaultViewState);}, [mapLibre, setViewState, viewState.bounds]);

    return (
        <ViewStateContext.Provider value={contextValue}>
            {children}
        </ViewStateContext.Provider>
    );
};

export function MapViewStateProvider({children}) {
    return <MapProvider>
        <ViewStateProvider>
            {children}
        </ViewStateProvider>
    </MapProvider>;

}

export function useViewState() {
    const context = useContext(ViewStateContext);
    if (!context) {
        throw new Error('useViewState must be used within a MapProvider');
    }
    return context;
}

/**
 * Convert bounds to the viewstate compatible with react-map-gl and ViewStateContext.
 * Insanely the react-map-gl view state has different format than maplibre camera.
 *
 * @returns {{bearing: number, latitude: number, longitude: number, pitch: number, zoom: number}}
 */
export function cameraForBounds(mapLibre, bounds, options) {
    const camera = mapLibre.cameraForBounds(bounds, options);
    if (!camera)
        return {};
    const {center, zoom, bearing, pitch} = camera,
        {lat: latitude, lng: longitude} = center;
    // noinspection JSValidateTypes
    return _.omitBy({latitude, longitude, zoom, bearing, pitch}, _.isNil);
}

const ANCHOR_DEBOUNCE_OPTIONS = {maxWait: 5000, leading: false, trailing: true};

function useDebouncedComparedState(initialValue, wait, debounceOptions) {
    const [state, setState] = useState(initialValue),
        setDebouncedState = useDebouncedCallback(setState, wait, debounceOptions),
        setComparedState = useCallback((next) => setDebouncedState((prev) => _.isEqual(prev, next) ? prev : next), [setDebouncedState]);
    return [state, setComparedState, setState];
}

function roundTo(num, precision) {
    return parseFloat(num.toFixed(precision));
}

// Skip comparing first argument, being the inspirationsImmediate flag. Prevents unnecessary re-fetching of inspirations.
function depsEqual([, ...prev], [, ...next]) {
    return _.isEqual(prev, next);
}

/**
 * Determine an anchor for displaying additional info, based on the current map view and/or route start, but avoiding temporary previewed locations.
 *
 * @param start - route start coordinates
 * @returns {{anchor, isMapIdle: boolean, mapLibre, previewingViewState, setViewState}}
 */
export function useAnchor({start}) {
    const [anchor, setAnchor, setAnchorNow] = useDebouncedComparedState({start}, 1500, ANCHOR_DEBOUNCE_OPTIONS);
    const {value: isMapIdle, setTrue: setMapIdle, setFalse: setMapBusy} = useBoolean(true);
    // noinspection JSUnusedGlobalSymbols
    const idleEvents = useMemo(() => ({
        idle: () => _.defer(setMapIdle), dataloading: () => _.defer(setMapBusy), load: () => _.defer(setMapIdle)
    }), [setMapIdle, setMapBusy]);
    const {mapLibre} = useMapLibreEvents(idleEvents);
    const viewState = useViewState();
    const {bounds, latitude, longitude, inspirationsImmediate, previewingViewState, setViewState} = viewState;

    useCustomCompareEffect(() => {
        if (previewingViewState || !isMapIdle) return;
        if (start) {
            setAnchorNow({start});
        } else if (bounds) {
            const [sw, ne] = bounds.toArray();
            if (sw && ne) {
                const distKm = distance(point(sw), point(ne), {units: 'kilometers'});
                const setAnchorFn = inspirationsImmediate ? setAnchorNow : setAnchor;
                setAnchorFn({start: [roundTo(latitude, 3), roundTo(longitude, 3)], distance: roundTo(distKm, 3)});
            }
        }
    }, [inspirationsImmediate, bounds, isMapIdle, latitude, longitude, previewingViewState, setAnchor, setAnchorNow, start], depsEqual);

    return {anchor, isMapIdle, mapLibre, previewingViewState, setViewState};
}