import classNames from 'classnames';
import {forwardRef} from 'react';
import {Accordion} from 'react-bootstrap';
import {useBootstrapPrefix} from 'react-bootstrap/ThemeProvider';


/**
 * Compact accordion header, with span-based "button" to avoid button-in-button react noise.
 */
export const CompactHeader = forwardRef(({as: Component = 'h2', buttonAs = 'div', bsPrefix, className, buttonClassName, children, onClick, ...props}, ref) => {
    bsPrefix = useBootstrapPrefix(bsPrefix, 'accordion-header');
    return <Component ref={ref} {...props} className={classNames(className, bsPrefix)}>
        <Accordion.Button as={buttonAs} role="button" onClick={onClick} className={classNames("py-0 py-lg-2", buttonClassName)}>
            {children}
        </Accordion.Button>
    </Component>;
});